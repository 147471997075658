import React, { Component } from "react";
import { Helmet } from "react-helmet";
import EmptyImg from "../Dashboard/assets/img/signupDone.gif";
import { Link } from "react-router-dom";
import LoginSignupMessage from "./siteComponents/LoginSignupMessage";
import logo from "../Dashboard/assets/img/logo.png";
import { HOST_URL } from "../Dashboard/api/Api";
class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errorMessage: "",
      successMessage: false,
      forgotScreen: true,
      bgColor: "alert alert-success alert-dismissible",
    };
  }

  forgotPassword() {
    this.setState({ successMessage: false });
    fetch(`${HOST_URL}/forgot_password.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp);
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            forgotScreen: false,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
        } else {
          this.setState({
            successMessage: true,
            forgotScreen: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Get register for free plan to Send SMS Email Voice Using one API -
            AuthKey.io
          </title>
          <meta
            name="description"
            content="Free plan for SMS voice Email API to send parallelly or use other channels as Fallback | High Volume APIs Built for Sending huge volume. Get free signup"
          />
        </Helmet>

        <section style={{ background: "#fff" }} className="loginbg">
          <div className="d-flex flex-column">
            <div className="row justify-content-center min-vh-100">
              <div className="col-md-6 col-lg-6 col-xl-6 ">
                <div className="login-new-box">
                  <form className="form-row col-md-8  pr-md-5 pr-sm-0">
                    <div className="form-group col-md-12">
                      {this.state.successMessage ? (
                        <div className={this.state.bgColor} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="">{this.state.errorMessage}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className=" form-group col-md-12">
                      <div className="">
                        {this.state.forgotScreen ? (
                          <>
                            <div className="mb-3">
                              <Link to="/">
                                <img
                                  className="img-fluid w-50 mb-3"
                                  alt="forget Password"
                                  src={logo}
                                />
                              </Link>
                              <h6 className="h3">Forgot Password</h6>
                              <p className="text-muted mb-0">
                                Enter Your Register Email to Send New Password
                              </p>
                            </div>

                            <div className="form-group">
                              <div className="form-group">
                                <input
                                  type="text"
                                  required
                                  name="username"
                                  onChange={(event) =>
                                    this.setState({ email: event.target.value })
                                  }
                                  className="form-control"
                                  placeholder="name@example.com"
                                />
                              </div>
                            </div>

                            <div className="d-flex mt-3 justify-content-between">
                              <p className="small">
                                <Link to="/signup?ref=signup">
                                  Don't have account ?
                                </Link>
                              </p>
                              <p className="Forgot">
                                <Link to="/login">Login </Link>
                              </p>
                            </div>

                            <div className="mt-2 d-flex justify-content-between">
                              <button
                                type="button"
                                onClick={() => {
                                  this.forgotPassword();
                                }}
                                className="home-button  btn-signup"
                              >
                                Send Password
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="text-center">
                            <div className="">
                              <img
                                alt="Empty Data"
                                style={{ width: "100px" }}
                                src={EmptyImg}
                              />
                              <p>
                                We have sent new Password on regiter email{" "}
                                <strong>{this.state.email}</strong>. Use that
                                Password to login into your account
                              </p>
                              <Link
                                className="btn btn-primary btn-sm"
                                to="login"
                              >
                                Go To Login
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <LoginSignupMessage />
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ForgotPassword;
