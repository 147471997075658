let userId, userToken, url;

if (localStorage.getItem("login") != null) {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  userId = lStorage.user.userId;
  userToken = lStorage.token;
  url = lStorage.url;
} else {
  // console.log('user Null');
}

export { userId, userToken, url };
