import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'


class RcsAgentList extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        agentList:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}

componentDidMount() {
    fetch(`https://googlercs.authkey.io/authkey/agents.php?userid=${this.state.user_id}&method=list&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            console.log(result)
            if(result.success===true){
            this.setState({agentList:result});
            }
        })
    })

}


render() {
    document.querySelector('body').scrollTo(0, 0);
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">

                 <div className="row mb-2 mb-xl-3">
								<div className="col-auto d-none d-sm-block">
									<h3><strong>RCS </strong> Agent List </h3>
								</div>

								<div className="col-auto ml-auto text-right mt-n1">
									<Link className="btn btn-primary btn-sm" to="/dashboard/add-agent"> <FontAwesomeIcon icon={faPlus} color='' /> Add New Agent </Link>
								</div>
							</div>


                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                    <div className="row">
                    
                    <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Agent List </h5>
                        </div>
                        <hr className="m-0" />
                        <div className="">
                        {

                            this.state.agentList?
                            
		            	<div className="table-responsive">


                            <table className="table table-hover  project  my-0">
                                <thead>
                                    <tr>
                                        <th>Agent Name</th>
                                        <th>Brand</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>

                                {
                                        this.state.agentList.data.map((item,i)=>
                                    <tr key={i}>
                                        <td>{item.name}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.created}</td>
                                        <td>
                                            {item.status===0?

                                            <span className="badge bg-danger"> Inactive </span>
                                            :

                                            <span className="badge bg-success">  Active </span>

                                        }
                                    
                                        </td>

                                        <td> <Link to={'/dashboard/add-agent/edit/'+item.id}> <span className="badge bg-info">  Edit </span> </Link> </td>

                                    </tr>)
                                }

                                    </tbody>
                            </table>
                            </div>

                            :<div className="text-center">
                            <div className="card-body">
                                <p>No Recod Found....</p>
                                </div>
                            </div> 
                            }

                           
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsAgentList;