import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

class CallerID extends Component {


    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">

                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto  d-sm-block">
                                    <h3><strong>Caller ID </strong> Setup </h3>
                                </div>

                                <div className="col-auto ml-auto text-right mt-n1">
                                    <button className="btn btn-success btn-sm" to="/dashboard/sms-template"> <FontAwesomeIcon icon={faPlus} color='' />  Add New Caller ID  </button>
                                </div>

                            </div>


                            <div className="">

                                <form className="form-row" id="createSernderID">

                                    <div className="mb-3 col-md-8">
                                        <input type="text" placeholder="Enter Caller ID" className="form-control" id="inputPasswordNew2" />
                                    </div>
                                    <div className="col-md-4">

                                        <button type="button" className="btn btn-block btn-sm btn-success">Update Sender ID</button>



                                    </div>


                                </form>
                            </div>

                            <div className="row">

                                <div className="col-md-12">





                                    <div className="card">
                                     
                                        <div className="">
                                            <div className="">
                                                <div className="flex-fill">

                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th className=" d-md-table-cell">Caller ID</th>
                                                                    <th className=" d-xl-table-cell">Updated Date / Time </th>
                                                                    <th className=" d-md-table-cell">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>132131</td>
                                                                    <td>2020-12-01 13:54:09</td>
                                                                    <td>
                                                                        <span className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                        <span className="badge bg-danger">
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>132131</td>
                                                                    <td>2020-12-01 13:54:09</td>
                                                                    <td>
                                                                        <span className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                        <span className="badge bg-danger">
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>132131</td>
                                                                    <td>2020-12-01 13:54:09</td>
                                                                    <td>
                                                                        <span className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                        <span className="badge bg-danger">
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>132131</td>
                                                                    <td>2020-12-01 13:54:09</td>
                                                                    <td>
                                                                        <span className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                        <span className="badge bg-danger">
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>132131</td>
                                                                    <td>2020-12-01 13:54:09</td>
                                                                    <td>
                                                                        <span className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                        <span className="badge bg-danger">
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                    </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>

                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default CallerID;