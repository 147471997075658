import React, { Component } from 'react';
import EmptyImg from "../../Dashboard/assets/img/signupDone.gif";
import { Link } from 'react-router-dom';
import DashboardFooter from '../../Footer/DashboardFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons' ;

class PaymentSuccess extends Component {
    render() {
        return (
        <div className="wrapper">
            <div className="main">
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row d-flex justify-content-center">
                            <div className="card col-md-6">
                                <div className="card-body">
                                <div className="row text-center">
                                <div className="text-center">
                                <div className="card-body">
                                    <img alt="Empty Data" style={{'width':'100px'}} src={EmptyImg} />
                                    <p className="text-success m-3">Thanks for making your payment transaction  successful <strong></strong>. </p>
                                    <Link className="btn btn-primary btn-sm" to="/dashboard/transaction-history">  <FontAwesomeIcon icon={faPrint} /> View / Print Transaction Details</Link>
                                    </div>
                                </div> 
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <DashboardFooter />
            </div>
        </div>
        );
    }
}
export default PaymentSuccess;