import React, { useEffect, useState } from "react";
import Spining from "../Spining/Spining";
import DataTable from 'react-data-table-component';
import { customTableStylesForSummery } from '../../Utils/customTableStyles';

function AdvanceWhatsappTable(props) {

    const TableCell = props.tableCell;

    const [TableData, setTableData] = useState()

    useEffect(() => {
        setTableData(props.tableData);
    }, [props.tableData])

    const filterData = (text) => {
        let data = TableData.filter((items) => {
            const regex = new RegExp(`${text}`, 'gi');
            if (regex == "/(?:)/gi") {
                return false
            } else {
                return items.email.match(regex)
            }
        })
        setTableData(data);
    }

    const reset = () => {
        setTableData(props.tableData);
    }

    return (
        <div className="row">

            {props.isLoading &&
                <Spining />
            }

            <div className="col-12 col-lg-12 col-xxl-12">

                <div className="card flex-fill  table-content-show">

                    <div className="d-flex align-items-center">
                        {props.tableName &&
                            <div className="card-body">
                                <h6 className="card-subtitle m-0 text-muted"> {props.tableName} </h6>
                            </div>
                        }

                    </div>


                    <div className="table-responsive">

                        <DataTable
                            columns={TableCell}
                            data={TableData || 0}
                            pagination={TableData ? true : false}
                            customStyles={customTableStylesForSummery}
                            highlightOnHover
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdvanceWhatsappTable;
