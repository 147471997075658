import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faInfoCircle,faEdit} from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip";
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';

class EmailTemplateList extends Component {
    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
			EmailTemplateList: null,
			user_id: userId,
            token: userToken,
			successShow:false,
			successMessage:"",
			bgColor:"alert alert-danger alert-dismissible"
			
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				// console.log(result)
				if(result.success===true){
					this.setState({EmailTemplateList:result});
				}
               
            })
        })
  
	}
	

	deleteProjectList(id){
		document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result.success);
				if(result.success === 'true' ){
					this.setState({successMessage:true});
					this.setState({errorMessage:result.message});
					this.setState({bgColor:"alert alert-success alert-dismissible"});
					window.location.reload(); 
					}else{
					this.setState({successMessage:true});	
					this.setState({errorMessage:result.message});	
					}
            })
        })


	}




render() {    
	document.querySelector('body').scrollTo(0, 0);
		return (

			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
				<div className="col-auto d-none d-sm-block">
					<h3><strong>Email Template </strong> List </h3>
				</div>

				<div className="col-auto ml-auto text-right mt-n1">
				<Link className="btn btn-primary btn-sm" to="/dashboard/email-template"> <FontAwesomeIcon icon={faPlus} color=''  />  Create New Email Template </Link>
				</div>
			</div>

			
			     {
                     this.state.successShow? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                }


			<div className="row justify-content-center">
			<div className="col-12 col-lg-12 col-xxl-12 d-flex">
			<div className="card flex-fill table-responsive">
			<div className="card-header">
			<h5 className="card-title mb-0">Email Template list</h5>
			</div>
			{
				this.state.EmailTemplateList?

			<table className="table table-hover  project  my-0">
				<thead>
					<tr>
					
						<th>MID</th>
						<th>Name</th>
						<th>Email Content</th>
						<th>Status</th>
						<th>Create Date</th>
						<th>Action</th>
						
					</tr>
				</thead>
				<tbody>

				{
						this.state.EmailTemplateList.data.map((itam,i)=>
					<tr key={i}>
						<td>{itam.id}</td>
						<td>{itam.template_name}</td>
						<td>
                             Message <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                            <ReactTooltip className="tooltip"  html={true}  id={"registerTip"+i} place="bottom" effect="solid">
											{itam.content}
                                            </ReactTooltip>
                                            </td>
						<td>{itam.is_active===1?
                            <span className="badge bg-success">Active</span>
                           :
                           <span className="badge bg-danger">Inactive</span>
                        }</td>
						<td>{itam.created}</td>
						
						<td>
							<Link to={'/dashboard/email-template/edit/'+itam.id}>
								<span className="badge bg-primary"> <FontAwesomeIcon icon={faEdit} /> </span> 
							</Link>
						</td>
					

					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
			<p>No Email Template Yet</p>
			<Link className="btn btn-success btn-sm" to="/dashboard/email-template">Create New Email Template</Link>
			</div>
		</div> 
}


	</div>
	</div>
	</div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default EmailTemplateList;