import React, { Component } from 'react';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import AdminLayout from '../UI/Layout/AdminLayout';
import AdvanceDataTable from '../UI/Table/AdvanceDataTable';
import { ADMIN_CONTACT_US } from '../UI/Table/ColumnConst';
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class ContactUsEnquiry extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        const AdminEmail = lStorage.user.username;
        const userPassword = lStorage.user.password;

        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);
        super();

        this.state = {
            user_id: userId,
            adminPassword: userPassword,
            userType: isAdmin,
            TodayDate: date,
            AdminEmail: AdminEmail,
            token: userToken,
            contactusData: '',
            serchData: '',
            CreatedDate: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }

    }

    componentDidMount() {
        this.onserch();
    }

    onserch() {

        const contactData = ({
            user_id: this.state.user_id,
            method: 'retrieve',
            type: this.state.userType,
            token: this.state.token
        });
        fetch(`${HOST_URL}/contact-us.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            }, body: JSON.stringify(contactData)
        }).then((result) => {
            console.log('result==>', result);
            result.json().then((resp) => {
                console.log(resp);
                if (resp.success === true) {
                    this.setState({ contactusData: resp.message });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: resp.message
                    });
                }
            })
        })
    }

    render() {

        return (
            <AdminLayout>
                <main>
                    <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                <h3><strong> Contact Us </strong> Enquiry </h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="">
                                <div className="pl-2 pr-2">
                                    {
                                        this.state.successMessage ?
                                            <div className={this.state.successBg} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div className="">
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                </div>

                                <AdvanceDataTable
                                    tableName={'Contact Us Enquire List'}
                                    tableCell={ADMIN_CONTACT_US}
                                    tableData={this.state.contactusData}
                                    isLoading={this.state.setIsLoading}
                                    isError={this.state.ErrorResponse}
                                />


                            </div>
                        </div>
                    </div>
                </main>
            </AdminLayout>

        );
    }
}
export default ContactUsEnquiry;