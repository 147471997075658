import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash,faEdit,faPlus} from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/loading.gif";
import { HOST_URL } from '../api/Api';

class EmailCampaningList extends Component {
    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
			EmailCampaningList: null,
			user_id: userId,
            token: userToken,
			successShow:false,
			successMessage:"",
			bgColor:''
			
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&method=retrievedata&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				// console.log(result)
				if(result.success===true){
					this.setState({EmailCampaningList:result});
				}
               
            })
        })
  
	}
	

	deleteEmailCampaningList(id){
		document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result.success);
				if(result.success === true ){
					this.setState({successShow:true});
					this.setState({errorMessage:result.message});
					this.setState({bgColor:"alert alert-success alert-dismissible"});
					setTimeout(function(){window.location.reload();}, 2000);
					 
					}else{
					this.setState({successMessage:true});	
					this.setState({errorMessage:result.message});	
					this.setState({bgColor:"alert alert-danger alert-dismissible"});
					}
            })
        })


	}




render() {    
	document.querySelector('body').scrollTo(0, 0);
		return (

			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
				<div className="col-auto d-none d-sm-block">
					<h3><strong>Email  </strong> Campaign </h3>
				</div>

				<div className="col-auto ml-auto text-right mt-n1">
				<Link className="btn btn-primary btn-sm" to="/dashboard/email-campaning"> <FontAwesomeIcon icon={faPlus} color=''  />  Create New Email Campanin </Link>
				</div>
			</div>

			
			     {
                     this.state.successShow? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                }


			<div className="row justify-content-center">
			<div className="col-12 col-lg-12 col-xxl-12 d-flex">
			<div className="card flex-fill table-responsive">
			<div className="card-header">
			<h5 className="card-title mb-0">Email Campaign list</h5>
			</div>
			{
				this.state.EmailCampaningList?

			<table className="table table-hover  project  my-0">
				<thead>
					<tr>
						<th>Name</th>
						<th>Campaign To</th>
                        <th>MID</th>
						<th>Trigger Date</th>
						<th>Trigger Time</th>
						<th>Action</th>
						
					</tr>
				</thead>
				<tbody>

				{
						this.state.EmailCampaningList.data.map((itam,i)=>
					<tr key={i}>
						<td>{itam.campaign_name}</td>
						<td>{itam.list_condition}</td>
						<td>{itam.mid}</td>
						<td>{itam.trigger_date}</td>
						<td>{itam.trigger_time}</td>
						
						<td>
							<Link to={'/dashboard/email-campaning/edit/'+itam.id}>
								<span className="badge bg-primary"> <FontAwesomeIcon icon={faEdit} /> </span> 
							</Link>
						
						<span onClick={()=> this.deleteEmailCampaningList(itam.id)} className="badge bg-danger ml-3"> <FontAwesomeIcon icon={faTrash} /> </span> 
						
						</td>

						
					

					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img alt="Empty Data" style={{width:100+'px'}} className="project-empty-img" src={EmptyImg} />
			<p>No Email Campaign Yet</p>
			<Link className="btn btn-success btn-sm" to="/dashboard/email-campaning">Create New Email Campaign</Link>
			</div>
		</div> 
}


	</div>
	</div>
	</div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default EmailCampaningList;