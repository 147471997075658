import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import imagercs from '../../Dashboard/assets/img/rcs.png';

class RcsApply extends Component {

    constructor () {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const userToken= lStorage.token;

    super();
        this.state = {
            user_id:userId,
            token:userToken,
            brandDescription:'',
            brandName:'',
            phone:'',
            website:'',
            email:'',
            largeImage:'',
            samllImage:'',
            privacyLink:'',
            termAndCond:'',
            testMobile:'',
            errorMessage:'',
            successMessage:false,
            bgColor:''
        }
    }

    SendSmallImage(e){
        if (e.target.files[0]) {
            this.setState({samllImage:e.target.files[0]})
        }
    }

    SendlargeImage(e){
        if (e.target.files[0]) {
            this.setState({largeImage:e.target.files[0]})
        }
    }

    RcsSendData(){

        document.querySelector('body').scrollTo(0, 0);

        const data = new FormData() 
        data.append('largeImage', this.state.largeImage)
        data.append('samllImage', this.state.samllImage)
        data.append('brandName', this.state.brandName)
        data.append('brandDescription', this.state.brandDescription)
        data.append('phone', this.state.phone)
        data.append('email', this.state.email)
        data.append('website', this.state.website)
        data.append('privacyLink', this.state.privacyLink)
        data.append('termAndCond', this.state.termAndCond)
        data.append('testMobile', this.state.testMobile)
        data.append('user_id', this.state.user_id)
        data.append('token', this.state.token)
        data.append('method','create')
  
        fetch('https://googlercs.authkey.io/api/manage_rcs.php',{
        method: "POST",
        body:data
        }).then((result)=>{
            result.json().then((resp)=>{
                console.log(resp)
        if(resp.success === true ){
            this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:resp.message});
            setTimeout(function(){window.location = "/dashboard/rich-media-template"}, 1000);
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:resp.message});
        }
            })
        })

    }




render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h3><strong> Apply   </strong> RCS </h3>
                        </div>
                    </div>

                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                    <div className="row">
                    
                    <div className="col-md-8">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Better carrier messaging for everyone</h5>
                        </div>
                        <hr className="m-0" />
                        <div className="card-body">
                      

                            <form id="newtiecktid">

                                <div className="row">

                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label"> Brand Name </label>
                                        <input type="text" placeholder="Full Name Of Brand" onChange={(event) => this.setState({ brandName: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>

                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label"> Brand Description </label>
                                        <textarea onChange={(event) => this.setState({brandDescription:event.target.value})} placeholder="Description should be 140 Character" className="form-control"></textarea>
                                    </div>

                                </div>


                                <div className="row mb-3">

                                    <div className="mb-3 col-md-4">
                                    <label className="col-form-label"> Phone Number </label>
                                        <input type="text" placeholder="Full Name Of Brand" onChange={(event) => this.setState({ phone: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>

                                    <div className="mb-3 col-md-4">
                                    <label className="col-form-label">  Website </label>
                                        <input type="text" placeholder="Website URL" onChange={(event) => this.setState({ website: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>


                                    <div className="mb-3 col-md-4">
                                    <label className="col-form-label">  Email </label>
                                        <input type="text" placeholder="Email Address" onChange={(event) => this.setState({ email: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>

                                </div>
           
                                <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9',borderBottom:'none'}} className="row">
                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Large Image</label>
                                      <input onChange={this.SendlargeImage} className="form-control" type="file"/>
                                      <small> Image Dimension : 1440 px * 448 px and Formate .jpg,.png
                                    </small>
                                    </div>
                                </div>


                                <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}} className="row">
                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Small Icons</label>
                                      <input onChange={this.SendSmallImage} className="form-control" type="file"/>
                                      <small> Image Dimension : 224 px * 224 px and Formate .jpg,.png
                                    </small>
                                    </div>
                                </div>

    

                                <div className="row mt-3">

                                    <div className="mb-3 col-md-6">
                                    <label className="col-form-label"> Privacy Policy Link </label>
                                        <input type="text" placeholder="Privacy Policy  URL" onChange={(event) => this.setState({ privacyLink: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>

                                    <div className="mb-3 col-md-6">
                                    <label className="col-form-label"> Term And Condition Link </label>
                                        <input type="text" placeholder="Term And Condition URL" onChange={(event) => this.setState({ termAndCond: event.target.value })} className="form-control" id="inputPasswordNew2"/>
                                    </div>
                                </div>


                                <div style={{background:'#f7f7f7',borderRadius:10}} className="row">
                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Test Message Mobile Number</label>
                                      <input className="form-control" onChange={(event)=>this.setState({testMobile:event.target.value})} placeholder="+91XXXXXXX, +91XXXXXXX, +91XXXXXXX" type="text"/>
                                      <small> Mobile Number Max 3. 
                                    </small>
                                    </div>
                                </div>
                             
                                <button type="button" onClick={()=> this.RcsSendData()} className="btn btn-sm mt-3 float-right btn-primary">Submit</button>

                            </form>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="card">
                            <p className="tooglebox">
                                <Link className="" data-toggle="collapse" to="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                RCS
                        </Link>
                            </p>
                            <div className="p-2">
                                <div className="collapse show" id="collapseExample">
                                    <div className="links-set">
                                    <img src={imagercs} className="img-fluid" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsApply;