import React, { Component } from 'react';
import AdminLayout from "../UI/Layout/AdminLayout";
import { changePassword } from '../Dashboard/api/Api';

class AdminChangePassword extends Component {

    constructor() {

        const lStorage =
        JSON.parse(localStorage.getItem("admin")) ||
        JSON.parse(localStorage.getItem("emp"))||
        JSON.parse(localStorage.getItem("reseller"));
      const userId = lStorage.user.userId;
      const userToken = lStorage.token;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            password: '',
            confirm_password: '',
            errorMessage: '',
            method: 'update',
            successMessage: false,
            bgColor: "alert alert-success alert-dismissible"
        }
    }

    SetNewPassword() {
        document.querySelector('body').scrollTo(0, 0);
        changePassword(this.state).then((resp) => {
            if (resp.data.success === true) {
                this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible" });
                this.setState({ errorMessage: resp.data.message });
            } else {
                this.setState({ successMessage: true });
                this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                this.setState({ errorMessage: resp.data.message });
            }
        })
    }
    render() {
        return (
            <AdminLayout>
                    < div className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Change </strong> Password </h3>
                                </div>
                            </div>
                            <div className="row">

                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Create Your New Password</h5>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className="mb-3">
                                                <input type="text" onChange={(event) => this.setState({ password: event.target.value })} placeholder="New Password" className="form-control" id="inputPasswordNew" />
                                            </div>
                                            <div className="mb-3">
                                                <input type="password" onChange={(event) => this.setState({ confirm_password: event.target.value })} placeholder="Re-enter New password" className="form-control" id="inputPasswordNew2" />
                                            </div>
                                            <button onClick={() => this.SetNewPassword()} type="button" className="btn btn-sm btn-success">Save changes</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </AdminLayout>
        );
    }
}

export default AdminChangePassword;