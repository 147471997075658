import React from 'react';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardHeader from '../../Header/DashboardHeader';


function DashboardLayout({ children }) {
    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                     {children}
                <DashboardFooter />
            </div>
        </div>
    );
}

export default DashboardLayout;