import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import Select from 'react-select';
import ChangedUserdRoute from '../Admin/ChangedUserdRoute'
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class ChangeRoute extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userdata: [],
            newuserlist: [],
            NewcountryList: [],
            selectedUser: null,
            routeList: '',
            SelectList: false,
            selectedChannel: '',
            seletedRoute: '',
            countryList: '',
            userType: isAdmin,
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
        this.getRought = this.getRought.bind(this);


    }



    componentDidMount() {
        this.alluserList();
        this.SearchRoute();
    }

    alluserList() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieveall&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                let userdata = [];
                if (result.success === true) {
                    for (var i = 0; i < result.data.length; i++) {
                        userdata.push({
                            label: result.data[i].username,
                            value: result.data[i].id
                        });
                    }
                    this.setState({
                        userdata: userdata
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    SearchRoute() {

        fetch(`${HOST_URL}/route.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ routeList: result });

                } else {
                    this.setState({
                        successMessage: true,
                        successbg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    getRought(e) {
        this.setState({ seletedRoute: e.target.value })
        const getCounrtyList = ({
            user_id: this.state.user_id,
            method: 'countrylist',
            type: this.state.userType,
            route_name: e.target.value,
            token: this.state.token
        })
        fetch(`${HOST_URL}/country_list.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(getCounrtyList)
        }).then((result) => {
            result.json().then((result) => {
                //console.log(result)
                let countryList = [];
                countryList.push({
                    label: 'All',
                    value: 0
                });
                if (result.success === true) {
                    for (var i = 0; i < result.data.length; i++) {
                        countryList.push({
                            label: result.data[i].cname,
                            value: result.data[i].country_code
                        });
                    }
                    this.setState({
                        countryList: countryList
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }



    SetChangeRoute() {
        const setRought = ({
            user_id: this.state.user_id,
            method: 'change',
            type: this.state.userType,
            users: this.state.newuserlist.toString(),
            route_name: this.state.seletedRoute,
            country_code: this.state.NewcountryList.toString(),
            channel: this.state.selectedChannel,
            token: this.state.token
        })

        // console.log(setRought)

        fetch(`${HOST_URL}/route_transfer.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(setRought)
        }).then((result) => {
            result.json().then((result) => {
                //console.log(result)
                if (result.success === true) {
                    this.setState({
                        countryList: result,
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    });
                    //setTimeout(function(){window.location = "/admin/change-user-route";}, 2000);
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })

    }


    handleChange = (selectedUser) => {

        var newuserlist = [];
        selectedUser.map((i) => {
            newuserlist.push(i.value)
        })
        this.setState({
            newuserlist: newuserlist
        })
        this.setState({ selectedUser }, () =>
            this.state.selectedUser
        );

    };


    handleCountry = (selectedcountry) => {

        var NewcountryList = [];
        selectedcountry.map((i) => {
            NewcountryList.push(i.value)
        })
        this.setState({
            NewcountryList: NewcountryList
        })

        this.setState({ selectedcountry }, () =>
            this.state.selectedcountry
        );

    };



    render() {

        const { selectedUser } = this.state;
        const { selectedcountry } = this.state;


        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Change User  </strong> Route  </h3>
                                </div>


                            </div>
                            <div className="row justify-content-center">
                                <div className="card col-md-12">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="">

                                                {
                                                    this.state.successMessage ?
                                                        <div className={this.state.bgColor} role="alert">
                                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                            <div className="">
                                                                {this.state.errorMessage}
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }

                                                <div className=" flex-fill">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="input-group col-md-12 mb-2">

                                                                <Select
                                                                    value={selectedUser}
                                                                    onChange={this.handleChange}
                                                                    options={this.state.userdata}
                                                                    placeholder={'Enter user Name'}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}

                                                                />


                                                            </div>

                                                            <div className="form-group drop-down-icons col-md-12">
                                                                <select onChange={(event) => this.setState({ selectedChannel: event.target.value })} className="form-control">
                                                                    <option value=''> Select Channel </option>
                                                                    <option value="sms"> SMS </option>
                                                                    <option value="voice"> Voice </option>
                                                                </select>
                                                            </div>

                                                            {
                                                                this.state.selectedChannel ?
                                                                    <div className="form-group drop-down-icons col-md-12">

                                                                        <select onChange={this.getRought} className="form-control ">
                                                                            <option>Select Route</option>
                                                                            {
                                                                                this.state.routeList.data.map((item, i) =>
                                                                                    <option key={i} value={item.id}>{item.route_name}</option>
                                                                                )}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                this.state.countryList ?

                                                                    <div className="form-group  col-md-12">

                                                                        <Select
                                                                            value={selectedcountry}
                                                                            onChange={this.handleCountry}
                                                                            options={this.state.countryList}
                                                                            placeholder={'Select Country'}
                                                                            isMulti
                                                                            closeMenuOnSelect={false}
                                                                        />

                                                                    </div>

                                                                    :

                                                                    null

                                                            }

                                                        </div>

                                                        <div className="float-right mt-1">
                                                            <input onClick={() => this.SetChangeRoute()} type="button" value="Change Route" className="btn btn-sm  btn-primary" />
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="">
                                            <ChangedUserdRoute all_list={this.state.routeList} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default ChangeRoute;