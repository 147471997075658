import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../api/Api';

class EmailContact extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            AllEmailStatus: '',
            errorMessage: '',
            successMessage: false,
            bgColor: ''
        }
    }
    componentDidMount() {
        this.GetEmailStatus();
    }

    GetEmailStatus() {
        fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log('result', result)
                if (result.success === true) {
                    this.setState({ AllEmailStatus: result.data });
                } else {
                    this.setState({ AllEmailStatus: true });
                }

            })
        })
    }


    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                <h3><strong>Email  </strong> Contact </h3>
                            </div>
                            <div className="col-auto ml-auto text-right mt-n1">
                            <Link className="btn btn-primary btn-sm" to="/dashboard/email-campaning"> <FontAwesomeIcon icon={faPlus} color=''  />  Create New Email Campanin </Link>
                            </div>
			        </div>
                            <div className="row">

                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                <div className="card">
                                    <div className="card-body">
                                         <h3 className="m-0">Summary</h3>
                                    </div>
                                  <hr className="m-0"/>
                                    <div className="">
                                   
                                        {
                                            this.state.AllEmailStatus ?

                                            <div className="row">

                                                {

                                                this.state.AllEmailStatus.map((itam,i)=>

                                                        <div className="col-md-3 text-center border-right">
                                                            <div className="card-body">
                                                              <h2 className="m-0"><strong>{itam.cnt}</strong>  </h2>
                                                              <hr className="mb-4"/>
                                                              <h4 className="text-muted font-weight-bold">{itam.status}</h4>
                                                            </div>
                                                              
                                                        </div>
                                                    )
                                                }

                                               </div>
												:

												null
									}
                             </div>
                      </div>
                  </div>
                </div>
        </main>
                        <DashboardFooter />
    </div>
                </div>
        );
    }
}

export default EmailContact;