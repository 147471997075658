import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import FallbackSetup from './FallbackSetup';
import { HOST_URL } from '../api/Api';


export default class componentName extends Component {

  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const token= lStorage.token;
    super();
    this.state = {
      userId: userId,
      token: token,
      projectId: '',
      event_name:'',
      NextStep:true,
      smsTemplateList: false,
      emailTemplateList: false,
      voiceTemplateList: false,
      setTime:'',
      errorMessage:'',
      successMessage:false,
      bgColor:"alert alert-success alert-dismissible",
      smsEventArray: [],
      voiceEventArray: [],
      emailEventArray: [],
      mainEventArray: [],
      eventResponscrArray:[],
      dynamicContentArray: []
    }
    this.getTemplateList = this.getTemplateList.bind(this);
    this.smsEventHandler = this.smsEventHandler.bind(this);
    this.voiceEventHandler = this.voiceEventHandler.bind(this);
    this.emailEventHandler = this.emailEventHandler.bind(this);
    this.eventStepTwo = this.eventStepTwo.bind(this);
    this.eventSubmit = this.eventSubmit.bind(this);
    this.timeHandler = this.timeHandler.bind(this);
}

  componentDidMount = () => {
    this.getTemplateList('sms');    
    this.getTemplateList('voice');  
    this.getTemplateList('email');   
    this.setState({projectId:this.props.match.params.id})    
};

getTemplateList(channel) {
   fetch(`${HOST_URL}/${channel}_template.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`).then((response)=> {
       response.json().then((result)=>{
           if(result.success===true){
              if (channel === 'sms') {
                this.setState({smsTemplateList:result.data});
              } else if (channel === 'voice') {
                this.setState({voiceTemplateList:result.data});
              } else if (channel === 'email') {
                this.setState({emailTemplateList:result.data});
              }               
           }
       })
   })
}

smsEventHandler = (channelKey,channelVal,templateKey, templateVal) => {
  this.setState({smsEventArray: {...this.state.smsEventArray, [channelKey]:channelVal,[templateKey]:templateVal.target.value}});
}

voiceEventHandler = (channelKey,channelVal,templateKey, templateVal) => {
  this.setState({voiceEventArray: {...this.state.voiceEventArray, [channelKey]:channelVal,[templateKey]:templateVal.target.value}});
};

emailEventHandler = (channelKey,channelVal,templateKey, templateVal) => {
  this.setState({emailEventArray: {...this.state.emailEventArray, [channelKey]:channelVal,[templateKey]:templateVal.target.value}});
};

eventStepTwo = () => {

  let mainEventArr = [];
  if (Object.keys(this.state.smsEventArray).length > 0) {
    mainEventArr.push(this.state.smsEventArray);
  }
  if (Object.keys(this.state.voiceEventArray).length > 0) {
    mainEventArr.push(this.state.voiceEventArray);
  }
  if (Object.keys(this.state.emailEventArray).length > 0) {
    mainEventArr.push(this.state.emailEventArray);
  }

  this.setState({mainEventArray: mainEventArr});
  const event = ({user_id:this.state.userId,project_id:this.state.projectId,event_name:this.state.event_name,fallback_reason:this.state.setTime,workflows:mainEventArr,method:'create',token:this.state.token,dynamic_content:''});
  //console.log(JSON.stringify(event));
  fetch(`${HOST_URL}/event.php`,{
        method: "post",
        headers:{
            'content-Type':'application/json'
         },
            body: JSON.stringify(event)
            }).then((result)=>{
            result.json().then((resp)=>{
           // console.warn('responce',resp);
            if(resp.success === true ){

                if(resp.message.length===0){
                  this.setState({dynamic_content:null});
                  this.eventSubmit();
                }else{
                  this.setState({eventResponscrArray:resp.message});
                  document.querySelector('body').scrollTo(0, 0);
                  this.setState({errorMessage:resp.message});
                  this.setState({NextStep:false});
                  for (var i = 0; i < this.state.eventResponscrArray.length; i++) {
                    let dynamicStr = this.state.eventResponscrArray[i];
                    this.setState({dynamicContentArray: {...this.state.dynamicContentArray, [dynamicStr]: 0}});
                }

              }
              }else{
                document.querySelector('body').scrollTo(0, 0);
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible"});
                this.setState({errorMessage:resp.message});
              }
           })
        })
};


eventSubmit = () => {
let event;

  if(this.state.dynamic_content===null){
     event = ({user_id:this.state.userId,project_id:this.state.projectId,event_name:this.state.event_name,workflows:this.state.mainEventArray,fallback_reason:this.state.setTime,method:'create',token:this.state.token,dynamic_content:'null'});

  }else{
     event = ({user_id:this.state.userId,project_id:this.state.projectId,event_name:this.state.event_name,workflows:this.state.mainEventArray,fallback_reason:this.state.setTime,method:'create',token:this.state.token,dynamic_content:this.state.dynamicContentArray});
  }
 

  fetch(`${HOST_URL}/event.php`,{
        method: "post",
        headers:{
            'content-Type':'application/json'
         },
            body: JSON.stringify(event)
            }).then((result)=>{
            result.json().then((resp)=>{
              if(resp.success === true){
                document.querySelector('body').scrollTo(0, 0);
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible"});
                this.setState({errorMessage:resp.message});
               // this.props.history.push('/dashboard/fallback-list/');
               setTimeout(function(){window.location = "/dashboard/fallback-list/";}, 2000);
              }else{
                alert(resp.message);
              }
              // alert(resp.message);
              
         })
        })
};

getCheckBoxValue = (e) => {
  const item = e.target.name;
  const isChecked = e.target.checked;
  if(isChecked){
    this.setState({dynamicContentArray: {...this.state.dynamicContentArray, [item]:1}});
  }else{
    this.setState({dynamicContentArray: {...this.state.dynamicContentArray, [item]:0}});
  }
};


timeHandler = (e) => {
        this.setState({
          setTime:parseInt(e.target.value)
        })     
}


  render() {
 // console.log(this.state)
    return (
      <> 
       <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    

                  {
                    this.state.smsTemplateList===false && this.state.emailTemplateList===false && this.state.voiceTemplateList===false?  <main className="content"> 
                    <div className="text-center">
                    <div className="card-body">
                      <img className="project-empty-img" src={EmptyImg} alt="Empty Project" />
                      <p>You don't have any template yet, Please create a first template of <Link to="/dashboard/sms-template-list"> SMS </Link>, <Link to="/dashboard/email-template-list"> Email </Link> or <Link to="/dashboard/voice-template-list"> Voice </Link> .</p>
                     
                      </div>
                    </div>
                     </main> :
                  

                    <main className="content">

                    <div className="row mb-2 mb-xl-3">
                      <div className="col-auto d-none d-sm-block">
                        <h3><strong>Create New </strong>  FallBack </h3>
                      </div>
                    </div>

                    <div className="row justify-content-center">
							<div className="col-md-8">
                 <div className="card">
                 <div className="card-body">
                 
                           {
                              this.state.successMessage? 
                              <div className={this.state.bgColor} role="alert">
                              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">×</span>
                              </button>
                              <div className="">
                                  {this.state.errorMessage}
                              </div>
                              </div>
                              :
                              null
                            }


                  {
                    this.state.NextStep?
                    <>
                    <input type="text" onChange={(event) => { this.setState({ event_name: event.target.value}) }} className="form-control mb-3" id=
                "inputEmail4" placeholder="Enter Event name(Example: For on registration)"/>

                      {this.state.smsTemplateList ?
                        <FallbackSetup 
                          type='sms' 
                          userId={this.state.userId} 
                          token={this.state.token}
                          smsList={this.state.smsTemplateList}
                          voiceList={this.state.voiceTemplateList}
                          emailList={this.state.emailTemplateList}
                          onChange={this.smsEventHandler}
                          onSelectTime={this.timeHandler}
                          />
                          : null }
                          {this.state.voiceTemplateList ?
                          <FallbackSetup 
                          type='voice' 
                          userId={this.state.userId} 
                          token={this.state.token}
                          smsList={this.state.smsTemplateList}
                          voiceList={this.state.voiceTemplateList}
                          emailList={this.state.emailTemplateList}
                          onChange={this.voiceEventHandler}
                          />
                          : null }
                          {this.state.emailTemplateList ?
                          <FallbackSetup 
                          type='email' 
                          userId={this.state.userId} 
                          token={this.state.token}
                          smsList={this.state.smsTemplateList}
                          voiceList={this.state.voiceTemplateList}
                          emailList={this.state.emailTemplateList}
                          onChange={this.emailEventHandler}
                          />
                          : null }

                          <button type="button" onClick={this.eventStepTwo} className="btn btn-block mt-3 btn-primary">Continue <FontAwesomeIcon icon={faArrowRight} /></button> 

                          </>
                  
                        :
                        <>
                        <p>Please Select Mandatory Fields  </p>
                        { this.state.eventResponscrArray.map((item) => {
                        return<div key={item} className="">
                        <label className="form-check form-check-inline">
                        <input className="form-check-input" value="0" name={item} onChange={this.getCheckBoxValue} type="checkbox"/>
                        <span className="form-check-label"> {item} </span>
                        </label>
                        </div>
                        ;
                        })
                      }
                        <button type="button" onClick={this.eventSubmit} className="btn btn-block mt-3 btn-primary">Create Event <FontAwesomeIcon icon={faArrowRight} /></button> 
                        </>
                      }
                      </div>
                      </div>
                      </div>
                      <div className="col-md-4">

                      <div className="card">
									<p className="tooglebox">
										<Link className="" data-toggle="collapse" to="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
											Useful Links
								</Link>
									</p>
									<div className="">
										<div className="collapse show" id="collapseExample">
											<div className="links-set">
												<ol className="mt-3">
												<li><Link to="/">How to Create Event</Link></li>
												<li><Link to="/">How to Create Choose Variable</Link></li>
												<li><Link to="/">How to Create Variable Required</Link></li>
												<li><Link to="/">What is Fall Back, How It Work?</Link></li>
												
												</ol>
											</div>
										</div>
									</div>
								</div>

                      </div>

                      </div>
                    </main>

                    }  {/* Check Template List Condistion */}
                    
                    


                    <DashboardFooter />
                </div>
            </div>
      </>
    );
  }
}
