import React, { useState } from 'react';


const LinkComponent = ({rank,onValueEnter,onValueEdit}) => {

    const [ editLink, seteditLink] = useState(true);
    const [ disiableLink, setdisiableLink] = useState('');
    const [ linkURL, setlinkURL] = useState('');
    const [ buttonText, setbuttonText] = useState('');

    const CreateLinkArray = () => {
        seteditLink(false)
        setdisiableLink('disabled')
        onValueEnter({action_type:1,action_content:buttonText,action_rank:rank,action_to:linkURL})
    }

    const EditButton = () => {
         onValueEdit(rank);
         seteditLink(true);
         setdisiableLink('');
    }


    return (
        <div style={{background:'#f0f0f0'}} className="row ml-0 mb-3 ">

            <div className="mb-3 col-md-5">
                <label className="col-form-label"> Link To Open </label>
                <input type="text" disabled={disiableLink} onChange={(e)=> setlinkURL(e.target.value)} placeholder="https://example.com" className="form-control" />
            </div>

            <div className="mb-3 col-md-5">
                <label className="col-form-label"> Button Text  </label>
                <input type="text" disabled={disiableLink} onChange={(e)=> setbuttonText(e.target.value)} placeholder="Visit Website" className="form-control" />
            </div>

            {editLink ?
                <div className="mb-3 col-md-2">
                  <button onClick={CreateLinkArray}  className="btn mt-5 btn-sm btn-primary">Save</button>
               </div>
            :
            <div className="mb-3 col-md-2">
             <button onClick={EditButton}  className="btn mt-5 btn-sm btn-primary">Edit</button>
            </div>
            }
          

           

        </div>
    )
}


const CalandarComponent = ({rank,onValueEnter}) => {

    const [ date, setDate] = useState('');
    const [ buttonText, setbuttonText] = useState('');
    const [ time, setTime] = useState('');
    const [ title, setTitle] = useState('');
    const [ event, setEvent] = useState('');

    const CreateCalandarArray = () => {
        onValueEnter({action_type:2,action_content:buttonText,action_rank:rank,action_to:{date:date,time:time,title:title,event:event}})
    }


    return (
            <div style={{background:'#f0f0f0'}} className="row ml-0 mb-3 ">
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Button Text </label>
                <input type="text" onChange={(e)=> setbuttonText(e.target.value)} placeholder="Add To Calandar" className="form-control" />
            </div>
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Date  </label>
                <input type="date"  onChange={(e)=> setDate(e.target.value)} placeholder="2021-08-15" className="form-control" />
            </div>
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Time  </label>
                <input type="time"  onChange={(e)=> setTime(e.target.value)} placeholder="10:30" className="form-control" />
            </div>
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Title </label>
                <input type="text"  onChange={(e)=> setTitle(e.target.value)} placeholder="Title of the event" className="form-control" />
            </div>
            <div className="mb-3 col-md-12">
                <label className="col-form-label"> Event </label>
                <textarea  onChange={(e)=> setEvent(e.target.value)} placeholder="Description of the event" className="form-control"></textarea>
            </div>
            <div className="mb-3 text-right col-md-12">
               <button onClick={CreateCalandarArray} className="btn btn-sm btn-primary">Save</button>
            </div>
        </div>
    )
}

const AppComponent = ({rank,onValueEnter}) => {

    const [ buttonText, setbuttonText] = useState('');
    const [ packagename, setpackagename] = useState('');
    const [ classname, setclassname] = useState('');
    const [ uri, setUri] = useState('');

    const CreateAppArray = () => {
        onValueEnter({action_type:3,action_content:buttonText,action_rank:rank,deep_links:[{action:"android.intent.action.MAIN",packageName:packagename,className:classname},{action:"android.intent.action.VIEW",packageName:"com.android.chrome",uriBase:'uri'},{action:"android.intent.action.VIEW",packageName:"com.android.browser",uriBase:'uri'}]})
    }

    return (
            <div style={{background:'#f0f0f0'}} className="row ml-0 mb-3 ">
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Button Text </label>
                <input type="text" onChange={(e)=> setbuttonText(e.target.value)} placeholder="Open App" className="form-control" />
            </div>
            <div className="mb-3 col-md-6">
                <label className="col-form-label"> Package Name  </label>
                <input type="text" onChange={(e)=> setpackagename(e.target.value)} placeholder="com.flipkart.android" className="form-control" />
            </div>
            <div className="mb-3 col-md-12">
                <label className="col-form-label"> Class Name  </label>
                <input type="text" onChange={(e)=> setclassname(e.target.value)} placeholder="com.flipkart.android.SplashActivity" className="form-control" />
            </div>
            <div className="mb-3 col-md-12">
                <label className="col-form-label"> URI Base </label>
                <input type="text" onChange={(e)=> setUri(e.target.value)} placeholder="https://play.google.com/store/apps/details?id=com.flipkart.android" className="form-control" />
            </div>
            <div className="mb-3 text-right col-md-12">
               <button  onClick={CreateAppArray} className="btn btn-sm btn-primary">Save</button>
            </div>
        </div>
    )
}

const CallComponent = ({rank,onValueEnter}) => {

    const [ phone, setPhone] = useState('');
    const [ buttonText, setbuttonText] = useState('');


    const CreateCallArray = () => {
        onValueEnter({action_type:5,action_content:buttonText,action_rank:rank,action_to:phone})
    }

    return (
            <div style={{background:'#f0f0f0'}} className="row ml-0 mb-3 ">
            <div className="mb-3 col-md-5">
                <label className="col-form-label"> Button Text </label>
                <input type="text"  onChange={(e)=> setbuttonText(e.target.value)} placeholder="Contact" className="form-control" />
            </div>
            <div className="mb-3 col-md-5">
                <label className="col-form-label"> Phone Number  </label>
                <input type="text"  onChange={(e)=> setPhone(e.target.value)} placeholder="+91XXXXXXXX" className="form-control" />
            </div>
            <div className="mb-3 col-md-2">
               <button onClick={CreateCallArray} className="btn mt-5 btn-sm btn-primary">Save</button>
            </div>
        </div>
    )
}


const ActionLinkManager = ({onValueEnter,onValueEdit}) => {

    const [actionType1, setactionType1] = useState('');
    const [actionType2, setactionType2] = useState('');
    const [actionType3, setactionType3] = useState('');


    const setUserActionType1 = (e) => {
        setactionType1(e.target.value);
    }

    const setUserActionType2 = (e) => {
        setactionType2(e.target.value);
    }

    const setUserActionType3 = (e) => {
        setactionType3(e.target.value);
    }

    // console.log('actionValue',actionValue);

    return (
        <div style={{ background: '#f0f8ff', border: '2px dotted #69b6f9' }} className="mt-3">
            <label className="col-form-label pl-3"> Create Action Buttons </label>
            <div className="row pl-3 pr-3">
                <div className="mb-3  col-md-12">
                    <select onChange={setUserActionType1} className="form-control">
                        <option>Button 1</option>
                        <option value="1">Open Link</option>
                        <option value="2">Add to Calandar</option>
                        <option value="3">Open APP</option>
                        <option value="5">Call Action </option>
                    </select>
                </div>
                {actionType1 === '1' && <LinkComponent onValueEdit={onValueEdit} onValueEnter={onValueEnter} rank={1} />}
                {actionType1 === '2' && <CalandarComponent onValueEnter={onValueEnter} rank={1} />}
                {actionType1 === '3' && <AppComponent onValueEnter={onValueEnter} rank={1} />}
                {actionType1 === '5' && <CallComponent onValueEnter={onValueEnter} rank={1} />}

            </div>
            <hr className="m-0 mb-3" />
            <div className="row pl-3 pr-3">
                <div className="mb-3  col-md-12">
                    <select onChange={setUserActionType2} className="form-control">
                        <option>Button 2</option>
                        <option value="1">Open Link</option>
                        <option value="2">Add to Calandar</option>
                        <option value="3">Open APP</option>
                        <option value="5">Call Action </option>
                    </select>
                </div>
                {actionType2 === '1' && <LinkComponent onValueEdit={onValueEdit} onValueEnter={onValueEnter} rank={2} />}
                {actionType2 === '2' && <CalandarComponent onValueEnter={onValueEnter} rank={2} />}
                {actionType2 === '3' && <AppComponent onValueEnter={onValueEnter} rank={2} />}
                {actionType2 === '5' && <CallComponent  onValueEnter={onValueEnter} rank={2} />}
            </div>
            <hr className="m-0 mb-3" />

            <div className="row pl-3 pr-3">
                <div className="form-comtrol ">
                    <div className="mb-3  col-md-12">
                        <select onChange={setUserActionType3} className="form-control">
                            <option>Button 3</option>
                            <option value="1">Open Link</option>
                            <option value="2">Add to Calandar</option>
                            <option value="3">Open APP</option>
                            <option value="5">Call Action </option>
                        </select>
                    </div>
                </div>
                {actionType3 === '1' && <LinkComponent onValueEdit={onValueEdit} onValueEnter={onValueEnter} rank={3}/>}
                {actionType3 === '2' && <CalandarComponent onValueEnter={onValueEnter} rank={3}/>}
                {actionType3 === '3' && <AppComponent onValueEnter={onValueEnter} rank={3} />}
                {actionType3 === '5' && <CallComponent onValueEnter={onValueEnter} rank={3}/>}
            </div>
        </div>
    )
}

export default ActionLinkManager;