import React, { Component } from 'react';
import AdminHeader from '../../Header/AdminHeader';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';
import Select from 'react-select';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';
class AssignPlan extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            selectedUser: null,
            selectUserID: '',
            planList: [],
            planDetails: [],
            selectedPlan: null,
            userdata: [],
            showPlan: '',
            successMessage: false,
            errorMessage: '',
            bgColor: "",
        }

    }

    componentDidMount() {
        this.alluserList();
        this.getPlanList();
    }

    alluserList() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieveall&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                let userdata = [];
                if (result.success === true) {
                    for (var i = 0; i < result.data.length; i++) {
                        userdata.push({
                            label: result.data[i].username,
                            value: result.data[i].id
                        });
                    }
                    this.setState({
                        userdata: userdata
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    handleChange = (selectedUser) => {

        var newuserlist = [];
        selectedUser.map((i) => {
            newuserlist.push(i.value)
        })

        this.setState({
            selectUserID: newuserlist[0]
        })

        this.setState({ selectedUser }, () =>
            this.state.selectedUser
        );

    };

    getPlanList() {
        fetch(`${HOST_URL}/plan.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ planList: result.data });

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    assignToUser() {
        fetch(`${HOST_URL}/assign_plan.php?user_id=${this.state.user_id}&method=assign&type=${this.state.userType}&token=${this.state.token}&plan_id=${this.state.selectedPlan}&assign_user_id=${this.state.selectUserID}`).then((response) => {
            response.json().then((result) => {
                document.querySelector('body').scrollTo(0, 0);
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    });

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    selectedPlanHandlart(e) {

        var plan_name = this.state.planList.filter(function (item) {
            return item.plan_name == e.target.value
        })
        this.setState({
            selectedPlan: plan_name[0].id,
            showPlan: true,
            planDetails: plan_name
        })
    }

    render() {
        const { selectedUser } = this.state;
        //console.log(this.state)
        return (
            <div className="wrapper blog-cover">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Assign </strong> Plan </h3>
                                </div>
                                {/* <div className="col-auto ml-auto text-right mt-n1"><a className="btn btn-primary btn-sm" href="/admin/add-blog"> Add New Blog </a></div> */}
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card table-content-show flex-fill">


                                        <div className="card-body">

                                            {
                                                this.state.successMessage ?
                                                    <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="">
                                                            {this.state.errorMessage}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="flex-fill">

                                                <form>
                                                    <div className="form-row">
                                                        <label className="form-label" htmlFor="inputCity">Select User </label>
                                                        <div className="input-group col-md-12 mb-2">
                                                            <Select
                                                                value={selectedUser}
                                                                onChange={this.handleChange}
                                                                options={this.state.userdata}
                                                                placeholder={'Enter user Name'}
                                                                isMulti
                                                                closeMenuOnSelect={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mb-2">
                                                        <label className="form-label" htmlFor="inputCity">Choose Plan</label>
                                                        <select onChange={(event) => this.selectedPlanHandlart(event)} className='form-control'>
                                                            <option value={null}>Select Plan</option>
                                                            {
                                                                this.state.planList.map((item) =>
                                                                    <option value={item.plan_name}>{item.plan_name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>


                                                    {
                                                        this.state.showPlan ?

                                                            <div className="table-responsive border mt-2">
                                                                <table className="table table-striped table-xs table-sm my-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Plan Name</th>
                                                                            <th>Allow Country Code</th>
                                                                            <th>Total Credit</th>
                                                                            <th>SMS Price</th>
                                                                            <th>Email Price</th>
                                                                            <th>Voice Price</th>
                                                                            <th>Voice Pulse</th>
                                                                            <th>Currency</th>
                                                                            <th>Created</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {
                                                                            this.state.planDetails.map((item, i) =>
                                                                                <tr>
                                                                                    <td>{item.plan_name} </td>
                                                                                    <td>{item.allow_other_country}</td>
                                                                                    <td>{item.total_credit}</td>
                                                                                    <td>{item.sms_price}</td>
                                                                                    <td>{item.email_price}</td>
                                                                                    <td>{item.voice_price}</td>
                                                                                    <td>{item.voice_pulse}</td>
                                                                                    <td>{item.currency}</td>
                                                                                    <td>{item.created}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            :
                                                            null
                                                    }

                                                    <div className="float-right mt-3">
                                                        <input onClick={() => this.assignToUser()} type="button" value="Assign Plan To User " className="btn btn-sm  btn-primary" />
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default AssignPlan;