import React from "react";
import { checkUserUrl } from "../Utils/Utils";
import { url } from "../Utils/userAuth";
const WhatsAppApiComponent = () => {
  return (
    <div className="api-docs">
      <p className=" mt-2 mb-0">
        Sending WhatsApp using {url} APIs is a breeze and lightning fast. Simply
        pass the message body, mobile number & {url} in the API request to send
        WhatsApp Message instantly.
      </p>

      <h3 className="mt-5">WhatsApp Templates: </h3>

      <p className=" mt-2 mb-0">
        You can also{" "}
        <a href="/dashboard/whatsapp-template-list">create templates</a> in the{" "}
        {url} portal and use the <strong>template ID (wid) </strong>in the API
        request. Templates are necessary in case of dynamic content present in
        the message. For example, in order to send an OTP message, you can
        create a template as below::
      </p>

      <p>
        {" "}
        Dear <strong> {`{#name#}`} </strong>, your otp is{" "}
        <strong> {`{#otp#}`} </strong> {url} automatically adds the dynamic
        values of the Template as API Parameters which can be used to change the
        content delivered to each recipient.
      </p>

      <p className=" mt-2 mb-0">
        Example usage for above template, assuming{" "}
        <strong> whatsaApp Template id (wid) </strong> generated as 101 is as
        follows.
      </p>

      <code>
        {`${checkUserUrl(url)
            ? "https://api.authkey.io/request"
            : `${url}//restapi/request.php`
          }`}
        ?authkey=<span className="paivariable">{'XXXXXX'}</span>&mobile=
        <span className="paivariable">RecepientMobile</span>&country_code=
        <span className="paivariable">CountryCode</span>&wid=
        <span className="paivariable">101</span>&name=
        <span className="paivariable">Twinkle</span>&otp=
        <span className="paivariable">1234</span>
      </code>
      <p>
        {" "}
        Here, <strong> 'name' </strong> and <strong>'otp'</strong> parameters
        are auto-added to the API and their values shall be replaced in the
        template body {`{...}`}
      </p>

      <h3 className="mt-5">Authkey Balance: </h3>
      <p>
        To fetch your authkey balance just pass your{" "}
        <strong> {`{#authkey#}`} </strong> as parameter, You can find{" "}
        <strong> {`{#authkey#}`} </strong> into your My Acount {`>`} Profile.
      </p>
      <code>
        {`${checkUserUrl(url)
            ? " https://console.authkey.io/restapi/getbalance.php?authkey="
            : `${url}/restapi/getbalance.php?authkey=`
          }`}
        <span className="paivariable">XXXXXX</span>
      </code>
    </div>
  );
};

export default WhatsAppApiComponent;
