import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { userloginDetails } from '../Utils/AdminAuth';
import Error from '../Dashboard/Error';
import { HOST_URL } from '../Dashboard/api/Api';
class EntityByPass extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const empType =lStorage.user.isAdmin;
        super();
        this.state = {
            user_id: userId,
            token: userToken,
            emp_type:empType,
            successMessage: false,
            userTemplateByPass: '',
            seletedUserId: '',
            errorMessage: '',
            bgColor: '',
        }
    }

    componentDidMount() {
        this.getUserDetails();
        this.setState({ seletedUserId: this.props.match.params.id });
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=retrieve_smstemp&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log('user',result);
                if (result.success === true) {
                    this.setState({ userTemplateByPass: result.data });
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    getUserDetails() {
        this.setState({ seletedUserId: this.props.match.params.id });
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log('user',result);
                if (result.success === true) {
                    this.setState({
                        email: result.data[0].email,
                    });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    bypass(id, bypass) {
        document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=bypass&template_id=${id}&current_status=${bypass}&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //console.log(result.success);
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                    window.location.reload();
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }




    render() {
        //  console.log(this.state);
        return (
            <>
            {this.state.emp_type==="reseller"?<Error/>:
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>User Details <strong className='text-info'> {this.state.email} </strong>  </h3>
                                </div>
                            </div>

                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className="row">
                                <div className="card">

                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to={'/admin/user-details/' + this.state.seletedUserId}><li className="breadcrumb-item">User Details </li> </Link>
                                                        <Link to={'/admin/user-transaction-details/' + this.state.seletedUserId}>  <li className="breadcrumb-item"> Transaction Details </li></Link>

                                                        <Link to={'/admin/user-price-details/' + this.state.seletedUserId}>
                                                            <li className="breadcrumb-item"> Price Details </li></Link>

                                                        <Link to={'/admin/user-add-price/' + this.state.seletedUserId}>  <li className="breadcrumb-item"> Add Credit </li></Link>

                                                        <Link to={'/admin/user-entity-pass/' + this.state.seletedUserId}>  <li className="breadcrumb-item active"> Entity By Pass   </li></Link>

                                                        <Link to={'/admin/change-currency/' + this.state.seletedUserId}>  <li className="breadcrumb-item "> Change Currency  </li></Link>

                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className="card flex-fill">

                                                    {this.state.userTemplateByPass ?
                                                        <div className="table-responsive">
                                                            <table className="table table-hover my-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="">Template Name</th>
                                                                        <th className="">Content</th>
                                                                        <th className="">Sender ID</th>
                                                                        <th className="">Created Date / Time</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        this.state.userTemplateByPass.map((item, i) =>
                                                                            <tr key={i}>

                                                                                <td>{item.template_name}</td>
                                                                                <td>{item.content}</td>
                                                                                <td>{item.senderid}</td>
                                                                                <td>{item.created}</td>
                                                                                <td>
                                                                                    {
                                                                                        item.bypass_entity === 1 ?
                                                                                            <span onClick={() => this.bypass(item.id, item.bypass_entity)} className="badge bg-success">  By Pass </span>
                                                                                            :
                                                                                            <span onClick={() => this.bypass(item.id, item.bypass_entity)} className="badge bg-danger"> Not By Pass    </span>
                                                                                    }

                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    }



                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div className="text-center">
                                                            <div className="card-body">
                                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                <p>No Any Transaction Details Found</p>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
    }</> );
    }
}

export default EntityByPass;