import React, { Component } from "react";
import DashboardHeader from "../../../Header/DashboardHeader";
import DashboardFooter from "../../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "react-bootstrap-tabs";

import {
    faBroadcastTower,
    faArrowCircleLeft,
    faAddressBook,
    faCheckCircle,
    faFlag,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../../UI/Alert/Alert";
import Spining from "../../../UI/Spining/Spining";
import { CAMPAIGN_CONTACT_LIST_API } from "../../../../constants/ServiceConstants";
import api from "../../../../axios/Axios";
import { Link } from "react-router-dom";
import WhatsAppPreview from "../../whatsapp/components/WhatsAppPreview";
import { no_data } from "../../../../images/WhatsAppImages";
import {
    campaignContactList,
    getSMSTemplateList,
    getCampaignSMSTemplateList,
    getUserPriceList,
    contactListAPI,
    createSMSCampaign,
    translateAPI,
} from "../../api/Api";
import { Helmet } from "react-helmet";
import UploadCSVFile from "../component/UploadCSVFile";
import SMSPreview from "../../sms/component/SMSPreview";

class SmsCampaignNew extends Component {
    constructor(props) {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        var today = new Date(),
            date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                ("0" + today.getDate()).slice(-2);
        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            contactList: "",
            step: 0, // TODO 0
            templatePreView: "",
            previewData: "",
            sampleData: "",
            WhatsappTemplateList: "",
            smsTemplateList: "",
            schedule_datetime: date,
            is_schedule: 0,
            parameter: [],
            mobile_colum: "",
            tableColume: [],
            countryList: "",
            varibaleInTemplate: "",
            CampaignName: "",
            channel: "sms",
            templteId: "",
            listId: "",
            is_flash: "",
            SMSmessage: [],
            country_code: 91,
            campaignType: "1",
            textnumbers: "",
            smsTemplateDetails: null,
            AllowLongMsg: false,
            selectedListName: "",
            totalCount: "",
            isLoading: false,
            errorMessage: "",
            isError: false,
            messageType: "",
            is_unicode: false,
            allow_Long_Msg: false,
            flash_message: false,
            language: "",
            numbersArray: [],
        };
        this.nextStep = this.nextStep.bind(this);
        this.findAndReplace = this.findAndReplace.bind(this);
        this.preViewSMSHandler = this.preViewSMSHandler.bind(this);
        this.getCsvData = this.getCsvData.bind(this);
        this.scheduleHandler = this.scheduleHandler.bind(this);
    }

    componentDidMount() {
        this.getContactList();
        this.getDLTSMSTemplateList();
        this.getcountryList();
    }

    getDLTSMSTemplateList = async () => {
        getSMSTemplateList().then((res) => {
            if (res.data.success === true) {
                this.setState({
                    smsTemplateList: res.data.data,
                });
            }
        });
    };

    getcountryList() {
        getUserPriceList().then((result) => {
            if (result.data.success === true) {
                this.setState({ countryList: result.data });
            } else {
                this.setState({
                    isError: true,
                    messageType: "alert alert-danger alert-dismissible",
                    errorMessage: result.data.message,
                });
            }
        });
    }

    isValid = () => {
        if (this.state.CampaignName === undefined || this.state.CampaignName === "") {
            this.setState({
                isError: true,
                messageType: "alert-danger",
                errorMessage: "Please Enter Campaign Name",
                isLoading: false,
            })
            return false;
        }
        return true
    }

    nextStep = async () => {
        if (this.isValid()) {
            this.setState({
                step: this.state.step + 1,
            });
        }
    };

    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    getContactList = async () => {
        contactListAPI("retrieve").then((res) => {
            if (res.data.success === true) {
                this.setState({
                    contactList: res.data.data,
                    isLoading: false,
                });
            } else {
                this.setState({ ErrorResponse: res.data, isLoading: false });
            }
        });
    };

    createCampaign = async () => {
        this.setState({ isLoading: true });
        let createSMSCampaignData = {
            token: this.state.token,
            user_id: this.state.user_id,
            channel: this.state.channel,
            template_id: this.state.templteId,
            list_id: this.state.listId,
            parameter: this.state.parameter,
            mobile_colum: this.state.mobile_colum,
            camp_name: this.state.CampaignName,
            total_count: this.state.totalCount,
            country_code: this.state.country_code,
            camp_type: this.state.campaignType,
            message: this.state.SMSmessage,
            textnumbers: this.state.textnumbers,
            entity_id: this.state.smsTemplateDetails[0].entity_id,
            template_id: this.state.smsTemplateDetails[0].template_id,
            sender_id: this.state.smsTemplateDetails[0].senderid,
            schedule_datetime: this.state.schedule_datetime,
            is_schedule: this.state.is_schedule,
            language: this.state.language,
            is_unicode: this.state.is_unicode === true ? 1 : 0,
            allow_Long_Msg: this.state.allow_Long_Msg === true ? 1 : 0,
            flash_message: this.state.flash_message === true ? 1 : 0,
            method: "create",
        };

        console.log("createSMSCampaignData", createSMSCampaignData);

        createSMSCampaign(createSMSCampaignData).then((res) => {
            console.log("res.data== SMS", res.data);
            if (res.data.success === true) {
                this.setState({
                    isError: true,
                    messageType: "alert-success",
                    errorMessage: res.data.message,
                    isLoading: false,
                });
                setTimeout(function () {
                  window.location = "/dashboard/campaign";
                }, 1000);
            } else {
                this.setState({
                    isError: true,
                    messageType: "alert-danger",
                    errorMessage: res.data.message,
                    isLoading: false,
                });
            }
        });
    };

    selectedSMSTemplate = async (e) => {
        this.setState({ templteId: e.target.value });
        getCampaignSMSTemplateList("retrieveid", e.target.value).then((res) => {
            if (res.data.success === true) {
                this.setState({
                    isError: false,
                    smsTemplateDetails: res.data.data,
                    SMSmessage: res.data.data[0].content,
                    templatePreView: res.data.data[0],
                });
                this.isUnicode(); // to check language
                // this.getVariable(res.data.data[0].content); // to get parameter
            } else {
                this.setState({
                    isError: true,
                    messageType: "alert-danger",
                    errorMessage: res.data.message,
                    isLoading: false,
                });
            }
        });
    };

    getVariable = (data) => {
        var regex = /{#([^#]+)#}/g;
        let m;
        var tempArr = ["mobile"];
        var parameter = { mobile: "" };
        while ((m = regex.exec(data)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            parameter[`${m[1]}`] = "";
            tempArr.push(m[1]);
        }
        this.setState({
            varibaleInTemplate: tempArr,
            parameter: [...this.state.parameter, parameter],
        });
    };

    setParameter = (e) => {
        this.state.parameter.map((item, i) => {
            Object.keys(item).map((key) => {
                if (key === e.target.name) {
                    let obj = (this.state.parameter[i][key] = e.target.value);
                    this.findAndReplace(e.target.name, obj);
                } else {
                    console.log("something went wrong");
                }
            });
        });
    };

    getColumedata = (id) => {
        campaignContactList(id).then((res) => {
            this.setState({ isLoading: true });
            try {
                if (res.data.success === true) {
                    this.setState({
                        tableColume: Object.keys(res.data.data[0]),
                        previewData: res.data.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            } catch (error) {
                console.log("error", error);
            }
        });
    };

    contactListhandler = (e) => {
        this.setState({ listId: e.target.value });
        var item = this.state.contactList.filter(
            (item) => item.id === parseInt(e.target.value)
        );
        this.setState({
            selectedListName: item[0].name,
            totalCount: item[0].number_count,
        });
        this.getColumedata(e.target.value);
        this.getSmapleData(e.target.value);
    };

    getSmapleData = async (id) => {
        try {
            const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
                token: this.state.token,
                user_id: this.state.user_id,
                list_id: id,
                method: "retrieve_data_sample",
            });
            if (data.success === true) {
                this.setState({ sampleData: data.data, setIsLoading: false });
            } else {
                this.setState({ ErrorResponse: data, setIsLoading: false });
            }
        } catch (err) {
            console.log(err);
        }
    };

    renderSampleData = () => {
        let data = this.state.sampleData;
        if (data.length > 0) {
            const column = Object.keys(data[0]);
            return (
                <>
                    <p>Your Table Data </p>
                    <table className="table table-hover  my-0">
                        <thead>
                            {column.map((data) => (
                                <th>{data}</th>
                            ))}
                        </thead>
                        {data.map((data) => (
                            <tr>
                                {column.map((v) => {
                                    return <td>{data[v]}</td>;
                                })}
                            </tr>
                        ))}
                    </table>
                </>
            );
        }
    };

    findAndReplace = (name, column) => {
        let text = this.state.templatePreView.bodyMessage;
        let replaceKeyword = `{{${name}}}`;
        let value = this.state.sampleData[0][column];
        let newtext = text.replace(replaceKeyword, value);
        let obj = (this.state.templatePreView["temp_body"] = newtext);
        this.setState({
            templatePreView: {
                ...this.state.templatePreView,
                ["bodyMessage"]: newtext,
            },
        });
    };

    getCsvData(data) {
        this.setState({
            sampleData: data.rowData,
            tableColume: data.tableColume,
        });
    }

    charaterCount = (text) => {
        var newstr = text.replace(/ /g, " ");
        var patt = new RegExp("[^\x00-\x7F]");
        var res = patt.test(newstr);
        var charLength = text.replace(/{|}|\[|\]|~|\^|\||\\/g, "  ");
        var outputString = parseInt(charLength.length);
        let obj = {
            total: "",
            credit: "",
        };
        if (res) {
            if (outputString < 70) {
                obj["total"] = outputString;
                obj["credit"] = 1;
            } else if (outputString / 70 == 1) {
                obj["total"] = outputString;
                obj["credit"] = Math.round(outputString / 70);
            } else {
                obj["total"] = outputString;
                obj["credit"] = Math.ceil(outputString / 67);
            }
        } else {
            if (outputString < 160) {
                obj["total"] = outputString;
                obj["credit"] = 1;
            } else if (outputString / 160 == 1) {
                obj["total"] = outputString;
                obj["credit"] = Math.round(outputString / 160);
            } else {
                obj["total"] = outputString;
                obj["credit"] = Math.ceil(outputString / 153);
            }
        }

        return (
            <p className="sms-count mb-0">
                <small>
                    Character Used <strong id="totalWords">{obj.total}</strong>
                </small>
                <small>
                    Credits
                    <strong> {obj.credit}</strong>
                </small>
            </p>
        );
    };

    preViewSMSHandler = async (e) => {
        const inputValue = e.target.value;
        let APIResponce = "";
        let lastWord = e.target.value.split(" ");
        if (this.state.is_unicode) {
            if (e.key === " " && inputValue.trim() !== "") {
                await translateAPI(
                    lastWord[lastWord.length - 1],
                    this.state.language
                ).then((result) => {
                    APIResponce = result.data + " ";
                });
            }
        }
        if (APIResponce === "") {
            this.setState({
                SMSmessage: e.target.value,
            });
        } else {
            if (inputValue !== null) {
                var lastIndex = inputValue.lastIndexOf(lastWord[lastWord.length - 1]);
                let originalMessage = inputValue.substring(0, lastIndex);
                this.setState({
                    SMSmessage: `${originalMessage}${APIResponce}`,
                });
            }
        }

        this.charaterCount(inputValue);
    };

    scheduleHandler(e) {
        if (e.target.checked === true) {
            this.setState({ is_schedule: 1 });
        } else {
            this.setState({ is_schedule: 0 });
        }
    }

    isUnicode() {
        var message = this.state.SMSmessage;
        var newstr = message.replace(/ /g, " ");
        var patt = new RegExp("[^\x00-\x7F]");
        var res = patt.test(newstr);
        if (res) {
            this.setState({
                is_unicode: true,
            });
        } else {
            this.setState({
                is_unicode: false,
            });
        }
        return res;
    }

    onNameChange = (e) =>{
            this.setState({ CampaignName: e.target.value })
            this.setState({isError: false})
        
    }

    onChange = (e) => {
        this.setState({ textnumbers: e.target.value })
        this.newArray(e.target.value)
    }

    newArray = (data) => {
        const numbers = data.split('\n').map(num => num.trim());
        this.setState({ numbersArray: numbers });
    }

    formRender = () => {
        console.log(this.state.textnumbers)
        console.log(this.state.is_unicode , this.state.is_flash)
        switch (this.state.step) {
            case 1:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="d-block">
                                        Choose Audiance to send the campaign on
                                        <span className="text-primary"> {this.state.channel} </span>
                                    </p>
                                    <div className="">
                                        <div>
                                            <Tabs id="fall-back-tab">
                                                {/* <Tab label="Select From Contact List">
                                                    {this.state.contactList ? (
                                                        <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                                                            <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faAddressBook} />
                                                            </div>
                                                            <select
                                                                onChange={this.contactListhandler}
                                                                className="form-control"
                                                                value={this.state.listId}
                                                            >
                                                                <option value={``}>Select Contact List</option>
                                                                {this.state.contactList.map((item, index) => (
                                                                    <option value={item.id} key={index}>
                                                                        {item.name} ({item.number_count})
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    ) : (
                                                        <p className="alert alert-danger d-block">
                                                            You don't have any contacts please create{" "}
                                                            <Link to="/dashboard/contact-list">Contacts</Link>{" "}
                                                            and then come back.
                                                        </p>
                                                    )}
                                                </Tab> */}
                                                <Tab label="Copy Paste Data">
                                                    <div className="input-group mb-2 mt-4 mr-sm-2">
                                                        <textarea
                                                            placeholder="Copy and paste mobile number with new line without country code"
                                                            onChange={this.onChange}
                                                            defaultValue={this.state.textnumbers}
                                                            className="form-control"
                                                            rows={15}
                                                        ></textarea>
                                                    </div>
                                                    <span style={{ float: "right" }}>count: {this.state.numbersArray.length}</span>
                                                </Tab>
                                                {/* <Tab label="Upload .csv File">
                                                    <UploadCSVFile sendCsvData={this.getCsvData} />
                                                </Tab> */}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="filter-option">
                            <div className="row">
                                <div className="col-md-7">
                                    <p className="d-block mt-4">
                                        Select{" "}
                                        <span className="text-primary">{this.state.channel}</span>{" "}
                                        template to campaign
                                    </p>

                                    {this.state.smsTemplateList ? (
                                        <>
                                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                                <div className="input-group-text w-25">
                                                    <FontAwesomeIcon
                                                        icon={faAddressBook}
                                                        className="mr-1"
                                                    />{" "}
                                                    Template
                                                </div>

                                                <select
                                                    onChange={this.selectedSMSTemplate}
                                                    className="form-control"
                                                >
                                                    <option value={``}>Select Template</option>
                                                    {this.state.smsTemplateList.map((item, index) => (
                                                        <option key={index} value={item.id}>
                                                            {item.template_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {this.state.smsTemplateDetails && (
                                                <>
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text w-25">
                                                            <FontAwesomeIcon
                                                                icon={faAddressBook}
                                                                className="mr-1"
                                                            />{" "}
                                                            Entity ID
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Entity ID"
                                                            disabled
                                                            value={this.state.smsTemplateDetails[0].entity_id}
                                                            className="form-control"
                                                        />
                                                    </div>

                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text w-25">
                                                            <FontAwesomeIcon
                                                                icon={faAddressBook}
                                                                className="mr-1"
                                                            />{" "}
                                                            Sender ID
                                                        </div>
                                                        <input
                                                            type="text"
                                                            placeholder="Sender ID"
                                                            className="form-control"
                                                            disabled
                                                            value={this.state.smsTemplateDetails[0].senderid}
                                                        />
                                                    </div>

                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text w-25">
                                                            <FontAwesomeIcon
                                                                icon={faAddressBook}
                                                                className="mr-1"
                                                            />{" "}
                                                            Template ID
                                                        </div>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            placeholder="Template ID"
                                                            className="form-control"
                                                            value={
                                                                this.state.smsTemplateDetails[0].template_id
                                                            }
                                                        />
                                                    </div>

                                                    <textarea
                                                        onChange={this.preViewSMSHandler}
                                                        rows={5}
                                                        disabled={
                                                            this.state.is_unicode &&
                                                                this.state.language === ""
                                                                ? true
                                                                : false
                                                        }
                                                        value={this.state.SMSmessage}
                                                        className="form-control mt-3"
                                                        onKeyDown={this.preViewSMSHandler} // Use onKeyDown instead of onKeyPress
                                                    ></textarea>

                                                    {this.charaterCount(this.state.SMSmessage)}

                                                    <div className="d-flex mb-3">
                                                        <small className="ml-4 mt-2 d-flex align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                value={this.state.is_unicode}
                                                                checked={this.state.is_unicode || false}
                                                                className="form-check-input mb-1 mr-2"
                                                                id="fallback"
                                                                onChange={() =>
                                                                    this.setState({
                                                                        is_unicode: !this.state.is_unicode,
                                                                    })
                                                                }
                                                            />
                                                            <span> Enable Unicode </span>
                                                        </small>
                                                        <small className="ml-4 mt-2 d-flex align-items-center">
                                                            <input
                                                                value="1"
                                                                type="checkbox"
                                                                checked={
                                                                    this.state.SMSmessage.length > 160
                                                                        ? true
                                                                        : false
                                                                }
                                                                className="form-check-input mb-1 mr-2"
                                                                id="fallback"
                                                            />
                                                            <span> Allow Long Msg </span>
                                                        </small>
                                                        <small className="ml-4 mt-2 d-flex align-items-center">
                                                            <input
                                                                value="1"
                                                                type="checkbox"
                                                                className="form-check-input mb-1 mr-2"
                                                                id="fallback"
                                                                onChange={(e) => this.setState({ is_flash: 1 })}
                                                            />
                                                            <span> Flash Message </span>
                                                        </small>
                                                    </div>
                                                    {this.state.is_unicode && (
                                                        <>
                                                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                                                <div className="input-group-text w-25">
                                                                    <FontAwesomeIcon
                                                                        icon={faAddressBook}
                                                                        className="mr-1"
                                                                    />{" "}
                                                                    Language
                                                                </div>

                                                                <select
                                                                    onClick={(e) =>
                                                                        this.setState({ language: e.target.value })
                                                                    }
                                                                    className="form-control"
                                                                >
                                                                    <option value={``}>Select Language</option>
                                                                    <option value="hi-t-i0-und">Hindi</option>
                                                                    <option value="am-t-i0-und">Amharic</option>
                                                                    <option value="ar-t-i0-und">Arabic</option>
                                                                    <option value="bn-t-i0-und">Bengali</option>
                                                                    <option value="el-t-i0-und">Greek</option>
                                                                    <option value="gu-t-i0-und">Gujarati</option>
                                                                    <option value="kn-t-i0-und">Kannada</option>
                                                                    <option value="ml-t-i0-und">Malayalam</option>
                                                                    <option value="mr-t-i0-und">Marathi</option>
                                                                    <option value="ne-t-i0-und">Nepali</option>
                                                                    <option value="or-t-i0-und">Oriya</option>
                                                                    <option value="fa-t-i0-und">Persian</option>
                                                                    <option value="pu-t-i0-und">Punjabi</option>
                                                                    <option value="ru-t-i0-und">Russian</option>
                                                                    <option value="sa-t-i0-und">Sanskrit</option>
                                                                    <option value="sr-t-i0-und">Serbian</option>
                                                                    <option value="si-t-i0-und">Sinhalese</option>
                                                                    <option value="ta-t-i0-und">Tamil</option>
                                                                    <option value="te-t-i0-und">Telugu</option>
                                                                    <option value="th-t-i0-und">Thai</option>
                                                                    <option value="ti-t-i0-und">Tigrinya</option>
                                                                    <option value="ur-t-i0-und">Urdu</option>
                                                                    <option value="yue-hant-t-i0-und">
                                                                        Chinese
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <small className="text-danger">
                                                                {this.state.is_unicode &&
                                                                    this.state.language === ""
                                                                    ? "Please select Language"
                                                                    : null}
                                                            </small>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <p className="alert alert-danger d-block">
                                            You don't have any template please create{" "}
                                            <Link to="/dashboard/whatsapp-template-list">
                                                Template
                                            </Link>{" "}
                                            and then come back.
                                        </p>
                                    )}

                                    {this.state.varibaleInTemplate && (
                                        <>
                                            <p>Please select column </p>
                                            {this.state.varibaleInTemplate.map((item, index) => (
                                                <div className="input-group mb-2 mr-sm-2">
                                                    <div className="input-group-text">{item}</div>
                                                    <select
                                                        name={item}
                                                        onChange={this.setParameter}
                                                        className="form-control"
                                                    >
                                                        {this.state.tableColume.map((item, index) => (
                                                            <option name={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    <hr />
                                    {this.state.channel === "whatsapp" && (
                                        <div className="card p-2">
                                            <div className="table-responsive">
                                                {this.renderSampleData()}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-5">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> Template Preview </h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="card-body">
                                            {this.state.templatePreView ? (
                                                <>
                                                    {this.state.channel === "sms" ? (
                                                        <SMSPreview
                                                            previewData={this.state.templatePreView}
                                                            smsContent={this.state.SMSmessage}
                                                        />
                                                    ) : (
                                                        <WhatsAppPreview
                                                            previewData={this.state.templatePreView}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <div className="text-center">
                                                    <img className="img-fluid" src={no_data} />
                                                    <p className="text-right">
                                                        Please select {this.state.channel} Templte to
                                                        preview
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );

            case 3:
                return (
                    <>
                        <div className="pb-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="campaign-preview sms-campaign-review ">
                                        <div className="hr">Campaign Review </div>
                                        <hr className="mt-2" />
                                        <p className="m-0">
                                            <span className="camp-name">Campaign Name: </span>
                                            <span className="">{this.state.CampaignName}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Channel Selected : </span>
                                            <span className="">{this.state.channel}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Total Number : </span>
                                            <span className="">{this.state.numbersArray.length}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Campaign Date : </span>
                                            <span className="">{this.state.schedule_datetime}</span>
                                        </p>

                                        <p className="m-0">
                                            <span className="camp-name"> Campaign Template : </span>
                                            <span className="">
                                                Test Campaign
                                                {this.state.channel === "sms"
                                                    ? this.state.templatePreView.template_name
                                                    : this.state.templatePreView.tampleName}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mt-3 ml-2">
                                <div className="form-check ">
                                    <p className="m-0 d-flex items-align-center">
                                        <input
                                            className="form-check-input mb-1"
                                            defaultValue={this.state.is_schedule}
                                            onChange={this.scheduleHandler}
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                        />
                                        <strong> Campaign Schedule </strong>
                                    </p>
                                </div>
                                <small>
                                    {" "}
                                    Campaign scheduling is the process of specifying the start and
                                    end dates and times for a campaign.
                                </small>
                            </div>
                            {this.state.is_schedule === 1 && (
                                <>
                                    <p className="mt-3 d-block">
                                        <strong> Select Date and Time of Campaign </strong>
                                    </p>
                                    <div className="input-group w-25 mb-2 mr-sm-2">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faAddressBook} />
                                        </div>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            onChange={(e) =>
                                                this.setState({ schedule_datetime: e.target.value })
                                            }
                                            placeholder="Example: campaign for digitl marketing"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                );

            default:
                return (
                    <div className="filter-option">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <p className="mt-3 d-block">Campaign Channel </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        onChange={(e) => this.setState({ channel: e.target.value })}
                                        className="form-control"
                                        disabled
                                        value={this.state.channel}
                                    >
                                        <option value={``}>Select Channel</option>
                                        <option value={`sms`}>SMS</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Country </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faFlag} />
                                    </div>
                                    <select
                                        onChange={(e) =>
                                            this.setState({ country_code: e.target.value })
                                        }
                                        className="form-control"
                                        value={this.state.country_code}
                                    >
                                        <option value="91">India</option>
                                        {/* {this.state.countryList &&
                      this.state.countryList.data.map((item, i) => (
                        <option key={i} value={item.country_code}>
                          {item.country_name === "India" ? item.country_name: null}
                        </option>
                      ))} */}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <p className="mt-3 d-block">Select Campaign Type </p>
                                <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faBroadcastTower} />
                                    </div>
                                    <select
                                        value={this.state.campaignType}
                                        onChange={(e) =>
                                            this.setState({ campaignType: e.target.value })
                                        }
                                        className="form-control"
                                    >
                                        <option value="1"> Normal Campaign </option>
                                        {/* <option value="3"> Custom (Dynamic) campaign</option> */}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <p className="mt-3 d-block">Enter Custom Campaign Name</p>
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faAddressBook} />
                                    </div>
                                    <input
                                        type="text"
                                        defaultValue={this.state.CampaignName}
                                        className="form-control"
                                        onChange={this.onNameChange}
                                        // onChange={(e) =>
                                        //     this.setState({ CampaignName: this.state.CampaignName.length < 100 ? e.target.value : null })
                                        // }
                                        placeholder="Example: campaign for digitl marketing"
                                    />
                                </div>
                                <small className="text-primary">
                                    Example : Campaign for remarketing
                                </small>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    render() {
        // console.log("this.state", this.state);
        return (
            <div className="wrapper">
                <Helmet>
                    <style type="text/css">{`
         .whatsapp ul {
            display:none!important;
          }
        `}</style>
                </Helmet>

                {this.state.isLoading && <Spining />}
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3>
                                        <strong>SMS Campaign </strong>
                                    </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="msform">
                                            <ul id="progressbar">
                                                {this.state.step === 0 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li>Choose Audiance </li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 1 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance</li>
                                                        <li>Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 2 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li> Preview Campaign </li>
                                                    </>
                                                ) : this.state.step === 3 ? (
                                                    <>
                                                        <li className="active">Campaign Details</li>
                                                        <li className="active">Choose Audiance </li>
                                                        <li className="active">Campaign Template </li>
                                                        <li className="active"> Preview Campaign </li>
                                                    </>
                                                ) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isError ? (
                                    <Alert type={this.state.messageType}>
                                        {this.state.errorMessage}
                                    </Alert>
                                ): null}

                                <div className="card">
                                    <div className="card-body campaign-box">
                                        {this.formRender()}

                                        <hr />

                                        <div className="row justify-content-center">
                                            <div className="col-md-3">
                                                <button
                                                    disabled={this.state.step === 0}
                                                    onClick={() => this.prevStep()}
                                                    className={
                                                        this.state.step === 0
                                                            ? "btn btn-block btn-secondary"
                                                            : "btn btn-block btn-primary"
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faArrowCircleLeft} /> Previous
                                                </button>
                                            </div>
                                            <div className="col-md-3">
                                                {this.state.step === 3 ? (
                                                    <button
                                                        onClick={this.createCampaign}
                                                        className="btn btn-info"
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle} /> Start
                                                        Campaign
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => this.nextStep()}
                                                        className="btn btn-block btn-primary"
                                                    >
                                                        Next <FontAwesomeIcon icon={faArrowRight} />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default SmsCampaignNew;
