import React, { useEffect, useState } from "react";
import DashboardHeader from "../Header/DashboardHeader";
import DashboardFooter from "../Footer/DashboardFooter";
import DashboardLeftMenu from "../Dashboard/DashboardLeftMenu";
import Home from "../Dashboard/Home";
import Sandbox from "../Sandbox/Sandbox";
// import { messaging } from "../../firebase";
// import { getToken } from "firebase/messaging";
import api from "../../axios/Axios";
function Dashboard() {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const [sandbox, setSandbox] = useState(false);

  // useEffect(() => {
  //   requestPermission();
  // });

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     const fcmtoken = await getToken(messaging, {
  //       vapidKey:
  //         "BEhln3rQmB-b2irv__7ZkHknDX1kSJENc2cHGEfGmRs4ctE4j5h1n3WucKxRXBBGnVWwuoa2F4-aZ2gtK-rBgNQ",
  //     });
  //     // console.log("Token Gen", fcmtoken);
  //     const sendData = {
  //       user_id: lStorage.user.userId,
  //       token:lStorage.token,
  //       fcmtoken,
  //       method: "create",
  //     };
  //     await api.post("api/fcm_token.php", sendData);
  //   } else if (permission === "denied") {
  //     // alert("You denied for the notification");

  //     const sendData = {
  //       user_id: lStorage.user.userId,

  //       method: "delete",
  //     };
  //     await api.post("api/fcm_token.php", sendData);
  //   }
  // }

  return (
    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        {sandbox === true ? <Sandbox /> : <Home />}

        <DashboardFooter />
      </div>
    </div>
  );
}

export default Dashboard;
