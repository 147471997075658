import React from "react";
import Dialog from "react-dialog";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { userloginDetails } from "../../../../Utils/AdminAuth";
import { HOST_URL } from "../../../../Dashboard/api/Api";

function CopyMediaURL(props) {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  // const isAdmin = lStorage.user.isAdmin;

  const [isDialogOpen, setisDialogOpen] = useState(false);
  const url = props.url;
  const id = props.id;


  const openDialog = (e) => {
    setisDialogOpen(true);
    copyClip();
  };

  const handleClose = () => {
    setisDialogOpen(false);
  };

  const copyClip = () => {
    var inp = document.createElement("input");
    document.body.appendChild(inp);
    inp.value = url;
    inp.select();
    document.execCommand("copy", false);
    inp.remove();
  };

  const Delete = async() => {
    let data = {
      user_id: user_id,
      method : "delete",
      token: token,
      id: id,
      file_url: url
    }
    try {
      let response = await fetch(`${HOST_URL}/uploadFileWhatsapp.php`,{
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      let result =await  response.json();
      if(result.success){
        console.log(result.message)
        setTimeout(()=>{
          window.location.reload('/dashboard/whatsapp-gallery')
        },2000)
      }
    } catch (error) {
       console.log(error)
    }
  }



  return (
    <>
      {isDialogOpen && (
        <Dialog
          title={"Alert"}
          modal={true}
          height={500}
          isDraggable={true}
          onClose={handleClose}
          buttons={[
            {
              text: "Ok",
              className: "btn-info btn-sm btn mr-2",
              onClick: () => handleClose(),
            },
          ]}
        >
          <p> URL Successfully Copied </p>
        </Dialog>
      )}

      <span onClick={() => openDialog()} className="badge bg-success mr-2">
        {" "}
        Copy URL
      </span>

      <a target="_blank" rel="noopener noreferrer" href={url}>
        {" "}
        <span className="badge bg-primary mr-2"> View</span>{" "}
      </a>

      <span onClick={()=>Delete()} className="badge bg-danger mr-2">Delete</span>{" "}
    </>
  );
}

export default CopyMediaURL;
