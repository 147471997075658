import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons' ;
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class RcsDetailsReport extends Component {

    constructor () {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const userToken= lStorage.token;

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);


    super();
        this.state = {
            user_id:userId,
            token:userToken,
            rcsDetailsReportList:'',
            brandList:'',
            TodayDate: date,
            dateFrom:date,
            dateTo:date,
            SelecteddateFrom:'',
            SelecteddateTo:'',
            errorMessage:'',
            successMessage:false,
            bgColor:''
        }
        this.brandName = this.brandName.bind(this);
        
    }

    componentDidMount() {
        fetch(`https://googlercs.authkey.io/authkey/manage_rcs.php?userid=${this.state.user_id}&method=retrieve&token=${this.state.token}&method=list`).then((response)=> {
            response.json().then((result)=>{
                 console.log(result);
                if(result.success===true){
                this.setState({brandList:result});
                }else{
                    this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:'Somthing Wrong'});
                }
            })
        })
    }

    brandName(e) {
        fetch(`https://googlercs.authkey.io/authkey/manage_rcs.php?userid=${this.state.user_id}&method=reports&token=${this.state.token}&brand=${e.target.value}`).then((response)=> {
            response.json().then((result)=>{
                console.log(result);
                if(result.success===true){
                this.setState({rcsDetailsReportList:result});
                }
            })
        })
    }
    

    dateFrom = (date) => {
        let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateFrom: dated,
            SelecteddateFrom:date
        });
    };

    dateTo = (date) => {
        let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
        this.setState({
            dateTo: dated,
            SelecteddateTo:date
        });
    };


render() {
	document.querySelector('body').scrollTo(0, 0);
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content mt-0">
				<div className="container-fluid p-0">

					<div className=" mb-2 mb-xl-3">
							<div className="">
								<h3 className="mb-3"><strong>RCS Report </strong> </h3>
							</div>
					</div>

					<div className="card mt-3">


						<div className="">
							<h5 className="card-title mt-5">
								<div className="col-auto ml-auto text-right mt-n1">
									<nav className="text-center" aria-label="breadcrumb">
										<ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
											<Link to="/dashboard/rcs-reports"><li className="breadcrumb-item "> Today's Summary </li> </Link>
											<Link to={'/dashboard/sms-detail-reports/'+'sms/'+this.state.dateFrom+'/'+this.state.dateTo}> <li className="breadcrumb-item active "> Detailed Reports </li> </Link>

										</ol>
									</nav>
								</div>
							</h5>
						</div>


					<div className="card-body">
						<div className="row">

                                   <div className="input-group  drop-down-icons col-md-12 mb-3">
										 <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                        { this.state.brandList?
                                        
                                    
                                        <select onChange={this.brandName} className="form-control">
                                             <option value="">Please Select Brad</option>
                                        {   
                                         
                                            this.state.brandList.data.map((item,i)=>
                                            <option key={i} value={item.brand_name}>{item.brand_name}</option>
                                          )
                                        }

                                    </select>
                                    :
                                    null

                                    }


								   </div>

						<div className="input-group col-md-4 mb-2">
										 <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
									   <DatePicker className="form-control btn-block"
										   dateFormat="yyyy/MM/dd" 
										   value={this.state.dateFrom}
										   onChange={this.dateFrom}
										   selected={this.state.SelecteddateFrom}
										   placeholderText='Date From'
										   /> 

								   </div>

								   <div className="input-group col-md-4 mb-2">
										 <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
										 <DatePicker className="form-control btn-block"
										   dateFormat="yyyy/MM/dd"
										   value={this.state.dateTo}
										   onChange={this.dateTo}
										   selected={this.state.SelecteddateTo}
										   placeholderText='Date To'
										   
										   /> 
								   </div>


								   <div className="col-md-4">
									   <button type="button" onClick={()=> this.fiterDatewiseData()}  className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch}/> Filter </button>
								   </div>
						   </div>
						</div>

						<hr className="m-0" />
						<div className="card-body">
								<h3 className="m-0">Details Report</h3>
						</div>

						<hr className="m-0"/>
						<div className="">

						<div className="">
						<div className="col-md-12">
							<div className="">
								<div className="">
									
                    <div className="row">

                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className=" flex-fill">
                        <div  className="">
                        { this.state.rcsDetailsReportList?
                            <table className="table table-striped table-sm my-0">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Status</th>
                                        <th>Mobile</th>
                                        <th>Message</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.rcsDetailsReportList.data.map((item,i)=>
                                        <tr>
                                            <td>{item.brand_name}</td>
                                            <td><span className="badge bg-success">{item.eventType===''?'User Reply':item.eventType}</span></td>
                                            <td>{item.senderPhoneNumber}</td>
                                            <td>{item.text}</td>
                                            <td>{item.created}</td>
                                        </tr>
                                        )
                                    }  
                                </tbody>
                            </table>
                            :
                            <div className="text-center">
                                <div className="card-body">
                                    <p>Please Select Brand </p>
                                </div>
                            </div>


                        }

                        </div>
                        </div>
                    </div>
                </div>

								
								</div>
							</div>
                        </div>
					</div>
				  </div>
				</div>
				</div>
			</main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsDetailsReport;