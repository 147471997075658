import React from 'react';
import VoiceApiComponent from '../../site/VoiceApiComponent'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
const VoiceApiDashboard = () => {
    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className=" row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                <h3><strong> Voice </strong> Docs </h3>
                            </div>
                        </div>
                        <div className="">

                            <div className="card">
                            <div className="card-header"><h5 className="card-title mb-0">Voice API</h5></div>
                                <div className="card-body">
                                    <VoiceApiComponent />
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
                <DashboardFooter />
            </div>
        </div>
    );
}

export default VoiceApiDashboard;