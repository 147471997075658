import React, { useEffect, useState } from 'react';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSearch } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../Dashboard/api/Api';

const ResellerSetting = () => {
  const lStorage = JSON.parse(localStorage.getItem("admin"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const type = lStorage.user.isAdmin;

  const [resellerData, setResellerData] = useState('0');
  const [parentData, setParentData] = useState('');
  const [MainData, setMainData] = useState([]);
  const [searchMessage, setSearchMessage] = useState('');
  const [resellerListData, setResellerListData] = useState([]);
  const [customId, setCustomId] = useState('');
  const [email, setEmail] = useState('');
  const [error, seterror] = useState({
    errorStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  useEffect(() => {
    SelectData();
    ResellerList();
  }, []);

  const SelectData = async () => {
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php?user_id=${userId}&method=retrieve&type=admin&token=${userToken}`);
      let result = await response.json();
      if (result.success) {
        setMainData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const ResellerData = async () => {
    let data = {
      user_id: userId,
      token: userToken,
      reseller_parent_id: parentData,
      reseller_id: resellerData,
      type: type,
      method: "update_reseller_parent"
    };
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      if (result.success) {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          seterror({ errorStatus: false });
          setParentData('');
          setResellerData('');
          ResellerList();
        }, 2000);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      seterror({
        errorStatus: true,
        errorMessage: error.toString(),
        bgcolor: "alert alert-danger",
      });
    }
  };

  const ResellerList = async () => {
    let data = {
      user_id: userId,
      token: userToken,
      type: type,
      method: "reseller_list_parent"
    };
    try {
      let response = await fetch(`${HOST_URL}/employee_user_list.php`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      let result = await response.json();
      if (result.success) {
        setResellerListData(result.data);
      } else {
        seterror({
          errorStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      seterror({
        errorStatus: true,
        errorMessage: error.toString(),
        bgcolor: "alert alert-danger",
      });
    }
  };

  const toggleUpdate = (reseller_id, parent_id, email) => {
    setResellerData(reseller_id);
    setParentData(parent_id);
    setCustomId(reseller_id);
    setEmail(email);
  };

  const filteredResellerOptions = MainData.filter(x => x.user_type === "reseller");

  return (
    <div>
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                {/* Optional header content */}
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="filter-option">
                        <div className='row'>
                          <div className='col-4'>
                            <label>Reseller</label>
                            <select
                              name='reseller'
                              onChange={(e) => setResellerData(e.target.value)}
                              className="form-control"
                              value={resellerData}
                            >
                              <option value="">Select Reseller</option>
                              {resellerData === customId ? 
                                <option key={resellerData} value={resellerData}>{email}</option> 
                                : (
                                  filteredResellerOptions.map((x) => (
                                    <option key={x.id} value={x.id}>{x.username}</option>
                                  ))
                                )
                              }
                            </select>
                          </div>
                          <div className='col-4'>
                            <label>Parent</label>
                            <select
                              name='parent'
                              onChange={(e) => setParentData(e.target.value)}
                              className="form-control"
                              value={parentData}
                            >
                              <option value="">Select Parent</option>
                              {MainData.map((x) => (
                                <option key={x.id} value={x.id}>{x.username}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className='row mt-2'>
                          <div className='col-6'>
                            {error.errorStatus ? (
                              <div className={error.bgcolor} role="alert">
                                <strong>Alert!</strong> {error.errorMessage}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-6'>
                            <button type="button" onClick={() => ResellerData()} className="btn btn-info float-right">Update</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header d-flex align-items-center border-bottom">
                      <FontAwesomeIcon icon={faPaperPlane} className="mr-2" style={{ fontSize: "1.5rem" }} />
                      <h3 className="card-title mb-0 font-weight-bold">List of Reseller and its parent</h3>
                    </div>

                    <div className="card-body">
                      <div className="filter-option">
                        <div className="row">
                          <div className='col-6'></div>
                          <div className='col-6'>
                            <div className="input-group">
                              <div className="input-group-prepend float-right">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                              <input
                                type="text"
                                id="searchInput"
                                name="searchdata"
                                value={searchMessage}
                                onChange={(e) => setSearchMessage(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="container mt-4">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>SNo.</th>
                                <th>Reseller</th>
                                <th>Parent</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resellerListData.reverse().filter((x) => {
                                return searchMessage.toLowerCase() === '' ? x : x.email.toLowerCase().includes(searchMessage) ||
                                  x.fullname.toLowerCase().includes(searchMessage) ||
                                  x.parent_email.toLowerCase().includes(searchMessage) ||
                                  x.parent_name.toLowerCase().includes(searchMessage);
                              }).map((item, index) => (
                                <tr key={item.reseller_id}>
                                  <td>{index + 1}</td>
                                  <td>{`${item.email} (${item.fullname})`}</td>
                                  <td>{`${item.parent_email} (${item.parent_name})`}</td>
                                  <td>
                                    <span onClick={() => toggleUpdate(item.reseller_id, item.parent_id, item.email)} className="badge bg-success">
                                      Update
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default ResellerSetting;
