import React, { useEffect, useState } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import api from "../../axios/Axios";
import Spining from "../UI/Spining/Spining";
import { userloginDetails } from "../Utils/AdminAuth";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LcrRouteSetting = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  // const isAdmin = lStorage.user.isAdmin;
  const [alert, setAlert] = useState({
    successMessage: false,
    errorMessage: "",
    bgColor: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [routeData, setRouteData] = useState([]);
  const [action, setAction] = useState(null);
  const [allRoute, setAllRoute] = useState([]);
  const [addNew, setAddNew] = useState({ id: "", cost: "", cost_euro: "" });
  const[deleteData, setDeleteData] = useState({})
  useEffect(() => {
    setTimeout(() => {
      setAlert({
        successMessage: false,
        errorMessage: "",
        bgColor: "",
      });
    }, 1000 * 10);
  }, [alert]);
  useEffect(() => {
    fetchCountry();
  },[]);
  const fetchCountry = async () => {
    const { data } = await api.get(`/api/country_master.php`);
    if (data.success === true) {
      setCountryList(data.data);
    }
  };
  const searchdata = async () => {
    if (channel === "" || selectedCountry === "") {
      setAlert({
        successMessage: true,
        errorMessage: "please select channel & country",
        bgColor: "alert alert-danger alert-dismissible",
      });
      return;
    }
    setLoading(true);
    let dataforroute = {
      user_id: userId,
      method: "fetch",
      token: userToken,
      channel,
      country: selectedCountry,
    };
    try {
      const { data } = await api.post(`/api/LcrData.php`, dataforroute);
      if (data.success === true) {
        setRouteData(data.data);
      }
      if (data.success === false) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const channelHandler = (e) => {
    setChannel("");
    setChannel(e.target.value);
  };
  const countrtHandler = (e) => {
    setSelectedCountry("");
    const countryvalue = e.target.value;
    const selectedObject = countryList.find(
      (option) => option.country_code === countryvalue
    );
    setSelectedCountry(selectedObject);
  };
  const editHandler = (item) => {
    setAction(item);
  };
  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setAction({ ...action, is_active: 1 });
    } else {
      setAction({ ...action, is_active: 0 });
    }
  };
  const saveEdit = async () => {
    if(action.cost_euro===""||action.cost===""){
      setAlert({
        successMessage: true,
        errorMessage: "please fill all the field",
        bgColor: "alert alert-danger alert-dismissible",
      });
      return;
    }
    setLoading(true);
    const dataforupdate = {
      user_id: userId,
      method: "update",
      routeid: action.routeid,
      is_active: action.is_active,
      cost: action.cost,
      cost_euro: action.cost_euro,
      id: action.id,
      token: userToken,
      channel,
      country: {
        country_name: action.country_name,
        country_code: action.country_code,
      },
    };

    try {
      const { data } = await api.post(`/api/LcrData.php`, dataforupdate);
      if (data.success === true) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-success alert-dismissible",
        });
        setRouteData((prevData) =>
          prevData.map((item) =>
            item.id === action.id
              ? {
                  ...item,
                  cost: action.cost,
                  is_active: action.is_active,
                  cost_euro: action.cost_euro,
                }
              : item
          )
        );
      }
      if (data.success === false) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    setLoading(true);
    let datafordelete = {
      user_id: userId,
      method: "delete",
      token: userToken,
      routeid: deleteData.routeid,
      id: deleteData.id,
      channel,
      country: selectedCountry,
    };

    try {
      const { data } = await api.post(`/api/LcrData.php`, datafordelete);
      if (data.success === true) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-success alert-dismissible",
        });
        setRouteData(prevList => prevList.filter(list => list.id !== deleteData.id));
      }
      if (data.success === false) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleAdd = async () => {

    try {
      const { data } = await api.get(
        `/api/route.php?user_id=1&method=retrieve&token=${userToken}&type=admin`
      );
      if (data.success === true) {
        setAllRoute(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddNew = async () => {
    
    if (addNew.id === "" || addNew.cost === "" || addNew.cost_euro === "") {
      setAlert({
        successMessage: true,
        errorMessage: "please fill all the input",
        bgColor: "alert alert-danger alert-dismissible",
      });
      return;
    }
    setLoading(true);
    const dataforadd = {
      user_id: userId,
      method: "create",
      routeid: addNew.id,
      cost: addNew.cost,
      cost_euro: addNew.cost_euro,
      token: userToken,
      channel,
      country: selectedCountry,
    };
    
    try {
      const { data } = await api.post(`/api/LcrData.php`, dataforadd);
      if (data.success === true) {
        // console.log(data);
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-success alert-dismissible",
        });

        setRouteData([...routeData,data.data]);
      }
      if (data.success === false) {
        setAlert({
          successMessage: true,
          errorMessage: data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="wrapper">
        {loading && <Spining />}
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>LCR Route Setting </h3>
                </div>
              </div>
              <div className="row justify-content-center">
                {alert.successMessage ? (
                  <div className={alert.bgColor} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{alert.errorMessage}</div>
                  </div>
                ) : null}

                <div className="card">
                  <div className="card-body">
                    <div className="">
                      <div className="filter-option">
                        <h6 className="card-subtitle text-muted">
                          LCR Route Setting{" "}
                        </h6>
                        <div className="row">
                          <div className="col-md-4">
                            <label>
                              Channel<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                              <div className="input-group mb-2 mr-sm-2">
                                <select
                                  className="form-control "
                                  onChange={channelHandler}
                                >
                                  <option value="">Select Channel</option>

                                  <option value="sms">SMS</option>
                                  <option value="voice">Voice</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label>
                              Country<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                              <div className="input-group mb-2 mr-sm-2">
                                <select
                                  className="form-control "
                                  onChange={countrtHandler}
                                >
                                  <option value="">Select Country</option>

                                  {countryList.map((item, i) => (
                                    <option key={i} value={item.country_code}>
                                      {item.country_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 mt-4">
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={searchdata}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {routeData.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table table-striped table-xs table-sm my-0">
                              <thead>
                                <tr>
                                  <th> Route Name</th>
                                  
                                  <th> Cost in INR </th>
                                  <th> Cost in Euro </th>
                                  <th>mccmnc</th>
                                  <th>Operator</th>
                                  <th> Statue </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {routeData.map((item, i) => (
                                  <tr
                                    key={i}
                                    style={
                                      item.is_active === 0
                                        ? { backgroundColor: "#b8b6b3a0" }
                                        : null
                                    }
                                  >
                                    <td>{item.route_name}</td>
                                    <td>{item.cost}</td>
                                    <td>{item.cost_euro}</td>
                                    <td>{item.mccmnc}</td>
                                    <td>{item.operator}</td>
                                    <td>
                                      {item.is_active === 1 ? (
                                        <span className="badge badge-success">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="badge badge-secondary">
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#editModal"
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                          color: "#007BFF",
                                        }}
                                        onClick={() => editHandler(item)}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </button>
                                      <button
                                        type="button"
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                          color: "#DC3545",
                                          marginLeft: "1em",
                                        }}
                                        data-toggle="modal"
                                        data-target="#deleteModal"
                                        onClick={() => setDeleteData(item)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="text-center">
                            <div className="card-body">
                              <img
                                alt="Empty Data"
                                className="project-empty-img"
                                src={EmptyImg}
                              />
                              <p> No Any Data Found </p>
                            </div>
                          </div>
                        )}
                      </div>

                      {routeData.length > 0 && (
                        <button
                          type="button"
                          className="btn btn-primary float-right mt-2"
                          data-toggle="modal"
                          data-target="#addnewModal"
                          onClick={handleAdd}
                        >
                          Add New
                        </button>
                      )}

                      <div
                        className="modal fade"
                        id="addnewModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add new
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setAddNew({ ...addNew, id: e.target.value })
                                }
                              >
                                <option value="">Select Route</option>
                                {allRoute?.map((item, i) => {
                                  if(item.channel===channel&&item.is_active===1){
                                    return(
                                      <option key={i} value={item.id}>
                                    {item.route_name}
                                  </option>
                                    )
                                  }
                                }
                                  
                          
                                )}
                              </select>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label>
                                    Cost in INR{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={addNew?.cost}
                                    className="form-control"
                                    placeholder="Cost in INR"
                                    onChange={(e) =>
                                      setAddNew({
                                        ...addNew,
                                        cost: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <label>
                                    Cost in Euro{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={addNew?.cost_euro}
                                    className="form-control"
                                    placeholder="Cost in Euro"
                                    onChange={(e) =>
                                      setAddNew({
                                        ...addNew,
                                        cost_euro: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddNew}
                                data-dismiss="modal"
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="modal fade"
                        id="editModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Edit
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="d-flex justify-content-between">
                                <h6>Route ID: {action?.routeid}</h6>
                                <h6>Country Name: {action?.country_name}</h6>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <label>
                                    Cost in INR{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={action?.cost}
                                    className="form-control"
                                    placeholder="Cost in INR"
                                    onChange={(e) =>
                                      setAction({
                                        ...action,
                                        cost: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div>
                                  <label>
                                    Cost in Euro{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    value={action?.cost_euro}
                                    className="form-control"
                                    placeholder="Cost in Euro"
                                    onChange={(e) =>
                                      setAction({
                                        ...action,
                                        cost_euro: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <label>
                                  Status <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="checkbox"
                                  checked={
                                    action?.is_active === 1 ? true : false
                                  }
                                  onChange={handleCheckBox}
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveEdit}
                                data-dismiss="modal"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade"
                        id="deleteModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h5>Are you sure want to delete ?</h5>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleDelete}>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default LcrRouteSetting;
