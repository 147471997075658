import React, {useCallback, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload} from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../Dashboard/api/Api';

const RouteManagement = () => {
	
    const lStorage = JSON.parse(localStorage.getItem("admin"));
	const userId = lStorage.user.userId;
	const userToken = lStorage.token;
	const [loding, setLoding] = useState(false);
	const [route, setRoute] = useState(null);
    const [imageFile, setimageFile] = useState('');
    const [conversionRate, setconversionRate] = useState('');
    const [message, setMessage] = useState('Please Upload .CSV File');

	const getRouteDetails =  useCallback(() => {
	 
	fetch(` ${HOST_URL}/route.php?user_id=${userId}&method=retrieve&token=${userToken}&type=admin`).then((response) => {
		
			response.json().then((result) => {
				if (result.success === true) {
                    setRoute(result.data);
				}
			})
		})
	},[userId,userToken])
	

	useEffect(() => {

		getRouteDetails();

	},[getRouteDetails])

   // console.log(route);



const onClickHandler = e => {
	e.preventDefault()
	setLoding(true)
	const data = new FormData() 
	//console.log(imageFile);
	//console.log(conversionRate);
	data.append('amdfile', imageFile)
	data.append('user_id', userId)
	data.append('token', userToken)
	data.append('conversion_rate', conversionRate)
	fetch(`${HOST_URL}/upload_amd_pricing.php`,{
		method: "POST",
		body:data
	}).then((result)=>{
		result.json().then((resp)=>{
			//console.log(resp);
		if(resp.success === true ){
			setLoding(false)
			setMessage(resp.message)
			}else{
				setLoding(false)
				setMessage(resp.message)
	     	}
		})
	})
}


    return (
  
<div className="wrapper">
    <AdminLeftMenu />
        <div className="main">
            <AdminHeader />
               <main className="content">
				<div className="container-fluid p-0">
					<div className="row mb-2 mb-xl-3">
                    
						<div className="col-auto d-none d-sm-block">
							<h3><strong> Route </strong> Management </h3>
						</div>

                        <div className="col-auto ml-auto text-right mt-n1"><Link className="btn btn-primary btn-sm" to="/admin/add-new-route">  Add New Route </Link></div>

					</div>

					<div className="row">
						<div className="col-12 col-lg-12 col-xxl-12 d-flex">
							<div className="card flex-fill">

								<div className="card-header">

									<h5 className="card-title mb-0">Route List</h5>
								</div>

                                <div className="card-body">
                        
                                
                                {
                                    route?
                                    <div className="table-responsive">
								<table className="table table-hover my-0">
									<thead>
										<tr>
											<th> Route Name </th>
											<th className="d-xl-table-cell">Vender Name</th>
											<th className="d-xl-table-cell">Address Date</th>
											<th>Channel</th>
											<th>Activity </th>
										</tr>
									</thead>
									<tbody>
									


                                    {
                                         route.map((item,i)=>
                                            <tr key={i}>
                                                <td>{item.route_name}</td>
                                                <td>{item.vendor_name}</td>
                                                <td>{item.vendor_address}</td>
                                                <td>{item.channel}</td>
                                                <td> 
                                               <Link to={'/admin/edit-route/'+item.id}> <span className="badge bg-success mr-2">Edit </span> </Link>
                                                
                                               <Link to="/admin/route-management"> <span className="badge bg-primary">Upload</span>  </Link>
                                                </td>
                                            </tr>
                                        )
                                    }

									
									</tbody>
								</table>
                                </div>

                                :<div className="text-center">
                                <div className="card-body">
                                   
                                    <p>Loading.......</p>
                                   
                                    </div>
                                </div> 

                                }
                                
                                </div>
							</div>
						</div>
					</div>


					<div className="row">


					
						<div className="col-12 col-lg-12 col-xxl-12 d-flex">
							<div className="card flex-fill">
								<div className="card-header">
									<h5 className="card-title mb-0">AMD Pricing </h5>
								</div>
                                <div className="card-body">

								
					<div className="alert alert-info alert-dismissible" role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                          </button>
                                <div className="text-center">
										{message}
                                    </div>
                    </div>

								<form className="">

						<div className="form-row">

							<div className="input-group col-md-6 mb-2">
								<div className="input-group-prepend">
								<div className="input-group-text">AMD CSV File</div>
								</div>
								<input type="file" onChange={(e) => setimageFile(e.target.files[0])}  className="form-control"/>
							</div>

							<div className="input-group col-md-6 mb-2">
								<div className="input-group-prepend">
								<div className="input-group-text">1 EURO = </div>
								</div>
								<input type="text" onChange={(e) => setconversionRate(e.target.value)}  className="form-control" />
								<div className="input-group-prepend">
								<div className="input-group-text"> INR</div>
								</div>
							</div>

							<div className="col-auto ml-auto text-right mt-3">

								{ loding?
									<button className="btn btn-primary btn-sm"  type="button"><span className="spinner-border text-white spinner-border-sm"></span> File Uploading Upload Please wait..... </button> 
									:
									<button onClick={onClickHandler} className="btn btn-primary btn-sm">
										<FontAwesomeIcon icon={faUpload}/> Upload
									</button>

								}
							  
								 

							</div>



							</div>

						</form>
                        
                                
                                </div>
							</div>
						</div>
					</div>



				</div>
			</main>
            <AdminFooter />
        </div>
    </div>

    );
}
 
export default RouteManagement;