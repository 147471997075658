import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import api from "../../axios/Axios";

const AddEmployee = () => {
  const history = useHistory();

  const [userId, setUserId] = useState("");
  const [userToken, setUserToken] = useState("");
  const [empName, setEmpName] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [empEmail, setEmpEmail] = useState("");
  const [empPhone, setEmpPhone] = useState("");
  const [empPassword, setEmpPassword] = useState("");
  const [cnfmPassword, setCnfmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [eyeIconToggle, setEyeIconToggle] = useState(false);
  const [passError, setPassError] = useState(0);
  const [checkBox, setCheckBox] = useState({
        all: 0,
        sms: 0,
        voice: 0,
        email: 0,
        whatsapp: 0,
        recharge: 0,
        notification: 0,
        rich_media: 0,
        fallback: 0,
  })

  useEffect(() => {
    const lStorage = JSON.parse(localStorage.getItem("admin"));
    if (lStorage) {
      setUserId(lStorage.user.userId);
      setUserToken(lStorage.token);
    }
  }, []);





  const addNewEmployee =async () => {
    document.querySelector("body").scrollTo(0, 0);

    setSuccessMessage(false);
    setBgColor("");
    setErrorMessage("",);



    if (!empName) {
      setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage("Please enter name",);
      return;
    }
    if(!empPhone){
      setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage("Please enter mobile no.",);
          return;
    }

    if(!empEmail){
      setSuccessMessage(true);
      setBgColor("alert alert-danger alert-dismissible");
      setErrorMessage("Please enter email",);
      return;
    }

    if(!employeeType){
      setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage("Please select empoloyee type",);
          return;
    }
    if(!empPassword){
      setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage("Please enter password",);
          return;
    }

    if(!empPassword===cnfmPassword){
      setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage("The passwords you entered don't match",);
          return;
    }


    const createUserData={
      user_id: userId,
      token: userToken,
      fullname: empName,
      employee_type:employeeType,
      mobile:empPhone,
      email:empEmail,
      password: empPassword,
      sms_menu: checkBox.sms,
      voice_menu: checkBox.voice,
      email_menu:checkBox.email,
      whatsapp_menu: checkBox.whatsapp,
      rechrg_menu: checkBox.recharge,
      notify_menu: checkBox.notification,
      rich_menu: checkBox.rich_media,
      fb_menu: checkBox.fallback,
      method: "add",
    }

    const { data } = await api.post("/api/employee_user_list.php", createUserData);

        if (data.success === true) {
          setSuccessMessage(true);
          setBgColor("alert alert-success alert-dismissible");
          setErrorMessage(data.message);
          setTimeout(() => {
          history.push('/admin/employee-management')
        }, 2000);
        } else {
          setSuccessMessage(true);
          setBgColor("alert alert-danger alert-dismissible");
          setErrorMessage(data.message);
        }
      

  };

const handleCheckbox =(e)=>{
 
  const { name, checked } = e.target;
  setCheckBox(prevState => ({
      ...prevState,
      [name]: checked ? 1 : 0,
  }));

  if(checkBox.sms===1&&checkBox.voice===1&&checkBox.email===1&&checkBox.whatsapp===1&&checkBox.recharge===1&&checkBox.notification===1&&checkBox.rich_media===1&&checkBox.fallback===1&&checked===true){
    setCheckBox(prevState => ({
      ...prevState,
      all: 1 ,
  }));
    }else{
      setCheckBox(prevState => ({
        ...prevState,
        all:  0,
    }));

   
    }

      if(checkBox.all===1&&checked===false){
        setCheckBox(prevState => ({
          ...prevState,
          all: 0 ,
      }));}



    
}
const handleCheckboxAll = (e)=>{
  // e.preventdefault();
  
  const value = e.target.checked;
    let val;
    if (value === true) {
      val = 1;
    } else {
      val = 0;
    }
    setCheckBox({
        all: val,
        sms: val,
        voice: val,
        email: val,
        whatsapp: val,
        recharge: val,
        notification: val,
        rich_media: val,
        fallback: val,
    })

}


const handlePass= (e)=>{
  setEmpPassword(e.target.value);

  
  if (e.target.value === "" && cnfmPassword === "")
    
      setPassError(0);
    

}

const handleCnfmPass=(e)=>{

  if (e.target.value === "" && empPassword === "")
    setPassError(0);

  if (empPassword === "") {
    return;
  } else {
    setPassError(1);
  }
  const pass = empPassword;
  const cnfmPass = e.target.value;
  setCnfmPassword(e.target.value);
  if (pass === cnfmPass) {
    setPassError(0);
  } else {
    setPassError(1);
  }


}


const eyeToggle= (e)=>{

  e.preventDefault();

  setEyeIconToggle(!eyeIconToggle);

  

}

  return (
    <div className="wrapper">
      <AdminLeftMenu />
      <div className="main">
        <AdminHeader />
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong>Add </strong> Employee{" "}
                </h3>
              </div>
            </div>
            {successMessage ? (
              <div className={bgColor} role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="">{errorMessage}</div>
              </div>
            ) : null}
            <div className="row">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">New Employee Details</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                      <div className=" flex-fill">
                        <form>
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputFirstName"
                              >
                                Employee type<span className="text-danger">*</span>
                              </label>
                              <select
                                onChange={(event) =>
                                  setEmployeeType(event.target.value)
                                }
                                className="form-control"
                              >
                                <option value="">Select Employee</option>
                                <option value={"subadmin"}>Sub Admin</option>
                                <option value={"emp"}>Employee</option>
                                <option value={"reseller"}>Reseller</option>
                              </select>
                            </div>
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputFirstName"
                              >
                                Full Name<span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                onChange={(event) =>
                                  setEmpName(event.target.value)
                                }
                                className="form-control"
                                placeholder="Enter Full Name"
                                required
                              />
                            </div>
                            
                          </div>
                          <div className="row">

                          <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputEmail4"
                              >
                                Email<span className="text-danger">*</span>
                              </label>
                              <input
                                onChange={(event) =>
                                  setEmpEmail(event.target.value)
                                }
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                required
                              />
                            </div>


                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="inputFirstName"
                              >
                                Mobile Number<span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength="10"
                                value={empPhone}
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                onChange={(event) =>
                                  setEmpPhone(event.target.value)
                                }
                                className="form-control"
                                placeholder="Enter 10 Digit Mobile Number"
                                required
                              />
                            </div>
                            <div className="row ml-2">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="password"
                                >
                                  Password <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={empPassword}
                                    onChange={handlePass}
                                    className="form-control"
                                    placeholder="Enter password"
                                    required
                                  />

                                  <button
                                    className="border-0 "
                                    onClick={eyeToggle}
                                  >
                                    {eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>
                              </div>

                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="Confirm Password"
                                >
                                  Confirm Password <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={cnfmPassword}
                                    onChange={handleCnfmPass}
                                    className="form-control"
                                    placeholder="Enter password"
                                    required
                                  />
                                  <button
                                    className="border-0 "
                                    onClick={eyeToggle}
                                  >
                                    {eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>

                                {passError === 1 && (
                                  <p
                                    className="text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    *The passwords you entered don't match
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="row ml-2">
                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  checked={
                                    checkBox.all === 1 ? true : false
                                  }
                                  name="all"
                                  value={checkBox.all}
                                  onChange={handleCheckboxAll}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  All
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  checked={
                                    checkBox.sms === 1 ? true : false
                                  }
                                  name="sms"
                                  value={checkBox.sms}
                                  onChange={handleCheckbox}
                                  
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  SMS
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="voice"
                                  checked={
                                    checkBox.voice === 1
                                      ? true
                                      : false
                                  }
                                  value={checkBox.voice}
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Voice
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  value={checkBox.email}
                                  checked={
                                    checkBox.email === 1
                                      ? true
                                      : false
                                  }
                                  name="email"
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Email
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="whatsapp"
                                  value={checkBox.whatsapp}
                                  checked={
                                    checkBox.whatsapp === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Whatsapp
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="recharge"
                                  value={checkBox.recharge}
                                  checked={
                                    checkBox.recharge === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Recharge
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="notification"
                                  value={checkBox.notification}
                                  checked={
                                    checkBox.notification === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Notification
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="rich_media"
                                  value={checkBox.rich_media}
                                  checked={
                                    checkBox.rich_media === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Rich Media
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="fallback"
                                  value={checkBox.fallback}
                                  checked={
                                    checkBox.fallback === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Fallback
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <input
                              type="button"
                              onClick={addNewEmployee}
                              value="Add"
                              className="btn float-right  btn-primary"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
      </div>
    </div>
  );
};

export default AddEmployee;
