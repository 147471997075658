import React, { Component } from 'react';
import AdminHeader from '../../Header/AdminHeader';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, } from '@fortawesome/free-solid-svg-icons';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';

class AffiliateCommision extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const adminType = lStorage.user.isAdmin;


        super();

        this.state = {
            user_id: userId,
            token: userToken,
            adminType: adminType,
            AffiliateCommision: null,
            serchData: '',
            userCurrentStatus: '',
            successMessage: false,
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }

    }

    componentDidMount() {

        if (this.props.match.params.id) {
            this.userWiseData();
        } else {
            this.onserch();
        }

    }

    onserch() {

        fetch(`${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve_trans&type=${this.state.adminType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({ AffiliateCommision: result });
                    this.setState({ userCurrentStatus: result.data[0].is_active });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    userWiseData() {

        fetch(`${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve_trans&aff_id=${this.props.match.params.id}&type=${this.state.adminType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({ AffiliateCommision: result });
                    this.setState({ userCurrentStatus: result.data[0].is_active });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    searchUser() {
        if (this.state.serchData === '') {
            this.onserch();
            this.setState({ successMessage: true });
            this.setState({ errorMessage: "Enter User Name " });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
        } else {

            fetch(`${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=search_affiliate&user_name=${this.state.serchData}&token=${this.state.token}`).then((response) => {
                response.json().then((result) => {
                    if (result.success === true) {
                        this.setState({ successMessage: false });
                        this.setState({ AffiliateCommision: result });
                    } else {
                        this.setState({ successMessage: true });
                        this.setState({ errorMessage: result.message });
                        this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    }
                })
            })
        }
    }




    render() {

        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Affiliate </strong> Commision </h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">

                                        <div className="card-header">
                                            <h5 className="card-title mb-0">Affiliate Commision List</h5>
                                        </div>
                                        <hr className='mt-0  mb-0' />
                                        <div className="card-body">
                                            <div className="filter-option">
                                                <h6 className="card-subtitle text-muted">Filter </h6>
                                                <div className="row">

                                                    <div className="col-md-9">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group-text"><FontAwesomeIcon icon={faSearch} /></div>
                                                            <input type="text" onChange={(event) => { this.setState({ serchData: event.target.value }) }} placeholder="Enter user id or name of users" className="form-control" />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-3 text-center">
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <input type="button" onClick={() => this.searchUser()} value="Search" className="btn mt-1  btn-primary btn-sm btn-block ml-auto " />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>


                                            {
                                                this.state.successMessage ?
                                                    <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                        <div className="">
                                                            {this.state.errorMessage}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {this.state.AffiliateCommision ?

                                                <div className="table-responsive">
                                                    <table className="table table-striped table-xs table-sm my-0">
                                                        <thead>
                                                            <tr>
                                                                <th> User Name </th>
                                                                <th> Recharge Amount </th>
                                                                <th> Transaction Type </th>
                                                                <th> Commision </th>
                                                                <th> Description </th>
                                                                <th>Created Date / Time</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.AffiliateCommision.data.map((item, i) =>
                                                                    <tr key={i}>
                                                                        <td>{item.username}</td>
                                                                        <td>{item.affiliate_currency} <span className='text-primary'> {item.recharge_amount} </span></td>
                                                                        <td>  {item.trans_type === "DEBIT" ? <span className='badge badge-danger'> {item.trans_type} </span> : <span className='badge badge-success'>  {item.trans_type} </span>} </td>
                                                                        <td> {item.affiliate_currency} <span className='text-primary'>{item.trans_amount}</span></td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.created}</td>

                                                                    </tr>
                                                                )
                                                            }


                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                <div className="text-center">
                                                    <div className="card-body">
                                                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                        <p> No Any Employee Found </p>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default AffiliateCommision;