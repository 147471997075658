import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class EmailPrice extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            emailCost: '',
            serchData: '',
            paymentTransactionList: null,
            successMessage: false,
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }
    }

    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Email Price </strong> Plan </h3>
                                </div>
                            </div>

                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }


                            <div className="row">

                                <div className="col-12 col-lg-12 col-xxl-12">

                                <div className="card">

                                <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                            <h2 className="display-4">Pricing</h2>
                            <p className="lead mb-3 mt-5">Authkey is a leader in trusted email delivery. Our powerful cloud-based solution sends transactional and marketing email for over 58,000 paying customers, allowing them to focus on their business, instead of their email infrastructure.</p>
                            </div>

                                    <div className="card-body">

                                    <div className="">
                                        <div className="card-deck mb-3 text-center">
                                            <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <h4 className="my-0 font-weight-normal">Forever Free</h4>
                                            </div>
                                            <hr className="m-0"/>
                                            <div className="card-body">
                                                <h1 className="card-title pricing-card-title">0 <small className="text-muted">/ mo</small></h1>
                                                <ul className="list-unstyled mt-3 ml-4 text-left mb-4">
                                                <li> <FontAwesomeIcon icon={faTimesCircle} color="red" /> Unlimited subscribers</li>
                                                <li> <FontAwesomeIcon icon={faTimesCircle} color="red" />Up to 62K emails per month </li>
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> authkey branding on emails and unsubscribe page </li>
                                              
                                                </ul>
                                                <button type="button" className="btn btn-lg btn-block btn-outline-primary">Choose Plan</button>
                                            </div>
                                            </div>
                                            <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <h4 className="my-0 font-weight-normal">Startup Plan</h4>
                                            </div>
                                            <hr className="m-0"/>
                                            <div className="card-body">
                                                <h1 className="card-title pricing-card-title">0.1 paisa <small className="text-muted">/ Email</small></h1>
                                                <ul className="list-unstyled mt-3 ml-4 text-left mb-4">
                                               
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> Unlimited subscribers </li>
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> Unlimited emails per month </li>
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> No authkey branding on emails and unsubscribe page </li>
                                                </ul>
                                                <button type="button" className="btn btn-lg btn-block btn-primary">Choose Plan</button>
                                            </div>
                                            </div>
                                            <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                <h4 className="my-0 font-weight-normal">Enterprise Plan</h4>
                                            </div>
                                            <hr className="m-0"/>
                                            <div className="card-body">
                                                <h1 className="card-title pricing-card-title">12000   <small className="text-muted">/ Year</small></h1>
                                                <ul className="list-unstyled mt-3 ml-4 text-left mb-4">
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> Unlimited subscribers </li>
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> Unlimited emails per month </li>
                                                <li> <FontAwesomeIcon icon={faCheckCircle} color="green" /> Authkey branding on emails and unsubscribe page </li>
                                                
                                                </ul>
                                                <button type="button" className="btn btn-lg btn-block btn-primary">Choose Plan</button>
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                </div>


                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default EmailPrice;