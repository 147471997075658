import React, { Component } from 'react';
import Dialog from 'react-dialog';
import { useState, useEffect } from 'react';

function GetMobileNo(props) {


    const [isDialogOpen, setisDialogOpen] = useState(false);
    const [data, setData] = useState([]);
    const openDialog = (e) => {
        setisDialogOpen(true);
    }

    const handleClose = () => {
        setisDialogOpen(false);
    }

    const fetchData = () => {
        const { id } = props;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABFalxmwxMkBAKPaaVqUHZABaCldGzuIUFOFEQDjoMqJDJFSZCctsq57ky2p29ma7CMZCd7vWZBFVAfXUnIOYhKkUc66RtTQpsREj6EEmKyLNwvMHZANAvDcbKsUOs3uHTiLrecZCbUdpU6cVQZCvQ3SYrDO5aouwcUZCyNJQU0JAQrQaPy3VlYU2wKxfNdGEbiGVimS2IIYoAZDZD");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`https://graph.facebook.com/v18.0/${id}/phone_numbers`, requestOptions)
            .then((response) => response.json())
            .then((actualData) => {
                //console.log(actualData);
                setData(actualData.data);
                //  console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

    };

    useEffect(() => {
        fetchData();
    }, []);


    return (

        <>
            {
                isDialogOpen &&
                <Dialog
                    title={'Get Mobile No Details'}
                    modal={true}
                    height={500}
                    isDraggable={true}
                    onClose={handleClose}
                >


                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th class="text-left">Name</th>
                                <th class="text-left">Verification Status</th>
                                <th class="text-left">Phone No</th>
                                <th class="text-left">Last Onboarded Time</th>
                                <th class="text-left">Platform Type</th>
                                <th class="text-center">Quality Rating</th>
                                <th class="text-left">Throughput</th>
                            </tr>
                        </thead>
                        <tbody>


                            {data.map((item, index) => (
                                <tr>
                                    <td class="text-left">{item.verified_name}</td>
                                    <td class="text-left">{item.code_verification_status}</td>
                                    <td class="text-left" style={{ width: '150px' }}>{item.display_phone_number}</td>
                                    <th class="text-left">{item.last_onboarded_time}</th>
                                    <th class="text-left">{item.platform_type}</th>
                                    <th class="text-center">{item.quality_rating}</th>
                                    <th class="text-left">{item.throughput.level}</th>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </Dialog >
            }

            <span onClick={() => openDialog()} className='badge bg-primary'>Get Mobile No</span>

        </>
    );
}




export default GetMobileNo;