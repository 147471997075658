import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faEdit } from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';


class VoiceTemplateList extends Component {
    constructor() {
		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;
        super();
        this.state = {
			VoiceTemplateList: null,
			user_id: userId,
            token: userToken,
			successMessage:false,
			bgColor:"alert alert-danger alert-dismissible"
        }
    }

    componentDidMount() {
        fetch(`${HOST_URL}/voice_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				// console.log(result)
				if(result.success===true){
				this.setState({VoiceTemplateList:result});
				}
            })
        })
	}
	
	deleteProjectList(id){
		document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/voice_template.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result.success);
				if(result.success === 'true' ){
					this.setState({successMessage:true});
					this.setState({errorMessage:result.message});
					this.setState({bgColor:"alert alert-success alert-dismissible"});
					window.location.reload(); 
					}else{
					this.setState({successMessage:true});	
					this.setState({errorMessage:result.message});	
					}
            })
        })
	}


render() {   
	document.querySelector('body').scrollTo(0, 0); 
		return (

			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
				<div className="col-auto d-none d-sm-block">
					<h3><strong>Voice Template </strong> List </h3>
				</div>

				<div className="col-auto ml-auto text-right mt-n1">
				<Link className="btn btn-primary btn-sm" to="/dashboard/voice-template"> <FontAwesomeIcon icon={faPlus} color=''  />  Create New Voice Template </Link>
				</div>
			</div>

			{
                     this.state.successMessage? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                        }


			<div className="table-content-show">
			<div className="row justify-content-center">
			<div className="col-12 col-lg-12 col-xxl-12 d-flex">
			<div className="card flex-fill table-responsive">
			<div className="card-header">
			<h5 className="card-title mb-0">Voice list</h5>
			</div>
			
			{
				this.state.VoiceTemplateList?

			<table className="table table-hover my-0">
				<thead>
					<tr style={{ whiteSpace: 'normal' }}>
						<th>VID</th>
						<th>Name</th>
						<th style={{ width: 300 + 'px'}}>Voice Content</th>
						<th>Status</th>
						<th>Create Date</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>

				{
						this.state.VoiceTemplateList.data.map((itam,i)=>
					<tr style={{ whiteSpace: 'normal' }} key={i}>
					    <td>{itam.id}</td>
						<td>{itam.template_name}</td>

						<td>
							{itam.content}
						</td>

						
						<td>{itam.is_active===1?
                            <span className="badge bg-success">Active</span>
                           :
                           <span className="badge bg-danger">Inactive</span>
                        }</td>
						<td>{itam.created}</td>
						
						<td>
						<Link to={'/dashboard/voice-template/edit/'+itam.id}><span className="badge bg-primary"> <FontAwesomeIcon icon={faEdit} /> </span> </Link>
						</td>

					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img className="project-empty-img" src={EmptyImg} alt="Empty Project" />
			<p>No Voice Template Yet</p>
			<Link className="btn btn-success btn-sm" to="/dashboard/voice-template">Create New Voice Template</Link>
			</div>
		</div> 
}


	</div>
	</div>
	</div>
	</div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default VoiceTemplateList;