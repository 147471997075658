import React, { Component } from "react";
import { CAMPAIGN_REPORT } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { Link } from "react-router-dom";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { CAMPAIGN_REPORT_SMS_TABLE, CAMPAIGN_REPORT_TABLE, CAMPAIGN_REPORT_WHATSAPP_TABLE } from "../../UI/Table/ColumnConst";
import Alert from "../../UI/Alert/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL_DB2 } from "../api/Api";

class CampaignReport extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super(props);

    this.state = {
      userId: user_id,
      token: token,
      id: this.props.match.params.id,
      channel: this.props.match.params.channel,
      campaignName: this.props.match.params.campaignName,
      CampaignReportList: [],
      filterContact: "",
      setIsLoading: true,
      ErrorResponse: "",
      campaignList: [],
      currentPage: 1,
      pagination: 10,
    };
  }

  componentDidMount() {
    this.campaignReport();
    this.CampaignData(this.state.currentPage);
  }

  forwardPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 })
    this.CampaignData(this.state.currentPage + 1);
  }

  BackwardPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 })
    this.CampaignData(this.state.currentPage - 1);
  }

  displayButton = () =>{

  }

  campaignReport = async () => {
    try {
      const { data } = await api.post(CAMPAIGN_REPORT, {
        token: this.state.token,
        user_id: this.state.userId,
        user_id: this.state.userId,
        campaign_id: this.state.id,
        method: "report",
        channel: this.state.channel,
      });
      console.log(data);
      if (data.success === true) {
        this.setState({ CampaignReportList: data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };


  filterData = async () => {
    this.setState({ setIsLoading: true, errorMessage: false })
    try {
      const { data } = await api.post(CAMPAIGN_REPORT, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "filter",
        campaign_id: this.state.id,
        mobile: this.state.filterContact
      });
      if (data.success === true) {
        this.setState({ CampaignReportList: data.data, setIsLoading: false, searchDataTable: true });
      } else {
        this.setState({ errorMessage: data.message, isError: true, messageType: 'alert-danger', setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  resetFilter = () => {
    this.campaignReport();
    this.setState({
      searchDataTable: false,
      filterContact: '',
    })
  }

  CampaignData(pageNumber) {
    let data = {
      page: pageNumber
    }
    console.log(data)
    fetch(
      `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.userId}&method=report&token=${this.state.token}&channel=${this.state.channel}&campaign_id=${this.state.id}&page_no=${pageNumber}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          console.log(result.data)
          this.setState({ campaignList: result.data });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  searchMobile() {
    fetch(
      `${HOST_URL_DB2}/bulk_campaign_sms.php?user_id=${this.state.userId}&method=report&token=${this.state.token}&channel=${this.state.channel}&mobile=${this.state.filterContact}&campaign_id=${this.state.id}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          console.log(result)
          this.setState({ campaignList: result.data });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  pagination = () => {
    let page = this.state.currentPage


  }

  totalPages = () => {
    return Math.ceil(this.props.total / 10);
  };

  updatePageRange = (newPage) => {
    const totalPages = this.totalPages();
    let start = Math.floor((newPage - 1) / 10) * 10 + 1;
    let end = Math.min(start + 9, totalPages);

    this.setState({ visibleRange: [start, end] });
  };

  handlePrevious = () => {
    const newPage = Math.max(this.state.currentPage - 1, 1);
    this.setState({ currentPage: newPage });
    this.updatePageRange(newPage);
  };

  handleNext = () => {
    const newPage = Math.min(this.state.currentPage + 1, this.totalPages());
    this.setState({ currentPage: newPage });
    this.updatePageRange(newPage);
  };

  handlePageClick = (page) => {
    this.setState({ currentPage: page });
    this.updatePageRange(page);
  };


  render() {
    console.log(this.state.campaignList)
    console.log(this.state.currentPage)
    console.log(this.props.match.params.total)
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={false}
              btnName={`Create New Contact List `}
              actionURL={`/dashboard/create-contact-list`}
            >
              Campaign Reports
            </Title>

            {this.state.isError && (
              <Alert type={this.state.messageType}>
                {this.state.errorMessage}
              </Alert>
            )}

            <div className="card">

              <h3 className="pl-3 pt-3"><span className="text-success">Campaign Name / ID : </span> {this.state.campaignName} / {this.state.id}</h3>
              <hr className="mt-0" />

              <div className="">
                <div className="filter-option">
                  <h6 className="card-subtitle text-muted">Filter </h6>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                          type="text"
                          onChange={(event) => {
                            this.setState({ filterContact: event.target.value });
                          }}
                          value={this.state.filterContact}
                          placeholder="Enter mobile number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group mb-2 mr-sm-2">
                        <input
                          type="button"
                          onClick={() => this.filterData()}
                          value="Search"
                          className="btn mt-1  btn-primary btn-sm btn-block ml-auto "
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group mb-2 mr-sm-2">
                        <button onClick={this.resetFilter} className="btn mt-1  btn-danger btn-sm btn-block ml-auto ">Reset Filter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="">
                <h5 className="card-title">
                  <div className="col-auto ml-auto text-right mt-n1">
                    <nav className="text-center" aria-label="breadcrumb">
                      <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                        <Link to={`/dashboard/campaign/summary-report/${this.state.channel}/${this.state.id}/${this.state.campaignName}`}>
                          <li className="breadcrumb-item">
                            Today's Summary
                          </li>
                        </Link>
                        <Link
                          to={`/dashboard/campaign/detailed-report/${this.state.channel}/${this.state.id}/${this.state.campaignName}`}
                        >
                          <li className="breadcrumb-item active">Detailed Reports</li>
                        </Link>
                      </ol>
                    </nav>
                  </div>
                </h5>
              </div>
              <div className="pagination-controls">
              <button
                  onClick={this.BackwardPage}
                  disabled={this.state.currentPage === 1}
                  className="btn btn-secondary btn-sm"
                >
                  <FontAwesomeIcon icon={faArrowLeft}/>
                </button>
                {/* <button
                  onClick={this.BackwardPage}
                  disabled={this.state.currentPage === 1}
                  className="btn btn-secondary btn-sm"
                >
                  Previous
                </button> */}
                {/* {this?.state?.pagination.map((x)=>{
                  return(
                    <button type="button" class="btn btn-primary">{x}</button>
                  )
                })} */}
                <span className="mx-2">Page {this.state.currentPage} of {this.props.match.params.total}</span>
                {/* <button
                  onClick={this.forwardPage}
                  disabled={this.state.currentPage === this.props.match.params.total}
                  className="btn btn-secondary btn-sm"
                >
                  Next
                </button> */}
                <button
                  onClick={this.forwardPage}
                  disabled={this.state.currentPage === this.props.match.params.total}
                  className="btn btn-secondary btn-sm"
                >
                  <FontAwesomeIcon icon={faArrowRight}/>
                </button>
              </div>
              <AdvanceDataTable
                tableName={""}
                tableCell={this.props.match.params.channel === "sms" ? CAMPAIGN_REPORT_SMS_TABLE : CAMPAIGN_REPORT_WHATSAPP_TABLE}
                tableData={this.props.match.params.channel === "sms" ? this.state.campaignList : this.state.CampaignReportList}
                isLoading={this.state.setIsLoading}
                isError={this.state.ErrorResponse}
              />
              <div className="pagination-controls">
                <button
                  onClick={this.BackwardPage}
                  disabled={this.state.currentPage === 1}
                  className="btn btn-secondary btn-sm"
                >
                  Previous
                </button>
                <span className="mx-2">Page {this.state.currentPage} of {this.props.match.params.total}</span>
                <button
                  onClick={this.forwardPage}
                  disabled={this.state.currentPage === this.props.match.params.total}
                  className="btn btn-secondary btn-sm"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default CampaignReport;