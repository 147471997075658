import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCopy,faPlus} from '@fortawesome/free-solid-svg-icons';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';

class FallbackList extends Component {

    constructor(){

        const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;


        super();
        this.state={
            projectEventList:null,
            project_id:null,
            user_id: userId,
            token: userToken,
            successMessage:false,
            errorMessage:'',
            bgColor:"alert alert-success alert-dismissible",
        }
    }
    
componentDidMount(){

    this.setState({project_id:this.props.match.params.id});

    fetch(`${HOST_URL}/fallback_list.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
           // console.log(result)
            if(result.success===true){
                this.setState({projectEventList:result});
            }
           
        })
    })
  }
 
clickToCopyApi(text){
    document.querySelector('body').scrollTo(0, 0);
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy); 
    this.setState({
        successMessage:true,
        errorMessage:'API Copied Successfully',
        bgColor:"alert alert-success alert-dismissible"});
}


// deleteEventList(id){
//     document.querySelector('body').scrollTo(0, 0);
//     fetch(`https://console.authkey.io/api/project_detail.php?user_id=${this.state.user_id}&id=${id}&method=delete&token=${this.state.token}`).then((response)=> {
//         response.json().then((result)=>{
//             //console.log(result.success);
//             if(result.success === 'true' ){
//                 this.setState({successMessage:true});
//                 this.setState({errorMessage:result.message});
//                 this.setState({bgColor:"alert alert-success alert-dismissible"});
//                 }else{
//                 this.setState({successMessage:true});	
//                 this.setState({errorMessage:result.message});	
//                 }
//         })
//     })
// }



    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Fallback </strong> List </h3>
                                </div>
                                <div className="col-auto ml-auto text-right mt-n1">
                                    <Link className="btn btn-primary btn-sm" to={'/dashboard/create-new-fallback/'}>
                                     <FontAwesomeIcon icon={faPlus}/> Create Fallback
                                    </Link>
                                </div>
                            </div>


            
				{
                     this.state.successMessage? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                        }



                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> Fallback List</h5>
                                        </div>



        { this.state.projectEventList?
            <div className="table-responsive">
			<table className="table table-hover  project  my-0">
    
				<thead>
					<tr>
						<th>Fallback Id</th>
						<th>Name</th>
						<th>API URL</th>
						<th>Created</th>
						<th>Updated</th>
					</tr>
				</thead>
				<tbody>

				{
						this.state.projectEventList.data.map((itam,i)=>
					<tr>
						<td>{itam.id}</td>
						<td>{itam.event_name}</td>
						
						<td> <input style={{border:'none'}} type="text"  ref={(input) => this.input = input} value={itam.event_api} /> <button onClick={()=> this.clickToCopyApi(itam.event_api)} className="badge btn-success ml-2"><FontAwesomeIcon icon={faCopy} color=''/>Click To Copy</button></td>
						<td>{itam.created}</td>
                        <td>{itam.modified}</td>
					
					</tr>
                    
                    )
				}
				
					</tbody>
                    </table>
                    </div>
                :<div className="text-center">
                <div className="card-body">
                    <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                    <p>No Event Of This Project Yet</p>
                    <Link className="btn btn-success btn-sm" to={'/dashboard/create-new-fallback/'+this.state.project_id}>Create New Event</Link>
                    </div>
                </div> 
                
        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default FallbackList;