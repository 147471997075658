import React, { Component } from 'react';
import DashboardLayout from '../../UI/Layout/DashboardLayout';
import Title from '../../UI/Text/Title';
import Card from '../../UI/Card/Card';
import Alert from '../../UI/Alert/Alert';
import { HOST_URL } from '../api/Api';
class CreateContactList extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const user_id = lStorage.user.userId;
        const token = lStorage.token;
        super();

        this.state = {
            userId: user_id,
            token: token,
            contactListName:'',
            description:'',
            setIsLoading: true,
            errorMessage:'',
            messageType:'',
        }
        this.createContact = this.createContact.bind(this);
    }

    
    createContact() {
          const createListData = {
              user_id: this.state.userId,
              token: this.state.token,
              method: 'create',
              name: this.state.contactListName,
              description: this.state.description,
          };
          fetch(`${HOST_URL}/contact-list.php`, {
              method: "post",
              headers: {
                  'content-Type': 'application/json',
              },
              body: JSON.stringify(createListData)
          }).then((result) => {
              result.json().then((resp) => {
                console.log(resp)
                  if (resp.success === true) {
                      document.querySelector('body').scrollTo(0, 0);
                      this.setState({ isError: true, messageType: "alert-success", errorMessage: resp.message });
                      setTimeout(() => {
                        this.props.history.push('contact-list');
                    }, 1000)
                  } else {
                      this.setState({ isError: true, messageType: "alert-danger", errorMessage: resp.message });
                  }
              })
          })
      }
      
    render() {
        return (
            <DashboardLayout>
                <main className="content">
                    <div className="container-fluid p-0">
                        <Title isButton={false}>  Create Contact List </Title>
                        <Card CardTitle={`Contact List`}>
                            <div className='row'>
                                <div className='col-md-8'>
                                {this.state.isError && <Alert type={this.state.messageType}>{this.state.errorMessage}</Alert>}
                                    <div className='form-group'>
                                        <label className='col-form-label'>Enter Contact List  Name</label>
                                        <input type='text' className='form-control' onChange={(e)=> this.setState({contactListName:e.target.value})} placeholder='Example: Data for Send Whatsapp' />
                                    </div>
                                    <div className='form-group'>
                                        <label className='col-form-label' >Contact List Description</label>
                                        <textarea rows={3} onChange={(e)=> this.setState({description:e.target.value})} placeholder="Enter Description" className='form-control'></textarea>
                                    </div>
                                    <div className='form-group text-right'>
                                        <button onClick={this.createContact} className='btn btn-sm btn-primary'>Create List</button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <p className="tooglebox"><a className="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="/dashboard/email-template#collapseExample">Note</a></p>
                                        <ol className="mt-3 template-mng">
                                            <li>While you use upload csv then pass sender id is null if you want that template id for all approved sender id</li>
                                            <li>During csv upload always use approved sender id other wise you don't use it during sending sms</li>
                                            <li>On csv upload don't remove first row of sample file</li>
                                        </ol>

                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </main>
            </DashboardLayout>
        );
    }
}

export default CreateContactList;