import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'

export default class componentName extends Component {

    constructor() {
        super();
        this.state = {
            defaultTemplateList: '',
            isFallback1: false,
            isFallback2: false,
            fallBack1Channel: '',
            fallBack2Channel: '',
            fallback1TemplateList: '',
            fallback2TemplateList: '',
            fallback1Template: '',
            fallback2Template: '',
        }
        this.fallBack1Handler = this.fallBack1Handler.bind(this);
        this.fallBack2Handler = this.fallBack2Handler.bind(this);
        this.settime = this.settime.bind(this);
        
    }

    componentDidMount = () => {
        if (this.props.type === 'sms') {
            this.setState({defaultTemplateList: this.props.smsList});
        } else if (this.props.type === 'email') {
            this.setState({defaultTemplateList: this.props.emailList});
      } else if (this.props.type === 'voice') {
        this.setState({defaultTemplateList: this.props.voiceList});
      }
    };

 

   fallBack1Handler(e) {
      this.setState({fallBack1Channel:e.target.value});
      if (e.target.value === 'sms') {
          this.setState({fallback1TemplateList: this.props.smsList});
      } else if (e.target.value === 'voice') {
          this.setState({fallback1TemplateList: this.props.voiceList});
      } else if (e.target.value === 'email') {
        this.setState({fallback1TemplateList: this.props.emailList});
      }

   } 

   fallBack2Handler(e) {
    this.setState({fallBack2Channel:e.target.value});
    if (e.target.value === 'sms') {
        this.setState({fallback2TemplateList: this.props.smsList});
    } else if (e.target.value === 'voice') {
        this.setState({fallback2TemplateList: this.props.voiceList});
    } else if (e.target.value === 'email') {
      this.setState({fallback2TemplateList: this.props.emailList});
    }
 }


settime = (e) => {
     this.setState({timeSet:e.target.value});
 };

    
render() {
   // console.log('timeSet',this.state.timeSet);
    return (
    <>
       <Collapsible trigger={`Add ` + this.props.type + ` channel`}>                 
            <div className="event-setup">
                <div className="add-more">
                    <i id="btn-copy" className="align-middle" data-feather="plus-circle"></i> 
                </div>
                    <span>Choose {this.props.type} Template</span>
                <div className="row">
                <div className="mb-3 col-md-12">
                { this.state.defaultTemplateList !== ''?
                <select id="fallBackType" className="form-control" onChange={this.props.onChange.bind(this,'channel-1', this.props.type,'template-1')}>
                        <option value="">Select Template</option>
                        {this.state.defaultTemplateList.map((e, key) => {
                            return <option key={e.id} value={e.id}>{e.template_name}</option>;
                        })}
                </select> : <span>No Templates Found</span>}
                </div>       
                </div>
                <div className="ml-2 col-md-12">
                    <span> 
                        <input 
                            value="1" 
                            onChange={() => {this.setState({isFallback1: !this.state.isFallback1})}}
                            type="checkbox" 
                            className="form-check-input"
                            id="fallback"
                        />  If Fall Back  </span>
                </div> 
                {this.state.isFallback1?
                <>
                <div className="row">

                    <div className= {this.props.type==='sms'?"col-md-4":"col-md-6"}>
                    <select className="form-control" onChange={this.fallBack1Handler}>
                        <option  value="">Choose Channel</option>
                        <option value="sms" disabled={this.props.type==='sms' || this.props.smsList===false ? true : false}>SMS</option>
                        <option value="email" disabled={this.props.type==='email' || this.props.emailList===false ? true : false}>Email</option>
                        <option value="voice" disabled={this.props.type==='voice' || this.props.voiceList===false ? true : false}>Voice</option>
                    </select>
                    </div>
                    
                    {
                    this.props.type==='sms'?
                      <div className="col-md-3">
                      <select onChange={this.props.onSelectTime.bind(this)} className="form-control">
                          <option value="">Time</option>
                          <option value="15">15s</option>
                          <option value="30">30s</option>
                          <option value="60">60s</option>
                      </select> 
                   </div>
                    :
                    null
                    }
                  

                    <div className={this.props.type==='sms'?"col-md-5":"col-md-6"}>

                    { this.state.fallback1TemplateList !== ''?
               
                <select id="fallBackType" className="form-control" onChange={this.props.onChange.bind(this,'channel-2', this.state.fallBack1Channel,'template-2')}>
                        <option value="">Select Template</option>
                        {this.state.fallback1TemplateList.map((e, key) => {
                            return <option key={e.id} value={e.id}>{e.template_name}</option>;
                        })}
                </select> 
                : <div id="addTemplateBox">
	                   <label className="form-label"> <FontAwesomeIcon icon={faArrowLeft}/>   Select   Fallback   </label>
                        </div>}
                
                    </div>
                </div>
                
                    <span className="ml-4 mt-2"> 
                        <input 
                            value="1" 
                            onChange={() => {this.setState({isFallback2: !this.state.isFallback2})}}
                            type="checkbox" 
                            className="form-check-input"
                            id="fallback"
                        />  If Fall Back  </span>
                    </>
                    
                    : null}
                     {this.state.isFallback2?

                        
                    <div className="row">

                        <div className="col-md-6">
                            <select className="form-control" onChange={this.fallBack2Handler}>
                                <option  value="">Choose Channel</option>
                                <option value="sms" disabled={this.props.type==='sms' || this.state.fallBack1Channel==='sms' || this.props.smsList===false ? true : false}>SMS</option>
                                <option value="email" disabled={this.props.type==='email' || this.state.fallBack1Channel==='email' || this.props.emailList===false ? true : false}>Email</option>
                                <option value="voice" disabled={this.props.type==='voice' || this.state.fallBack1Channel==='voice' || this.props.voiceList===false ? true : false}>Voice</option>
                            </select>
                        </div>

                        <div className="col-md-6">
                                { this.state.fallback2TemplateList !== ''?
                                <select id="fallBackType" className="form-control" onChange={this.props.onChange.bind(this,'channel-3', this.state.fallBack2Channel,'template-3')}>
                                <option value="">Select Template</option>
                                {this.state.fallback2TemplateList.map((e, key) => {
                                    return <option key={e.id} value={e.id}>{e.template_name}</option>;
                                })}
                              </select> : <div id="addTemplateBox">
                              <label className="form-label"> <FontAwesomeIcon icon={faArrowLeft} color=''/>  Select Fallback Channel  </label>
                                </div>}
                        </div>
                    </div>

               


                 : null }

            </div>
        </Collapsible>
    </>
    );
  }
}
