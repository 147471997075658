import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { HOST_URL } from '../api/Api';

class Support extends Component {


	constructor() {
		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
			ticketList: [],
			user_id: userId,
            token: userToken,
			successShow:false,
			successMessage:"",
			errorMessage:'',
			bgColor:"alert alert-danger alert-dismissible"
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/support.php?user_id=${this.state.user_id}&method=retrieve&type=client&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				  console.log(result)
				if(result.success===true){
					this.setState({ticketList:result.data});
				}else{
					this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
				}
            })
        })

	}
	

render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
				<div className="container-fluid p-0">
					<div className="row mb-2 mb-xl-3">
						<div className="col-auto d-sm-block">
							<h3><strong>Open </strong> Ticket </h3>
						</div>
                     
					</div>

					<div className="card flex-fill support">
								<div className="card-header">

					<div className="row mt-4">
						<div className="col-xl-12 col-xxl-5 d-flex">
							<div className="w-100">
								<div className="row">
									<div className="col-sm-3">
										<div className="card">
											<div className="card-body">
												<span className="mb-0">Open Ticket</span>
                                                <span className="text-success float-right"> <i className="mdi mdi-arrow-bottom-right"></i> 0% </span>
											</div> 
										</div>
                                        </div>
                                        <div className="col-sm-3">
										<div className="card">
											<div className="card-body">
												<span className="mb-0">Total Answered</span>
                                                <span className="text-success float-right"> <i className="mdi mdi-arrow-bottom-right"></i> 0% </span>
											</div>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="card">
											<div className="card-body">
												<span className="mb-0">Total Closed</span>
                                                <span className="text-danger float-right"> <i className="mdi mdi-arrow-bottom-right"></i> 0% </span>
											</div>
										</div>
                                       </div>
                                       <div className="col-sm-3"> 
										<div className="card">
											<div className="card-body">
												<span className="mb-0"> Customer Reply </span>
                                                <span className="text-danger float-right"> <i className="mdi mdi-arrow-bottom-right"></i> 0% </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="">
						<div className="col-auto ">
					<Link className="btn btn-primary btn-sm" to={'/dashboard/create-new-ticket'}>   Create New Ticket </Link>
					</div>
						</div>
					</div>

					<div className="">
						<div className="">
						

								<h3 className="mt-3 ml-0">Your ticket history</h3>
								</div>
								<div className="table-responsive">
								<table className="table table-hover my-0">
									<thead>
										<tr>
											<th>Ticket ID </th>
											<th>Department</th>
											<th>Subject</th>
											<th>Status</th>
											<th>Last Update</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
									{
									  this.state.ticketList.map((item,i)=>
										<tr key={i}>
											<td>{item.tkt_number}</td>
											<td>{item.department}</td>
											<td>{item.title}</td>
											<td>{item.status===1?
												<span className="badge text-white bg-success">Ticket Open </span>
											:
											<span className="badge text-white bg-danger">Ticket Closed</span>
											}</td>
											<td>{item.createdDate}</td>
											<td className="d-md-table-cell"><Link to={'/dashboard/ticket/'+item.id+'/'+item.tkt_number+'/'+item.status}><span className="badge btn-primary">View Ticket</span></Link></td>
										
										</tr>)
									}

										
									
									</tbody>
								</table>
								</div>
							</div>
						</div>
						
					</div>


				</div>
			</main>

        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default Support;