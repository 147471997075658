import React, { useState, useEffect } from "react";

import pabbly from "../../Dashboard/assets/img/signup/pabbly.webp";
import meetashi from "../../Dashboard/assets/img/signup/meetashi.webp";
import zapier from "../../Dashboard/assets/img/signup/zapier.webp";
import hubspot from "../../Dashboard/assets/img/signup/hubspot.webp";
import kylas from "../../Dashboard/assets/img/signup/kylas.webp";
import integrately from "../../Dashboard/assets/img/signup/integrately.webp";
import Acc from "../../Dashboard/assets/img/signup/acc.webp";
import Apollo from "../../Dashboard/assets/img/signup/apollo.webp";
import Atulaya from "../../Dashboard/assets/img/signup/atulaya.webp";
import Ayushman from "../../Dashboard/assets/img/signup/ayushman.webp";
import Unionbank from "../../Dashboard/assets/img/signup/unionbank.webp";
import Vlcc from "../../Dashboard/assets/img/signup/vlcc.webp";
import Wh from "../../Dashboard/assets/img/signup/WH.webp";
import IndianSpinal from "../../Dashboard/assets/img/signup/indianSpinalInjury.webp";
import Vypar from "../../Dashboard/assets/img/signup/vypar.webp";
import NeoDove from "../../Dashboard/assets/img/signup/neodove.webp";
import Upward from "../../Dashboard/assets/img/signup/upward.webp";
import PhysicsWallah from "../../Dashboard/assets/img/signup/physicswallah.webp";

const LoginSignupMessage = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slides = [hubspot, zapier, pabbly, integrately, meetashi, kylas];
  const newSlides = [
    Acc,
    Apollo,
    Atulaya,
    Ayushman,
    Unionbank,
    Vlcc,
    Wh,
    Vypar,
    NeoDove,
    Upward,
    IndianSpinal,
    PhysicsWallah
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [slides.length]); // Re-run effect when slides length changes

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % newSlides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [newSlides.length]); // Re-run effect when newSlides length changes

  const startIdx = currentSlideIndex % slides.length;
  const endIdx = (startIdx + 5) % slides.length;
  const visibleSlides =
    endIdx >= startIdx
      ? slides.slice(startIdx, endIdx)
      : [...slides.slice(startIdx), ...slides.slice(0, endIdx)];

  const newStartIdx = currentSlideIndex % newSlides.length;
  const newEndIdx = (newStartIdx + 6) % newSlides.length;
  const newVisibleSlides =
    newEndIdx >= newStartIdx
      ? newSlides.slice(newStartIdx, newEndIdx)
      : [...newSlides.slice(newStartIdx), ...newSlides.slice(0, newEndIdx)];

  return (
    <div className="col-lg-6 d-md-block cardinboxslg borderMobs arrow-left order-last">
      <div className="d-flex align-items-center heightVh min-vh-100 ">
        <div className="row w-50 cardinboxlayout">
          <div className="col-lg-12">
              <h2>Effortlessly Simplified & Powerful CPaaS Platform.</h2>
              <p className="cardinPara">Explore amazing Communication APIs SMS, Voice, Email, WhatsApp, and many more…</p>
              <ul className="panel_listbox">
                <li>Scale with powerful integrations</li>
                <li>Broadcast messages with WhatsApp Business API</li>
                <li>Targeted Campaigns to deliver personalized offers</li>
                <li>
                  Pre-built templates to test Deliveries before going live
                </li>
                <li>
                  Developer-Friendly Portal with Simple API Documentation
                </li>
                <li>Powerful automation to resolve issues faster</li>
              </ul>
          </div>
          <div className="col-lg-12 mt-3">
              <h4>Integrations</h4>
              <div className="slider-container">
                <ul className="panel_listbox">
                  {visibleSlides.map((image, index) => (
                    <li key={index}>
                      <img
                        className="img-fluid slide"
                        src={image}
                        alt={`Slide ${currentSlideIndex + 1 + index}`}
                      />
                    </li>
                  ))}
                  {[...Array(5 - visibleSlides.length)].map((_, index) => (
                    <li key={index}>
                      <img
                        className="img-fluid slide"
                        src={slides[0]} // Show the first image infinitely
                        alt={`Slide ${currentSlideIndex + 1}`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
          </div>
          <div className="col-lg-12 mt-3">
              <h4>Trusted by 5000+ customers</h4>
              <div className="slider-container">
                <ul className="panel_listbox sliders">
                  {newVisibleSlides.map((image, index) => (
                    <li key={index}>
                      <img
                        className="img-fluid slide"
                        src={image}
                        alt={`Slide ${currentSlideIndex + 1 + index}`}
                      />
                    </li>
                  ))}
                  {[...Array(6 - newVisibleSlides.length)].map((_, index) => (
                    <li key={index + newVisibleSlides.length}>
                      <img
                        className="img-fluid slide"
                        src={newSlides[0]} // Show the first image infinitely
                        alt={`Slide ${currentSlideIndex + 1}`}
                      />
                    </li>
                  ))}
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupMessage;