import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faSearch,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { HOST_URL } from "../Dashboard/api/Api";

class SupportTicket extends Component {
  constructor() {
    const lStorage =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("emp"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;
    const AdminEmail = lStorage.user.username;
    const userPassword = lStorage.user.password;

    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
    super();

    this.state = {
      user_id: userId,
      adminPassword: userPassword,
      userType: isAdmin,
      TodayDate: date,
      AdminEmail: AdminEmail,
      token: userToken,
      allSupportList: null,
      serchData: "",
      CreatedDate: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
    };
  }

  componentDidMount() {
    this.onserch();
  }

  onserch() {
    fetch(
      `${HOST_URL}/support.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({ allSupportList: result });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  searchUser() {
    if (this.state.serchData === "") {
      this.onserch();
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Enter User Name or Ticket ID " });
      this.setState({ bgColor: "alert alert-danger alert-dismissible" });
    } else {
      fetch(
        `${HOST_URL}/support.php?user_id=${this.state.user_id}&method=search&type=${this.state.userType}&keyword=${this.state.serchData}&token=${this.state.token}`
      ).then((response) => {
        response.json().then((result) => {
          // console.log()
          if (result.success === true) {
            this.setState({ successMessage: false });
            this.setState({ allSupportList: result });
          } else {
            this.setState({ successMessage: true });
            this.setState({ errorMessage: result.message });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
          }
        });
      });
    }
  }

  getDifferenceInDays(date1, date2) {
    //console.log('date1',date1)
    //console.log('date2',date2)
  }

  render() {
    //console.log(this.state)
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Support </strong> Management{" "}
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-body">
                      <div className="filter-option">
                        <h6 className="card-subtitle text-muted">Filter </h6>

                        <div className="row">
                          <div className="col-md-9">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                              </div>
                              <input
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    serchData: event.target.value,
                                  });
                                }}
                                placeholder="Enter User Name or Ticket ID "
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-md-3 text-center">
                            <div className="input-group mb-2 mr-sm-2">
                              <input
                                type="button"
                                onClick={() => this.searchUser()}
                                value="Search"
                                className="btn mt-1  btn-primary btn-block btn-sm ml-auto"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pl-2 pr-2">
                        {this.state.successMessage ? (
                          <div className={this.state.successBg} role="alert">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="">{this.state.errorMessage}</div>
                          </div>
                        ) : null}
                      </div>

                      {this.state.allSupportList ? (
                        <div
                          style={{ height: 400 + "px" }}
                          className="table-responsive"
                        >
                          <table className="table table-striped table-xs table-sm my-0">
                            <thead>
                              <tr>
                                <th> Action </th>
                                <th> Parent Email </th>
                                <th> User Email </th>
                                <th> Status </th>
                                <th width="20%"> Subject </th>
                                <th> Reply Peding </th>
                                <th> Created Date / Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allSupportList.data.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <Link
                                      className="mr-3 badge bg-success"
                                      to={
                                        "/admin/support/edit/" +
                                        item.id +
                                        "/" +
                                        item.tkt_number +
                                        "/" +
                                        item.userid
                                      }
                                    >
                                      <FontAwesomeIcon icon={faReply} /> View
                                      Ticket
                                    </Link>
                                  </td>
                                  <td className="table-short-content">
                                    {item.parent_email}
                                  </td>
                                  <td className="table-short-content">
                                    {item.email}
                                  </td>
                                  <td>
                                    {item.status === 1 ? (
                                      <span className="badge bg-danger text-white">
                                        Ticket Open{" "}
                                      </span>
                                    ) : (
                                      <span
                                        style={{ background: "green" }}
                                        className="badge text-white "
                                      >
                                        Ticket Closed
                                      </span>
                                    )}
                                  </td>

                                  <td title={item.title} className="">
                                    {item.title}
                                    <br />
                                    <FontAwesomeIcon
                                      icon={faTicketAlt}
                                    /> :{" "}
                                    <small className="text-danger font-bold">
                                      {item.tkt_number}
                                    </small>
                                  </td>

                                  <td>Pending From </td>
                                  <td>{item.createdDate}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              className="project-empty-img"
                              src={EmptyImg}
                            />
                            <p> No Any Record Found </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}
export default SupportTicket;
