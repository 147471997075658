import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { HOST_URL } from "../api/Api";

class RcsSetting extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      email: "",
      department: "",
      projectList: "",
      messageBody: "",
      parent_id: "",
      errorMessage: "",
      successMessage: false,
      bgColor: "",
    };
  }

  componentDidMount() {
    fetch(
      `${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result);
        if (result.success === true) {
          this.setState({ projectList: result });
        }
      });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Set </strong> Trigger{" "}
                  </h3>
                </div>
              </div>

              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Set Trigger</h5>
                  </div>
                  <hr className="m-0" />
                  <div style={{ marginTop: -15 + "px" }} className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="flex-fill">
                          <form className="new-from">
                            <div className="row">
                              <div className="col-md-12">
                                <label
                                  className="form-label col-form-label"
                                  htmlFor="inputFirstName"
                                >
                                  {" "}
                                  Trigger Name
                                </label>
                                <input
                                  placeholder="Trigger Name"
                                  className="form-control"
                                  type="text"
                                />
                              </div>

                              <div className="col-md-8">
                                <label
                                  className="form-label col-form-label"
                                  htmlFor="inputFirstName"
                                >
                                  When Trigger Run
                                </label>

                                <div className="drop-down-icons">
                                  <select
                                    className="form-control"
                                    name="strategy"
                                  >
                                    <option value="sequence" selected="">
                                      Please Select{" "}
                                    </option>
                                    <option value="parallel">
                                      When a visitor requests a chat
                                    </option>
                                    <option value="random">
                                      When a visitor sends a chat message
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <label
                                  className="form-label col-form-label"
                                  htmlFor="inputFirstName"
                                >
                                  {" "}
                                  Delay Time
                                </label>
                                <div className="drop-down-icons">
                                  <select
                                    className="form-control"
                                    name="strategy"
                                  >
                                    <option value="sequence" selected="">
                                      No Delay
                                    </option>
                                    <option value="parallel">10 Sec</option>
                                    <option value="random">20 Sec</option>
                                    <option value="random">30 Sec</option>
                                    <option value="random">40 Sec</option>
                                    <option value="random">1 Minutes</option>
                                  </select>
                                </div>
                              </div>

                              <hr className="mt-4 mb-0" />

                              <div className="col-md-12">
                                <label
                                  className="form-label col-form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Set Message
                                </label>
                                <textarea
                                  rows="5"
                                  placeholder="Thanku for Message, while we connect you. for Security Purpose May I Know Your name & Contact No."
                                  className="form-control"
                                ></textarea>
                              </div>

                              <div className=" text-right col-md-12">
                                <input
                                  type="button"
                                  onClick={() => this.addNewEmployedd()}
                                  value="Add Trigger"
                                  className="btn mt-5 btn-sm float-right btn-primary"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default RcsSetting;
