import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../api/Api';
class WebHook extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        is_method:'',
        url:'',
        is_json:'',
        updateId:'',
        webhookList:'',
        errorMessage:'',
        successMessage:false,
        bgColor:'',
        updateWebHook:false,
    }
}


setupWebhook() {
    document.querySelector('body').scrollTo(0, 0);
    fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=create&url=${this.state.url}&is_json=${this.state.is_json}&is_method=${this.state.is_method}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
             // console.log(result)
            if(result.success===true){
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
              
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
            }
        })
    }) 
}



UpdateWebhook() {
    fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=update&id=${this.state.updateId}&url=${this.state.url}&is_json=${this.state.is_json}&is_method=${this.state.is_method}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
              //console.log(result)
            if(result.success===true){
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                setTimeout(() => {
                    window.location.reload();
                  }, 2000);
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
            }
        })
    }) 
}

componentDidMount(){
    fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
                 //console.log(result)
            if(result.success===true){
                this.setState({webhookList:result.data});
            }else{
                this.setState({bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
            }
        })
    }) 
}



EditWebhook(id){
    document.querySelector('body').scrollTo(0, 0);
    fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=retrieveid&id=${id}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
                // console.log(result)
                 this.setState({ 
                    updateId:id,
                    updateWebHook:true,
					url: result.data[0].url, 
					is_jsonEdit: result.data[0].is_json,
					is_method: result.data[0].method,
				});
        })
    }) 
}


DeleteWebHook(id){
    document.querySelector('body').scrollTo(0, 0);
    fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=delete&id=${id}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
                // console.log(result)
                 if(result.success===true){
                    this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                    setTimeout(() => {
                        window.location.reload();
                      }, 2000);
                }else{
                    this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
                }
        })
    }) 
}




render() {
   // console.log(this.state)
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h3><strong>Webhook </strong> Setup </h3>
                        </div>
                    </div>
                    <div className="row">
                    
                    {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                            <div className="">
                                {this.state.errorMessage}
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Configure Webhook</h5>
                        </div>
                        <div className="card-body">
                            <form id="webhookFrom">

                                <div className="mb-3">
                                    <input type="url" defaultValue={this.state.url} onChange={(event)=> this.setState({url:event.target.value})} placeholder="Enter Your URL" className="form-control" />
                                </div>
                                <div className='row'>

                                <div className="col-md-6">
                                   <select value={this.state.is_method} onChange={(event)=> this.setState({is_method:event.target.value})} className="form-control">
                                       <option value="">Select Method</option>
                                       <option value="GET">GET</option>
                                       <option value="POST">POST</option>
                                   </select>
                                </div>

                                <div className="col-md-6 mt-2">
                                    <input onChange={(event)=>
                                                {this.setState({is_json:event.target.value}) }}
                                                defaultChecked={this.state.is_jsonEdit===1?'checked':null}
                                        value='1' 
                                        type="checkbox" 
                                        className="form-check-input mr-2"
                                        id="fallback"
                                    /> <span className="p-2">Is JSON</span>  
                                </div>

                        

                                <div className="col-md-12">
                                <div className="webhook">
                                    Below parameters will be added in your API URL. 
                                    <ul>
                                        <li>Mobile</li>
                                        <li>Email</li>
                                        <li>Status</li>
                                        <li>Log ID</li>
                                        <li>Time</li>
                                    </ul>
                                </div>
                                </div>
                                </div>
                                
                                {this.state.updateWebHook?
                                    <div className="mt-3 text-right">
                                <button onClick={()=> this.UpdateWebhook()} type="button" className="btn btn-sm btn-primary">Update WebHook</button>
                                </div>:

                                <div className="mt-3 text-right">
                                <button onClick={()=> this.setupWebhook()} type="button" className="btn btn-sm btn-primary">Create WebHook</button>
                                </div>

                                }
                               

                       


                            </form>
                        </div>
                    </div>


             <div className="">

			<div className="">
			<div className="card flex-fill">
			<div className="card-header">
			<h5 className="card-title mb-0">Webhook List</h5>
			</div>

			<div className="table-responsive">

			{

				this.state.webhookList?

			<table className="table table-hover  project  my-0">
				<thead>
					<tr>
						<th>Id</th>
						<th>URL</th>
						<th>is_json</th>
						<th>Method </th>
						<th>Created Date</th>
						<th>Action</th>
						
					</tr>
				</thead>
				<tbody>


				{
						this.state.webhookList.map((item,i)=>
					<tr key={i}>
						<td>{item.id}</td>
						<td>{item.url}</td>
						<td>{item.is_json}</td>
						<td>{item.method}</td>
						<td>{item.created}</td>

                        <td>
						<span style={{cursor:'pointer'}} onClick={()=> this.EditWebhook(item.id)} className="badge mr-1 bg-primary"> <FontAwesomeIcon icon={faEdit} /> </span> 

                        <span style={{cursor:'pointer'}} className="badge bg-danger"  onClick={()=> this.DeleteWebHook(item.id)}  > <FontAwesomeIcon icon={faTrash} /> </span> 

                        
						</td>

					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img className="project-empty-img" src={EmptyImg} alt="Empty Project" />
			<p>No Webhook Yet</p>
			
			</div>
		</div> 
}

</div>
	</div>
</div>
	</div>



                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default WebHook;