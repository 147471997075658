import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';


class EventList extends Component {

    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
            projectList: null,
            user_id: userId,
            token: userToken,
			successMessage:false,
			bgColor:"alert alert-danger alert-dismissible"
        }
	}


    componentDidMount() {
        fetch(`${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				// console.log(result);
				if(result.success===true){
				this.setState({projectList:result});
				}
            })
        })

	}



render() {  

		return (
			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
					<div className="col-auto d-none d-sm-block">
						<h3><strong>Event </strong> List </h3>
					</div>
				</div>

				{
                     this.state.successMessage? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                        }

				
			<div className="row justify-content-center">
			<div className="col-12 col-lg-12 col-xxl-12 d-flex">
			<div className="card flex-fill">
			<div className="card-header">
			<h5 className="card-title mb-0">Events list</h5>
			</div>

			<div className="table-responsive">

			{

				this.state.projectList?

			<table className="table table-hover  project  my-0">
				<thead>
					<tr>
						<th>Project Id</th>
						<th>Project Name</th>
						<th>Project Website Name</th>
						<th>Created Date</th>
						<th>View Created Events</th>
						
					</tr>
				</thead>
				<tbody>


				{
						this.state.projectList.data.map((item,i)=>
					<tr key={i}>
						<td>{item.id}</td>
						<td>{item.project_name}</td>
						<td>{item.website}</td>
						<td>{item.created}</td>
						<td><Link to={'/dashboard/event-list/'+item.id}><span className="badge bg-success"> <FontAwesomeIcon icon={faAngleDoubleRight} color=''/> View Events</span></Link></td>
					
					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img className="project-empty-img" src={EmptyImg} alt="Empty Project" />
			<p>No Project Yet</p>
			<Link className="btn btn-success btn-sm" to="/dashboard/create-project">Create New Project</Link>
			</div>
		</div> 
}

</div>
	</div>
	</div>
	</div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default EventList;