import React, { Component } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { userloginDetails } from "../Utils/AdminAuth";
import { HOST_URL } from "../Dashboard/api/Api";

class AddBlackList extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const isAdmin = lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      userType: isAdmin,
      ipAddressList: "",
      domainNameList: "",
      domainName: "",
      IpAddress: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
    };
  }

  componentDidMount() {
    this.onload();
  }

  onload() {
    fetch(
      `${HOST_URL}/blacklistDomainIP.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //  console.log('result', result);
        if (result.success === true) {
          this.setState({
            ipAddressList: result.data.ipdata,
            domainNameList: result.data.domaindata,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  addDomain() {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/blacklistDomainIP.php?user_id=${this.state.user_id}&method=addDomain&type=${this.state.userType}&domain=${this.state.domainName}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          this.onload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  addIPAddress() {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/blacklistDomainIP.php?user_id=${this.state.user_id}&method=addIP&type=${this.state.userType}&ip=${this.state.IpAddress}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          this.onload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  deleteDomain(e) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/blacklistDomainIP.php?user_id=${this.state.user_id}&method=removeDomain&type=${this.state.userType}&domain=${e}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          this.onload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  deleteIpAddress(e) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/blacklistDomainIP.php?user_id=${this.state.user_id}&method=removeIP&type=${this.state.userType}&ip=${e}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          this.onload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }
  render() {
    // console.log(this.state)
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Blacklist </strong>{" "}
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="card">
                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                      </div>
                    ) : null}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        domainName: event.target.value,
                                      })
                                    }
                                    placeholder="Enter Domain Name"
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <input
                                      type="button"
                                      onClick={() => this.addDomain()}
                                      value="Add Domain Name "
                                      className="btn btn-sm  btn-primary btn-block text-center "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-lg-12 col-xxl-12 d-flex ">
                            <div className="card flex-fill">
                              {this.state.domainNameList ? (
                                <div
                                  style={{ height: 400 + "px" }}
                                  className="table-responsive"
                                >
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th>Domain Name </th>
                                        <th className=""> Date / Time</th>
                                        <th className="">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.domainNameList.map(
                                        (item, i) => (
                                          <tr key={i}>
                                            <td>{item.domain}</td>
                                            <td>{item.created_date}</td>
                                            <td>
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.deleteDomain(item.domain)
                                                }
                                                className="mr-3 text-danger"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />{" "}
                                                Delete
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <div className="card-body">
                                    <img
                                      alt="Empty Data"
                                      className="project-empty-img"
                                      src={EmptyImg}
                                    />
                                    <p>No Any Spam Word Found </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    onChange={(event) =>
                                      this.setState({
                                        IpAddress: event.target.value,
                                      })
                                    }
                                    placeholder="Enter IP Address "
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-4">
                                  <div className="input-group">
                                    <input
                                      type="button"
                                      onClick={() => this.addIPAddress()}
                                      value="Add IP Address"
                                      className="btn  btn-sm  btn-primary btn-block text-center "
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-lg-12 col-xxl-12 d-flex ">
                            <div className="card flex-fill">
                              {this.state.ipAddressList ? (
                                <div
                                  style={{ height: 400 + "px" }}
                                  className="table-responsive"
                                >
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th>IP Address</th>
                                        <th className=""> Date / Time</th>
                                        <th className=""> Action </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.ipAddressList.map(
                                        (item, i) => (
                                          <tr key={i}>
                                            <td>{item.ip}</td>
                                            <td>{item.created_date}</td>
                                            <td>
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.deleteIpAddress(item.ip)
                                                }
                                                className="mr-3 text-danger"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />{" "}
                                                Delete
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <div className="card-body">
                                    <img
                                      alt="Empty Data"
                                      className="project-empty-img"
                                      src={EmptyImg}
                                    />
                                    <p>No Any Spam Word Found </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default AddBlackList;
