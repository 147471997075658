import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBroadcastTower,faCalendarCheck, faInfoCircle,faMobile,faAddressBook,faBan,faDownload,faSearch} from '@fortawesome/free-solid-svg-icons'
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';

class ReportsDetails extends Component {

 
    constructor (props){

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId= lStorage.user.userId;
		const userToken= lStorage.token;
        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            smsDetailsReportList:'',
            emailDetailsReportList:'',
            voiceDetailsReportList:'',
            responceMessage:'',
            status:'',
            senderid:'',
            mobile:'',
            email:'',
            channel:this.props.match.params.channel,
            dateFrom:this.props.match.params.dateFrom,
            dateTo:this.props.match.params.dateTo,
            formInputType : 'text',
            toInputType : 'text',
        }
    }

componentDidMount() {
    this.detailsReport();
}

detailsReport(){
    //console.log('channel',this.state.channel)
    fetch(`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=${this.state.channel}&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`).then((response)=> {
        response.json().then((result)=>{
             // console.log('result',result)
            if(result.success===true){
                   if(this.state.channel==='sms'){
                    this.setState({smsDetailsReportList:result.message});
                    }
                    if(this.state.channel==='email'){
                        this.setState({emailDetailsReportList:result.message});
                    }if(this.state.channel==='voice'){
                    this.setState({voiceDetailsReportList:result.message});
                    }
                }else{
                    this.setState({responceMessage:result.message});
                }
            })
    })
}

FilderDetailsWithChannel(){
    this.setState({smsDetailsReportList:'',emailDetailsReportList:'',voiceDetailsReportList:''});
    fetch(`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=${this.state.channel}&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&mobile=${this.state.mobile}&sernderid=${this.state.sernderid}&status=${this.state.status}&email=${this.state.email}`).then((response)=> {
        response.json().then((result)=>{
         //  console.log(result)
           if(result.success===true){
                if(this.state.channel==='sms'){
                this.setState({smsDetailsReportList:result.message});
                }
                if(this.state.channel==='email'){
                    this.setState({emailDetailsReportList:result.message});
                }if(this.state.channel==='voice'){
                this.setState({voiceDetailsReportList:result.message});
                }
                }else{
                    this.setState({successMessage:true,errorMessage:result.message,bgColor:"alert alert-danger alert-dismissible"})
                }
        })
    })

}

dataDownload(){
    window.location.href=`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=${this.state.channel}&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
}

formDateType() {
    this.setState({formInputType:'date'});

}

toDateType() {
    this.setState({toInputType:'date'});
}

setStatus(){
    if(this.state.channel==='sms'){
        return (
            <>
            <option value=""> All </option>
            <option value="delivered"> Delivered </option>
            <option value="failed"> Failed </option>
            <option value="submitted"> Submitted </option>
            </>
        )
    }else if (this.state.channel==='voice') {

        return (
            <>
            <option value=""> All </option>
            <option value="success"> Success </option>
            <option value="failed"> Failed </option>
            <option value="submitted"> Submitted </option>
            </>
        )

    }else if (this.state.channel==='email') {

        return (
            <>
            <option value=""> All </option>
            <option value="opened"> Opened </option>
            <option value="sent"> Sent </option>
            <option value="delivered"> Delivered </option>
            <option value="bounce"> Bounce </option>
            </>
        )

    }else{
        return (
            <>
            <option value=""> No Channel Selected </option>
            </>
        )
    }
}

render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
    <div className="main">
        <DashboardHeader />
<main className="content">
<div className="container-fluid p-0">
    <div className=" mb-2 mb-xl-3">
        <div className="col-auto d-none d-sm-block">
            <h3><strong>Reports </strong> Details </h3>
        </div>
    </div>
    <div className="">
        <div className="card">
        <div className="card-header">
                <h5 className="card-title mb-0">
                    <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link to="/dashboard/reports"><li className="breadcrumb-item">Summary </li> </Link>
                            <Link to="/dashboard/details-reports"> <li className="breadcrumb-item active">Details </li> </Link>
                           
                            </ol>
                        </nav>
                    </div>
                </h5>
        </div>

        <div className="card-body">
            <div className="filter-option">
                <h6 className="card-subtitle text-muted">Filter </h6> 
                <div className="row">
                    <div className="col-md-4">
                    <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text"><FontAwesomeIcon icon={faBroadcastTower}/></div>
                            <select value={this.state.channel} onChange={(event) => this.setState({ channel: event.target.value })} className="form-control">
                                <option value=""> Select Channel </option>
                                <option value="sms"> SMS </option>
                                <option value="email"> Email </option>
                                <option value="voice"> Voice </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>
                            <input value={this.state.dateFrom}  onChange={(event) => this.setState({ dateFrom:event.target.value })} type={this.state.formInputType} onFocus={()=>{ this.formDateType()}} placeholder="From Date" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>
                            <input value={this.state.dateTo} onChange={(event) => this.setState({ dateTo:event.target.value })} type={this.state.toInputType} onFocus={()=>{ this.toDateType()}} placeholder="To Date" className="form-control" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faMobile}/></div>
                            {this.state.channel==='email'?
                            <input  onChange={(event) => this.setState({ email:event.target.value })} type="text"  placeholder="Enter Email" className="form-control" />
                            :
                            <input  onChange={(event) => this.setState({ mobile:event.target.value })} type="text"  placeholder="Enter Mobile NO" className="form-control" />
                            }
                           


                        </div>
                    </div>

                        

                        <div className='col-md-4'>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faAddressBook}/></div>
                            <input disabled={this.state.channel!=='sms'}  onChange={(event) => this.setState({ senderid:event.target.value })} type="text"  placeholder="Enter Sender ID" className="form-control" />

                        </div>
                    </div>
                        
                        
             
                    <div className='col-md-4'>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faBan}/></div>
                            <select onChange={(event) => this.setState({ status: event.target.value })} className="form-control">
                                <option value=""> Select Status </option>
                                {
                                    this.setStatus()
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-3">
                        <button type="button" onClick={()=> this.dataDownload()} className="btn-success btn-sm btn-block ml-auto"><FontAwesomeIcon icon={faDownload}/> Download All Reports </button>
                    </div>
                    <div className="col-md-3">
                        <button type="button" onClick={()=> this.FilderDetailsWithChannel()}  className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch}/> Filter Search </button>
                            </div>
                        </div>
                    </div>
        


            

                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div style={{overflow:'scroll',height:500+'px'}} className=" flex-fill">
                        <div  className="">
                        { this.state.channel==='sms' && this.state.smsDetailsReportList!==''?
                            <table className="table table-striped table-sm my-0">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                         <th>Mobile</th>
                                         <th>Cost</th>
                                         <th>Country</th>
                                        <th >Message</th>
                                        <th >Sender ID</th>
                                        <th >Submitted </th>
                                        <th >Delivered </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.smsDetailsReportList.map((item,i)=>
                                        <tr>
                                            <td>{item.status==='Delivered'?<span  className="badge bg-success">Delivered</span>:<span  className="badge bg-danger">{item.status}</span>}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.sms_cost}</td>
                                            <td>{item.country}</td>
                                            <td>
                                            Message <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                            <ReactTooltip className="tooltip"  html={true}  id={"registerTip"+i} place="bottom" effect="solid">
                                             {item.message}
                                            </ReactTooltip>
                                            </td>
                                            <td>{item.sender_id}</td>
                                            <td>{item.submitted_on}</td>
                                            <td>{item.delivered_on}</td>
                                        </tr>
                                        )
                                    }  
                                </tbody>
                            </table>
                           

                            : ( this.state.channel==='email' && this.state.emailDetailsReportList!==''?

                                <table className="table table-striped table-sm my-0">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Email</th>
                                        <th>Cost</th>
                                        <th >Message</th>
                                        <th >Sender</th>
                                        <th >Submitted </th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.emailDetailsReportList.map((item,i)=>
                                        <tr key={i}>

                                            
                                        <td>{item.status==='Delivered' || item.status==='Opened' || item.status==='Sent' || item.status==='Clicked' ?<span  className="badge bg-success">{item.status}</span>:<span  className="badge bg-danger">{item.status}</span>}</td>



                                            <td>{item.to_email}</td>
                                            <td>{item.email_cost}</td>
                                          
                                            <td>
                                            
                                            Message <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                            <ReactTooltip className="tooltip"  html={true}  id={"registerTip"+i} place="bottom" effect="solid">
                                             {item.message}
                                            </ReactTooltip>
                                            
                                            </td>

                                            <td>{item.from_email}</td>
                                            <td>{item.submitted_on}</td>
                                           
                                        </tr>
                                        )
                                    }  
                                </tbody>
                              </table>     
                            : this.state.channel==='voice' && this.state.voiceDetailsReportList!==''?
                            <table className="table table-striped table-sm my-0">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Cost</th>
                                        <th>Country</th>
                                        <th>Message</th>
                                        <th>Call Duration</th>
                                        <th>Attempt</th>
                                        <th>Submitted </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.voiceDetailsReportList.map((item,i)=>
                                        <tr>
                                        <td>{item.status==='Success' || item.status==='Submitted'  ?<span  className="badge bg-success">{item.status}</span>:<span  className="badge bg-danger">{item.status}</span>}</td>
                                        <td>{item.voice_cost}</td>
                                            <td>{item.country}</td>

                                            <td>
                                            Message <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                            <ReactTooltip className="tooltip" html={true}  id={"registerTip"+i} place="bottom" effect="solid">
                                             {item.message}
                                            </ReactTooltip>
                                            
                                            </td>

                                            <td>{item.call_duration}</td>
                                            <td>{item.attempt}</td>
                                            <td>{item.submitted_on}</td>

                                        </tr>
                                        )
                                    }  
                                </tbody>
                            </table>
                            :
                            
                            <div className="text-center">
                            <div className="card-body">
                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                <p>{this.state.responceMessage} in <strong> {this.state.channel}  </strong> Between {this.state.dateFrom} to {this.state.dateTo} </p>
                                </div>
                            </div>

                            )
                           

                        }



                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default ReportsDetails;