import React, { Component } from "react";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import api from "../../axios/Axios";
import Alert from "../UI/Alert/Alert";
import { HOST_URL } from "../Dashboard/api/Api";
class EditEmployee extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("admin"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      successMessage: false,
      selectEmpDetails: "",
      errorMessage: "",
      bgColor: "alert alert-success alert-dismissible",
      emp_id: null,
      emp_token: "",
      name: "",
      mobile: "",
      email: "",
      password: "",
      cnfmPassword: "",
      employeeType: "",
      checkBox: {
        all: null,
        sms: null,
        voice: null,
        email: null,
        whatsapp: null,
        recharge: null,
        notification: null,
        rich_media: null,
        fallback: null,
      },
      eyeIconToggle: false,
      passError: 0,
    };
    this.eyeToggle = this.eyeToggle.bind(this);
    this.handleCnfmPass = this.handleCnfmPass.bind(this);
    this.handlePass = this.handlePass.bind(this);
    this.handleCheckboxAll = this.handleCheckboxAll.bind(this);
    this.HandleEmployeeType = this.HandleEmployeeType.bind(this);
  }

  componentDidMount() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            selectEmpDetails: result.data[0],
            name: result.data[0].fullname,
            email: result.data[0].email,
            mobile: result.data[0].mobile,
            emp_token: result.data[0].token,
            emp_id: result.data[0].id,
            employeeType: result.data[0].user_type,
          });

          this.setState((prevState) => ({
            checkBox: {
              ...prevState.checkBox,

              ["sms"]: result.data[0].sms_menu,
              ["voice"]: result.data[0].voice_menu,
              ["email"]: result.data[0].email_menu,
              ["whatsapp"]: result.data[0].whatsapp_menu,
              ["recharge"]: result.data[0].rechrg_menu,
              ["notification"]: result.data[0].notify_menu,
              ["rich_media"]: result.data[0].rich_menu,
              ["fallback"]: result.data[0].fb_menu,
            },
          }));
          if (
            result.data[0].sms_menu === 1 &&
            result.data[0].voice_menu === 1 &&
            result.data[0].email_menu === 1 &&
            result.data[0].whatsapp_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].rechrg_menu === 1 &&
            result.data[0].notify_menu === 1 &&
            result.data[0].rich_menu === 1 &&
            result.data[0].fb_menu === 1
          ) {
            this.setState((prevState) => ({
              checkBox: {
                ...prevState.checkBox,

                ["all"]: 1,
              },
            }));
          }
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.successMessage !== this.state.successMessage) {
      if (this.state.successMessage === true) {
        this.interval = setInterval(() => {
          this.setState({
            successMessage: false,
          });
        }, 5000);
      }
    }
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        [name]: value,
      },
    }));
  };

  eyeToggle(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      eyeIconToggle: !prevState.eyeIconToggle,
    }));
  }

  handlePass(e) {
    this.setState({
      password: e.target.value,
    });
    if (e.target.value === "" && this.state.cnfmPassword === "")
      this.setState({
        passError: 0,
      });
  }

  handleCnfmPass(e) {
    if (e.target.value === "" && this.state.password === "")
      this.setState({
        passError: 0,
      });

    if (this.state.password === "") {
      return;
    } else {
      this.setState({
        passError: 1,
      });
    }
    const pass = this.state.password;
    const cnfmPass = e.target.value;
    this.setState({
      cnfmPassword: e.target.value,
    });
    if (pass === cnfmPass) {
      this.setState({
        passError: 0,
      });
    } else {
      this.setState({
        passError: 1,
      });
    }
  }
  handleCheckboxAll(e) {
    const { checked } = e.target;
    let value;
    if (checked === true) {
      value = 1;
    } else {
      value = 0;
    }
    this.setState((prevState) => ({
      checkBox: {
        ...prevState.checkBox,
        all: value,
        sms: value,
        voice: value,
        email: value,
        whatsapp: value,
        recharge: value,
        notification: value,
        rich_media: value,
        fallback: value,
      },
    }));
  }

  HandleEmployeeType(e) {
    this.setState({
      employeeType: e.target.value,
    });
  }

  updateEmployedd = async () => {
    if (!this.state.name) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter your name",
      });

      return;
    }
    if (!(this.state.cnfmPassword === this.state.password)) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "The passwords you entered don't match",
      });

      return;
    }

    const updateData = {
      id: this.state.emp_id,
      user_id: this.state.user_id,
      token: this.state.token,
      employee_type: this.state.employeeType,
      email: this.state.email,
      fullname: this.state.name,
      password: this.state.password,
      sms_menu: this.state.checkBox.sms,
      voice_menu: this.state.checkBox.voice,
      email_menu: this.state.checkBox.email,
      whatsapp_menu: this.state.checkBox.whatsapp,
      rechrg_menu: this.state.checkBox.recharge,
      notify_menu: this.state.checkBox.notification,
      rich_menu: this.state.checkBox.rich_media,
      fb_menu: this.state.checkBox.fallback,
      method: "update_emp",
    };
    // const data= await axios.post(`${server}/employee_user_list.php`, updateData)
    const { data } = await api.post("/api/employee_user_list.php", updateData);

    if (data.success === true) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-success alert-dismissible",
        errorMessage: data.message,
      });
    }
    if (data.success === false) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: data.message,
      });
    }
  };

  render() {
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              {this.state.successMessage && (
                <Alert type={this.state.bgColor}>
                  {this.state.errorMessage}
                </Alert>
              )}
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Edit </strong> Employee{" "}
                  </h3>
                </div>
              </div>
              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Update Employee List </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className=" flex-fill">
                          <form>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Employee type
                                </label>
                                <select
                                  onChange={this.HandleEmployeeType}
                                  className="form-control cursor-pointer"
                                >
                                  <option value="">Select Employee</option>
                                  <option
                                    selected={
                                      this.state.employeeType === "subadmin"
                                        ? true
                                        : false
                                    }
                                    value={"subadmin"}
                                  >
                                    Sub Admin
                                  </option>
                                  <option
                                    selected={
                                      this.state.employeeType === "emp"
                                        ? true
                                        : false
                                    }
                                    value={"emp"}
                                  >
                                    Employee
                                  </option>
                                  <option
                                    selected={
                                      this.state.employeeType === "reseller"
                                        ? true
                                        : false
                                    }
                                    value={"reseller"}
                                  >
                                    Reseller
                                  </option>
                                </select>
                              </div>

                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  defaultValue={this.state.name}
                                  onChange={(event) =>
                                    this.setState({
                                      name: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  required
                                />
                              </div>
                              <div
                                className="mb-3 col-md-6 cursor-no-drop
"
                              >
                                <label
                                  className="form-label"
                                  htmlFor="inputEmail4"
                                >
                                  Email
                                </label>
                                <input
                                  readOnly
                                  type="email"
                                  defaultValue={this.state.email}
                                  className="form-control cursor-no-drop
                                  "
                                  placeholder="Email"
                                  disabled
                                />
                              </div>

                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Enter Mobile Number
                                </label>
                                <input
                                  readOnly
                                  type="text"
                                  defaultValue={this.state.mobile}
                                  onChange={(event) =>
                                    this.setState({
                                      mobile: event.target.value,
                                    })
                                  }
                                  className="form-control"
                                  placeholder="Enter 10 Digit Mobile Number"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Password
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      this.state.eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={this.state.password}
                                    onChange={this.handlePass}
                                    className="form-control"
                                    placeholder="Enter new password"
                                    required
                                  />

                                  <button
                                    className="border-0 "
                                    onClick={this.eyeToggle}
                                  >
                                    {this.state.eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>
                              </div>

                              <div className="mb-3 col-md-6">
                                <label
                                  className="form-label"
                                  htmlFor="inputFirstName"
                                >
                                  Confrim Password
                                </label>
                                <div className="d-flex flex-row">
                                  <input
                                    type={
                                      this.state.eyeIconToggle === true
                                        ? "text"
                                        : "password"
                                    }
                                    defaultValue={this.state.cnfmPassword}
                                    onChange={this.handleCnfmPass}
                                    className="form-control"
                                    placeholder="Enter new password"
                                    required
                                  />
                                  <button
                                    className="border-0 "
                                    onClick={this.eyeToggle}
                                  >
                                    {this.state.eyeIconToggle === true ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </button>
                                </div>

                                {this.state.passError === 1 && (
                                  <p
                                    className="text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    *The passwords you entered don't match
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row ml-2">
                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  checked={
                                    this.state.checkBox.all === 1 ? true : false
                                  }
                                  name="all"
                                  value={this.state.checkBox.all}
                                  onChange={this.handleCheckboxAll}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  All
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  checked={
                                    this.state.checkBox.sms === 1 ? true : false
                                  }
                                  name="sms"
                                  value={this.state.checkBox.sms}
                                  onChange={this.handleCheckbox}
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  SMS
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="voice"
                                  checked={
                                    this.state.checkBox.voice === 1
                                      ? true
                                      : false
                                  }
                                  value={this.state.checkBox.voice}
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Voice
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  value={this.state.checkBox.email}
                                  checked={
                                    this.state.checkBox.email === 1
                                      ? true
                                      : false
                                  }
                                  name="email"
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Email
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="whatsapp"
                                  value={this.state.checkBox.whatsapp}
                                  checked={
                                    this.state.checkBox.whatsapp === 1
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Whatsapp
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="recharge"
                                  value={this.state.checkBox.recharge}
                                  checked={
                                    this.state.checkBox.recharge === 1
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Recharge
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="notification"
                                  value={this.state.checkBox.notification}
                                  checked={
                                    this.state.checkBox.notification === 1
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Notification
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="rich_media"
                                  value={this.state.checkBox.rich_media}
                                  checked={
                                    this.state.checkBox.rich_media === 1
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Rich Media
                                </label>
                              </div>

                              <div className="form-check col-md-2">
                                <input
                                  className="form-check-input cursor-pointer
                                  "
                                  type="checkbox"
                                  name="fallback"
                                  value={this.state.checkBox.fallback}
                                  checked={
                                    this.state.checkBox.fallback === 1
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleCheckbox}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Fallback
                                </label>
                              </div>
                            </div>

                            <div className="">
                              <input
                                type="button"
                                onClick={() => this.updateEmployedd()}
                                value="Update Employee Details"
                                className="btn float-right  btn-primary"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default EditEmployee;
