import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { HOST_URL } from "../Dashboard/api/Api";
const DashboardFooter = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const [feedback, setFeedBack] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const shareFeedback = () => {
    fetch(
      `${HOST_URL}/user_suggestion.php?user_id=${userId}&method=create&message=${feedback}&token=${userToken}`
    ).then((response) => {
      response.json().then((result) => {
        console.log(result);
        if (result.success === true) {
          setSuccessMessage(true);
        } else {
        }
      });
    });
  };

  const inputsHandler = (e) => {
    setFeedBack(e.target.value);
  };

  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-12 text-right"></div>
          </div>
        </div>
      </footer>

      <div
        className="modal fade feedback-form"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Help Us Make Better
              </h5>
              <FontAwesomeIcon
                data-dismiss="modal"
                aria-label="Close"
                icon={faTimesCircle}
              />
            </div>
            <div className="modal-body">
              {successMessage ? (
                <div className="alert alert-success" role="alert">
                  Thank you for shareing your feedback.
                </div>
              ) : (
                <textarea
                  className="form-control"
                  onChange={inputsHandler}
                  name="feedback"
                  rows="8"
                  placeholder="Tell Us. How we can improve"
                ></textarea>
              )}
            </div>
            <div className="modal-footer">
              {successMessage ? (
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn w-100 btn-secondary btn-sm"
                >
                  {" "}
                  Close{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={shareFeedback}
                  className="btn w-100 btn-bg-color btn-sm"
                >
                  {" "}
                  Share Feedback{" "}
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faArrowAltCircleRight}
                  />{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardFooter;
