import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt,faEye,faDownload } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL } from '../api/Api';
class WhatsAppCountReports extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
 
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);
        super();
        this.state = {
            user_id: userId,
            token: userToken,
            TodayDate: date,
            channel:'wp',
            smsReports: '',
            smsReportsKeys: '',
            voiceReports: '',
            voiceReportsKeys: '',
            emailReports: '',
            emailReportsKeys: '',
            dateFrom:date,
            dateTo:date,
            SelecteddateFrom:'',
            SelecteddateTo:'',
            errorMessage: '',
            smsSuccessMessage: false,
            voicesuccessMessage: false,
            emailSuccessMessage: false,
            resultUpdate: false,
            bgColor:'',
        }

        this.FilterDateWise = this.FilterDateWise.bind(this);

    } 

    componentDidMount() {
        this.smsReportSummery(this.state.TodayDate,this.state.TodayDate);
    }


    smsReportSummery(dateFrom,dateTo) {
        fetch(`${HOST_URL}/whatsapp_report.php?user_id=${this.state.user_id}&method=summary&token=${this.state.token}&date_from=${dateFrom}&date_to=${dateTo}`).then((response) => {
            response.json().then((result) => {
                   // console.log(result)
                if (result.success === true) {
                    this.setState({ smsReports: result.message, smsReportsKeys: Object.keys(result.message) });
                } else {
                    this.setState({ smsSuccessMessage: true, errorMessage: result.message, bgColor: "alert alert-danger alert-dismissible"})
                }
            })
        })
    }

   
    dataDownload(){
        window.location.href=`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=${this.state.channel}&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
    }


    FilterDateWise() {

        this.setState({ resultUpdate: true, errorMessage:"Result Updated", bgColor: "alert alert-success alert-dismissible"})
        this.smsReportSummery(this.state.dateFrom,this.state.dateTo);
    }


    dateFrom = (date) => {
        let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
         this.setState({
             dateFrom: dated,
               SelecteddateFrom:date
         });
     };
 
     dateTo = (date) => {
         let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
          this.setState({
              dateTo: dated,
              SelecteddateTo:date
          });
      };

    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>WhatsApp</strong> Reports </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="">
                                        <h5 className="card-title mt-5">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to="/dashboard/whatsapp-report"><li className="breadcrumb-item active"> Today's Summary </li> </Link>
                                                        <Link to={'/dashboard/whatsapp-detail-reports/'+this.state.dateFrom+'/'+this.state.dateTo}> <li className="breadcrumb-item "> Detailed Reports </li> </Link>
 
                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        <div className="filter-option">
                                            <h6 className="card-subtitle text-muted">Select Data Range  </h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                                        <DatePicker className="form-control btn-block"
                                                            dateFormat="yyyy/MM/dd"
                                                            value={this.state.dateFrom}
                                                            onChange={this.dateFrom}
                                                            selected={this.state.SelecteddateFrom}
                                                            placeholderText='From Date'
                                                        /> 
 
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                <div className="text-center  btn-block mb-2 mr-sm-2">
                                                <label className="mt-2" for="inlineFormCustomSelectPref">To</label>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
                                                        <DatePicker className="form-control btn-block"
                                                        dateFormat="yyyy/MM/dd"
                                                        value={this.state.dateTo}
                                                        onChange={this.dateTo}
                                                        selected={this.state.SelecteddateTo}
                                                        placeholderText='Date To'
                                                        /> 
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="input-group btn-block mb-2 mr-sm-2">
                                                    <button onClick={() => this.FilterDateWise()} className="btn mt-1 btn-blue btn-sm  btn-block">Search </button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                                        this.state.resultUpdate? 
                                                                <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                            <div className="">
                                                                    {this.state.errorMessage}
                                                                </div>
                                                            </div>
                                                                :
                                                                null
                                                            }


                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="summary-title">WhatsApp</h4>


                                                        {
                                                        this.state.smsSuccessMessage? 
                                                                <div className={this.state.bgColor} role="alert">
                                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                            <div className="">
                                                                    {this.state.errorMessage}
                                                                </div>
                                                            </div>
                                                                :
                                                                null
                                                            }


                                                        {this.state.smsReports ?
                                                            <div className="d-flex summary-data text-center">
                                                                {
                                                                    this.state.smsReportsKeys.map((item, i) =>
                                                                        <div className="mb-1">
                                                                            <span className="text-muted"> {item}  </span> <br /> <Link to={'/dashboard/whatsapp-detail-reports/'+this.state.dateFrom+'/'+this.state.dateTo}> <span className="text-success"> {this.state.smsReports[item]} </span> </Link>
                                                                        </div>
                                                                    )
                                                                }
                                                                
                                                                <div className="mb-1">
                                                                            <span className="text-muted"> View  </span> <br /> <Link  to={'/dashboard/whatsapp-detail-reports/'+this.state.dateFrom+'/'+this.state.dateTo}> <span className="text-success"> 
                                                                            <FontAwesomeIcon icon={faEye}/>
                                                                  </span> </Link>
                                                               </div>


                                                               <div className="mb-1">
                                                                            <span className="text-muted"> Download  </span> <br /> <Link> <span  onClick={()=> this.dataDownload()}className="text-success"> 
                                                                            <FontAwesomeIcon  icon={faDownload}/>
                                                                  </span> </Link>
                                                               </div>
  
   



                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}
export default WhatsAppCountReports;