import React from 'react';
import '../assets/css/app.css'; // Import your CSS file for styling

const AlertBox = ({ type, message, onClose }) => {
  // Define CSS classes based on alert type
  const alertClass = `alert ${type}`;

  return (
    <div className={alertClass}>
      {/* <span className="close-btn" onClick={onClose}>&times;</span> */}
      <p>{message}</p>
    </div>
  );
};

export default AlertBox;