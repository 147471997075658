import React from "react";
import { Redirect, Route } from "react-router-dom";



const StanmaxRoutes = ({ component: Cmp, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
        localStorage.getItem("stanmaxuser") ? (
        <Cmp {...rest} {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default StanmaxRoutes;