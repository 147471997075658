import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardLayout from '../../UI/Layout/DashboardLayout';
import AlertBox from './AlertBox';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../api/Api';

const AgentForm = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const blankDataItem = {
        name: '',
        email: '',
        mobile: '',
        password: '',
        cpassword: ''
    };

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [alert, setAlert] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const PasswordHandler = () => {
        setShowPassword(!showPassword)
    }

    const ConfirmPasswordHandler = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const user = JSON.parse(localStorage.getItem("login"));
    const user_id = user.user.userId;
    const token = user.token;

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    };


    const showAlert = (type, message) => {
        setAlert({ type, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };



    const isValid = () => {
        if (dataItem.name === undefined || dataItem.name === "") {
            showAlert("error", "Please Enter Name")
            return false;
        }
        if (dataItem.email === undefined || dataItem.email === "") {
            showAlert("error", "Please Enter Email")
            return false;
        }
        if (!emailRegex.test(dataItem.email)) {
            showAlert("error", "Please Enter Valid Email")
            return false;
        }
        if (dataItem.mobile === undefined || dataItem.mobile === 0) {
            showAlert("error", "Please Enter Mobile")
            return false;
        }
        if (isNaN(dataItem.mobile) || dataItem.mobile.length !== 10) {
            showAlert("error", "Please Enter Valid Mobile")
            return false;
        }
        if (dataItem.password === undefined || dataItem.password === "") {
            showAlert("error", "Please Enter Password")
            return false;
        }
        if (dataItem.cpassword === undefined || dataItem.cpassword === "") {
            showAlert("error", "Please Enter Confirm Password")
            return false;
        }
        if (dataItem.password !== dataItem.cpassword) {
            showAlert("error", "Password Does not Match")
            return false;
        }
        return true;
    };

    const onSubmit = async () => {
        if (isValid()) {
            const data = {
                user_id: user_id,
                method: "create",
                token: token,
                name: dataItem.name,
                email: dataItem.email,
                mobile: dataItem.mobile,
                password: dataItem.password,
                cpassword: dataItem.cpassword
            };

            try {
                const saveAPI = await fetch(`${HOST_URL}/agent.php`, {
                    method: "post",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(data),
                });

                if (saveAPI.ok) {
                    showAlert('success', 'Data Saved Successfully')
                    setDataItem(blankDataItem);
                    window.location.href = '/dashboard/agent-list';
                } else {
                    showAlert('error', 'Data is not saved')
                    console.error("Failed to save data:", saveAPI.statusText);
                }
            } catch (error) {
                console.error("Error occurred while saving data:", error);
            }
        }

    };

    return (
        <DashboardLayout>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mx-auto">
                                <div className="card mt-5">
                                    <div className="card-header">
                                        <h2 className="text-center">Add Agent</h2>
                                    </div>
                                    <div className="card-body">
                                        {alert && (
                                            <AlertBox
                                                type={alert.type}
                                                message={alert.message}
                                                onClose={() => setAlert(null)}
                                            />
                                        )}
                                        <form>
                                        <div className="form-row">
                                        <div className="form-group col-md-12">
                                                <label htmlFor="email" className="form-label">Email</label> <span style={{color:"red"}}>*</span>
                                                <input type="email" className="form-control" name="email" value={dataItem.email} onChange={onChange} placeholder="Enter Email" />
                                            </div>
                                            </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="fullname" className="form-label">Full Name</label> <span style={{color:"red"}}>*</span>
                                                <input type="text" className="form-control" name="name" value={dataItem.name} onChange={onChange} placeholder="Enter Full Name" />

                                            </div>
                                            
                                            <div className="form-group col-md-6">
                                                <label htmlFor="mobile" className="form-label">Mobile</label> <span style={{color:"red"}}>*</span>
                                                <input type="number" className="form-control" name="mobile" value={dataItem.mobile} onChange={onChange} placeholder="Enter Mobile Number" />

                                            </div>
                                            </div>
                                            
                                          
                                            <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="password" className="form-label">Password</label> <span style={{color:"red"}}>*</span>
                                                <div className="input-group input-group-merge">
                                                    <input type={showPassword ? "text" : "password"} className="form-control" name="password" value={dataItem.password} onChange={onChange} placeholder="Enter Password" />
                                                    <span className="input-group-text cursor-pointer">
                                                        <FontAwesomeIcon
                                                            onClick={PasswordHandler}
                                                            icon={showPassword ? faEyeSlash : faEye}
                                                        />
                                                    </span>
                                                </div>

                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label> <span style={{color:"red"}}>*</span>
                                                <div className="input-group input-group-merge">
                                                    <input type={showConfirmPassword ? "text" : "password"} className="form-control" name="cpassword" value={dataItem.cpassword} onChange={onChange} placeholder="Confirm Password" />
                                                    <span className="input-group-text cursor-pointer">
                                                        <FontAwesomeIcon
                                                            onClick={ConfirmPasswordHandler}
                                                            icon={showConfirmPassword ? faEyeSlash : faEye}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            </div>
                                            <div className='form-row'>
                                                <div className='form-group col-md-2' style={{marginLeft:"60vh"}}>
                                            <button type="button" className="btn btn-primary" style={{ cursor: "pointer" }} onClick={onSubmit}>Submit</button>
                                            </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    );
};

export default AgentForm;