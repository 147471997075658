import React from "react";
import "../../../../style/components/whatsAppPreview.css";
import { bg_whatsapp, whatsapp_brand } from "../../../../images/WhatsAppImages";

function SMSPreview(props) {
  let previewData = props.previewData;

  return (
    <div className="whatsapp">
      {previewData && (
        <>
          <ul>
            <li>
              {" "}
              <strong> Template name: </strong> {previewData.template_name}{" "}
            </li>
            <li>
              {" "}
              <strong> Sender ID: </strong> {previewData.senderid}{" "}
            </li>
            <li>
              {" "}
              <strong> Entity ID : </strong> {previewData.entity_id}{" "}
            </li>
            <li>
              {" "}
              <strong> Template ID : </strong> {previewData.template_id}{" "}
            </li>
          </ul>
          <div
            style={{ backgroundImage: `url(${bg_whatsapp})` }}
            className="whatsapp-review whatsapp-bg"
          >
            <div>
              <div className="text-section">
                <div className="whats-app-header">
                  <div className="">
                    {" "}
                    <img src={whatsapp_brand} />{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p>Authkey.io </p>{" "}
                  </div>
                </div>
                <div className="body-message">
                  <p> {props.smsContent}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SMSPreview;
