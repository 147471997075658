import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';


class RcsAddAgent extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;


super();
    this.state = {
        user_id:userId,
        token:userToken,
        email:'',
        password:'',
        name:'',
        mobile:'',
        brand:'',
        brandList:'',
        channel:[0,0],
        EditChannel:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
    this.brandName = this.brandName.bind(this);
    this.ChannelRcs = this.ChannelRcs.bind(this);
    this.ChannelWhatsaApp = this.ChannelWhatsaApp.bind(this);
    
}

createAgent() {
    fetch(`https://googlercs.authkey.io/authkey/agents.php?userid=${this.state.user_id}&method=create&token=${this.state.token}&brand=${this.state.brand}&name=${this.state.name}&email=${this.state.email}&mobile=${this.state.mobile}&channels=${this.state.channel}&password=${this.state.password}`).then((response)=> {
        response.json().then((result)=>{
            if(result.success===true){
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                setTimeout(function(){window.location = "/dashboard/rcs-agent-list"}, 1000);
                
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
            }
        })
    })
}

componentDidMount() {
    this.getAgentEditData();
    this.getBardList();    
}

getAgentEditData() {
    fetch(`https://googlercs.authkey.io/authkey/agents.php?userid=${this.state.user_id}&method=listid&token=${this.state.token}&id=${this.props.match.params.id}`).then((response)=> {
        response.json().then((result)=>{
           // console.log(result);
            if(result.success===true){
                this.setState({
                    name:result.data[0].name,
                    email:result.data[0].email,
                    mobile:result.data[0].mobile,
                    brand:result.data[0].brand,
                    password:result.data[0].password,
                    EditChannel:result.data[0].channels.split(',')
                })
            }
        })
    })
    
}

updateAgent() {
    fetch(`https://googlercs.authkey.io/authkey/agents.php?userid=${this.state.user_id}&method=edit&token=${this.state.token}&name=${this.state.name}&mobile=${this.state.mobile}&channels=${this.state.channel}&password=${this.state.password}&id=${this.props.match.params.id}`).then((response)=> {
        response.json().then((result)=>{
            if(result.success===true){
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                 setTimeout(function(){window.location.reload()}, 1000);
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:result.message});
            }
        })
    })
}

getBardList(){
    fetch(`https://googlercs.authkey.io/authkey/manage_rcs.php?userid=${this.state.user_id}&method=retrieve&token=${this.state.token}&method=list`).then((response)=> {
        response.json().then((result)=>{
            // console.log(result);
            if(result.success===true){
            this.setState({brandList:result});
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:'Somthing Wrong'});
            }
        })
    })
}

brandName(e) {
    this.setState({brand:e.target.value});
}

ChannelRcs(e) {

    const newchannel = [...this.state.channel];

    if(e.target.checked===true){
        newchannel[0]=1;
    }else{
        newchannel[0]=0;
    }
    this.setState({channel:newchannel})

}

ChannelWhatsaApp(e) {
    const newchannel = [...this.state.channel];

    if(e.target.checked===true){
        newchannel[1]=1;
    }else{
        newchannel[1]=0;
    }
    this.setState({channel:newchannel})
}

render() {
 console.log('channel-1',this.state.brand);
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">

                 <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                                {
                                this.props.match.params.id ?
                                <h3><strong>Edit </strong> Agent Details </h3>
                                :
                                <h3><strong>Add New </strong> Agent </h3>
                                }
                                
                            </div>
                        </div>

                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                <div className="row">
                    <div className="card">

                        <div className="card-body">
                            <div className="row">
                            
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className=" flex-fill">
                                    <form>
                                            <div className="row">
                                           
                                            {
                                            this.state.brandList ?
                                              <div className="mb-3 drop-down-icons col-md-12">
                                                <select disabled={this.props.match.params.id?'disabled':null}  onChange={this.brandName} className="form-control">
                                                    <option value=" ">Select Brand Name</option>
                                                    {
                                                        this.state.brandList.data.map((item, i) =>
                                                            <option key={i} value={item.brand_name}>{item.brand_name}</option>
                                                    )}
                                                </select>
                                                </div>
                                                :
                                                <div className="mb-3 ml-2 col-md-12">
                                                    <p className="form-label"> You don't have any brand  please <Link to="/dashboard/apply-rcs"> Create New One  </Link>  </p>
                                                </div>
                                          }

                                       

                                        <div className="row ml-1">
                                        <label className="form-label col-form-label ml-3" htmlFor="inputEmail4 ">Select channels</label>
                                            <div className="col-md-2">
                                                <span className="ml-4 mt-2"> 
                                                <input onChange={this.ChannelRcs}
                                                    value={1} 
                                                    type="checkbox" 
                                                    defaultChecked={this.state.EditChannel[0]==='1'?'checked':null} 
                                                    className="form-check-input mr-2"
                                                    
                                                /> <span className="p-2"> RCS </span>  </span>
                                            </div>
                                            <div className="col-md-2">
                                                <span className="ml-4 mt-2"> 
                                                <input onChange={this.ChannelWhatsaApp}
                                                    value={1} 
                                                    type="checkbox"
                                                    defaultChecked={this.state.EditChannel[1]==='1'?'checked':null} 
                                                    className="form-check-input mr-2"
                                                    
                                                /> <span className="p-2"> WhatsaApp </span>  </span>
                                            </div>
                                        </div>
                                          
                                          <hr />

                                                <div className="mb-3 col-md-6">
                                               
                                                    <label className="form-label col-form-label"  htmlFor="inputFirstName">Agent Full Name</label>
                                                    <input type="text" defaultValue={this.state.name}  onChange={(event) => this.setState({name: event.target.value })} className="form-control"  placeholder="Enter Full Name" />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label col-form-label" htmlFor="inputEmail4">Email Address</label>
                                                    <input readOnly={this.props.match.params.id?'readonly':null} defaultValue={this.state.email}    onChange={(event) => this.setState({email: event.target.value })}   type="email"   className="form-control" placeholder="Email" />
                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label col-form-label"  htmlFor="">Enter Mobile Number</label>
                                                    <input defaultValue={this.state.mobile}  type="text" onChange={(event) => this.setState({ mobile: event.target.value })} className="form-control"  placeholder="Enter 10 Digit Mobile Number" />
                                                </div>
                                                <div className="mb-3 col-md-6">
                                                    <label className="form-label col-form-label"  htmlFor="">Create Password</label>
                                                    <input autocomplete="new-password"  type="password" onChange={(event) => this.setState({ password: event.target.value })} className="form-control"  placeholder="New Password" />
                                                </div>
                                             </div>
                                          {this.props.match.params.id?

                                            <div className="">
                                            <input type="button" onClick={()=> this.updateAgent()}  value="Update" className="btn btn-sm float-right  btn-primary"/>
                                            </div>
                                          
                                        :

                                        <div className="">
                                                  <input type="button" onClick={()=> this.createAgent()}  value="Add" className="btn btn-sm float-right  btn-primary"/>
                                            </div>

                                        }
                                            
                                     
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsAddAgent;