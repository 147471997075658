

export const customTableStylesForSummery = {
    rows: {
        style: {
            minHeight: '50px', // override the row height
        }
    },
    headCells: {
        style: {
            background: '#3f3966',
            color: '#fff'
        },
    },
    cells: {
        style: {
            color: '#000',
            lineHeight: '22px',
            textAlign:'center'
        },
    },
};

