import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { HOST_URL } from "../Dashboard/api/Api";
class UserPriceDetails extends Component {
  constructor() {
    const lStorage =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("emp"))||
      JSON.parse(localStorage.getItem("reseller"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const empType =lStorage.user.isAdmin;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      emp_type:empType,
      id: "",
      price_update: true,
      emailCost: "",
      countryCode: "",
      addEmailCost: "",
      smsCost: "",
      voiceCost: "",
      voivePulse: "",
      wp_sending_cost: "",
      wp_conv_cost: "",
      successMessage: false,
      selectUserPriceDetails: "",
      seletedUserId: "",
      errorMessage: "",
      bgColor: "",
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.setState({ seletedUserId: this.props.match.params.id });

    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=pricelist&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        console.log("pricelist", result);
        if (result.success === true) {
          this.setState({ selectUserPriceDetails: result.data });
          this.setState({ emailCost: result.email_cost });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  getUserDetails() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=userdetails&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log('user',result);
        if (result.success === true) {
          this.setState({
            email: result.data[0].email,
          });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  addPriceTo() {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=create&country_code=${this.state.countryCode}&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&ret_user_id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  updatePrice(
    id,
    countrycode,
    smscost,
    voiceCost,
    voicepulse,
    wp_conv_cost,
    wp_sending_cost
  ) {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({
      seletedUserId: this.props.match.params.id,
      countryCode: countrycode,
      smsCost: smscost,
      voiceCost: voiceCost,
      voivePulse: voicepulse,
      wp_conv_cost: wp_conv_cost,
      wp_sending_cost: wp_sending_cost,
      price_update: false,
      successMessage: true,
      errorMessage: "Add Price Details",
      bgColor: "alert alert-info alert-dismissible",
      id: id,
    });
  }

  updateUserPrice() {
    this.setState({ seletedUserId: this.props.match.params.id });
    fetch(
      `${HOST_URL}/user_pricelist.php?user_id=${this.state.user_id}&method=update&id=${this.state.id}&type=admin&voice_cost=${this.state.voiceCost}&sms_cost=${this.state.smsCost}&wp_sending=${this.state.wp_sending_cost}&wp_conv=${this.state.wp_conv_cost}&pulse=${this.state.voivePulse}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result);
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  render() {
    //console.log(this.state);
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    User Details{" "}
                    <strong className="text-info"> {this.state.email} </strong>{" "}
                  </h3>
                </div>
              </div>

              <div className="row">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">
                      <div className="col-auto ml-auto text-right mt-n1">
                        <nav className="text-center" aria-label="breadcrumb">
                          <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                            <Link
                              to={
                                "/admin/user-details/" +
                                this.state.seletedUserId
                              }
                            >
                              <li className="breadcrumb-item ">
                                User Details{" "}
                              </li>{" "}
                            </Link>
                            <Link
                              to={
                                "/admin/user-transaction-details/" +
                                this.state.seletedUserId
                              }
                            >
                              <li className="breadcrumb-item">
                                {" "}
                                Transaction Details{" "}
                              </li>
                            </Link>
                            <Link
                              to={
                                "/admin/user-price-details/" +
                                this.state.seletedUserId
                              }
                            >
                              <li className="breadcrumb-item active">
                                {" "}
                                Price Details{" "}
                              </li>
                            </Link>
                            {this.state.emp_type==="reseller"?null:<>
                            <Link
                              to={
                                "/admin/user-add-price/" +
                                this.state.seletedUserId
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item"> Add Credit </li>
                            </Link>
                            <Link
                              to={
                                "/admin/user-entity-pass/" +
                                this.state.seletedUserId
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item">
                                {" "}
                                Entity By Pass{" "}
                              </li>
                            </Link>
                            <Link
                              to={
                                "/admin/change-currency/" +
                                this.state.seletedUserId
                              }
                            >
                              {" "}
                              <li className="breadcrumb-item ">
                                {" "}
                                Change Currency{" "}
                              </li>
                            </Link>
                            </>}
                          </ol>
                        </nav>
                      </div>
                    </h5>
                  </div>

                  <div className="card-body">
                    {this.state.successMessage ? (
                      <div className={this.state.bgColor} role="alert">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                        <div className="">{this.state.errorMessage}</div>
                      </div>
                    ) : null}
                      {this.state.emp_type==="reseller"?null:<>
                    <div className="filter-option">
                      <h6 className="card-subtitle text-muted">
                        Add New Price{" "}
                      </h6>
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="form-label">County Code</label>
                              <input
                                type="text"
                                defaultValue={this.state.countryCode}
                                onChange={(event) =>
                                  this.setState({
                                    countryCode: event.target.value,
                                  })
                                }
                                placeholder="91"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">SMS Cost</label>
                              <input
                                type="text"
                                defaultValue={this.state.smsCost}
                                onChange={(event) =>
                                  this.setState({ smsCost: event.target.value })
                                }
                                placeholder="Rs."
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">Voice Cost</label>
                              <input
                                type="text"
                                defaultValue={this.state.voiceCost}
                                onChange={(event) =>
                                  this.setState({
                                    voiceCost: event.target.value,
                                  })
                                }
                                placeholder="Rs."
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">WP conv cost</label>
                              <input
                                type="text"
                                defaultValue={this.state.wp_conv_cost}
                                onChange={(event) =>
                                  this.setState({
                                    wp_conv_cost: event.target.value,
                                  })
                                }
                                placeholder="Rs."
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">
                                WP Sending Cost{" "}
                              </label>
                              <input
                                type="text"
                                defaultValue={this.state.wp_sending_cost}
                                onChange={(event) =>
                                  this.setState({
                                    wp_sending_cost: event.target.value,
                                  })
                                }
                                placeholder="Rs."
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">Voice Pulse </label>
                              <select
                                value={this.state.voivePulse}
                                onChange={(event) =>
                                  this.setState({
                                    voivePulse: event.target.value,
                                  })
                                }
                                className="form-control"
                              >
                                <option value=""> Select </option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="60">60</option>
                              </select>
                            </div>

                            <div className="col-md-12 text-right">
                              {this.state.price_update ? (
                                <div
                                  style={{ marginTop: 40 }}
                                  className="input-group text-right"
                                >
                                  <input
                                    type="button"
                                    onClick={() => this.addPriceTo()}
                                    value="Add"
                                    className="btn  btn-sm  btn-primary  text-center "
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ marginTop: 40 }}
                                  className="input-group"
                                >
                                  <input
                                    type="button"
                                    onClick={() => this.updateUserPrice()}
                                    value="Update"
                                    className="btn  btn-sm  btn-primary  text-center "
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    </>}
                    <div className="row">
                      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card table-responsive flex-fill">
                          {this.state.selectUserPriceDetails ? (
                            <table className="table  table-hover my-0">
                              <thead>
                                <tr>
                                  <th>Country Code </th>

                                  <th className="">Currency </th>
                                  <th className="">SMS Cost </th>
                                  <th className="">Voice / Pulse Cost </th>
                                  <th className=""> Email </th>
                                  <th className=""> WP conv cost </th>
                                  <th className=""> WP Sending Cost </th>
                                  <th className=""> Date / Time</th>
                                  {this.state.emp_type==="reseller"?null:<>
                                  <th className=""> Edit </th>
                                  </>}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.selectUserPriceDetails.map(
                                  (item, i) => (
                                    <tr key={i}>
                                      <td>
                                        {item.country_name} ({item.country_code}
                                        )
                                      </td>
                                      <td>{item.currency_name}</td>
                                      <td>{item.sms_cost}</td>
                                      <td>
                                        {item.voice_cost} / {item.voice_pulse}
                                      </td>
                                      <td>{this.state.emailCost}</td>
                                      <td>{item.wp_conv_cost}</td>
                                      <td>{item.wp_sending_cost}</td>
                                      <td>{item.created}</td>
                                      {this.state.emp_type==="reseller"?null:<>
                                      <td>
                                        <span
                                          onClick={() =>
                                            this.updatePrice(
                                              item.id,
                                              item.country_code,
                                              item.sms_cost,
                                              item.voice_cost,
                                              item.voice_pulse,
                                              item.wp_conv_cost,
                                              item.wp_sending_cost
                                            )
                                          }
                                          className="badge bg-success"
                                        >
                                          <FontAwesomeIcon icon={faEdit} /> Edit
                                        </span>
                                      </td>
                                      </>}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          ) : (
                            <div className="text-center">
                              <div className="card-body">
                                <img
                                  alt="Empty Data"
                                  className="project-empty-img"
                                  src={EmptyImg}
                                />
                                <p>No Any User Price Found </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default UserPriceDetails;
