import React, { Component } from 'react';
import Title from '../../../UI/Text/Title';
import DashboardLayout from '../../../UI/Layout/DashboardLayout';
import Spining from '../../../UI/Spining/Spining';
import { whatsAppAgent } from '../../api/Api';
import Alert from '../../../UI/Alert/Alert';

class HuntStrategy extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const user_id = lStorage.user.userId;
        const token = lStorage.token;
        super();

        this.state = {
            userId: user_id,
            token: token,
            agentList: '',
            agentID:'',
            hunt_strategy: '',
            isError: false,
            messageType: '',
            errorMessage: ''

        }
    }

    componentDidMount() {
        this.getAgentList();
    }

    getAgentList = () => {
        this.setState({ setIsLoading: true })
        let data = {
            user_id: this.state.userId,
            token: this.state.token,
            method: 'retrieve',
        }
        whatsAppAgent(data).then((res) => {
            if (res.data.success === true) {
                this.setState({
                    agentList: res.data.data,
                    setIsLoading: false
                })
            } else {
                this.setState({
                    isError: true,
                    messageType: 'alert alert-danger',
                    errorMessage: res.data.message,
                    setIsLoading: false
                })
            }
        })
    };

    updateSetting = async () => {
        this.setState({ setIsLoading: true })
        let data = {
            user_id: this.state.userId,
            token: this.state.token,
            hunt_strategy: this.state.hunt_strategy,
            method: 'setting',
        }
        whatsAppAgent(data).then((res) => {
            if (res.data.success === true) {
                this.setState({
                    isError: true,
                    messageType: 'alert alert-success',
                    errorMessage: res.data.message,
                    setIsLoading: false
                })
            } else {
                this.setState({
                    isError: true,
                    messageType: 'alert alert-danger',
                    errorMessage: res.data.message,
                    setIsLoading: false
                })
            }
        })

    };

    getAgentID = () => {

    }

    render() {
        document.querySelector('body').scrollTo(0, 0);
        return (
            <DashboardLayout>
                {this.state.setIsLoading && <Spining />}
                <main className="content">
                    <div className="container-fluid p-0">

                        <Title isButton={true} btnName={` Go Back`} actionURL={`/dashboard/wp-agent-list`} > Agent Setting </Title>
                        <div className="card p-3">

                            {this.state.isError && (
                                <Alert type={this.state.messageType}>
                                    {this.state.errorMessage}
                                </Alert>
                            )}

                            <div className='row'>
                                <div className="mb-3 col-md-6">
                                    
                                    {this.state.agentList &&
                                        <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">
                                            
                                            <select
                                                onChange={this.getAgentID}
                                                className="form-control"
                                                value={this.state.listId}
                                            >
                                                <option value={``}>Select Agent</option>
                                                {this.state.agentList.map((item, index) => (
                                                    <option value={item.id} key={index}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    }
                                </div>
                                <div className="mb-3 col-md-6">
    
                                    <div className="input-group drop-down-icons mb-2 mt-4 mr-sm-2">

                                        <select
                                            onChange={(e)=> this.setState({hunt_strategy : e.target.value})}
                                            className="form-control"
                                        >
                                            <option value={``}>Select Agent</option>
                                            <option value="paralell">Paralell</option>
                                            <option value="sequence">Sequence</option>
                                            <option value="random">Random</option>
                                          
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <input type="button" onClick={this.updateSetting} value="Create Agent" className="btn btn-sm float-right  btn-primary" />
                            </div>

                        </div>


                    </div>
                </main>
            </DashboardLayout >
        );
    }
}

export default HuntStrategy;