import React, { Component } from 'react';
import AdvanceDataTable from '../../../UI/Table/AdvanceDataTable';
import Title from '../../../UI/Text/Title';
import DashboardLayout from '../../../UI/Layout/DashboardLayout';
import {AGENT_LIST} from '../../../UI/Table/ColumnConst';
import { whatsAppAgent } from '../../api/Api';
class AgentList extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const user_id = lStorage.user.userId;
        const token = lStorage.token;
        super();

        this.state = {
            userId: user_id,
            token: token,
            setIsLoading: true,
            agentList: null,
        }
    }

    componentDidMount() {
        this.getAgentList();
    }

    getAgentList = () => {
            this.setState({ setIsLoading: true })
            let data = {
                user_id: this.state.userId,
                token: this.state.token,
                method:'retrieve',
            }
            whatsAppAgent(data).then((res)=>{
                console.log('res', res.data);
                if(res.data.success === true){
                    this.setState({
                        agentList: res.data.data,
                        setIsLoading: false
                    })
                }else{
                    this.setState({
                        isError:true,
                        messageType:'alert alert-danger',
                        errorMessage: res.data.message,
                        setIsLoading: false
                    })
                }
            })
        };

    render() {
        return (
            <DashboardLayout>
                  <main className="content">
                        <div className="container-fluid p-0">
                            <Title isButton={true} btnName={`Create New Agent`} actionURL={`/dashboard/add-agent`} >  Agent List </Title>
                            <AdvanceDataTable
                                tableName={'Agent List'}
                                tableCell={AGENT_LIST}
                                tableData={this.state.agentList}
                                isLoading={this.state.setIsLoading}
                            />
                        </div>
                    </main>
            </DashboardLayout>
        );
    }
}

export default AgentList;