import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import richMediaImage from '../../Dashboard/assets/img/rich-media.png';
import { HOST_URL } from '../api/Api';


class TestRichMedia extends Component {

constructor () {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        richMedaiList:'',
        yid:null,
        template_name:'',
        title:'',
        message_type:"",
        content:'',
        sender_id:'',
        actionLinksName:[],
        actionValue:[],
        image_url:'',
        image_url_local:'',
        video_url:'',
        video_url_local:'',
        videoPreviewshow:false,
        authkey:'',
        mobile:'',
        richMediaType:1,
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
    this.getTemplateDetails = this.getTemplateDetails.bind(this);
}

componentDidMount() {
    this.getUserDetails();
    this.GetTemplateList();
}

getUserDetails(){
    fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
        response.json().then((result) => {
            // console.log(result)
            if (result.success === true) {
                this.setState({authkey:result.data[0].authkey})
            }
        })
    })
}

MobielNumberCheck(){
    document.querySelector('body').scrollTo(0, 0);
    console.log(this.state.mobile.length);
    if(this.state.mobile===''){
        this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:"Please Enter Mobile Number"});

    }else if(this.state.mobile.length!==10){
        this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:"Enter 10 digit mobile number."});
    }
    else{
        fetch(`${HOST_URL}/yulore_cheknumber.php?user_id=${this.state.user_id}&token=${this.state.token}&mobile=${this.state.mobile}`).then((response)=> {
            response.json().then((result)=>{
                // console.log(result);
                if(result.status===404){
                    this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:"This mobile number does't support rich media content"});
                }else{
                    this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:"Test Message Send"});
                    this.sendTestTemplate();
                }
            })
        })

    }
}

sendTestTemplate(){
    fetch(`https://api.authkey.io/request?authkey=${this.state.authkey}&mobile=${this.state.mobile}&country_code=91&yid=${this.state.yid}`).then((response)=> {
        response.json().then((result)=>{
            // console.log(result);
        })
    })
}

GetTemplateList(){
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
           // console.log(result)
            if(result.success===true){
            this.setState({richMedaiList:result});
            }
        })
    })
}

getTemplateDetails(e){
    this.setState({yid:e.target.value})
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieveid&id=${e.target.value}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            console.log('data',result.data);
            if(result.success===true){
                this.setState({ 
                    template_name: result.data.template_name, 
                    title: result.data.title, 
                    content: result.data.content,
                    sender_id: result.data.senderid,
                    action_links: result.data.action_links,
                    image_url_local: result.data.image_url,
                    message_type: result.data.message_type,
                    video_url_local: result.data.video_url,
                    richMediaType:result.data.message_type,
                });



                var actionLinksName =[];
                for (var i = 0; i < result.data.action_links.length ; i++) {
                        
                    actionLinksName.push({
                        label : JSON.parse(result.data.action_links[i]).action_content,
                        value : JSON.parse(result.data.action_links[i]).action_to
                      })
                } 

                this.setState({actionLinksName:actionLinksName});
                

                setTimeout(()=>{
                    this.setState({ videoPreviewshow:true });
                  }, 3000)
                
            }else{

            }
        })
    })
    
}

render() {
    //console.log(this.state)
    return (
<div className="wrapper">
    <DashboardLeftMenu />
    <div className="main">
        <DashboardHeader />
        <main className="content">
            <div className="container-fluid p-0">
                <div className="row mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3><strong> Test Rich  </strong> Media </h3>
                    </div>
                </div>

                {
                    this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <div className="">
                                {this.state.errorMessage}
                            </div>
                        </div>
                    :
                    null
                }


                <div className="row justify-contect-center">
                <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Send Message on Mobile </h5>
                    </div>
                    <hr className="m-0" />
                    <div className="card-body">
                    
                        <form id="newtiecktid">
                
                            <div style={{background:'#f7f7f7',borderRadius:0}} className="">
                                <div className="mb-3 col-md-12">
                                <label className="col-form-label">Test Message Mobile Number</label>
                                    <input className="form-control" onChange={(event) => this.setState({ mobile: event.target.value })} placeholder="XXXXXXXXXX" type="number"/>
                                    <small className="pb-2 d-block"> Enter Mobile Number to received test message.
                                </small>
                                </div>
                            </div>

                            <div className="row">
                                
                                {
                                this.state.richMedaiList ?
                                <div className="mb-3 drop-down-icons col-md-12">
                                    <select onChange={this.getTemplateDetails} className="form-control ">
                                        <option value="">Select Test Templte</option>
                                        {
                                            this.state.richMedaiList.data.map((item, i) =>
                                                <option key={i} value={item.id}>{item.template_name}</option>
                                            )}
                                    </select>
                                    </div>
                                    :
                                    <div className="mb-3 ml-2 col-md-12">
                                        <p className="form-label"> You don't have any test template please <Link to="/dashboard/create-rich-media-template"> Create New One  </Link>  </p>
                                    </div>
                            }
                                
                            </div>

                            {this.state.yid ?
                            <>

                          


                            { 
                            
                                
                            
                            this.state.richMediaType===1?

                            <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}     className="mt-3">

                          <div className="row p-3">

                                <div className="col-md-4">
                                <label className="col-form-label"> Uploaded Image </label>
                                    <img alt="rich Media" className="img-fluid" src={this.state.image_url_local ? this.state.image_url_local : richMediaImage } />
                                </div>

                                <div className="col-md-8">
                                <label className="col-form-label"> Description Message </label>
                                        <textarea readOnly rows="7" defaultValue={this.state.content} placeholder="Description message" className="form-control"></textarea>
                                </div>
    
                            </div>

                            
                                {this.state.actionLinksName?
                                    <>
                                        {this.state.actionLinksName.map((item,index)=>{
                                        return (
                                            <div key={index} className="row p-3">
                                            <div className="mb-3 col-md-12">
                                                <label className="col-form-label"> {item.label} </label>
                                                <input  readOnly type="text" value={item.value} className="form-control" id="inputPasswordNew2"/>
                                            </div>
                                        </div>
                                        )
                                    })}
                                    </>
                                    :
                                    null
                                    }



                                </div>


                            :

                            this.state.richMediaType===2?

                            <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}     className="mt-3">

                            <div className="row p-3">
                            <div className="col-md-2"></div>
                                <div className="col-md-4">
                                    <label className="col-form-label d-block"> Uploaded Video </label>
                                    
                                        <video height="100%" style={{width:100+'%',marginTop:-18+'px'}} autoPlay muted loop>
                                                <source  src={ this.state.video_url_local ? this.state.video_url_local : 'https://www.w3schools.com/html/mov_bbb.mp4' }/>
                                        </video>
                                
                                </div>

                                <div className="col-md-4">
                                    <label className="col-form-label"> Video Thumbnail Image </label>
                                    <img alt="rich Media" className="img-fluid mt-2" src={this.state.image_url_local ? this.state.image_url_local : richMediaImage } />
                                </div>

                            </div>

                            <div className="p-3">
                            <label className="col-form-label"> Description </label>
                                    <textarea readOnly defaultValue={this.state.content}placeholder="Description message" className="form-control"></textarea>
                            </div>

                           
                            {this.state.actionLinksName?
                                <>
                                    {this.state.actionLinksName.map((item,index)=>{
                                    return (
                                        <div key={index} className="row p-3">
                                        <div className="mb-3 col-md-12">
                                            <label className="col-form-label"> {item.label} </label>
                                            <input  readOnly type="text" value={item.value} className="form-control" id="inputPasswordNew2"/>
                                        </div>
                                    </div>
                                    )
                                })}
                                </>
                                :
                                null
                                }



                        </div>

                            :

                            null
                                
                            }

                            </>
                            
                            :
                            null
                        }

                            <button type="button" onClick={()=> this.MobielNumberCheck()} className="btn btn-sm mt-3 float-right btn-primary">Send Test Message </button>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default TestRichMedia;