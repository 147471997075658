import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor, { buttonList } from "suneditor-react";
import { HOST_URL } from '../api/Api';

class SupportHistory extends Component {
  
	constructor(props) {
		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

    super(props);
       this.state = {
			selectedTicketDetials:'',
            message:'',
            user_id: userId,
            token: userToken,
            status:this.props.match.params.status,
			successShow:false,
			successMessage:"",
            errorMessage:'',
			bgColor:''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.allmessage();
	}

    allmessage(){
        fetch(`${HOST_URL}/support.php?user_id=${this.state.user_id}&method=ticketdetail&&type=client&token=${this.state.token}&id=${this.props.match.params.id}`).then((response)=> {
            response.json().then((result)=>{
				 // console.log(result)
				if(result.success===true){
					this.setState({
                        selectedTicketDetials:result.data,
                        status:result.data[0].status,
                    });
				}else{
					this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
				}
            })
        })
    }


   
    ReplyMessage() {
        document.querySelector('body').scrollTo(0, 0);
        const reply = ({
            user_id:this.state.user_id,
            type:'client',
            message:this.state.message,
            id:this.props.match.params.id,
            replyuserid:this.state.user_id,
            method:'reply',
            token:this.state.token,
        });
        fetch(`${HOST_URL}/support.php`,{
        method: "post",
        headers:{
            'content-Type':'application/json'
        },
        body: JSON.stringify(reply)
        }).then((result)=>{
            result.json().then((result)=>{
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                this.allmessage();
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    handleChange(message){
        this.setState({message:message});
    }
	

render() {
    //console.log(this.state)
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto  d-sm-block">
                            <h3><strong>  View    </strong> Ticket </h3>
                        </div>
                    </div>
                    <div className="row">
                    
                    <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0"> <strong> Ticket ID : {this.props.match.params.tiketid} </strong>  </h5>
                            {/* <small> Ticket Generated Date : <strong> {this.state.selectedTicketDetials.createdDate} </strong> </small> */}
                        </div>
                        <hr className="m-0"/>
                        <div className="card-body">
                       
                       
                       <div className="messaging">
                             <div className="inbox_msg">
                               <div className="">
                               {this.state.selectedTicketDetials ?
                                 <div className="">
                       
                                       {
                                           this.state.selectedTicketDetials.reverse().map((item,i)=>
                       
                                       <>
                                           {item.update_by==='client' ?
                                            <div className="incoming_msg">
                                            <div className="received_msg">
                                            <div className="received_withd_msg">
                                            <span>You</span> 
                                                <p dangerouslySetInnerHTML={{ __html: item.message }}  className="mt-1"></p>
                                                <span className="time_date text-right"> {item.createdDate} </span></div>
                                            </div>
                                            </div>
                                            :
                                            null
                                           }
                                          
                                          {item.update_by==='emp' || item.update_by==='admin' ?
                                           <div className="outgoing_msg">
                                           <div className="sent_msg">
                                           <span>Authkey</span> 
                                           <p dangerouslySetInnerHTML={{ __html: item.message }}  className="text-white"></p>
                                           <span className="time_date"> {item.createdDate} </span> 
                                           </div>
                                           </div>
                                           :
                                           null
                                          }
                                       </>
                                         )
                                       }
                       
                                       </div>
                       
                                       :
                                       null
                                                   
                                   }
                       
                                  
                       
                       
                       
                               
                       
                       
                                
                                   {this.state.status==='2' ?

                                    <div className="alert alert-danger alert-dismissible">
                                        <p>This ticket has been closed permanently, if you have any concern then please  <Link to="/dashboard/create-new-ticket"> create new ticket </Link></p> 
                                    </div>

                                    :
                                   
                                 <div className="filter-option">
                                    <h6 className="card-subtitle text-muted">Reply Message </h6> 
                                    <div className="row">
        
                                    <div className="col-md-12">
                                        <SunEditor setContents={this.state.message} height="300" onChange={this.handleChange} setOptions={{
                                            buttonList: buttonList.complex
                                        }}
                                            placeholder="Please type here..." />
                                    </div>

                                        <div className="col-md-12 text-right">
                                            <div className="input-group mb-2 mr-sm-2">
                                                <input type="button" onClick={()=> this.ReplyMessage()} value="Send" className="btn mt-1  btn-primary btn-sm ml-auto text-right "/>
                                            </div>
                                        </div>
                                         </div>
                                        </div>
                                     }
        
                               </div>
                             </div>
                           </div>
                       
                                               
                                               </div>
                    </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default SupportHistory;