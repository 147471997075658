import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import Alert from "../../../UI/Alert/Alert";
import Title from "../../../UI/Text/Title";
import { HOST_URL, WhstappAccountList } from "../../api/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";

const WhatsappConvDownload = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const [alert, setAlert] = useState({
    successMessage: false,
    bgColor: "",
    errorMessage: "",
  });
  const [brandNo, setBrandNo] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  function addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  const days = 30;

  var maxDatee = addDays(startDate, days);

  useEffect(() => {
    downloadConv();
  });

  const downloadConv = async () => {
    try {
      let dataForBrandNo = {
        token: token,
        user_id: user_id,
        method: "retrieve",
      };

      await WhstappAccountList(dataForBrandNo).then((res) => {
        if (res.data.success === true) {
          setBrandNo(res.data.data[0].brand_number);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const startDateHandler = (date) => {
    setStartDate(date);
  };
  const endDateHandler = (date) => {
    setEndDate(date);
  };

  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <Title> WhatsApp Conversation Download</Title>
          <div className="">
            {alert.successMessage && (
              <Alert type={alert.bgColor}>{alert.errorMessage}</Alert>
            )}

            <div className="card">
              <div className="card-body">
                {/* <h3>Send a Test Message</h3> */}

                <div className="d-flex ml-2">
                  
                  <h5 className="card-subtitle text-muted mt-1 ml-2 mr-2">
                    From:
                  </h5>
                  <ReactDatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => startDateHandler(date)}
                    selectsStart
                    startDate={startDate}
                  />
                  <h5 className="card-subtitle text-muted mt-1 mx-2">To:</h5>
                  <ReactDatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={endDate}
                    onChange={(date) => endDateHandler(date)}
                    selectsEnd
                    startDate={startDate}
                    minDate={startDate}
                    maxDate={maxDatee}
                  />

                  <div className="ml-2">
                    <a
                      href={`${HOST_URL}/netcore_conversation.php?user_id=${user_id}&token=${token}&method=chat_download&brand_number=${brandNo}&date_from=${startDate}&date_to=${endDate}`}
                      className="btn btn-success"
                    >
                      download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};

export default WhatsappConvDownload;
