import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONVERSATION_API } from "../../../../constants/ServiceConstants";
import {
  faPaperPlane,
  faPaperclip,
  faImage,
  faVolumeUp,
  faVideo,
  faCross,
} from "@fortawesome/free-solid-svg-icons";
import api from "../../../../axios/Axios";
import { HOST_URL } from "../../api/Api";
import SendWhstappMessagepop from "../components/NewReplyPopUp";
import styles from "./Reply.module.css";

const Reply = (props) => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage ? lStorage.user.userId : "8367";
  const token = lStorage ? lStorage.token : "e7a90f3eace43f0f5ba3bae6fdfc6d16";

  const [content, setContent] = useState("");
  const [attachment, setAttachment] = useState(false);
  const [attachmentType, setAttachmentType] = useState();
  const [file, setFile] = useState();
  const [filePopUp, setFilePopUp] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fileSize, setFileSize] = useState(1);
  const [caption, setCaption] = useState("");
  const inputRef = useRef(null);
  const textareaRef = useRef(null);
  const [error, setError] = useState({
    error: false,
    errorMessage: "",
    errorType: "",
  });

  useEffect(() => {
    const handleResize = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        const newHeight = Math.min(textareaRef.current.scrollHeight, 90);
        textareaRef.current.style.height = `${newHeight}px`;
        textareaRef.current.style.overflowY =
          textareaRef.current.scrollHeight > 90 ? "auto" : "hidden";
      }
    };

    const textarea = textareaRef.current;
    textarea.addEventListener("input", handleResize);

    return () => {
      textarea.removeEventListener("input", handleResize);
    };
  }, []);
  useEffect(() => {
    setContent("");
  }, [props]);

  const updateMessage = () => {
    props.sentData(props.selectMobile);
  };

  const uploadWhatsAppMedia = async () => {
    try {
      var data = await new Promise((resolve, reject) => {
        const data = new FormData();
        data.append("amdfile", file);
        data.append("doc_name", "test MK");
        data.append("doc_type", attachmentType);
        data.append("user_id", user_id);
        data.append("token", token);
        data.append("method", "create");
        let url = "";
        fetch(`${HOST_URL}/uploadFileWhatsapp.php`, {
          method: "POST",
          body: data,
        }).then((result) => {
          result.json().then((resp) => {
            resolve(resp.url);
          });
        });
      });
      // setFile(undefined);
      return data;
    } catch (error) {
      console.log("error");
    }
  };
  const sendMessage = async () => {
    setError({
      error: false,
    });

    if (attachment === true) {
      setButtonLoader(true);
      let url = await uploadWhatsAppMedia();
      const { data } = await api.post(CONVERSATION_API, {
        token: token,
        user_id: user_id,
        method: "media_reply",
        attachment_url: url,
        caption: caption,
        message_type: attachmentType,
        brand_number: props.brand_number,
        mobile: props.selectMobile,
        content: content,
        agent_id: "1",
      });
      if (data.success === true) {
        // setFile(undefined);
        setPreviewUrl("");
        setFileSize(1);
        setAttachmentType(undefined);
        setFilePopUp(false);
        updateMessage();
        setAttachment(false);
        setButtonLoader(false);
        setContent("");
        setCaption("");
      } else {
        setFilePopUp(false);
        setButtonLoader(false);
        setError({
          error: true,
          errorMessage: data.message,
          errorType: "alert-danger",
        });
      }
    } else {
      const { data } = await api.post(CONVERSATION_API, {
        token: token,
        user_id: user_id,
        method: "reply",
        brand_number: props.brand_number,
        mobile: props.selectMobile,
        content: content,
      });

      if (data.success === true) {
        updateMessage();
        setContent("");
      } else {
        setError({
          error: true,
          errorMessage: data.message,
          errorType: "alert-danger",
        });
      }
    }
  };

  const fileChangedHandler = (type) => {
    setAttachmentType(type);
    inputRef.current.click();
  };

  const [isOpen, setIsOpen] = useState(false);

  const togglePopUp = () => {
    setIsOpen(!isOpen);
  };

  const togglePopup1 = () => {
    setFilePopUp(!filePopUp);
    setFile(undefined);
    setPreviewUrl("");
    setFileSize(1);
    setAttachmentType(undefined);
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    setFilePopUp(!filePopUp);
    document.body.style.overflow = !filePopUp ? "hidden" : "auto";

    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      setFileSize(fileSizeInMB);
      if (file && file.type.startsWith("video/")) {
        const objectURL = URL.createObjectURL(file);
        setPreviewUrl(objectURL);
        setFile(file);
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    } else {
      setFile(null);
      setPreviewUrl("");
    }
  };
  const ponupCancel = () => {
    setError({
      error: false,
      errorMessage: "",
      errorType: "",
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      // event.preventDefault();
    } else if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="row reply">
      {error.error && (
        <div className={`whatsapp-alert alert ${error.errorType}`}>
          {error.errorMessage}
          <span> </span>
          <span>
            <button onClick={togglePopUp} className="btn btn-success btn-xs">
              click here to send fresh message
            </button>
          </span>
          <div
            className="float-right text-black p-2 cursor-pointer"
            onClick={ponupCancel}
          >
            x
          </div>
        </div>
      )}

      {isOpen && (
        <div
          style={{
            zIndex: "1000",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(5px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "90%",
              backgroundColor: "white",
              padding: "5px",
              borderRadius: "5px",
              textAlign: "center",
              overflowY: "auto",
            }}
          >
            <SendWhstappMessagepop selectedmob={props.selectMobile} />
          </div>
          <button
            style={{
              border: "none",
              fontSize: "30px",
              backgroundColor: "transparent",
              color: "white",
              position: "absolute",
              top: "10px",
              right: "160px",
            }}
            onClick={togglePopUp}
          >
            x
          </button>
        </div>
      )}

      {/* <div className="attachment">hi</div> */}

      <div>
        {/* <button onClick={togglePopup1}>Open Popup</button> */}
        {filePopUp && (
          <div className={styles.popup}>
            <div className={styles.popupInner}>
              <button className={styles.cancelButton} onClick={togglePopup1}>
                x
              </button>
              {previewUrl && (
                <div>
                  <h4>Preview:</h4>
                  <div className={styles.popupcontent}>
                    {file.type.startsWith("video/") ? (
                      <video controls width="500">
                        <source src={previewUrl} type={file.type} />
                        Your browser does not support the video tag.
                      </video>
                    ) : file.type === "application/pdf" ? (
                      <iframe
                        title="pdf"
                        src={previewUrl}
                        style={{ width: 200, height: 300 }}
                      ></iframe>
                    ) : (
                      <img
                        src={previewUrl}
                        alt="Preview"
                        style={{ maxWidth: "50%", maxHeight: "50%" }}
                      />
                    )}
                  </div>
                  {fileSize > 10.0 && (
                    <div>
                      <small className="text-danger">
                        *File size should be smaller than 10MB
                      </small>
                    </div>
                  )}
                  <textarea
                    type="text"
                    value={caption}
                    className="form-control mt-4"
                    placeholder="Enter caption (optional)"
                    onChange={(e) => setCaption(e.target.value)}
                  ></textarea>
                </div>
              )}
              {buttonLoader === false ? (
                <div
                  onClick={fileSize > 10.0 ? null : sendMessage}
                  className="col-sm-2 col-xs-2 text-center reply-send float-right mt-5 mb-2 mr-2"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
              ) : (
                <div className="col-sm-2 col-xs-2 text-center reply-send float-right mt-5 mb-2 mr-2">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {attachment && (
        <div className="col-md-12">
          <input
            ref={inputRef}
            accept={`${attachmentType}/*`}
            style={{ display: "none" }}
            type="file"
            onChange={fileHandler}
          />

          <div className="attachment">
            <FontAwesomeIcon
              title="Image"
              onClick={() => fileChangedHandler("image")}
              role="button"
              icon={faImage}
            />
            <FontAwesomeIcon
              title="Video"
              onClick={() => fileChangedHandler("video")}
              role="button"
              icon={faVideo}
            />
            <FontAwesomeIcon
              title="File"
              onClick={() => fileChangedHandler("file")}
              role="button"
              icon={faPaperclip}
            />
          </div>
        </div>
      )}

      <div className="col-md-1">
        <div className="d-flex mt-2 justify-content-center align-items-cente absolutebtm">
          <FontAwesomeIcon
            role="button"
            onClick={() => setAttachment(!attachment)}
            size="lg"
            icon={faPaperclip}
          />
          {/* <div role='button' className="ml-3">😊 </div> */}
        </div>
      </div>

      <div className="col-sm-9 col-xs-9 reply-main">
        <textarea
          ref={textareaRef}
          onChange={(e) => setContent(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
          className="form-control chat_input"
          rows="1"
          value={content}
          placeholder="Type Message"
          id="comment"
        ></textarea>
      </div>

      <div className="col-sm-2 col-xs-2">
        <div
          onClick={sendMessage}
          className="text-center reply-send absolutebtnBtm"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </div>
      </div>
    </div>
  );
};

export default Reply;
