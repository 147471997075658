import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor, { buttonList } from "suneditor-react";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class AddFaq extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            title: '',
            url: '',
            meta: '',
            post_details: '',
            cat_name: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.titleHandler = this.titleHandler.bind(this);
    }


    componentDidMount() {
        this.FatchFaqTemplate();
    }

    AddnewFaq() {

        document.querySelector('body').scrollTo(0, 0);

        const addFaq = ({ user_id: this.state.user_id, user_type: this.state.userType, title: this.state.title, url: this.state.url, meta: this.state.meta, post_details: this.state.post_details, cat_name: this.state.cat_name, method: 'create', token: this.state.token });

        fetch(`${HOST_URL}/faq.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(addFaq)
        }).then((result) => {
            result.json().then((resp) => {

                if (resp.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: resp.message
                    })
                    setTimeout(function () { window.location = "/admin/faq-list"; }, 2000);
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: resp.message
                    })
                }
            })
        })
    }


    FatchFaqTemplate() {
        fetch(`${HOST_URL}/faq.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&user_type=${this.state.userType}&method=retrieveid&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({
                        cat_name: result.data[0].cat_name,
                        meta: result.data[0].meta,
                        content: result.data[0].post_details,
                        title: result.data[0].title,
                        url: result.data[0].url,
                    });
                }
            })
        })
    }


    updateFaq() {

        document.querySelector('body').scrollTo(0, 0);

        const addFaq = ({ user_id: this.state.user_id, user_type: this.state.userType, title: this.state.title, url: this.state.url, meta: this.state.meta, post_details: this.state.post_details, method: 'update', id: this.props.match.params.id, token: this.state.token, cat_name: this.state.cat_name });

        fetch(`${HOST_URL}/faq.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(addFaq)
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {

                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: resp.message
                    })
                    setTimeout(function () { window.location = "/admin/faq-list"; }, 2000);
                } else {

                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: resp.message
                    })
                }
            })
        })
    }


    handleChange(content) {
        this.setState({ post_details: content });
    }


    titleHandler(e) {
        var spacesString = this.state.title;
        var newSrt = spacesString.replace(/[^a-zA-Z ]/g, "")
        var removedSpacesText =
            newSrt.split(" ").join("-").toLowerCase();
        this.setState({ url: removedSpacesText })
    }

    render() {
        console.log(this.state);
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Add New </strong> FAQ </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">

                                    {
                                        this.state.successMessage ?
                                            <div className={this.state.bgColor} role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                                <div className="">
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                    <div className="card">
                                        <div className="card-body">

                                            <form>
                                                <div className="row">
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="inputFirstName"> Title </label>
                                                        <input type="text" defaultValue={this.state.title} onChange={(event) => this.setState({ title: event.target.value })} className="form-control" placeholder="Enter Page Tiitle" />
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="inputEmail4">Custom Canonical URL </label>
                                                        <input type="text" onMouseEnter={this.titleHandler} defaultValue={this.state.url} onChange={(event) => this.setState({ url: event.target.value })} className="form-control" placeholder="Page Slag URL" />
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="inputFirstName">Meta Description</label>
                                                        <textarea rows="5" defaultValue={this.state.meta} onChange={(event) => this.setState({ meta: event.target.value })} className="form-control" placeholder="Enter Meta Discription max 150 character "></textarea>
                                                    </div>
                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="inputFirstName">Post Details</label>
                                                        <SunEditor setContents={this.state.content} height="300" onChange={this.handleChange} setOptions={{
                                                            buttonList: buttonList.complex
                                                        }}
                                                            placeholder="Please type here..." />
                                                    </div>

                                                    <div className="mb-3 col-md-12">
                                                        <label className="form-label" htmlFor="inputFirstName"> Category Name </label>
                                                        <input type="text" defaultValue={this.state.cat_name} onChange={(event) => this.setState({ cat_name: event.target.value })} className="form-control" placeholder="Enter Page Tiitle" />
                                                    </div>

                                                </div>

                                                {this.props.match.params.id ?

                                                    <div className="float-right">
                                                        <input onClick={() => this.updateFaq()} type="button" value="Update Post" className="btn btn-sm  btn-primary" />
                                                    </div>
                                                    :
                                                    <div className="float-right">
                                                        <input onClick={() => this.AddnewFaq()} type="button" value="Publish Post" className="btn btn-sm  btn-primary" />
                                                    </div>
                                                }

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default AddFaq;