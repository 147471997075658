import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartBar } from '@fortawesome/free-solid-svg-icons' ;

function ScoreCard({title,icon,type,value}) {
   
  return (
    <div className="homeCardBorder">
      <div className="card-body ">
        <h5 className="card-title mb-2">{title} </h5>
        <span className="homeIcon">
            {type==='icon' ? <FontAwesomeIcon icon={icon} /> : <img src={icon} /> }
          
        </span>
        <h4 className="">
          {value}
        </h4>
      </div>
      <div className="bottmHome">
        <span className="">
          <i className="mdi mdi-arrow-bottom-right"></i> 0%{" "}
        </span>
        <span className="">last week</span>
        <FontAwesomeIcon style={{ float: "right" }} icon={faChartBar} />
      </div>
    </div>
  );
}

export default ScoreCard;
