import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft,faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../Dashboard/api/Api';
class RichMediaViewTemplate extends Component {

	constructor(){

        const lStorage = JSON.parse(localStorage.getItem("admin"));
        const userId= lStorage.user.userId;
        const userToken= lStorage.token;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            richMediaType:'image',
            template_name:'',
            title:'',
            is_active:'',
            message_type:"",
            content:'',
            sender_id:'',
            actionLinksName:[],
            actionLinks:[],
            actionValue:[],
            image_url:'',
            image_url_local:'',
            video_url:'',
            video_url_local:'',
            successMessage:false,
            errorMessage:'',
            bgColor:'',
        }
        
    }
    
    componentDidMount(){
        this.onserch();
    }

onserch(){
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieveid&id=${this.props.match.params.id}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            //console.log('data',result.data);
            if(result.success===true){
                this.setState({ 
                    template_name: result.data.template_name, 
                    title: result.data.title, 
                    content: result.data.content,
                    sender_id: result.data.senderid,
                    action_links: result.data.action_links,
                    image_url_local: result.data.image_url,
                    message_type: result.data.message_type,
                    video_url_local: result.data.video_url,
                    richMediaType:result.data.message_type,
                    is_active:result.data.is_active,
                });

                  let actionLinksName = [];

                    for (var i = 0; i < result.data.action_links.length ; i++) {
                        
                        actionLinksName.push({
                            label : JSON.parse(result.data.action_links[i]).action_content,
                            value : JSON.parse(result.data.action_links[i]).action_to
                          })
                    } 
                 
                this.setState({actionLinksName:actionLinksName})

                setTimeout(()=>{
                    this.setState({ videoPreviewshow:true });
                  }, 3000)
                
            }else{

            }
        })
    })
}



ActiveRichMediaTemplate(id){
    document.querySelector('body').scrollTo(0, 0);
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=changestatus&id=${id}&status=${this.state.is_active}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
          // console.log(result.success);
            if(result.success === true ){
                this.setState({successMessage:true,errorMessage:result.message,bgColor:"alert alert-success alert-dismissible"});
                this.onserch();
                } else {
                    this.setState({successMessage:true,errorMessage:result.message,bgColor:"alert alert-danger alert-dismissible"})
                }
        })
    })
}


render() {
   // console.log(this.state);
		return (
<div className="wrapper">
    <AdminLeftMenu />
        <div className="main">
            <AdminHeader />
               <main className="content">
				<div className="container-fluid p-0">

                <div className="text-right mb-2">
                    <Link to="/admin/rich-media"> <button className="btn btn-success btn-sm"> <FontAwesomeIcon icon={faArrowLeft} color=''  /> Go Back </button>  </Link>
                </div>

                <div className="card">
                        

                    <div className="card-body mt-1">

                    {
                                      this.state.successMessage? 
                                    <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                          </button>
                                <div className="">
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                                    :
                                    null
                        }
                           
                           
					<div className="row mb-2 mb-xl-3">
                    <div className="col-md-6 text-left">
						<div className="col-auto d-none d-sm-block">
							<h3><strong> Rich Media </strong> Request </h3>
						</div>
						</div>

                        <div className="col-md-6 text-right">
                            {
                                this.state.is_active===1?
                                <button onClick={()=> this.ActiveRichMediaTemplate(this.props.match.params.id)} className="btn btn-danger btn-sm"> <FontAwesomeIcon icon={faCheckCircle} color=''  /> Make inactive </button>
                                :
                                <button onClick={()=> this.ActiveRichMediaTemplate(this.props.match.params.id)} className="btn btn-success btn-sm"> <FontAwesomeIcon icon={faCheckCircle} color=''  /> Make Active </button>

                            }
                               


                                </div>

					</div>


               
					{

                    this.state.richMediaType===1?

<div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}    className="mt-3">

<div className="row p-3">
<div className="col-md-4">
<label className="col-form-label"> Uploaded Image </label>
    <img alt="rich Media" className="img-fluid" src={this.state.image_url_local } />
</div>

<div className="col-md-8">
<label className="col-form-label"> Description Message </label>
        <textarea readOnly rows="7" defaultValue={this.state.content} placeholder="Description message" className="form-control"></textarea>
</div>

<div className="col-md-12 mt-2">
<label className="col-form-label"> Title </label>
     <input readOnly defaultValue={this.state.title} className="form-control" type="text" />
</div>

</div>

{this.state.actionLinksName?
    <>
        {this.state.actionLinksName.map((item,index)=>{
        return (
            <div key={index} className="row p-3">
            <div className="mb-3 col-md-12">
                <label className="col-form-label"> {item.label} </label>
                <input  readOnly type="text" value={item.value} className="form-control" id="inputPasswordNew2"/>
            </div>
        </div>
        )
    })}
    </>
    :
    null
    }



    </div>


:

this.state.richMediaType===2?

<div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}     className="mt-3">

<div className="row p-3">
<div className="col-md-2"></div>
    <div className="col-md-4">
        <label className="col-form-label d-block"> Uploaded Video </label>
        
            <video height="100%" style={{width:100+'%',marginTop:-18+'px'}} autoPlay muted loop>
                    <source  src={ this.state.video_url_local ? this.state.video_url_local : 'https://www.w3schools.com/html/mov_bbb.mp4' }/>
            </video>
    
    </div>

    <div className="col-md-4">
        <label className="col-form-label"> Video Thumbnail Image </label>
        <img alt="rich Media" className="img-fluid mt-2" src={this.state.image_url_local} />
    </div>

</div>

<div className="p-3">
<label className="col-form-label"> Description </label>
        <textarea readOnly defaultValue={this.state.content}placeholder="Description message" className="form-control"></textarea>
</div>

<div className="col-md-12 mt-2">
<label className="col-form-label"> Title </label>
     <input readOnly defaultValue={this.state.title} className="form-control" type="text" />
</div>


    {this.state.actionLinksName?
    <>
        {this.state.actionLinksName.map((item,index)=>{
        return (
            <div key={index} className="row p-3">
            <div className="mb-3 col-md-12">
                <label className="col-form-label"> {item.label} </label>
                <input  readOnly type="text" value={item.value} className="form-control" id="inputPasswordNew2"/>
            </div>
        </div>
        )
    })}
    </>
    :
    null
    }

  


    </div>

    :

    null
        
    }
    
</div>
</div>



				</div>
			</main>
            <AdminFooter />
        </div>
    </div>

    );
 }
}
export default RichMediaViewTemplate;