import React from 'react';
import {WHATSAPP_OPTIN_OPTOUT} from '../../../../../constants/ServiceConstants'
import api from '../../../../../axios/Axios';
import Dialog from 'react-dialog';
import { useState } from 'react';

function OptOutNumber(props) {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const userToken= lStorage.token;

    const [isDialogOpen,setisDialogOpen]=useState(false);
    const [mobile,setmobile]=useState(null);
    
    const optPut = async () => {  
        try {
            const { data } = await api.post(WHATSAPP_OPTIN_OPTOUT, {
              token: userToken,
              user_id: userId,
              method: 'optin',
              mobile: mobile,
            });
            if (data.success === true) {
             setTimeout(function () { window.location = "/dashboard/whatsapp-optin-otpout"; },);
            }
          } catch (err) {
              console.log(err)
          }
    
    }

   const openDialog = (e) => {
    setisDialogOpen(true);
    setmobile(e);
   }

   const handleClose = () => {
    setisDialogOpen(false)
   }

    return (

        <>
        {
            isDialogOpen &&
            <Dialog
                title={'Alert'}
                modal={true}
                height={500}
                isDraggable={true}
                onClose={handleClose}
                buttons={
                    [{
                        text: "No",
                        className: 'btn-info btn-sm btn mr-2',
                        onClick: () => handleClose()
                    },
                    {
                        text: "Yes",
                        className: 'btn btn-sm btn-danger',
                        onClick: () => optPut()
                    }

                    ]
                }>
                <p>Are You Sure ? Want to OPT IN This Number {mobile} </p>
            </Dialog>
        }

        <span onClick={()=> openDialog(props.mobile)}  className='badge bg-success mr-2'> OPTIN Number </span>

        </>
    );
}

export default OptOutNumber;