import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle,faEdit,faPlus,faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../api/Api';

class RcsApplyedList extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        richMedaiList:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}

componentDidMount() {
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            // console.log(result);
            if(result.success===true){
            this.setState({richMedaiList:result});
            }
        })
    })
}

deleteTemplate(id) {
    document.querySelector('body').scrollTo(0, 0);
    fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=delete&id=${id}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
           // console.log(result);
            if(result.success===true){
                this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:result.message});
                setTimeout(function(){window.location = "/dashboard/rich-media-template"}, 2000);
            }
        })
    })
}


render() {
    document.querySelector('body').scrollTo(0, 0);
    return (
        
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">

                 <div className="row mb-2 mb-xl-3">
								<div className="col-auto d-none d-sm-block">
									<h3><strong>Rich Media </strong> List </h3>
								</div>

								<div className="col-auto ml-auto text-right mt-n1">
									<Link className="btn btn-primary btn-sm" to="/dashboard/create-rich-media-template"> <FontAwesomeIcon icon={faPlus} color='' /> Add Template Rich Media  </Link>
								</div>
							</div>


                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                    <div className="row">
                    
                    <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Rich Media List </h5>
                        </div>
                        <hr className="m-0" />
                        <div className="text-left">
                      
                        {

                            this.state.richMedaiList?

		            	<div className="table-responsive">


                            <table className="table table-hover  project  my-0">
                                <thead>
                                    <tr>
                                        <th>YID</th>
                                        <th>Template Name</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>

                                
                                {
                                        this.state.richMedaiList.data.map((item,i)=>
                                    <tr key={i}>
                                        <td>{item.id}</td>
                                        <td>{item.template_name}</td>
                                        <td>{item.created}</td>
                                        <td>
                                            {item.is_active===0?

                                            <span className="badge bg-danger"> <FontAwesomeIcon icon={faCheckCircle} color=''/> Pending </span>
                                            :

                                            <span className="badge bg-success"> <FontAwesomeIcon icon={faCheckCircle} color=''/> Active </span>

                                        }
                                           
                                            
                                            </td>
                                        <td>
                                            <Link to={'/dashboard/create-rich-media-template/edit/'+item.id}><span className="badge bg-info"> <FontAwesomeIcon icon={faEdit} color=''/> Edit / View  </span></Link>

                                           <span onClick={()=>this.deleteTemplate(item.id)} className="badge bg-danger ml-1"> <FontAwesomeIcon icon={faTrashAlt} color=''/> </span>
                                            </td>
                                    </tr>)
                                }

                                   

                            </tbody>
                            </table>
                            </div>

                        :<div className="text-center">
                        <div className="card-body">
                            <p>No Recod Found....</p>
                            </div>
                        </div> 
                        }

                           
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsApplyedList;