import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import { HOST_URL } from '../api/Api';

class NotificationStatus extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        DailyUsageStatus:false,
        LowBalanceStatus:false,
        LowBalanceAmount:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}


componentDidMount() {
    this.getUserStatus()
}

getUserStatus(){
    fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
        response.json().then((result) => {
            if (result.success === true) {
                
                if (result.data[0].is_daily_usage === 1) {
                    this.setState({ DailyUsageStatus: true});
                } else {
                    this.setState({ DailyUsageStatus: false});
                }

                this.setState({ LowBalanceAmount: result.data[0].low_balance_val});

                if (result.data[0].is_low_balance === 1) {
                    this.setState({LowBalanceStatus: true});
                } else {
                    this.setState({ LowBalanceStatus: false});
                }
            }
        })
    })
}


updateStatus(){

    let DailyUsagestatus =0 ;
    let LowBalance =0 ;
    
    if(this.state.DailyUsageStatus){
        DailyUsagestatus=1;
    }

    if(this.state.LowBalanceStatus){
        LowBalance=1;
    }


    fetch(`${HOST_URL}/manage_daily_usage_report.php?user_id=${this.state.user_id}&status=${DailyUsagestatus}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            console.log(result)
            if (result.success === true) {
               
                this.setState({ 
                    successMessage:true,
                    bgColor:'alert alert-success alert-dismissible',
                    errorMessage:result.data,
                });
              //  setTimeout(function(){window.location = "/dashboard/notification";}, 2000);
            }else{

                this.setState({ 
                    successMessage:true,
                    bgColor:'alert alert-danger alert-dismissible',
                    errorMessage:result.data,
                });
            }
            
        })
    })

    fetch(`${HOST_URL}/manage_low_balance_report.php?user_id=${this.state.user_id}&status=${LowBalance}&amount=${this.state.LowBalanceAmount}&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            if (result.success === true) {
                this.setState({ 
                    successMessage:true,
                    bgColor:'alert alert-success alert-dismissible',
                    errorMessage:result.data,
                });
                setTimeout(function(){window.location = "/dashboard/notification";}, 2000);
            }else{
                this.setState({ 
                    successMessage:true,
                    bgColor:'alert alert-danger alert-dismissible',
                    errorMessage:result.data,
                });
            }
            
        })
    })
}


toggleDailyStatus = () => {
    this.setState({ DailyUsageStatus: !this.state.DailyUsageStatus }) 
}

toggleLowBalance = () => {
    this.setState({ LowBalanceStatus: !this.state.LowBalanceStatus }) 
}


render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h3><strong>Notification </strong> Setup </h3>
                        </div>
                    </div>
                    <div className="row">
                    
                    {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                            <div className="">
                                {this.state.errorMessage}
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className="card">
                        <div className="card-body">   

                        
                        <div className="notification-list">
                            <p>Get Daily Usage Reports on your email </p>
                            <label className="switch">
                            <input
                            type="checkbox"
                            onChange={this.toggleDailyStatus}
                            checked={this.state.DailyUsageStatus}
                            />
                            <span className="slider round"></span>
                            </label>
                        </div>
                        <hr className="mt-0 mb-2" />
                        <div className="notification-list">
                            <p> Low Balance Alert Email </p>
                            <p>Enter Low Balance Threshold:
                            
                            <input
                                 type="text" style={{width:200+'px',height:20+'px'}} 
                                 onChange={(event) => this.setState({ LowBalanceAmount: event.target.value})}
                                 defaultValue={this.state.LowBalanceAmount}
                                 className="form-control"
                            /></p>

                            <label className="switch">
                            <input
                            type="checkbox"
                            onChange={this.toggleLowBalance}
                            checked={this.state.LowBalanceStatus}
                            />
                            <span className="slider round"></span>
                            </label>
                        </div>

                        <hr className="mt-0 mb-0" />
                        <div className="text-right d-block">
                         <button onClick={()=> this.updateStatus()} className="btn btn-sm mt-4 btn-primary">Update and Save </button>
                        </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default NotificationStatus;