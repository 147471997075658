import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../Dashboard/assets/img/signupDone.gif";
import logo from "../Dashboard/assets/img/logo.png";
import { Link } from "react-router-dom";
import LoginSignupMessage from "./siteComponents/LoginSignupMessage";
import ReactTelInput from "react-telephone-input";
import flags from "../Dashboard/assets/img/flags.png";
import Select from "react-select";
import { HOST_URL } from "../Dashboard/api/Api";
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      mobile: "",
      password: "",
      currency: "INR",
      otp: "",
      emailotp: "",
      allCountryList: [],
      method: "send",
      isChecked: false,
      IndiaUser: true,
      countryCode: "",
      loginScreen: true, // true
      otpScreen: false, // false
      isLoading: true,
      successMessage: false,
      signupDone: false,
      errorMessage: "",
      bgColor: "",
      defaultCountry: "in",
      countryCode: "91",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleInputChange = (telephone, selectedCountry) => {
    if (selectedCountry) {
      const { dialCode, iso2 } = selectedCountry;
      this.setState({
        countryCode: dialCode,
        selectedCountryCode: iso2,
      });
    }
  };

  componentDidMount() {
    this.getAllCountryList();
    var url = document.location.href;
    var mainURL = "console.authkey.io";
    var localHost = "localhost:3000";
    if (url.indexOf(localHost) !== -1 || url.indexOf(mainURL) !== -1) {
      console.log("URL test passed");
    } else {
      window.location = "/my-account";
    }
  }

  getAllCountryList() {
    fetch(`${HOST_URL}/country_master.php`).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        let allCountryList = [];
        if (result.success === true) {
          for (var i = 0; i < result.data.length; i++) {
            allCountryList.push({
              label: result.data[i].country_name,
              value: result.data[i].country_code,
            });
          }
          this.setState({
            allCountryList: allCountryList,
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  handleChange = (selectedCountry) => {
    // console.log(selectedCountry)
    this.setState({
      countryCode: selectedCountry.value,
    });
  };

  checkBoxhandle = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  };

  signUpIndia() {
    document.querySelector("body").scrollTo(0, 0);
    if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/"
    ) {
      this.signup(null);
    } else {
      this.signup(this.props.match.params.source);
    }
  }

  signup(source) {
    if (this.state.isChecked) {
      const signup = {
        email: this.state.email,
        name: this.state.name,
        password: this.state.password,
        mobile: this.state.mobile,
        country_code: this.state.countryCode,
        currency: this.state.currency,
        method: "send",
        source: source,
      };
      this.setState({ isLoading: false });
      fetch(`${HOST_URL}/signup_india.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(signup),
      }).then((result) => {
        result.json().then((resp) => {
          //  console.log(resp);
          if (resp.success === true) {
            this.setState({
              loginScreen: false,
              isLoading: true,
              otpScreen: true,
              successMessage: false,
            });
          } else {
            this.setState({
              successMessage: true,
              isLoading: true,
              bgColor: "alert alert-danger alert-dismissible",
              errorMessage: resp.message,
            });
          }
        });
      });
    } else {
      this.setState({
        successMessage: true,
        isLoading: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please accept terms and conditions",
      });
    }
  }

  VerifyMobile() {
    document.querySelector("body").scrollTo(0, 0);
    var url = document.location.href;
    if (
      window.location.pathname === "/signup" ||
      window.location.pathname === "/signup/"
    ) {
      const signup = {
        email: this.state.email,
        name: this.state.name,
        mobile: this.state.mobile,
        password: this.state.password,
        country_code: this.state.countryCode,
        currency: this.state.currency,
        method: "verify",
        otp: this.state.otp,
        emailotp: this.state.emailotp,
        source: "",
      };
      this.setState({ isLoading: false });
      fetch(`${HOST_URL}/signup_india.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(signup),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === true) {
            resp["url"] = url;
            this.setState({
              successMessage: false,
              loginScreen: false,
              otpScreen: false,
              signupDone: true,
              isLoading: true,
            });
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            setTimeout(function () {
              window.location = "/signup-success";
            });
          } else {
            this.setState({ successMessage: true, isLoading: true });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
            this.setState({ errorMessage: resp.message });
          }
        });
      });
    } else {
      var url = document.location.href;
      const signup = {
        email: this.state.email,
        name: this.state.name,
        mobile: this.state.mobile,
        password: this.state.password,
        country_code: this.state.countryCode,
        currency: this.state.currency,
        method: "verify",
        otp: this.state.otp,
        emailotp: this.state.emailotp,
        source: this.props.match.params.source,
      };
      this.setState({ isLoading: false });
      fetch(`${HOST_URL}/signup_india.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(signup),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === true) {
            resp["url"] = url;
            this.setState({
              successMessage: false,
              loginScreen: false,
              otpScreen: false,
              signupDone: true,
              isLoading: true,
            });
            localStorage.setItem("login", JSON.stringify(resp));
            localStorage.setItem("admin", JSON.stringify({ success: false }));
            setTimeout(function () {
              window.location = "/signup-success";
            });
          } else {
            this.setState({ successMessage: true, isLoading: true });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
            this.setState({ errorMessage: resp.message });
          }
        });
      });
    }
  }

  responseFacebook = (response) => {
    var url = document.location.href;
    const loginWithFacebook = {
      email: response.email,
      name: response.name,
      api_response: response,
    };
    fetch(`${HOST_URL}/facebook_login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(loginWithFacebook),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true && resp.account === "old") {
          this.setState({
            show: true,
            bgColor: "alert alert-success alert-dismissible",
            loginMessage: "success",
          });
          resp["url"] = url;
          localStorage.setItem("login", JSON.stringify(resp));
          localStorage.setItem("admin", JSON.stringify({ success: false }));
          setTimeout(function () {
            window.location = "/dashboard";
          });
        } else if (resp.success === true && resp.account === "new") {
          resp["url"] = url;
          this.setState({
            show: true,
            bgColor: "alert alert-success alert-dismissible",
            loginMessage: "success",
          });
          localStorage.setItem("login", JSON.stringify(resp));
          localStorage.setItem("admin", JSON.stringify({ success: false }));
          setTimeout(function () {
            window.location = "/signup-success";
          });
        } else {
          this.setState({
            show: true,
            bgColor: "alert alert-danger alert-dismissible",
            loginMessage: resp.message,
          });
        }
      });
    });
  };

  render() {
    const { selectedCountry } = this.state;
    return (
      <div style={{overflow:"auto"}}>
        <Helmet>
          <title>
            Free signup for SMS API Voice API Email API | Signup |Authkey
          </title>
          <meta
            name="description"
            content="Free signup- SMS API Voice API Email API, Send SMS Voice or Email parallelly or use other channels as Fallback. Signup for free"
          />
          <link rel="canonical" href="https://authkey.io/signup/signup" />
          <style type="text/css">{`
                    @media only screen and (max-width: 600px) {
                        .mobile-img {
                        display: none;
                        }
            }
        `}</style>
        </Helmet>

        <section>
          <div className=" container-fluid d-flex flex-column">
            <div className="row justify-content-center heightVh min-vh-100">
              <LoginSignupMessage />
              <div className="col-lg-6">
                <div className="login-new-box sm-p-0">
                  {this.state.loginScreen ? (
                    <form className="form-row col-md-6 col-lg-10 pl-md-5 pr-sm-0 mt-4">
                      {/* <FacebookLogin
                            appId="4884679621657801"
                            textButton="Signup With Facebook"
                            autoLoad={false}
                            fields="name,email"
                            callback={this.responseFacebook}
                          />

                          <div className="form-group col-md-12 mt-4">
                            <hr className="mt-0 mb-0" />{" "}
                            <span className="or-center">
                              or use Email address & password
                            </span>
                          </div> */}

                      <div className="form-group col-md-12">
                        {this.state.successMessage ? (
                          <div className={this.state.bgColor} role="alert">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="">{this.state.errorMessage}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-0 col-md-12 text-left login-box">
                        <Link to="/">
                          <img
                            className="img-fluid mb-3"
                            src={logo}
                            style={{ height: "32px" }}
                          />
                        </Link>
                        <h3 className="">Create a free account now </h3>
                      </div>
                      <div className="form-group col-md-6  ">
                        <label className="form-control-label">Name</label>
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={(event) => {
                              this.setState({ name: event.target.value });
                            }}
                            className="form-control logininput"
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>

                      <div className="form-group col-md-6 ">
                        <label className="form-control-label">Email</label>
                        <div className="input-group">
                          <input
                            type="email"
                            onChange={(event) => {
                              this.setState({ email: event.target.value });
                            }}
                            className="form-control logininput"
                            placeholder="Enter Email"
                          />
                        </div>
                      </div>

                      <div className="col-md-12 ">
                        <label className="form-control-label">
                          Select Your Country{" "}
                        </label>

                        <div className="form-row">
                          <div className="input-group col-md-12 mb-1">
                            <Select
                              value={selectedCountry}
                              onChange={this.handleChange}
                              options={this.state.allCountryList}
                              placeholder={"Enter Your Country Name"}
                              closeMenuOnSelect={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-md-12">
                        <label className="form-control-label">Mobile</label>
                        <div className="input-group">
                          <div className="input-group-prepend ReactTelFullbox">
                            <ReactTelInput
                              defaultCountry={this.state.defaultCountry}
                              flagsImagePath={flags}
                              onChange={this.handleInputChange}
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control logininput"
                            placeholder="Enter Mobile Number"
                            onChange={(event) => {
                              this.setState({ mobile: event.target.value });
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group col-md-12 ">
                        <label className="form-control-label">Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            onChange={(event) => {
                              this.setState({
                                password: event.target.value,
                              });
                            }}
                            className="form-control logininput"
                            placeholder="Enter Strong Password"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="ml-4">
                          <input
                            type="checkbox"
                            onChange={this.checkBoxhandle}
                            value={this.state.isChecked}
                            className="form-check-input mr-2"
                            id="fallback"
                          />{" "}
                          <small
                            style={{ display: "block", lineHeight: "20px" }}
                            className="mb-2 pl-2"
                          >
                            By submitting this form, you agree to the{" "}
                            <a href="https://authkey.io/term-and-condition">
                              Terms of Use
                            </a>{" "}
                            and have read the{" "}
                            <a href="https://authkey.io/privacy-policy">
                              Privacy Policy
                            </a>
                          </small>
                        </div>
                      </div>

                      <div className="mt-4 col-md-12  m-auto">
                        {this.state.isLoading ? (
                          <button
                            type="button"
                            onClick={() => this.signUpIndia()}
                            className="btn w-100 btn-block btn-sm btn-primary"
                          >
                            {" "}
                            create an account{" "}
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-block btn-sm btn-primary"
                          >
                            <span className="spinner-border text-white spinner-border-sm"></span>{" "}
                            Please Wait..
                          </button>
                        )}
                      </div>

                      <div className="form-group text-center col-md-12 ">
                        <div className="mt-2">
                          <p>
                            Already Have Account ?{" "}
                            <Link to="/login">Sign in</Link>{" "}
                          </p>
                        </div>
                      </div>

                      <div style={{textAlign:"center", width:"100%"}}>
                        <small style={{fontSize:"11px"}}>
                          © 2020-2024 AuthKey.io Datagen Internet Services Pvt.
                          Ltd. | All rights reserved
                        </small>
                      </div>
                    </form>
                    
                  ) : null}

                  {this.state.otpScreen ? (
                    <>
                      <form
                        className="form-row col-md-8  pr-5"
                        id="otp-verify-form"
                      >
                        <div className="mb-3 text-left col-md-12 login-box">
                          <h5 className="alert  alert-success">
                            We have sent 6 digit OTP on your{" "}
                            {this.state.countryCode === "91"
                              ? "Mobile & Email"
                              : "Email"}{" "}
                          </h5>
                        </div>
                        <span className="clearfix"></span>

                        {this.state.countryCode === "91" ? (
                          <div className="form-group col-md-12">
                            <div className="">
                              <label className="form-control-label">
                                Enter Mobile OTP
                              </label>
                              <input
                                type="email"
                                onChange={(event) => {
                                  this.setState({
                                    otp: event.target.value,
                                  });
                                }}
                                className="form-control logininput"
                                placeholder="Enter 6 Digit Mobile OTP"
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="form-group col-md-12">
                          <label className="form-control-label">
                            Enter Email OTP
                          </label>
                          <div className="">
                            <input
                              type="email"
                              onChange={(event) => {
                                this.setState({
                                  emailotp: event.target.value,
                                });
                              }}
                              className="form-control logininput"
                              placeholder="Enter 6 Email OTP"
                            />
                          </div>
                        </div>

                        {this.state.isLoading ? (
                          <div className="mt-4 col-md-12">
                            <button
                              type="button"
                              onClick={() => this.VerifyMobile()}
                              className="btn w-100 btn-block btn-sm btn-primary"
                            >
                              Verify Account{" "}
                              <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn w-100 btn-block btn-sm btn-primary"
                          >
                            <span className="spinner-border text-white spinner-border-sm"></span>{" "}
                            Please Wait..
                          </button>
                        )}
                      </form>
                    </>
                  ) : null}

                  {this.state.signupDone ? (
                    <div className="text-center pr-5">
                      <div className="card-body">
                        <img
                          alt="Empty Data"
                          style={{ width: "100px" }}
                          src={EmptyImg}
                        />
                        <p>
                          Your account has been created successfully, <br />{" "}
                          Please find login credentials on your email.
                        </p>
                        <Link className="btn btn-primary btn-sm" to="login">
                          Login To Account
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Signup;
