import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import ImageDemo from '../../Dashboard/assets/img/rich-Image.jpeg';
import videoDemo from '../../Dashboard/assets/img/rich-video.jpeg';
import ActionLinkManager from './ActionLinkManager';
import { HOST_URL } from '../api/Api';



class TemplateRichMedia extends Component {

    constructor () {

    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId= lStorage.user.userId;
    const userToken= lStorage.token;

    super();
        this.state = {
            user_id:userId,
            token:userToken,
            richMediaType:'image',
            template_name:'',
            title:'',
            message_type:"",
            content:'',
            sender_id:'',
            actionLinksName:[],
            actionValue:[],
            image_url:'',
            image_url_local:'',
            video_url:'',
            video_url_local:'',
            videoPreviewshow:false,
            errorMessage:'',
            successMessage:false,
            bgColor:''
        }
        this.handleClick = this.handleClick.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.videoFileHandler = this.videoFileHandler.bind(this);
        this.onValueEnter = this.onValueEnter.bind(this);
        this.onValueEdit = this.onValueEdit.bind(this);
    }

    componentDidMount(){
          this.EditRichMediaTemplate();
    }

    EditRichMediaTemplate(){
        fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieveid&id=${this.props.match.params.id}&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
               // console.log('data',result.data);
                if(result.success===true){
                    this.setState({ 
                        template_name: result.data.template_name, 
                        title: result.data.title, 
                        content: result.data.content,
                        sender_id: result.data.senderid,
                        action_links: result.data.action_links,
                        image_url_local: result.data.image_url,
                        message_type: result.data.message_type,
                        video_url_local: result.data.video_url,
                        richMediaType: result.data.message_type===2?'video':'image'
                    });
                    let actionLinksName = [];
                        for (var i = 0; i < result.data.action_links.length ; i++) {
                            actionLinksName.push(JSON.parse(result.data.action_links[i]).action_content);
                        } 
                    this.setState({actionLinksName:actionLinksName})
                    setTimeout(()=>{
                        this.setState({ videoPreviewshow:true });
                      }, 3000)
        
                }else{

                }
            })
        })
    }

    handleClick(e) {
        if(e.target.value==='image'){
            this.setState({richMediaType:e.target.value,message_type:1})
        }else{
            this.setState({richMediaType:e.target.value,message_type:2})
        }
    }

    fileChangedHandler(e){
        console.log(URL.createObjectURL(e.target.files[0]));
        if (e.target.files[0]) {
            this.setState({image_url:e.target.files[0],image_url_local:URL.createObjectURL(e.target.files[0])})
        }
    }

    videoFileHandler(e){
        if (e.target.files[0]) {
            this.setState({video_url:e.target.files[0]})
        }
    }

    createTemplate() {
        document.querySelector('body').scrollTo(0, 0);
        const data = new FormData() 
        data.append('image_url', this.state.image_url)
        data.append('video_url', this.state.video_url)
        data.append('template_name', this.state.template_name)
        data.append('title', this.state.title)
        data.append('sender_id', this.state.sender_id)
        //data.append('sender_id', this.state.actionValue)
        this.state.actionValue.forEach(actionValue =>data.append('action_links[]',actionValue))
        data.append('message_type', this.state.message_type)
        data.append('content', this.state.content)
        data.append('user_id', this.state.user_id)
        data.append('token', this.state.token)
        data.append('method','create')
  
        fetch(`${HOST_URL}/yulore_template.php`,{
        method: "POST",
        body:data
        }).then((result)=>{
            result.json().then((resp)=>{
                console.log(resp)
        if(resp.success === true ){
            this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:resp.message});
            setTimeout(function(){window.location = "/dashboard/rich-media-template"}, 1000);
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:resp.message});
        }
            })
        })
    }

    updateTemplate() {
        document.querySelector('body').scrollTo(0, 0);
        const data = new FormData() 
        data.append('image_url', this.state.image_url)
        data.append('video_url', this.state.video_url)
        data.append('template_name', this.state.template_name)
        data.append('title', this.state.title)
        data.append('sender_id', this.state.sender_id)
        this.state.actionValue.forEach(actionValue =>data.append('action_links[]',actionValue))
        data.append('message_type', this.state.message_type)
        data.append('content', this.state.content)
        data.append('user_id', this.state.user_id)
        data.append('token', this.state.token)
        data.append('method','update')
        data.append('id',this.props.match.params.id)

        fetch(`${HOST_URL}/yulore_template.php`,{
        method: "POST",
        body:data
        }).then((result)=>{
            result.json().then((resp)=>{
                console.log(resp)
        if(resp.success === true ){
            this.setState({successMessage:true,bgColor:"alert alert-success alert-dismissible",errorMessage:resp.message});
            }else{
                this.setState({successMessage:true,bgColor:"alert alert-danger alert-dismissible",errorMessage:resp.message});
        }
            })
        })
    }

    onValueEdit(rank){
       // console.log('rank',rank)
        let spliceArray = [...this.state.actionValue];
        for (var i = 0; i < spliceArray.length ; i++) {
            let elementI = JSON.parse(spliceArray[i])
            // console.log('spliceArray[i]',elementI)
            // console.log('spliceArray[i].rank',elementI.action_rank)
            if(elementI.action_rank===rank){
                spliceArray.splice(i,1)
                this.setState({actionValue:spliceArray})
            }
           //console.log(this.state.actionValue[i])
        } 
    }
    onValueEnter(value) {

        const newActionValue = [...this.state.actionValue];
        newActionValue.push(JSON.stringify(value));
        this.setState({actionValue:newActionValue})

        let actionLinksName = value.action_content;
        this.setState({ actionLinksName: [...this.state.actionLinksName, actionLinksName] }) 
    }

render() {
 console.log('actionValue',this.state.actionLinksName)
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="row mb-2 mb-xl-3">
                        <div className="col-auto d-none d-sm-block">
                            <h3><strong> Create New Rich  </strong> Media Template </h3>
                        </div>
                    </div>


                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


                    <div className="row justify-contect-center">
                    
                    <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">  Template Details </h5>
                        </div>
                        <hr className="m-0" />
                        <div className="card-body">
                      
                            <div id="newtiecktid">


                                <div className="row">
                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Template Name</label>
                                      <input defaultValue={this.state.template_name} className="form-control" onChange={(event) => this.setState({ template_name: event.target.value })}  placeholder="Template name " type="text"/>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="mb-3 drop-down-icons col-md-6">
                                       <select value={this.state.message_type===1?'image':this.state.message_type===2?'video':''} disabled={this.props.match.params.id?'disabled':''} onChange={this.handleClick} className="form-control">
                                           <option value="">Choose Message Type</option>
                                           <option value="image">Image</option>
                                           <option value="video">Video</option>
                                       </select>
                                    </div>
                                    
                                
                                    <div className="mb-3 col-md-6">
                                      <input defaultValue={this.state.sender_id} onChange={(event) => this.setState({ sender_id: event.target.value })} className="form-control" placeholder="Sender Name" type="text"/>
                                    </div>

                                </div>

                                                                


                                { 
                                
                                
                                this.state.richMediaType==='image'?


                                <div className="row">
                                <div className="col-md-8">

                                <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}     className="mt-3">
                                <div className="mb-3 col-md-12">
                                   <label className="col-form-label"> Image Message </label>
                                  <input className="form-control" onChange={this.fileChangedHandler} type="file"/>
                                  <small> Recormmended Image Dimension : 300 px * 250 px, Format .jpg or .png and Size should be less than 200KB.
                                </small>
                                </div>

                                <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Title in message</label>
                                      <input defaultValue={this.state.title}  onChange={(event) => this.setState({ title: event.target.value })} className="form-control" placeholder="Title in message" type="text"/>
                                </div>


                                <div className="p-3">
                                <label className="col-form-label"> Description </label>
                                        <textarea defaultValue={this.state.content} onChange={(event) => this.setState({ content: event.target.value })} placeholder="Description message" className="form-control"></textarea>
                                </div>

                                </div>

                                        <ActionLinkManager onValueEnter={this.onValueEnter} onValueEdit={this.onValueEdit} />


                               
                                 </div>

                                <div className="col-md-4">

                                {this.state.message_type ?
                                    
                                    <div className="text-center mobile-perview">
                                        <h3>{this.state.sender_id}</h3>
                                        <div className="preview-bg">
                                            <img className="img-fluid" alt="image Icons" src={this.state.image_url_local} />
                                            <h4>{this.state.title}</h4>
                                            <p>{this.state.content}</p>
                                            {this.state.actionLinksName?
                                            <>
                                             {this.state.actionLinksName.map((item,index)=>{
                                                return (
                                                    <a key={index}>{item}</a>
                                                )
                                            })}
                                            </>
                                            :
                                            null
                                            }
                                            

                                        </div>
                                    </div>

                                    :
                                    <img className="img-fluid" src={ImageDemo} />
                                }


                                 </div>
                                </div>

                                :

                                this.state.richMediaType==='video'?

                                <div className="row">
                                    <div className="col-md-8">

                                    <div style={{background:'#f0f8ff',border:'2px dotted #69b6f9'}}     className="mt-3">
                                <div className="mb-3 col-md-12">
                                   <label className="col-form-label"> Video Message </label>
                                  <input className="form-control"  onChange={this.videoFileHandler} type="file"/>
                                  <small> Video Formate .mp4 and Size should be less than 4MB.
                                </small>
                                </div>

                                <div  className="mt-3">
                                    <div className="mb-3 col-md-12">
                                    <label className="col-form-label"> Video Thumbnail Image </label>
                                    <input onChange={this.fileChangedHandler} className="form-control" type="file"/>
                                    <small> Recormmended Image Dimension : 300 px * 250 px, Format .jpg or .png and Size should be less than 200KB.
                                    </small>
                                    </div>
                                </div>

                                <div className="mb-3 col-md-12">
                                    <label className="col-form-label">Title in message</label>
                                      <input  defaultValue={this.state.title}  onChange={(event) => this.setState({ title: event.target.value })} className="form-control" placeholder="Title in message" type="text"/>
                                </div>

                                <div className="p-3">
                                <label className="col-form-label"> Description </label>
                                        <textarea  defaultValue={this.state.content}  onChange={(event) => this.setState({ content: event.target.value })} placeholder="Description message" className="form-control"></textarea>
                                </div>

                                

                                 </div>

                                 <ActionLinkManager onValueEnter={this.onValueEnter} onValueEdit={this.onValueEdit} />
                                        
                                    </div>

                                    <div className="col-md-4">

                                        {this.state.message_type ?
                                            
                                            <div className="text-center mobile-perview">
                                                <h3>{this.state.sender_id}</h3>
                                                <div className="preview-bg">

                                                   {this.state.videoPreviewshow ?
                                                       <video height='100%' style={{width:100+'%'}} autoPlay loop>
                                                       <source  src={this.state.video_url_local}/>
                                                   </video>
                                                    :
                                                    <img className="img-fluid" src={this.state.image_url_local} />
                                                   }
                                        
                                                    <h4>{this.state.title}</h4>
                                                    <p>{this.state.content}</p>

                                                    { this.state.actionLinksName?
                                                        <>
                                                        {this.state.actionLinksName.map((item,index)=>{
                                                            return (
                                                                <a key={index}>{item}</a>
                                                            )
                                                        })}
                                                        </>
                                                        :
                                                        null
                                                    }
                                                        
                                                   

                                                </div>
                                            </div>
                                            :
                                            <img className="img-fluid" src={videoDemo} />
                                        }

                                    </div>
                                </div>

                                :

                                null
                                    
                                }

                                <hr className="mb-0" />
                                {
                                 this.props.match.params.id?
                                 <button type="button" onClick={()=> this.updateTemplate()} className="btn btn-sm mt-3 float-right btn-primary"> Update Template </button>
                                 :
                                 <button type="button" onClick={()=> this.createTemplate()} className="btn btn-sm mt-3 float-right btn-primary"> Save Template </button>
                                }
                               



                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default TemplateRichMedia;