import React, { useEffect, useState } from "react";
// import DashboardLeftMenu from "./Navbar/AdminLeftMenu";
import SmsAPiSampleCode from "../Dashboard/sms/SmsSampleCode";
// import DashboardHeader from "./Header/DashboardHeader";
import "../Sandbox/Sandbox.css";
import CnfAlert from "../UI/Alert/CnfAlert";
import { FaSms } from "react-icons/fa";
import { MdEmail, MdKeyboardVoice  } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

const Sandbox = () => {
  const [dataFromChild, setDataFromChild] = useState(false);
  const [status, setStatus] = useState(false);
  const [isChecked, setIsChecked] = useState(null);

 
  const handleChildData = (data) => {
    setDataFromChild(data);
  };

  const modeToggle = (e) => {

    if (isChecked === null) {
      setStatus(true);
    }
    // setStatus(true)
    // if(!dataFromChild===null){
    setIsChecked(!isChecked);
    // }
    // setDataFromChild(null)
  };
console.log(dataFromChild)
  return (
    <>
      {/* <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader /> */}
      <main className="content">
        <div class="switchboard">
          <span className="switchName">Sandbox Mode</span>
          <input
            type="checkbox"
            id="switch"
            className="checkbox"
            checked={isChecked}
            onChange={modeToggle}
          />
          <label for="switch" className="toggle"></label>
          <span className="switchName">Live mode</span>
        </div>
        <CnfAlert status={status} message={"hi"} onData={handleChildData} />

        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12 mb-2">
              <h3 className="d-flex">Sandbox</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-md-12 mt-4 mb-4 d-flex">
              <div className="col-sm-3 ">
                <div className="cards sandcardinbox sandcardSelect">
                  <a href="#">
                    <div className="p-2 d-flex">
                      <span className="text-warning sandbox_icon2">
                       <FaSms/>
                      </span>
                      <span className="font-weight-bold text-right"> SMS</span>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="cards sandcardinbox">
                  <a href="#">
                    <div className="p-2 d-flex">
                      <span className="text-danger sandbox_icon3 voilet">
                      <MdKeyboardVoice />
                      </span>
                      <span className="font-weight-bold text-right">
                        {" "}
                        Voice
                      </span>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="cards sandcardinbox">
                  <a href="#">
                    <div className="p-2 d-flex">
                      <span className="text-danger sandbox_icon3 orange">
                      <MdEmail />
                      </span>
                      <span className="font-weight-bold text-right">
                        {" "}
                        Email
                      </span>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="cards sandcardinbox">
                  <a href="#">
                    <div className="p-2 d-flex">
                      <span className="text-warning sandbox_icon green">
                      <IoLogoWhatsapp />
                      </span>
                      <span className="font-weight-bold text-right">
                        {" "}
                        WhatsApp
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="form-group">
              <h3>Send a Test Message</h3>
            </div>

            <div className="form-group row">
              <label for="colFormLabel" className="col-sm-2 col-form-label">
                Send Message to{" "}
              </label>
              <div className="col-sm-4">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="+912384930480"
                  />
                </div>
              </div>
              <label
                for="colFormLabel"
                className="col-sm-2 text-right col-form-label"
              >
                Send Message to{" "}
              </label>
              <div className="col-sm-4">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="+912384930480"
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label for="colFormLabel" className="col-sm-2 col-form-label">
                Entity ID{" "}
              </label>
              <div className="col-sm-4">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    disabled=""
                    class="form-control"
                    id="inlineFormInputGroup"
                    value=""
                  />
                </div>
              </div>
              <label
                for="colFormLabel"
                className="col-sm-2 text-right col-form-label"
              >
                Template ID
              </label>
              <div className="col-sm-4">
                <div className="input-group mb-2">
                  <input
                    type="text"
                    disabled=""
                    class="form-control"
                    id="inlineFormInputGroup"
                    value=""
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              onClick={() => this.SendMessage()}
              className="btn mt-3 btn-sm text-right button-cus"
            >
              {" "}
              Test Message
            </button>

            <h3 className="mt-5 mb-3">Sample Code</h3>
            <SmsAPiSampleCode />
          </div>
        </div>
      </main>
      {/* </div>
      </div> */}
    </>
  );
};
export default Sandbox;
