import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor, { buttonList } from "suneditor-react";
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';
class ViewTicket extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const isAdmin = lStorage.user.isAdmin;
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            selectedTicketDetials: '',
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            status: '',
            message: '',
            successMessage: false,
            errorMessage: '',
            bgColor: "alert alert-danger alert-dismissible"
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.allmessage();
    }

    allmessage() {
        fetch(`${HOST_URL}/support.php?user_id=${this.state.user_id}&method=ticketdetail&type=${this.state.userType}&token=${this.state.token}&id=${this.props.match.params.id}`).then((response) => {
            response.json().then((result) => {
                //console.log(result)
                if (result.success === true) {
                    this.setState({
                        selectedTicketDetials: result.data,
                        status: result.data[0].status,
                        message: '',
                    });
                } else {
                    this.setState({ successMessage: true, bgColor: "alert alert-danger alert-dismissible", errorMessage: result.message });
                }
            })
        })
    }


    ReplyMessage() {
        document.querySelector('body').scrollTo(0, 0);
        const reply = ({
            user_id: this.state.user_id,
            type: this.state.userType,
            message: this.state.message,
            id: this.props.match.params.id,
            replyuserid: this.props.match.params.replyuserid,
            method: 'reply',
            token: this.state.token,
        });
        fetch(`${HOST_URL}/support.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(reply)
        }).then((result) => {
            result.json().then((result) => {
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                    this.allmessage();
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    closedTicket() {
        document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/support.php?user_id=${this.state.user_id}&type=${this.state.userType}&id=${this.props.match.params.id}&method=close&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: "Ticket closed successfully"
                    })
                    setTimeout(function () { window.location = "/admin/support"; }, 2000);

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    handleChange(message) {
        this.setState({ message: message });
    }



    render() {
        //console.log(this.state)
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Support </strong> Management </h3>
                                </div>
                            </div>

                            <div className="">
                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className=" flex-fill">

                                        <div className="">

                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="card">
                                                        <div className="card-header justify-content-between align-items-center d-flex">
                                                            <h5 className="card-title mb-0"> <strong> Ticket ID : {this.props.match.params.ticketnumber} </strong>  </h5>
                                                            <h5 className="card-title mb-0"> <button onClick={() => this.closedTicket()} className="btn btn-sm btn-danger">Close Ticket </button>  </h5>
                                                        </div>
                                                        <hr className="m-0" />
                                                        <div className="card-body">


                                                            <div className="messaging">
                                                                <div className="inbox_msg">
                                                                    <div className="">
                                                                        {this.state.selectedTicketDetials ?
                                                                            <div className="">

                                                                                {
                                                                                    this.state.selectedTicketDetials.reverse().map((item, i) =>

                                                                                        <>
                                                                                            {item.update_by === 'client' ?
                                                                                                <div className="incoming_msg">
                                                                                                    <div className="received_msg">
                                                                                                        <div className="received_withd_msg">
                                                                                                            <span>You</span>
                                                                                                            <p dangerouslySetInnerHTML={{ __html: item.message }} className="mt-1"></p>
                                                                                                            <span className="time_date text-right"> {item.createdDate} </span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {item.update_by === 'emp' || item.update_by === 'admin' ?
                                                                                                <div className="outgoing_msg">
                                                                                                    <div className="sent_msg">
                                                                                                        <span>Authkey</span>
                                                                                                        <p dangerouslySetInnerHTML={{ __html: item.message }} className="text-white"></p>
                                                                                                        <span className="time_date"> {item.createdDate} </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }

                                                                            </div>

                                                                            :
                                                                            null

                                                                        }

                                                                        <div className="filter-option">
                                                                            <h6 className="card-subtitle text-muted">Reply Message </h6>
                                                                            <div className="row">
                                                                                {/* <div className="col-md-12">
                                <div className="input-group mb-2 mr-sm-2">
                                   <textarea placeholder="Reply Message" rows="5" onChange={(event) => this.setState({ message: event.target.value })}   className="form-control">
                                   </textarea>
                                    </div>
                                </div> */}

                                                                                <div className="col-md-12">
                                                                                    <SunEditor setContents={this.state.message} height="300" onChange={this.handleChange} setOptions={{
                                                                                        buttonList: buttonList.complex
                                                                                    }}
                                                                                        placeholder="Please type here..." />
                                                                                </div>


                                                                                <div className="col-md-12 text-right">
                                                                                    <div className="input-group mb-2 mr-sm-2">
                                                                                        <input type="button" onClick={() => this.ReplyMessage()} value="Send" className="btn mt-1  btn-primary btn-sm ml-auto text-right " />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>

        );
    }
}
export default ViewTicket;