import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy,faPlus,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import 'react-telephone-input/css/default.css'
import EmailSampleCode from '../../Dashboard/email/EmailSampleCode';
import { HOST_URL } from '../api/Api';

class SendEmail extends Component {

    

    constructor() {
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        super();
        this.state = {
            UserDetails: [],
            ListodSernderID: '',
            user_id: userId,
            token: userToken,
            verifiedEmailID:'',
            EmailTemplateList:'',
            email:'',
            from_email:'',
            mid:'',
            authkey: '',
            message: 'This is test mail from authkey account',
            subject: 'Test Email Subject',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getUserDetails();
        this.GetListOfSenderId();
        this.GetverifiedEmailID();
        this.GetEmailList();
    }

    getUserDetails() {
        fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ UserDetails: result.data[0], authkey: result.data[0].authkey, mobile: result.data[0].mobile, email: result.data[0].email });
                }
            })
        })
    }


    GetListOfSenderId() {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //   console.log(result)
                if (result.success === true) {
                    this.setState({ ListodSernderID: result });
                }

            })
        })
    }


    SendMessage() {
        fetch(`https://api.authkey.io/request?authkey=${this.state.authkey}&mobile=${this.state.mobile}&country_code=${this.state.countryCode}&sms=${this.state.message}&sender=${this.state.senderid}`).then((response) => {
            response.json().then((result) => {
                document.querySelector('body').scrollTo(0, 0);
                this.setState({ successMessage: true });
                this.setState({ bgColor: "alert alert-success alert-dismissible" });
                this.setState({ errorMessage: JSON.stringify(result) });
            })
        })
    }

    handleInputChange(telNumber) {
        this.setState({ countryCode: telNumber.substring(1) })
    }

    GetverifiedEmailID(){
        fetch(`${HOST_URL}/aws_from_email.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
                if(result.success===true){
                    this.setState({verifiedEmailID:result});
                }else{
                    this.setState({listOFverifyedEmail:true});
                }
               
            })
        })
    }

    GetEmailList(){
        fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				// console.log(result)
				if(result.success===true){
					this.setState({EmailTemplateList:result});
				}
               
            })
        })
    }

   
    SendEmail(){
        document.querySelector('body').scrollTo(0, 0);
        fetch(`https://api.authkey.io/request?authkey=${this.state.authkey}&email=${this.state.email}&mid=${this.state.mid}`).then((response)=> {
            response.json().then((result)=>{
              this.setState({
                successMessage:true,
                errorMessage:result.Message,
                bgColor:"alert alert-success alert-dismissible"});
            })
        })
    }




    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Get Started With Authkey </strong> Email </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-body">
                                    <h3>Send Test Email </h3>
                                   


                                    {
                                        this.state.successMessage? 
                                                <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                            <div className="">
                                                    {this.state.errorMessage}
                                                </div>
                                            </div>
                                                :
                                                null
                                    }

                                        <div className="form-group mt-5 row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label">Send Email to </label>
                                            <div className="col-sm-4">
                                            <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                        <div className="input-group-text">
                                                              <FontAwesomeIcon icon={faEnvelope} />
                                                        </div>
                                                        <input type="text" defaultValue={this.state.UserDetails.email}
                                                            onChange={(event) => this.setState({ email: event.target.value })}
                                                            className="form-control" id="inlineFormInputGroup" placeholder="Email Address" />
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="colFormLabel" className="col-sm-2 text-right col-form-label">Sender Email </label>
                                            <div className="col-sm-3 text-right">
                                                <div className="input-group drop-down-icons mb-2">
               
                                                    {
														
														this.state.verifiedEmailID?
			
														 <select defaultValue={this.state.from_email} onChange={(event)=>
											        	{this.setState({from_email:event.target.value}) }}  className="form-control">
														 <option value="">Select Sender Email</option>
														 {
														   this.state.verifiedEmailID.data.map((item,i)=>
															<option  key={i} value={item.email}> {item.email}</option>
														 )}
														 </select>
														 :
                                                         <div>
                                                <Link  className="form-control" to="/dashboard/aws-email-verify">  <FontAwesomeIcon icon={faPlus} /> Add Verified Email </Link>
                                                </div>
												}
                                              
                                                </div>
                                                <small >
                                                <Link to="/dashboard/aws-email-verify">Add Email ID </Link> 
                                                </small>
                                            </div>
                                           
                                        </div>
                                        <div className="form-group row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label">AuthKey </label>
                                            <div className="col-sm-6">
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                    <input type="text" value={this.state.UserDetails.authkey}
                                                        onChange={(event) => this.setState({ authkey: event.target.value })}
                                                        className="form-control" id="inlineFormInputGroup" placeholder="ksdfkjsfhe89324926734343424342423" />
                                                        <div className="input-group-text">
                                                              <FontAwesomeIcon icon={faCopy} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label"> Email Subject </label>
                                            <div className="col-sm-6">
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                    <input type="text" className="form-control"
                                                            onChange={(event) => this.setState({ subject: event.target.value })}
                                                            id="inlineFormInputGroup" defaultValue="Test Email Subject" placeholder="Test Email Subject " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label for="colFormLabel" className="col-sm-2 col-form-label"> Email Content </label>
                                            <div className="col-sm-6">
                                            {
				                                this.state.EmailTemplateList?
                                                <div className="col-auto">
                                                    <div className="input-group mb-2">
                                                  
                                                    {
														
														this.state.EmailTemplateList?
			
														 <select defaultValue={this.state.mid} onChange={(event)=>
											        	{this.setState({mid:event.target.value}) }}  className="form-control">
														 <option value="">Select Template Email</option>
														 {
														   this.state.EmailTemplateList.data.map((item,i)=>
															<option  key={i} value={item.id}> {item.template_name}</option>
														 )}
														 </select>
														 :
                                                      <option value="">Default</option>
												}
                                                
                                                    </div>
                                                </div>

                                                :
                                                <div className="col-auto">
                                                <Link className="form-control" to="/dashboard/email-template"> <FontAwesomeIcon icon={faPlus} /> Create New Email Template </Link>
                                                </div>

                                            }
                                            </div>
                                        </div>

                                        <button type="button" onClick={()=> this.SendEmail()} className="btn mt-3 btn-sm button-cus"> Send Test Email</button>

                               
                                        <EmailSampleCode />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default SendEmail;