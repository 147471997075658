import React, { Component } from "react";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import Axios from "../../../axios/Axios";
import { dateFormate } from "../../Utils/Utils";
import Spining from "../../UI/Spining/Spining";
import { userloginDetails } from "../../Utils/AdminAuth";
import { HOST_URL } from "../api/Api";

class WhatsAppSummaryUser extends Component {
  constructor() {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const adminType = lStorage.user.isAdmin;
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        ("0" + today.getDate()).slice(-2);
    super();

    this.state = {
      user_id: userId,
      token: userToken,
      adminType: adminType,
      allUserList: null,
      dateFrom: date,
      dateTo: date,
      summaryData: [],
      selectedUserID: "",
      serchData: "",
      userCurrentStatus: "",
      setIsLoading: true,
      ErrorResponse: "",
    };
    this.dateFrom = this.dateFrom.bind(this);
    this.dateTo = this.dateTo.bind(this);
    this.userHandler = this.userHandler.bind(this);
  }

  componentDidMount() {
    fetch(
      `${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve_whatsapp_user&type=${this.state.adminType}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          let data = result.data.filter((items) => {
            if (items.status === 1) {
              return items;
            }
          });
          this.setState({ allUserList: data, setIsLoading: false });
        } else {
          this.setState({
            setIsLoading: false,
            ErrorResponse: result.message,
          });
        }
      });
    });
  }

  searchSummary() {
    this.setState({
      setIsLoading: true,
      summaryData: [],
    });
    const method = [
      {
        name: "Api Summary",
        method: "api_summary_admin",
      },
      {
        name: "Campaign Summary ",
        method: "camp_summary_admin",
      },
      {
        name: "Conversation Summary",
        method: "conv_summary_admin",
      },
    ];

    method.map((item) => {
      let data = {
        user_id: this.state.user_id,
        method: item.method,
        token: this.state.token,
        date_from: this.state.dateFrom,
        date_to: this.state.dateTo,
        ret_user_id: this.state.selectedUserID,
      };
      Axios.post(`${HOST_URL}/whatsapp_report.php`, data).then(
        (res) => {
          this.setState({
            summaryData: [
              ...this.state.summaryData,
              Object.assign(res.data, { name: item.name }),
            ],
            setIsLoading: false,
          });
        }
      );
    });
  }

  dateFrom(date) {
    let selectedDate = dateFormate(date);
    this.setState({
      dateFrom: selectedDate,
    });
  }

  dateTo(date) {
    let selectedDate = dateFormate(date);
    this.setState({
      dateTo: selectedDate,
    });
  }

  userHandler(event) {
    this.setState({
      selectedUserID: event.target.value,
      summaryData: [],
    });
  }

  render() {
    return (
      <AdminLayout>
        <Title isButton={false}> WhatsApp Summary </Title>
        <div className="row">
          <div className="">
            <div className="card">
              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              {this.state.setIsLoading && <Spining />}

              <div className="card-body">
                <div className="filter-option">
                  <h6 className="card-subtitle text-muted">
                    Select WhatsApp User / Data Range{" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="input-group drop-down-icons mb-2 mr-sm-2">
                        <div className="input-group mb-2 mr-sm-2">
                          <div className="input-group-text">User </div>

                          <select
                            onChange={this.userHandler}
                            className="form-control "
                          >
                            <option>Select User</option>

                            {this.state.allUserList &&
                              this.state.allUserList.map((item, i) => (
                                <option key={i} value={item.user_id}>
                                  {item.user_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                        <DatePicker
                          className="form-control btn-block"
                          dateFormat="yyyy/MM/dd"
                          value={this.state.dateFrom}
                          onChange={this.dateFrom}
                          selected={this.state.SelecteddateFrom}
                          placeholderText="From Date"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                        <DatePicker
                          className="form-control btn-block"
                          dateFormat="yyyy/MM/dd"
                          value={this.state.dateTo}
                          onChange={this.dateTo}
                          selected={this.state.SelecteddateTo}
                          placeholderText="Date To"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group btn-block mb-2 mr-sm-2">
                        <button
                          onClick={() => this.searchSummary()}
                          className="btn mt-1 btn-blue btn-sm  btn-block"
                        >
                          Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.summaryData ? (
                  <div className="summary-data text-center">
                    <div className="row">
                      {this.state.summaryData.map((item, i) => (
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body ">
                              <h4 className="summary-title text-left">
                                {item.name}
                              </h4>
                              <div className="d-flex justify-content-between">
                                {Object.keys(item.message).map((keys) => (
                                  <div className="mb-1">
                                    <span className="text-muted"> {keys} </span>
                                    <br />
                                    <span className="text-success">
                                      {" "}
                                      {item.message[keys]}{" "}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
export default WhatsAppSummaryUser;
