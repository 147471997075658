import React, { Component } from 'react';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css'; 
import { HOST_URL } from './api/Api';
class LatestRequestComponent extends Component {

    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;
		var today = new Date(),
		date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);

        super();
        this.state = {
            latestUserRequest:'',
            user_id: userId,
			token: userToken,
			noAnyRequest:'',
			arrerMessage:'',
			dateFrom:date,
            dateTo:date,
        }
	}


    componentDidMount() {
        fetch(`${HOST_URL}/user_api_request.php?user_id=${this.state.user_id}&method=details&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`).then((response)=> {
            response.json().then((result)=>{
				//console.log('latestUserRequest',result);
				if(result.success===true){
						this.setState({latestUserRequest:result.message});
					}else{
						this.setState({arrerMessage:result.message})
						//console.log(result.message);
					}
            })
        })
	}
	


render() {  
		return (
            <div className="">
			<div className="row">
			<div>
			<div className="card flex-fill">
			<div className="card-header">
			<h5 className="card-title mb-0">Latest Request</h5>
			</div>

		{ this.state.latestUserRequest?

			<div style={{height:400+'px', overflow:'auto'}}>
			<table className="table table-striped table-sm  project  my-0">
				<thead >
					<tr className="text-left">
					  
					    <th>Email/Mobile</th>
						<th>API Request</th>
						<th>API Status / Response</th>
						<th>Country</th>
						<th>Received</th>
					</tr>
				</thead>
				<tbody>


			    	{

			    	this.state.latestUserRequest.map((itam,i)=>
					<tr className={itam.status==='success'?'bg-success-table':'bg-danger-table' } key={i}>
					
					<td  className="text-left">

					{itam.email&& `{`}{itam.email}{itam.email&&`}`} 
					<br/>
					{itam.mobile&&`{`}{itam.mobile}{itam.mobile&&`}`} 
					
					</td>
				
					<td  className="text-left"><JSONPretty id="json-pretty" data={itam.api_request}></JSONPretty></td>
					<td  className="text-left">
					<JSONPretty id="json-pretty" data={itam.message}></JSONPretty>
					</td>
					<td  className="text-left">{itam.country
					}</td>
					<td  className="text-left">{itam.submitted_on}</td>
					</tr>

					)}
				
					</tbody>
			</table>
			</div>
		:
			<div className="text-left">
				<div className="card-body text-center">
				<img className="project-empty-img" src={EmptyImg} alt="Empty Project" />
				<p> No Request Submitted Today </p>
				</div>
			</div> 
		}


	</div>
	</div>
	</div>
	</div>





	);
  }
}
 
export default LatestRequestComponent;