import React, { Component } from 'react';
import AdminLayout from '../../UI/Layout/AdminLayout';
import Title from '../../UI/Text/Title';
import AdvanceWhatsappTable from '../../UI/Table/AdvanceWhatsappTable';
import { EMBEDED_SIGNUP_LIST } from '../../UI/Table/ColumnConst';
import { userloginDetails } from '../../Utils/AdminAuth';

class EmbededSignup extends Component {

    constructor() {
        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const adminType = lStorage.user.isAdmin;
        super();
        this.state = {
            user_id: userId,
            token: userToken,
            adminType: adminType,
            allUserList: null,
            isLoading: true, // Change to isLoading for consistency
            ErrorResponse: '',
        };
    }

    componentDidMount() {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer EABFalxmwxMkBAKPaaVqUHZABaCldGzuIUFOFEQDjoMqJDJFSZCctsq57ky2p29ma7CMZCd7vWZBFVAfXUnIOYhKkUc66RtTQpsREj6EEmKyLNwvMHZANAvDcbKsUOs3uHTiLrecZCbUdpU6cVQZCvQ3SYrDO5aouwcUZCyNJQU0JAQrQaPy3VlYU2wKxfNdGEbiGVimS2IIYoAZDZD");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://graph.facebook.com/v18.0/393667671215929/client_whatsapp_business_accounts", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                
                if (result.data) {
                    this.setState({ allUserList: result.data, isLoading: false });
                } else {
                    this.setState({
                        isLoading: false,
                        ErrorResponse: result.message
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    render() {
        return (
            <AdminLayout>
                <Title isButton={false}>Embeded Signup</Title>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12">
                        <div>
                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <AdvanceWhatsappTable
                                tableName={'Embeded Signup List'}
                                filter={true}
                                tableCell={EMBEDED_SIGNUP_LIST}
                                tableData={this.state.allUserList}
                                isLoading={this.state.isLoading}
                                isError={this.state.ErrorResponse}
                            />


                        </div>
                    </div>
                </div>
            </AdminLayout>
        );
    }
}

export default EmbededSignup;