import React from 'react';
import {Link} from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAlignRight,faSearch } from '@fortawesome/free-solid-svg-icons';
const TrafficManagement = () => {
    return (
<div className="wrapper">
    <AdminLeftMenu />
        <div className="main">
            <AdminHeader />
               <main className="content">
				<div className="container-fluid p-0">
					<div className="row mb-2 mb-xl-3">
						<div className="col-auto d-none d-sm-block">
							<h3><strong> Traffic </strong> Management </h3>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-lg-12 col-xxl-12 d-flex">
							<div className="card flex-fill">

								<div className="card-header">

									<h5 className="card-title mb-0">User List</h5>
								</div>

                                <div className="card-body">
                                <div className="filter-option">
                           <h6 className="card-subtitle text-muted">Filter </h6> 
                           <div className="row">

                                <div className="col-md-12">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faSearch}/></div>
                                    <input type="text" placeholder="Enter user id or name of users" className="form-control"/>
                                    </div>
                                </div>
                 

                                <div className="col-md-12 text-right">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <input type="button" value="Search" className="btn mt-1  btn-primary btn-sm ml-auto text-right "/>
                                    </div>
                                </div>

                                    </div>
                                </div>
                                

                                
								<table className="table table-hover my-0">
									<thead>
										<tr>
											<th> Name</th>
											<th className="d-none d-xl-table-cell">Start Date</th>
											<th className="d-none d-xl-table-cell">Expriy Date</th>
											<th>Amount</th>
											<th>Status</th>
											<th className="d-none d-md-table-cell">Active</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Project Apollo</td>
											<td className="d-none d-xl-table-cell">01/01/2020</td>
											<td className="d-none d-xl-table-cell">31/06/2020</td>
                                            	<td className="d-none d-xl-table-cell">Rs. 43286</td>

											<td><span className="badge bg-success">Done</span></td>
											<td><span className="badge bg-success"> <Link className="text-white"  to='/admin/user-details'><FontAwesomeIcon icon={faAlignRight} /> View / Edit </Link></span></td>
										</tr>
										<tr>
											<td>Project Fireball</td>
											<td className="d-none d-xl-table-cell">01/01/2020</td>
											<td className="d-none d-xl-table-cell">31/06/2020</td>
                                            	<td className="d-none d-xl-table-cell">Rs. 43286</td>
											<td><span className="badge bg-danger">Cancelled</span></td>
											<td><span className="badge bg-success"> <Link className="text-white"  to='/admin/user-details'><FontAwesomeIcon icon={faAlignRight} /> View / Edit </Link> </span></td>
										</tr>

                                        <tr>
											<td>Project Fireball</td>
											<td className="d-none d-xl-table-cell">01/01/2020</td>
											<td className="d-none d-xl-table-cell">31/06/2020</td>
                                            	<td className="d-none d-xl-table-cell">Rs. 43286</td>
											<td><span className="badge bg-danger">Cancelled</span></td>
											<td><span className="badge bg-success"> <Link className="text-white"  to='/admin/user-details'><FontAwesomeIcon icon={faAlignRight} /> View / Edit </Link> </span></td>
										</tr>

                                        <tr>
											<td>Project Fireball</td>
											<td className="d-none d-xl-table-cell">01/01/2020</td>
											<td className="d-none d-xl-table-cell">31/06/2020</td>
                                            	<td className="d-none d-xl-table-cell">Rs. 43286</td>
											<td><span className="badge bg-danger">Cancelled</span></td>
											<td><span className="badge bg-success"> <Link className="text-white"  to='/admin/user-details'><FontAwesomeIcon icon={faAlignRight} /> View / Edit </Link> </span></td>
										</tr>

                                        <tr>
											<td>Project Fireball</td>
											<td className="d-none d-xl-table-cell">01/01/2020</td>
											<td className="d-none d-xl-table-cell">31/06/2020</td>
                                            	<td className="d-none d-xl-table-cell">Rs. 43286</td>
											<td><span className="badge bg-warning">Not Active</span></td>
											<td><span className="badge bg-success"> <Link className="text-white"  to='/admin/user-details'><FontAwesomeIcon icon={faAlignRight} /> View / Edit </Link> </span></td>
										</tr>
	
									</tbody>
								</table>

                                </div>
							</div>
						</div>
					</div>
				</div>
			</main>
            <AdminFooter />
        </div>
    </div>

    );
}
 
export default TrafficManagement;