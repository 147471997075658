import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faDownload,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL } from "../api/Api";

class DownloadCentre extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      downloadList: "",
      user_id: userId,
      token: userToken,
      channel: "",
      channelType: "",
      dateFrom: "",
      dateTo: "",
      SelecteddateFrom: "",
      SelecteddateTo: "",
      successShow: false,
      successMessage: "",
      errorMessage: "",
      bgColor: "",
      maxDate:null,
    };
  }

  componentDidMount() {
    fetch(
      `${HOST_URL}/download_centre.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //  console.log(result)
        if (result.success === true) {
          this.setState({ downloadList: result.data });
        } else {
          // this.setState({
          //   successMessage: true,
          //   bgColor: "alert alert-success alert-dismissible",
          //   errorMessage: result.message,
          // });
        }
      });
    });
  }

  dataDownload() {
    if(this.state.channel === ""||this.state.channelType===""||this.state.dateFrom===""){
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please select all fields",
      });
      return;
    }
   
    let enddate;
    if (this.state.channelType === "campaign") {
      enddate = this.state.dateFrom;
    } else {
      enddate = this.state.dateTo;
    }
    // var date1 = new Date(this.state.dateFrom);
    // var date2 = new Date(this.state.dateTo);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    // //console.log('Difference_In_Days', Math.trunc(Difference_In_Days))

    // if (Math.trunc(Difference_In_Days) <= 15) {
    //   window.location.href = `${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=sms&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
    // } else {
    const downloadReportCenter = {
      user_id: this.state.user_id,
      channel: this.state.channel,
      token: this.state.token,
      method: "create",
      startdate: this.state.dateFrom,
      enddate: enddate,
      req_for: this.state.channelType,
    };

    fetch(`${HOST_URL}/download_centre.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(downloadReportCenter),
    }).then((result) => {
      result.json().then((resp) => {
        //  console.log(resp);
        if (resp.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
          setTimeout(function () {
            window.location = "/dashboard/download-centre";
          }, 1000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
    // }
  }

  dateFrom = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateFrom: dated,
      SelecteddateFrom: date,
    });
  };

  dateTo = (date) => {
    let dated =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    this.setState({
      dateTo: dated,
      SelecteddateTo: date,
    });
  };
  currentDate(currentDate) {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  addDays(date) {
    const days = 30;
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.SelecteddateFrom !== this.state.SelecteddateFrom) {
      let maxDatee =this.addDays(this.state.SelecteddateFrom)
      this.setState({
        maxDate:maxDatee
      })
    }
  }
  render() {
    // console.log(this.state.downloadList)
    const oneDayBefore = new Date();
    oneDayBefore.setDate(oneDayBefore.getDate() - 1);

    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-sm-block">
                  <h3>
                    <strong>Download </strong> Centre{" "}
                  </h3>
                </div>
              </div>

              <div className="card flex-fill support">
                <div className="card-header">
                  {this.state.successMessage ? (
                    <div className={this.state.bgColor} role="alert">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="">{this.state.errorMessage}</div>
                    </div>
                  ) : null}

                  <div className="filter-option">
                    <h6 className="card-subtitle text-muted">
                      Select Date Range{" "}
                    </h6>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-group mb-2 mr-sm-2">
                          {/* <div className="input-group-text">
                            <FontAwesomeIcon icon={faBan} />
                          </div> */}
                          <select
                            onChange={(event) =>
                              this.setState({ channel: event.target.value })
                            }
                            className="form-control"
                          >
                            <option value=""> Select Channel </option>
                            <option value="sms"> sms </option>
                            <option value="email"> Email </option>
                            <option value="voice"> Voice </option>
                            <option value="whatsapp"> WhatsApp </option>
                          </select>
                        </div>
                      </div>

                      
                        <div className="col-md-3">
                          <div className="input-group mb-2 mr-sm-2">
                            {/* <div className="input-group-text">
                            <FontAwesomeIcon icon={faBan} />
                          </div> */}
                            <select
                              onChange={(event) =>
                                this.setState({
                                  channelType: event.target.value,
                                })
                              }
                              className="form-control"
                            >
                              <option value=""> Select Channel type</option>
                              <option value="api"> Api </option>
                              {this.state.channel === "whatsapp"&&<option value="campaign"> Campaign </option>}
                            </select>
                          </div>
                        </div>
                    

                      <div className="col-md-3">
                        <div className="input-group mb-2 mr-sm-2">
                          {/* <div className="input-group-text">
                            <FontAwesomeIcon icon={faCalendarCheck} />
                          </div> */}

                          <DatePicker
                            className="form-control btn-block"
                            dateFormat="yyyy/MM/dd"
                            value={this.state.dateFrom}
                            onChange={this.dateFrom}
                            maxDate={oneDayBefore}
                            selected={this.state.SelecteddateFrom}
                            selectsStart
                            startDate={this.state.SelecteddateFrom}
                            placeholderText={
                              this.state.channelType === "campaign"
                                ? "Select Date"
                                : "From Date"
                            }
                          />
                        </div>
                      </div>
                      {(this.state.channelType !== "campaign" ||
                        this.state.channelType === "") && (
                        <div className="col-md-3">
                          <div className="input-group mb-2 mr-sm-2">
                            {/* <div className="input-group-text">
                            <FontAwesomeIcon icon={faCalendarCheck} />
                          </div> */}

                            <DatePicker
                              className="form-control btn-block"
                              dateFormat="yyyy/MM/dd"
                              value={this.state.dateTo}
                              onChange={this.dateTo}
                              // maxDate={oneDayBefore}
                              selected={this.state.SelecteddateFrom}
                              placeholderText="Date To"
                              selectsEnd
                              startDate={this.state.SelecteddateFrom}
                              minDate={this.state.SelecteddateFrom}
                              maxDate={this.state.maxDate}
                            />
                          </div>
                        </div>
                      )}
                      <hr className="mt-2 mb-3" />
                      <div className="col-md-7">
                        <p className="mt-1">
                          Download Center Use for large data. Date range grater
                          then 15 days. Download request will take upto 2
                          minutes.{" "}
                        </p>
                      </div>
                      <div className="col-md-5">
                        <button
                          type="button"
                          onClick={() => this.dataDownload()}
                          className="btn-success btn-sm btn-block ml-auto"
                        >
                          <FontAwesomeIcon icon={faDownload} /> Request Download
                          Report{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      style={{ height: 400 + "px", overflow: "auto" }}
                      className=""
                    >
                      {this.state.downloadList ? (
                        <table className="table table-hover my-0">
                          <thead>
                            <tr>
                              <th>Channel</th>
                              <th>Channel Type</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Status</th>
                              <th>Request Date / Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.downloadList.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  {item.channel === "sms" ? (
                                    <span className="badge text-white badge-info">
                                      SMS
                                    </span>
                                  ) : item.channel === "voice" ? (
                                    <span className="badge text-white bg-warning ">
                                      Voice
                                    </span>
                                  ) : item.channel === "email"?(
                                    <span className="badge text-white bg-danger">
                                      Email
                                    </span>
                                  ):(
                                    <span className="badge text-white badge-success">
                                      WhatsApp
                                    </span>
                                  )}
                                </td>
                                <td>{item.req_for}</td>
                                <td> {item.startdate}</td>
                                <td> {item.enddate}</td>
                                <td>
                                  {item.status === 1 ? (
                                    <a
                                      target="_blank"
                                      href={
                                        "https://console.authkey.io/report/downloadcenter/" +
                                        item.linkfordownload
                                      }
                                    >
                                      <span className="badge text-white btn-primary">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faDownload}
                                        />{" "}
                                        Download
                                      </span>
                                    </a>
                                  ) : (
                                    <span>
                                      {" "}
                                      <span className="badge text-white bg-danger">
                                        Pending{" "}
                                      </span>{" "}
                                      <small className="ml-2">
                                        {" "}
                                        (Upto 2 minutes){" "}
                                      </small>
                                    </span>
                                  )}
                                </td>
                                <td> {item.created_date}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              className="project-empty-img"
                              src={EmptyImg}
                            />
                            <p>No Download Request Yet</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default DownloadCentre;
