import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCheckDouble,
  faSearch,
  faEllipsisV,
  faTimes,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import "../assets/css/whatsapp.css";
import whatsapp from "../assets/img/whatsapp.png";
import conversations from "../assets/img/whstaApp-conversations.png";
import pdfIcons from "../assets/img/pdf.png";
import { CONVERSATION_API } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
// import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Alert from "../../UI/Alert/Alert";
import Spining from "../../UI/Spining/Spining";
import { HOST_URL, WhstappAccountList, conversation } from "../api/Api";
import Reply from "../whatsapp/components/Reply";
import dayjs from "dayjs";
import { debounce } from "lodash";
import mapIcon from "../assets/img/whatsapp/map.jpg";
import { RxCross1 } from "react-icons/rx";
import "../whatsapp/WhatsappConv.css";
import Axios from "../../../axios/Axios";

class ImageViewer extends Component {
  render() {
    const { imageUrl, onClose } = this.props;
    return (
      <div className="popup">
        <div className="popupInner">
          <img src={imageUrl} alt="Preview" />
          <button className="cancelButton" onClick={onClose}>
            <RxCross1 />
          </button>
        </div>
      </div>
    );
  }
}

class StanmaxConv extends Component {
  messagesEndRef = React.createRef();

  constructor() {
    
    // const user_id = 8367;
    // const token = "e7a90f3eace43f0f5ba3bae6fdfc6d16";
    super();
    this.state = {
      userId: "8367",
      token: "e7a90f3eace43f0f5ba3bae6fdfc6d16",
      conversationNumberList: "",
      filterList: [],
      content: "",
      unreadMessage: [],
      unreadMessageNumber: [],
      active: "all",
      isLoading: false,
      selectMobile: "",
      page: 0,
      hasMore: true,
      items: Array.from({ length: 20 }),
      selectName: "",
      conversationData: [],
      brand_number: "",
      companyName: "",
      successMessage: false,
      isError: false,
      errorMessage: "",
      messageType: "",
      searchValue: "",
      searchToggle: false,
      isLoadingcov: false,
      isViewerOpen: false,
      selectedImage: null,
      option: [],
      starList: 0,
      showSearch: false,
      searchMessage: "",
    };
    this.searchInput = this.searchInput.bind(this);
    this.debouncedFilterApi = debounce(this.debouncedFilterApi, 1000); // 1000ms debounce delay
  }

  componentDidMount() {
    this.getWhatsaAppNumberList();
  }
  stanmaxLogout=()=>{
    localStorage.removeItem("stanmaxuser");
    window.location = "/admin";
  }
  getConversationNumberList = async () => {
    this.setState({ isLoading: true, active: "all" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "left_menunew",
      start: this.state.page,
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        let starCount = 0;
        res.data.data.forEach((item) => {
          if (item.stared === 1) {
            starCount = starCount + 1;
          }
        });
        this.setState((prevState) => ({
          starList: starCount,
          conversationNumberList: [
            ...prevState.conversationNumberList,
            ...res.data.data,
          ],
        }));

        this.setState({
          unreadMessage: res.data.unread_count,
          isLoading: false,
        });
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  unreadMessage = async () => {
    this.setState({ isLoading: true, active: "unread" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "get_unread_list",
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        this.setState({
          conversationNumberList: res.data.data,
          isLoading: false,
        });
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  starMessage = async () => {
    this.setState({ isLoading: true, active: "star" });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "get_star_list",
      brand_number: this.state.brand_number,
    };
    await conversation(data).then((res) => {
      if (res.data.success === true) {
        let arr = res.data.data;
        let arrLenght = arr.length;
        this.setState({
          conversationNumberList: res.data.data,
          starList: arrLenght,
          isLoading: false,
        });
      } else {
        this.setState({
          ErrorResponse: res.data,
          isLoading: false,
          conversationNumberList: " ",
        });
      }
    });
  };

  getWhatsaAppNumberList = async () => {
    this.setState({ isLoading: true });
    let data = {
      token: this.state.token,
      user_id: this.state.userId,
      method: "retrieve",
    };

    await Axios.get(
      `${HOST_URL}/wp_profile.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`
    ).then((res) => {
      if (res.data.success === true) {
        this.setState({
          brand_number: res.data.data[0].brand_number,
          companyName: res.data.data[0].comp_name,
          isLoading: true,
        });
        this.getConversationNumberList();
      } else {
        this.setState({ ErrorResponse: res.data, isLoading: false });
      }
    });
  };

  getConversation = async (mobile) => {
    this.setState({ isLoading: true });
    try {
      const { data } = await api.post(CONVERSATION_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "conv_list",
        brand_number: this.state.brand_number,
        from_mobile: mobile,
      });
      if (data.success === true) {
        // console.log(JSON.parse(data.data));
        this.setState({
          conversationData: data.data,
          isLoading: false,
        });
        this.setState((prevState) => ({
          unreadMessageNumber: [...prevState.unreadMessageNumber, mobile],
        }));

        // this.getConversationNumberList();
        setTimeout(
          function () {
            let div = document.getElementById("conversation");
            div.scrollTop = div.scrollHeight;
          }.bind(this),
          100
        );
      } else {
        this.setState({ ErrorResponse: data, isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  selectedNumberHandler = async (mobile, name) => {
    this.setState({ selectMobile: mobile, selectName: name });
    await this.getConversation(mobile);

    const updatedData = this.state.conversationNumberList.map((item) => {
      if (item.mobile === mobile) {
        return { ...item, read_status: 1 };
      }
      return item;
    });

    this.setState({ conversationNumberList: updatedData });
  };

  searchInput = async (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
    this.debouncedFilterApi(value);
  };

  debouncedFilterApi = (value) => {
    if (value === "") {
      this.setState({
        unreadMessage: [],
        conversationNumberList: [],
      });
      this.getWhatsaAppNumberList();
    } else {
      this.setState({ isLoadingcov: true, active: "all" });
      let data = {
        token: this.state.token,
        user_id: this.state.userId,
        method: "left_menunew",
        search_keyword: value,
        brand_number: this.state.brand_number,
      };

      conversation(data).then((res) => {
        if (res.data.success === true) {
          this.setState({
            conversationNumberList: res.data.data,
          });
          this.setState({
            unreadMessage: res.data.unread_count,
            isLoadingcov: false,
          });
        } else {
          this.setState({ ErrorResponse: res.data, isLoadingcov: false });
        }

        if (res.data.success === false) {
          this.setState({
            unreadMessage: [],
            conversationNumberList: " ",
          });
        }
      });
    }
  };

  openImageViewer = (imageUrl) => {
    this.setState({
      isViewerOpen: true,
      selectedImage: imageUrl,
    });
  };

  closeImageViewer = () => {
    this.setState({
      isViewerOpen: false,
      selectedImage: null,
    });
  };

  showsearch = () => {
    this.setState({ showSearch: true });
  };

  HideSearch = () => {
    this.setState({ showSearch: false });
    this.setState({ searchMessage: "" });
  };

  handleSearchChange = (e) => {
    this.setState({ searchMessage: e.target.value });
  };

  renderConversationData = () => {
    if (this.state.conversationData.length === 0) {
      return (
        <div className="d-flex text-center align-items-center justify-content-center">
          <p className="empty-message">
            {" "}
            <img
              className="w-25"
              alt="whatsApp conversations"
              src={conversations}
            />{" "}
            <br />{" "}
            <p className="mt-3"> Select any number to start conversation</p>
          </p>
        </div>
      );
    } else {
      return this.state.conversationData
        .filter((x) => {
          return this.state.searchMessage.toLowerCase() === ""
            ? x
            : x.message_content
                .toLowerCase()
                .includes(this.state.searchMessage);
        })
        .map((item, index) => {
          if (item.req_from === "USER") {
            return (
              <div
                ref={this.messagesEndRef}
                key={index}
                className="row message-body"
              >
                <div className="col-sm-12 message-main-receiver">
                  <div className="receiver">
                    <div className="message-text">
                      {item.message_type === "TEXT" ? (
                        item.message_content
                      ) : item.message_type === "IMAGE" ? (
                        <div className="cursor-pointer">
                          <img
                            className="img-fluid whatsapp-image"
                            alt="img"
                            src={
                              // item.file_url.includes("amazonaws")
                              item.file_url
                              // : `https://console.authkey.io/whatsapp/chatimages/reply/${item.file_url}`
                            }
                            onClick={() =>
                              this.openImageViewer(`${item.file_url}`)
                            }
                          />

                          <p className="m-0 image-caption text-wrap text-break" style={{width:"17rem"}}>
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "DOCUMENT" ? (
                        <div className="text-center">
                          {" "}
                          <a download href={item.file_url} target="_blank">
                            <img className="img-fluid" src={pdfIcons} />{" "}
                            <p>Download</p>
                          </a>
                          <p className="m-0 image-caption text-wrap text-break " style={{width:"17rem"}}>
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "AUDIO" ? (
                        <audio controls>
                          <source src={`${item.file_url}`} type="audio/mpeg" />
                        </audio>
                      ) : item.message_type === "VIDEO" ? (<>
                        <video width="320" height="240" controls>
                          <source src={`${item.file_url}`} type="video/mp4" />
                        </video>
                        <p className="m-0 image-caption text-wrap text-break" style={{width:"17rem"}}>
                        {item.image_caption ? item.image_caption : null}
                      </p>
                      </>
                      ) : item.message_type === "LOCATION" ? (
                        <div className="text-center">
                          {" "}
                          <a
                            download
                            href={`https://www.google.com/maps/place/${item.message_content}`}
                            target="_blank"
                          >
                            <img
                              alt="map"
                              className="img-thumbnail"
                              src={mapIcon}
                              style={{ width: "200px", height: "100px" }}
                            />{" "}
                            <p>Location</p>
                          </a>
                        </div>
                      ) : null}
                    </div>

                    <small className="ml-1">
                      {item.status === "read" ? (
                        <FontAwesomeIcon color="#26cf8e" icon={faCheckDouble} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}{" "}
                      <span className="ml-1">
                        {dayjs(item.created).format("M/D/YYYY h:mm A")}
                      </span>{" "}
                    </small>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row message-body">
                <div className="col-sm-12 message-main-sender">
                  <div className="sender">
                    <div className="message-text">
                      {item.message_type === "TEXT" ? (
                        item.message_content
                      ) : item.message_type === "IMAGE" ? (
                        <div className="cursor-pointer">
                          <img
                            alt="img"
                            src={item.file_url}
                            className="img-fluid whatsapp-image"
                            // src={`${item.file_url}`}
                            onClick={() =>
                              this.openImageViewer(`${item.file_url}`)
                            }
                          />
                          <p className="m-0 image-caption text-wrap text-break" style={{width:"17rem"}}>
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "document" ? (
                        <div className="text-center">
                          {" "}
                          <a download target="_blank" href={`${item.file_url}`}>
                            <img className="img-fluid" src={pdfIcons} />{" "}
                            <p>Download</p>
                          </a>
                          <p className="m-0 image-caption text-wrap text-break" style={{width:"17rem"}}>
                            {item.image_caption ? item.image_caption : null}
                          </p>
                        </div>
                      ) : item.message_type === "AUDIO" ? (
                        <audio controls>
                          <source src={`${item.file_url}`} type="audio/mpeg" />
                        </audio>
                      ) : item.message_type === "VIDEO" ? (<>
                        <video width="320" height="240" controls>
                          <source src={`${item.file_url}`} type="video/mp4" />
                        </video>
                        <p className="m-0 image-caption text-wrap text-break" style={{width:"17rem"}}>
                        {item.image_caption ? item.image_caption : null}
                      </p>
                      </>
                      ) : null}
                    </div>
                    {item.status === "read" ? (
                      <FontAwesomeIcon color="#26cf8e" icon={faCheckDouble} />
                    ) : (
                      <FontAwesomeIcon icon={faCheck} />
                    )}
                    <small className="ml-1">
                      {dayjs(item.created).format("M/D/YYYY h:mm A")}
                    </small>
                  </div>
                </div>
              </div>
            );
          }
        });
    }
  };

  fetchMoreData = () => {
    this.setState(
      (prevState) => ({ page: prevState.page + 100 }),
      () => {
        this.getConversationNumberList();
      }
    );
  };
  searchButton = async () => {
    this.setState((prevState) => ({
      searchToggle: !prevState.searchToggle,
    }));
    if (this.state.searchToggle === true) {
      const value = "";
      await this.debouncedFilterApi(value);
    }
  };
  optionToggle = (index) => {
    const { option } = this.state;
    const updatedoption = [...option];
    updatedoption[index] = !updatedoption[index];
    this.setState({ option: updatedoption });
  };
  addStar = async (mobile) => {
    // console.log(mobile)
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_started",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: 1,
    });

    if (data.success === true) {
      this.setState({
        starList: this.state.starList + 1,
      });
    }

    this.setState({ option: [] });
  };
  removeStar = async (mobile, index) => {
    
    // console.log(mobile)
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_started",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "0",
    });
    if (data.success === true) {
      this.setState({
        starList: this.state.starList - 1,
      });
    }
    // const t= this.state.conversationNumberList.indexOf(mobile);
    // console.log(index)
    // console.log(this.state.conversationNumberList)
    // let arrConv
    // if (index) {
    //   arrConv=this.state.conversationNumberList.splice(index, 1);
    // }

    // console.log(arrConv)

    // this.setState({ option: [],
    //   conversationNumberList:arrConv,
    //  });
    window.location.reload(false);
  };
  unRead= async(mobile)=>{
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_unread",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "0",
    });
    if(data.success===true){
      window.location.reload(false);
    }
  }
  read= async(mobile)=>{
    const { data } = await api.post(CONVERSATION_API, {
      token: this.state.token,
      user_id: this.state.userId,
      method: "add_unread",
      brand_number: this.state.brand_number,
      from_mobile: mobile,
      key_value: "1",
    });
    if(data.success===true){
      window.location.reload(false);
    }
  }

  render() {
   
    return (
      <>
        {this.state.isLoading && <Spining />}

        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex justify-content-between">
            <Title isButton={false}> WhatsApp Conversation </Title>
            <div className="cursor-pointer p-1" onClick={this.stanmaxLogout}><FontAwesomeIcon icon={faPowerOff} /></div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12 col-xxl-12">
                {this.state.isError && (
                  <Alert type={this.state.messageType}>
                    {this.state.errorMessage}
                  </Alert>
                )}
                {this.state.isViewerOpen && (
                  <ImageViewer
                    imageUrl={this.state.selectedImage}
                    onClose={this.closeImageViewer}
                  />
                )}
                <div className="app">
                  <div className="row app-one">
                    <div className="col-sm-3 side">
                      <div className="side-one">
                        <div className="heading">
                          <div className="heading-avatar d-flex justify-content-between align-items-center ">
                            <div
                              onClick={this.getConversationNumberList}
                              className="heading-avatar-icon"
                            >
                              <img alt="whatsApp Icons" src={whatsapp} />{" "}
                              <span className="whatsapp-chat-compname">
                                {this.state.companyName
                                  ? this.state.companyName
                                  : "Loading...."}
                              </span>
                            </div>
                            <div>
                              {this.state.searchToggle === false ? (
                                <button
                                  className="conv-search-icon"
                                  onClick={this.searchButton}
                                >
                                  <FontAwesomeIcon icon={faSearch} />
                                </button>
                              ) : null}
                            </div>
                          </div>

                          {this.state.searchToggle && (
                            <div className="d-flex align-item-center">
                              <input
                                type="text"
                                value={this.state.searchValue}
                                onChange={this.searchInput}
                                className="form-control"
                                placeholder="search"
                              />
                              <button
                                style={{
                                  border: "none",
                                  fontSize: "20px",
                                  opacity: "70%",
                                }}
                                onClick={this.searchButton}
                              >
                                x
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center pl-3 p-2">
                          <a href="/stanmax/whatsappconv">
                            <div
                              className={`badge  mr-3 ${
                                this.state.active === "all"
                                  ? "badge-dark"
                                  : "badge-secondary"
                              }`}
                            >
                              All
                            </div>{" "}
                          </a>
                          {this.state.unreadMessage === "0" ? null : (
                            <div
                              onClick={this.unreadMessage}
                              className={`badge  mr-3 ${
                                this.state.active === "unread"
                                  ? "badge-dark"
                                  : "badge-secondary"
                              }`}
                            >
                              Unread{" "}
                              <small> ({this.state.unreadMessage}) </small>
                            </div>
                          )}

                          <div
                            onClick={this.starMessage}
                            className={`badge  mr-3 ${
                              this.state.active === "star"
                                ? "badge-dark"
                                : "badge-secondary"
                            }`}
                          >
                            Star <small> ({this.state.starList}) </small>
                          </div>
                        </div>

                        {this.state.conversationNumberList ? (
                          <InfiniteScroll
                            dataLength={
                              this.state.conversationNumberList.length
                            }
                            height={"100vh"}
                            next={this.fetchMoreData}
                            hasMore={this.state.hasMore}
                          >
                            <div className="row sideBar">
                              {this.state.isLoadingcov === true ? (
                                <Spining />
                              ) : (
                                <>
                                  {this.state.conversationNumberList === " " ? (
                                    <div>
                                      <p>No message</p>
                                    </div>
                                  ) : (
                                    <>
                                      {this.state.conversationNumberList.map(
                                        (item, index) => (
                                          <div
                                            key={index}
                                            className="sideBar-outerBody"
                                          >
                                            <div
                                              key={index}
                                              onClick={() =>
                                                this.selectedNumberHandler(
                                                  item.mobile,
                                                  item.name
                                                )
                                              }
                                              className={
                                                item.mobile ===
                                                this.state.selectMobile
                                                  ? "sideBar-body active"
                                                  : "sideBar-body"
                                              }
                                            >
                                              <div
                                                className={`${
                                                  item.read_status === 1
                                                    ? "d-flex justify-content-between"
                                                    : "d-flex justify-content-between unread-message"
                                                }`}
                                              >
                                                <div
                                                  role="buttom"
                                                  className="sideBar-name"
                                                >
                                                  {this.state.selectMobile ===
                                                  item.mobile ? null : (
                                                    <span className="unread-m-count">
                                                      1
                                                    </span>
                                                  )}
                                                  <span className="name-meta">
                                                    {item.name
                                                      ? item.name
                                                      : item.mobile}
                                                  </span>
                                                  <small>
                                                    {item.message_type ===
                                                    "TEXT"
                                                      ? item.content
                                                      : item.message_type ===
                                                        "DOCUMENT"
                                                      ? "document"
                                                      : item.message_type ===
                                                        "AUDIO"
                                                      ? "Audio"
                                                      : item.message_type ===
                                                        "ERROR"
                                                      ? null
                                                      : item.message_type ===
                                                        "IMAGE"
                                                      ? "image"
                                                      : item.message_type ===
                                                        "VIDEO"
                                                      ? "video"
                                                      : null}
                                                  </small>
                                                </div>
                                                <div className="sideBar-time">
                                                  <span className="time-meta pull-right">
                                                    {dayjs(item.created).format(
                                                      "M/D/YYYY h:mm A"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="drop">
                                              <div className="dropdownn">
                                                <button
                                                  onClick={() =>
                                                    this.optionToggle(index)
                                                  }
                                                  className={
                                                    this.state.option[index]
                                                      ? "active-drop-butn"
                                                      : "drop-butn"
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faEllipsisV}
                                                    // onClick={this.markImp}
                                                  />
                                                </button>
                                                {this.state.option[index] && (
                                                  <div className="dropdownn-content">
                                                    {item.stared === 0 ? (
                                                      <button
                                                        className="addstarbtn"
                                                        onClick={() =>
                                                          this.addStar(
                                                            item.mobile
                                                          )
                                                        }
                                                      >
                                                        Mark as star
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="removestarbtn"
                                                        onClick={() =>
                                                          this.removeStar(
                                                            item.mobile,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Mark as unstar
                                                      </button>
                                                    )}
                                                     {item.read_status === 0 ? (
                                                      <button
                                                        className="removestarbtn"
                                                        onClick={() =>
                                                          this.read(
                                                            item.mobile
                                                          )
                                                        }
                                                      >
                                                        Mark as read
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="addstarbtn"
                                                        onClick={() =>
                                                          this.unRead(
                                                            item.mobile,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Mark as unread
                                                      </button>
                                                    )}
                                                    
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </InfiniteScroll>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-sm-9 conversation">
                      {(this.state.selectName || this.state.selectMobile) && (
                        <div className="heading">
                          <div className="heading-avatar">
                            <div className="heading-avatar-icon d-flex justify-content-between">
                              <div>
                                <img alt="WhatsApp" src={whatsapp} />{" "}
                                {this.state.selectName} (
                                {this.state.selectMobile})
                              </div>
                              <div className="d-flex align-items-center">
                                {this.state.showSearch ? (
                                  <>
                                    <input
                                      style={{
                                        width: "150px",
                                        marginRight: "20px",
                                      }}
                                      type="text"
                                      id="searchInput"
                                      name="searchdata"
                                      value={this.state.searchMessage} // Assuming this.state.searchMessage holds the value
                                      onChange={this.handleSearchChange} // Assuming handleSearchChange is your change handler function
                                      className="form-control"
                                      placeholder="Search Message"
                                    />
                                    <FontAwesomeIcon
                                      onClick={this.HideSearch}
                                      icon={faTimes}
                                    />
                                  </>
                                ) : (
                                  <FontAwesomeIcon
                                    onClick={this.showsearch}
                                    icon={faSearch}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row message" id="conversation">
                        {this.renderConversationData()}
                      </div>
                      {this.state.conversationData.length > 0 && (
                        <Reply
                          brand_number={this.state.brand_number}
                          selectMobile={this.state.selectMobile}
                          sentData={this.getConversation}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default StanmaxConv;

