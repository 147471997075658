import React from 'react';

function Card({ CardTitle, children }) {
    return (
        <div className="card flex-fill">
            <div className="card-header">
                <h5 className="card-title mb-0"> {CardTitle}</h5>
            </div>
            <hr className='m-0' />
            <div className="card-body">
              {children}
            </div>
        </div>
    );
}

export default Card;