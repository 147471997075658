import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../Header/AdminHeader";
import AdminFooter from "../Footer/AdminFooter";
import AdminLeftMenu from "../Navbar/AdminLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSignInAlt,
  faEdit,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { HOST_URL } from "../Dashboard/api/Api";
class EmployeeManagement extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("admin"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    const AdminEmail = lStorage.user.username;
    const userPassword = lStorage.user.password;

    super();

    this.state = {
      user_id: userId,
      adminPassword: userPassword,
      AdminEmail: AdminEmail,
      token: userToken,
      allUserList: null,
      serchData: "",
      userCurrentStatus: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "alert alert-success alert-dismissible",
    };
  }

  componentDidMount() {
    this.onserch();
  }

  onserch() {
    fetch(
      `${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=admin&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({ allUserList: result });
          this.setState({ userCurrentStatus: result.data[0].is_active });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  searchUser() {
    if (this.state.serchData === "") {
      this.onserch();
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Enter User Name " });
      this.setState({ bgColor: "alert alert-danger alert-dismissible" });
    } else {
      fetch(
        `${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=search&user_name=${this.state.serchData}&token=${this.state.token}`
      ).then((response) => {
        response.json().then((result) => {
          if (result.success === true) {
            this.setState({ successMessage: false });
            this.setState({ allUserList: result });
          } else {
            this.setState({ successMessage: true });
            this.setState({ errorMessage: result.message });
            this.setState({ bgColor: "alert alert-danger alert-dismissible" });
          }
        });
      });
    }
  }

  userDisabled(isactive, selectedUserId) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=enabledisable&current_status=${isactive}&ret_user_id=${selectedUserId}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            successMessage: true,
            successBg: "alert alert-success alert-dismissible",
            errorMessage: result.message,
          });
          window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  loginToUserAccount(emailaddress, password, usertype) {
    console.log(usertype)
    const singleTemplateData = {
      username: emailaddress,
      password: password,
      admintype: usertype,
    };
    localStorage.clear();
    fetch(`${HOST_URL}/login.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(singleTemplateData),
    }).then((result) => {
      result.json().then((result) => {
        if (result.success === true) {
          if (result.user.isAdmin === usertype) {
            localStorage.setItem("emp", JSON.stringify(result));
            // localStorage.setItem("loginthrough", "admin");
            setTimeout(function () {
              window.location = "/admin";
            }, 1000);
          }
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
          <AdminHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> Employee </strong> Management{" "}
                  </h3>
                </div>
                <div className="col-auto ml-auto text-right mt-n1">
                  <a
                    className="btn btn-primary btn-sm"
                    href="/admin/add-employee"
                  >
                    {" "}
                    Add New{" "}
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className="card flex-fill">
                    <div className="card-header">
                      <h5 className="card-title mb-0">Employee List</h5>
                    </div>

                    <div className="card-body">
                      <div className="filter-option">
                        <h6 className="card-subtitle text-muted">Filter </h6>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                              </div>
                              <input
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    serchData: event.target.value,
                                  });
                                }}
                                placeholder="Enter user id or name of users"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 text-right">
                            <div className="input-group mb-2 mr-sm-2">
                              <input
                                type="button"
                                onClick={() => this.searchUser()}
                                value="Search"
                                className="btn mt-1  btn-primary btn-sm ml-auto text-right "
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.successMessage ? (
                        <div className={this.state.bgColor} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="">{this.state.errorMessage}</div>
                        </div>
                      ) : null}

                      {this.state.allUserList ? (
                        <div className="table-responsive">
                          <table className="table table-striped table-xs table-sm my-0">
                            <thead>
                              <tr>
                                <th> Email ID / Username</th>
                                <th> Full Name</th>
                                <th> User Type </th>
                                <th> Mobile </th>
                                <th>Created Date / Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.allUserList.data.map((item, i) => (
                                <tr key={i}>
                                  <td>{item.email}</td>
                                  <td>{item.fullname}</td>
                                  <td>{item.user_type}</td>
                                  <td>{item.mobile}</td>
                                  <td>{item.created}</td>
                                  <td>
                                    <Link
                                      className="mr-3"
                                      to={
                                        "/admin/edit-employee/" + item.id + "/"
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Link>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      className="mr-3"
                                      onClick={() =>
                                        this.userDisabled(
                                          item.is_active,
                                          item.id
                                        )
                                      }
                                    >
                                      {item.is_active === 1 ? (
                                        <FontAwesomeIcon
                                          color="green"
                                          icon={faUserLock}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          color="red"
                                          icon={faUserLock}
                                        />
                                      )}
                                    </span>

                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.loginToUserAccount(
                                          item.username,
                                          item.password,
                                          item.user_type
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faSignInAlt}
                                        color="green"
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              className="project-empty-img"
                              src={EmptyImg}
                            />
                            <p> No Any Employee Found </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </div>
      </div>
    );
  }
}
export default EmployeeManagement;
