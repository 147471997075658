import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlus,faEdit,faInfoCircle,faTrash} from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import ReactTooltip from "react-tooltip";
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../api/Api';
 
class SmsTemplateList extends Component {
    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
			SmsTemplateList: null,
			user_id: userId,
            token: userToken,
			successShow:false,
			successMessage:"",
			bgColor:'',
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/dlt_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result)
				if(result.success===true){
					this.setState({SmsTemplateList:result});
				}
               
            })
        })

	}

	deleteDltTemplate(id,template_id){
		document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/dlt_template.php?user_id=${this.state.user_id}&id=${id}&template_id=${template_id}&method=delete&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result.success);
				if(result.success === true ){
					this.setState({successShow:true});
					this.setState({successMessage:result.message});
					this.setState({bgColor:"alert alert-success alert-dismissible"});
					//window.location.reload(); 
					}else{
					this.setState({successShow:true});	
					this.setState({bgColor:"alert alert-danger alert-dismissible"});
					this.setState({successMessage:result.message});	
					}
            })
        })


	}





render() {    
	document.querySelector('body').scrollTo(0, 0);
		return (

			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
				<div className="col-auto d-none d-sm-block">
					<h3><strong>DLT Template </strong> List </h3>
				</div>
 
				<div className="col-auto ml-auto text-right mt-n1">
				<Link className="btn btn-primary btn-sm" to="/dashboard/template-management"> <FontAwesomeIcon icon={faPlus} color=''  />  Add DLT Template</Link>
				</div>

			</div>

			
		      {
				this.state.successShow? 
					<div className={this.state.bgColor} role="alert">
			<button type="button" className="close" data-dismiss="alert" aria-label="Close">
		<span aria-hidden="true">×</span>
		</button>
				<div className="">
						{this.state.errorMessage}
					</div>
				</div>
					:
					null
				}


			<div className="row justify-content-center">
			<div className="col-12 col-lg-12 col-xxl-12 d-flex">
			<div className="card flex-fill table-responsive">
			
			{
				this.state.SmsTemplateList?

			<table className="table table-hover  project  my-0">
				<thead>
					<tr>
						
						<th>Action</th>
						<th>Entity ID</th>
						<th>Template ID</th>
						<th>Template Name</th>
						<th>Template Content</th>
						<th>Sender ID</th>
						<th>Is Unicode</th>
						<th>Create Date</th>
					
						
					</tr>
				</thead>
				<tbody>

				{
						this.state.SmsTemplateList.data.map((itam,i)=>
					<tr key={i}>

							<td>
							<Link to={'/dashboard/template-management/edit/'+itam.id}>
								<span title="Edit Template" className="badge bg-primary"> <FontAwesomeIcon icon={faEdit} /> </span> 
							</Link>
						
						<span title="Delete Template" onClick={()=> this.deleteDltTemplate(itam.id,itam.template_id)} className="badge bg-danger ml-3"> <FontAwesomeIcon icon={faTrash} /> </span> 
						
						</td>

					    <td>{itam.entityid}</td>
					    <td>{itam.template_id}</td>
						<td>{itam.template_name}</td>
						<td>
                             Message <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                            <ReactTooltip className="tooltip"  html={true}  id={"registerTip"+i} place="bottom" effect="solid">
											{itam.template_msg}
                                            </ReactTooltip>
                                </td>

								<td className="">
								{itam.senderid}
							</td>

						<td className="">
						{itam.enable_unicode===1?'Yes':'No'}
						</td>


				

						<td>{itam.created_date}</td>
						
						
						
					</tr>)
				}
				
					</tbody>
			</table>
		:<div className="text-center">
		<div className="card-body">
			<img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
			<p>No SMS Template Yet</p>
			</div>
		</div> 
}


	</div>
	</div>
	</div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default SmsTemplateList;