import React, { Component } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class ShiftRouter extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        super();

        this.state = {
            user_id: userId,
            token: userToken,
            userType: isAdmin,
            routeList: '',
            country_code: 91,
            from_route: '',
            to_route: '',
            channel: '',
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
    }

    componentDidMount() {
        this.Routelist();
    }

    Routelist() {
        fetch(`${HOST_URL}/route.php?user_id=${this.state.user_id}&method=retrieve&type=${this.state.userType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ routeList: result });

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    shiftRouter = () => {

        fetch(`${HOST_URL}/route_transfer.php?user_id=${this.state.user_id}&method=transferall&channel=${this.state.channel}&from_route=${this.state.from_route}&to_route=${this.state.to_route}&country_code=${this.state.country_code}&type=admin&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    render() {
        //  console.log(this.state)
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Shift All  </strong> Route  </h3>
                                </div>


                            </div>
                            <div className="row justify-content-center">
                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }


                                <div className="card">
                                    <div className="card-body">
                                        <div className="">

                                            <div className="filter-option">
                                                <h6 className="card-subtitle text-muted mb-3">Shift Routes to Routes  </h6>
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <div className="input-group-text">From  </div>
                                                                {
                                                                    this.state.routeList ?
                                                                        <select onChange={(event) => this.setState({ from_route: event.target.value })} className="form-control ">
                                                                            <option>Select Route</option>
                                                                            {
                                                                                this.state.routeList.data.map((item, i) =>
                                                                                    <option key={i} value={item.id}>{item.route_name}</option>
                                                                                )}
                                                                        </select>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-3">
                                                        <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <div className="input-group-text"> To </div>
                                                                {
                                                                    this.state.routeList ?
                                                                        <select onChange={(event) => this.setState({ to_route: event.target.value })} className="form-control ">
                                                                            <option>Select Route</option>
                                                                            {
                                                                                this.state.routeList.data.map((item, i) =>
                                                                                    <option key={i} value={item.id}>{item.route_name}</option>
                                                                                )}
                                                                        </select>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <div className="input-group-text"><FontAwesomeIcon icon={faUser} /></div>
                                                                <input disabled onChange={(event) => this.setState({ countryCode: event.target.value })} defaultValue={91} type="text" placeholder="Country Code" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group drop-down-icons  col-md-3">
                                                        <select onChange={(event) => this.setState({ channel: event.target.value })} className="form-control">
                                                            <option value=''> Select Channel </option>
                                                            <option value="sms"> SMS </option>
                                                            <option value="voice"> Voice </option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-12 text-right ">
                                                        <button type="button" onClick={() => this.shiftRouter()} className="btn-success btn-sm  ml-auto"> Shift Router </button>
                                                    </div>



                                                </div>



                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default ShiftRouter;