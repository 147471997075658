import React, { useEffect, useState } from "react";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from "../../../constants/ServiceConstants";
import { useParams } from "react-router-dom";
import Spining from "../../UI/Spining/Spining";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { WHATSAPP_TEMPLATE_LIST_ADMIN } from "../../UI/Table/ColumnConst";
import Alert from "../../UI/Alert/Alert";
import { userloginDetails } from "../../Utils/AdminAuth";
import { HOST_URL } from "../../Dashboard/api/Api";

function WhatsappSetting() {
  let { id, brandNumber } = useParams();
  const adminAuthDetails = userloginDetails();
  const [whatsTemplateList, setwhatsTemplateList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [route, setRoute] = useState();
  const [isError, setisError] = useState(false);
  const [errorType, seterrorType] = useState("");
  const [ErrorResponse, setErrorResponse] = useState(null);
  const [tokenupdate, settokenupdate] = useState("");
  const [apiupdate, setApiupdate] = useState("");
  const [whatsApp, setwhatsApp] = useState({
    address: "",
    brand_number: "",
    category: "",
    comp_name: "",
    description: "",
    email: "",
    image_url: "",
    waba_id: "", // for Whatsapp Token
    number_not_active: "",
    status: "",
    phone_id: "",
    wp_token: "",
    token: "",
  });
  const [changeRouteOptions, setChangeRouteOptions] = useState([]);

  const handleFields = (e) => {
    setwhatsApp({ ...whatsApp, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (adminAuthDetails) {
      getWhatsAppDetails();
      getTemplateList();
      getRouteDetails();
      getRouteOptions();
    }
  }, []);
  // console.log(adminAuthDetails.token,"  ", adminAuthDetails.user.userId,"  ",id,"  ",brandNumber)

  const getRouteOptions = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(ADMIN_USERS_LIST, {
        token: adminAuthDetails.token,
        user_id: adminAuthDetails.user.userId,
        ret_user_id: id,
        brand_number: brandNumber,
        method: "ret_whatsapp_route_master",
      });

      if (data.success === true) {
        setIsLoading(false);

        setChangeRouteOptions(data.data);
      } else {
        setChangeRouteOptions([]);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getWhatsAppDetails = async () => {
    try {
      const { data } = await api.post(ADMIN_USERS_LIST, {
        token: adminAuthDetails.token,
        user_id: adminAuthDetails.user.userId,
        ret_user_id: id,
        brand_number: brandNumber,
        method: "ret_whatsapp_profile",
      });
      if (data.success === true) {
        setwhatsApp({
          address: data.data[0].address,
          brand_number: data.data[0].brand_number,
          category: data.data[0].category,
          comp_name: data.data[0].comp_name,
          description: data.data[0].description,
          email: data.data[0].email,
          image_url: data.data[0].image_url,
          number_not_active: data.data[0].number_not_active,
          status: data.data[0].status,
          app_id: data.data[0].app_id,
          waba_id: data.data[0].waba_id,
          phone_id: data.data[0].phone_number_id,
          daily_sending_limit: data.data[0].daily_sending_limit,
        });
        settokenupdate(data.data[0].token);
        setApiupdate(data.data[0].api_key)
        setIsLoading(false);
      } else {
        setErrorResponse(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTemplateList = async () => {
    try {
      const { data } = await api.post(ADMIN_USERS_LIST, {
        token: adminAuthDetails.token,
        user_id: adminAuthDetails.user.userId,
        ret_user_id: id,
        method: "whatsapp_template",
      });
      //console.log(data)
      if (data.success === true) {
        setwhatsTemplateList(data.data);
        setIsLoading(false);
      } else {
        setErrorResponse(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getRouteDetails = async () => {
    try {
      const { data } = await api.post(ADMIN_USERS_LIST, {
        token: adminAuthDetails.token,
        user_id: adminAuthDetails.user.userId,
        ret_user_id: id,
        method: "ret_whatsapp_route",
      });
      // console.log('ret_whatsapp_route', data)
      if (data.success === true) {
        setRoute(data.data);
        setIsLoading(false);
      } else {
        setErrorResponse(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateDetails = async () => {
    if (!whatsApp.daily_sending_limit) {
      setisError(true);
      seterrorType("alert alert-danger alert-dismissible");
      setErrorResponse("Daily sending limit cannot be empity");
      return;
    }
    try {
      document.querySelector("body").scrollTo(0, 0);
      const data = new FormData();
      data.append("status", whatsApp.status);
      data.append("wp_token", tokenupdate);
      data.append("api_key", apiupdate);
      data.append("app_id", whatsApp.app_id);
      data.append("waba_id", whatsApp.waba_id);
      data.append("phone_id", whatsApp.phone_id);
      data.append("user_id", adminAuthDetails.user.userId);
      data.append("method", "update_whatsapp_profile11");
      data.append("token", adminAuthDetails.token);
      data.append("ret_user_id", id);
      data.append("brand_number", whatsApp.brand_number);
      data.append("sending_limit", whatsApp.daily_sending_limit);
      const response = await fetch(
        `${HOST_URL}/admin_user_list.php`,
        {
          method: "POST",
          body: data,
        }
      );

      const result = await response.json(); // Parse JSON here

      if (result.success === true) {
        // Update states or handle response
        setisError(true);
        seterrorType("alert alert-success alert-dismissible");
        setErrorResponse(result.message);

        // setTimeout(function () {
        //   window.location.reload();
        // }, 2000);
      } else {
        setisError(true);
        seterrorType("alert alert-danger alert-dismissible");
        setErrorResponse(result.message);
      }
    } catch (err) {
      console.log(err);
      // Handle error - display an error message or take appropriate action
    }
  };

  const UpdateRoute = async () => {
    try {
      const { data } = await api.post(ADMIN_USERS_LIST, {
        token: adminAuthDetails.token,
        user_id: adminAuthDetails.user.userId,
        ret_user_id: id,
        route_id: route,
        method: "update_whatsapp_route",
      });

      if (data.success === true) {
        setisError(true);
        setErrorResponse(data.message);
        seterrorType("alert-success");
      } else {
        setErrorResponse(data.message);
        setisError(true);
        seterrorType("alert-danger");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(whatsApp);
  return (
    <AdminLayout>
      {isLoading && <Spining />}
      <Title
        isButton={true}
        btnName={`Back`}
        actionURL={`/admin/whatsapp-account-list`}
      >
        {" "}
        Whatsapp User List
      </Title>
      {isError && <Alert type={errorType}> {ErrorResponse} </Alert>}
      <div className="row">
        <div className="col-12 col-lg-12 col-xxl-12">
          <div className="">
            <div className="card">
              <div className="card-body">
                <div className="admin-tabs-menu">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Profile Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        WhatsApp Template List
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#contact"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                      >
                        Update WhatsApp Token
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="route-tab"
                        data-toggle="tab"
                        href="#route"
                        role="tab"
                        aria-controls="route"
                        aria-selected="false"
                      >
                        Change Route{" "}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="card-body">
                      <div className="form-row ">
                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Company Name:
                          </label>
                          <input
                            className="form-control"
                            placeholder="Company Pvt. Ltd."
                            type="text"
                            disabled
                            defaultValue={whatsApp.comp_name}
                            name="comp_name"
                            id="flexCheckDefault"
                            onChange={handleFields}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            No. not active on WhatsApp
                          </label>
                          <select
                            value={whatsApp.number_not_active}
                            onChange={handleFields}
                            disabled
                            className="form-control"
                          >
                            <option value=""> Select </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>

                        <div className="form-group col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <label className="col-form-label">
                                Display Picture:
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                disabled
                                name="emailunsub"
                                id="flexCheckDefault"
                              />
                              <small>
                                (Max size of 5MB, an image size of 640x640 is
                                recommended)
                              </small>
                            </div>
                            <div className="col-md-6">
                              <img
                                className="img-fluid"
                                src={whatsApp.image_url}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-12">
                          <label className="col-form-label">
                            Business Profile Address:
                          </label>
                          <textarea
                            placeholder="Enter Full Address (Maximum of 256 characters)"
                            className="form-control"
                            value={whatsApp.address}
                            disabled
                            name="address"
                            onChange={handleFields}
                          ></textarea>
                        </div>

                        <div className="form-group col-md-12">
                          <label className="col-form-label">
                            Business Description:
                          </label>
                          <textarea
                            placeholder="Enter Full Address (Maximum of 256 characters)"
                            className="form-control"
                            value={whatsApp.description}
                            disabled
                            name="description"
                            onChange={handleFields}
                          ></textarea>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Business Category:
                          </label>
                          <input
                            className="form-control"
                            placeholder="Grocery, Technology"
                            type="text"
                            disabled
                            id="flexCheckDefault"
                            value={whatsApp.category}
                            name="category"
                            onChange={handleFields}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Business Email:
                          </label>
                          <input
                            className="form-control"
                            placeholder="company@mail.com"
                            type="text"
                            id="flexCheckDefault"
                            disabled
                            value={whatsApp.email}
                            name="email"
                            onChange={handleFields}
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <label className="col-form-label">
                            WhatsApp Profile Status:
                          </label>
                          <input
                            className="form-control"
                            placeholder="The max length for the string is 139 characters."
                            type="text"
                            disabled
                            name="profile_status"
                            onChange={handleFields}
                            id="flexCheckDefault"
                            value={whatsApp.profile_status}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Proposed WhatsApp Number:
                          </label>
                          <input
                            className="form-control"
                            placeholder="91XXXXXXXX"
                            type="text"
                            // disabled
                            value={whatsApp.brand_number}
                            name="brand_number"
                            id="flexCheckDefault"
                            onChange={handleFields}
                          />
                          <small>
                            (For netcore remove 91 from brand number and rest
                            all add 91 with brand number)
                          </small>
                        </div>

                        <div className="form-group col-md-6">
                          <label className="col-form-label">
                            Daily Sending Limit
                          </label>
                          <input
                            className="form-control"
                            placeholder="daily sending limit"
                            type="text"
                            defaultValue={whatsApp.daily_sending_limit}
                            name="daily_sending_limit"
                            id="flexCheckDefault"
                            onChange={handleFields}
                            required
                          />
                        </div>

                        <div className="form-group col-md-12">
                          <div className="row">
                            <div className="col-md-8">
                              <label className="col-form-label">
                                App Id (Mendatory for Pinnacle and meta direct route)
                              </label>
                              <input
                            className="form-control"
                            placeholder="app_id"
                            type="text"
                            name="app_id"
                            onChange={handleFields}
                            id="flexCheckDefault"
                            value={whatsApp.app_id}
                          />                           
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="col-form-label">Waba Id:</label>
                          <input
                            className="form-control"
                            placeholder="Waba Id"
                            type="text"
                            name="waba_id"
                            onChange={handleFields}
                            id="flexCheckDefault"
                            value={whatsApp.waba_id}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label className="col-form-label">Phone No Id:</label>
                          <input
                            className="form-control"
                            placeholder="Phone No Id"
                            type="text"
                            name="phone_id"
                            onChange={handleFields}
                            id="flexCheckDefault"
                            value={whatsApp.phone_id}
                          />
                        </div>

                        <div className="form-group col-md-4">
                          <label className="col-form-label">
                            WhatsApp Account Status:
                          </label>
                          <select
                            onChange={handleFields}
                            value={whatsApp.status}
                            className="form-control"
                            name="status"
                          >
                            <option>Select Account Status </option>
                            <option value={0}>Pending </option>
                            <option value={1}>Approved </option>
                            <option value={2}>Processed </option>
                          </select>
                        </div>
                      </div>
                      <button
                        onClick={UpdateDetails}
                        className="btn btn-sm float-right btn-primary"
                      >
                        Update Account Status
                      </button>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="form-row ">
                      <div className="form-group col-md-12">
                        <label className="col-form-label">Access Token:</label>
                        <input
                          className="form-control"
                          placeholder="access Token"
                          onChange={(e) => settokenupdate(e.target.value)}
                          type="text"
                          defaultValue={tokenupdate}
                          name="token"
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                    <div className="form-row ">
                      <div className="form-group col-md-12">
                        <label className="col-form-label">API Key:</label>
                        <input
                          className="form-control"
                          placeholder="Enter api key"
                          onChange={(e) => setApiupdate(e.target.value)}
                          type="text"
                          defaultValue={apiupdate}
                          name="api"
                          id="flexCheckDefault"
                        />
                        <span style={{fontSize:"12px", fontWeight:"bold"}}>Note: Api key in mendatory  in case of pinnacel route given to user</span>
                      </div>
                    </div>
                    <button
                      onClick={UpdateDetails}
                      className="btn btn-sm float-right btn-primary"
                    >
                      Update Token
                    </button>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="form-row ">
                      <div className="form-group col-md-12">
                        <AdvanceDataTable
                          tableName={``}
                          tableCell={WHATSAPP_TEMPLATE_LIST_ADMIN}
                          tableData={whatsTemplateList}
                          isLoading={isLoading}
                          isError={ErrorResponse}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="route"
                    role="tabpanel"
                    aria-labelledby="route-tab"
                  >
                    <div className="form-row ">
                      <div className="form-group col-md-12">
                        <label className="col-form-label">Route Name:</label>
                        <select
                          value={route?.[0]?.routeid || route}
                          onChange={(e) => setRoute(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Select Route </option>

                          {changeRouteOptions?.length
                            ? changeRouteOptions.map((options) => (
                                <option value={options.id} key={options.id}>
                                  {options.route_name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={UpdateRoute}
                      className="btn btn-sm float-right btn-primary"
                    >
                      Update Route
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default WhatsappSetting;
