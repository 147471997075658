import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarCheck, faInfoCircle,faMobile,faBan,faDownload,faSearch} from '@fortawesome/free-solid-svg-icons'
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import ReactTooltip from "react-tooltip";
import { HOST_URL } from '../api/Api';

class RichMediaReport extends Component {

    constructor (props){

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);
        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            yulorelist:'',
            responceMessage:'',
            status:'',
            senderid:'',
            mobile:'',
            email:'',
            channel:'yulore',
            dateFrom:date,
            dateTo:date,
            SelecteddateFrom:'',
            SelecteddateTo:'',
        }
    }

componentDidMount() {
    this.detailsReport();
}

detailsReport(){
    fetch(`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=yulore&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`).then((response)=> {
        response.json().then((result)=>{
            //  console.log('result',result)
            if(result.success===true){
                    this.setState({yulorelist:result.message});
                }else{
                    this.setState({responceMessage:result.message});
                }
            })
    })
}

FilderDetailsWithChannel(){
    this.setState({yulorelist:''});
    fetch(`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=details&channel=${this.state.channel}&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}&mobile=${this.state.mobile}&sernderid=${this.state.sernderid}&status=${this.state.status}&email=${this.state.email}`).then((response)=> {
        response.json().then((result)=>{
          // console.log(result)
           if(result.success===true){
                this.setState({yulorelist:result.message});
                }else{
                    this.setState({successMessage:true,errorMessage:result.message,bgColor:"alert alert-danger alert-dismissible"})
                }
        })
    })
}

dataDownload(){
    window.location.href=`${HOST_URL}/user_report.php?user_id=${this.state.user_id}&method=download&channel=yulore&token=${this.state.token}&date_from=${this.state.dateFrom}&date_to=${this.state.dateTo}`;
}

dateFrom = (date) => {
    let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
     this.setState({
         dateFrom: dated,
           SelecteddateFrom:date
     });
 };

 dateTo = (date) => {
     let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
      this.setState({
          dateTo: dated,
          SelecteddateTo:date
      });
  };


setStatus(){
    if(this.state.channel==='yulore'){
        return (
            <>
            <option value=""> All </option>
            <option value="Phone Not Supported"> Phone Not Supported </option>
            <option value="Delivered"> Delivered </option>
            <option value="Sent"> Sent </option>
            </>
        )
    }else{
        return (
            <>
            <option value=""> No Channel Selected </option>
            </>
        )
    }
}



render() {
// console.log(this.state)
    return (

<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">
                <div className=" mb-2 mb-xl-3">
        <div className="col-auto d-none d-sm-block">
            <h3><strong>Rich Media </strong> Report </h3>
        </div>
    </div>
    <div className="">
        <div className="card">

        <div className="card-body">
            <div className="filter-option">
                <h6 className="card-subtitle text-muted">Filter </h6> 
                <div className="row">
                    <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>
                            
                            <DatePicker className="form-control btn-block"
                            dateFormat="yyyy/MM/dd"
                            value={this.state.dateFrom}
                            onChange={this.dateFrom}
                            selected={this.state.SelecteddateFrom}
                            placeholderText='From Date'
                          /> 
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck}/></div>

                            <DatePicker className="form-control btn-block"
                                dateFormat="yyyy/MM/dd"
                                value={this.state.dateTo}
                                onChange={this.dateTo}
                                selected={this.state.SelecteddateTo}
                                placeholderText='Date To'
                                /> 

                        </div>
                    </div>

                    <div className="col-md-4">
                         <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faMobile}/></div>
                            {this.state.channel==='email'?
                            <input  onChange={(event) => this.setState({ email:event.target.value })} type="text"  placeholder="Enter Email" className="form-control" />
                            :
                            <input  onChange={(event) => this.setState({ mobile:event.target.value })} type="text"  placeholder="Enter Mobile NO" className="form-control" />
                            }
                        </div>
                    </div>

                    </div>
                        
                    <div className="row mt-3">
              

                        
                    <div className='col-md-6'>
                        <div className="input-group mb-2 mr-sm-2">
                            <div className="input-group-text"><FontAwesomeIcon icon={faBan}/></div>
                            <select onChange={(event) => this.setState({ status: event.target.value })} className="form-control">
                                <option value=""> Select Status </option>
                                {
                                    this.setStatus()
                                }
                            </select>
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <button type="button" onClick={()=> this.dataDownload()} className="btn-success btn-sm btn-block ml-auto"><FontAwesomeIcon icon={faDownload}/> Download Reports </button>
                    </div>
                    <div className="col-md-3">
                        <button type="button" onClick={()=> this.FilderDetailsWithChannel()}  className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch}/> Filter Search </button>
                            </div>
                        </div>
                    </div>
        

                    <hr/>
            

                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div style={{overflow:'scroll',height:400+'px'}} className=" flex-fill">
                        <div  className="">
                        { this.state.yulorelist ?
                            <table className="table table-striped table-sm my-0">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                         <th>Mobile</th>
                                        <th >Message</th>
                                        <th >Sender ID</th>
                                        <th >Submitted </th>
                                        <th >Delivered </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    this.state.yulorelist.map((item,i)=>
                                        <tr>
                                            <td>
                                            {
                                                item.status === 'Delivered' ? <span className="badge btn-success">{item.status}</span> : 
                                                item.status === 'Sent' ? <span  className="badge bg-info">{item.status}</span> : 
                                                
                                                <span  className="badge bg-danger">
                                                    Phone Not Supported
                                                </span>
                                                
                                            }  
                                             
                                             </td>


                                            <td>{item.mobile}</td>
                                       
                                            <td>
                                             <span className="badge bg-info" data-tip data-event='click focus' data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <small style={{fontSize:10+'px'}}>Message Show / Hide </small> <FontAwesomeIcon icon={faInfoCircle} color='yellow' size={5} /></span>
                                            <ReactTooltip  globalEventOff='click' 
                                            className="tooltip" 
                                             html={true} 
                                             clickable={true}
                                             onClick={() => { ReactTooltip.show(this.fooRef) }}
                                            id={"registerTip"+i}  
                                            place="bottom" effect="solid">
                                             {item.message}
                                            </ReactTooltip>
                                            </td>
                                            <td>{item.sender_id}</td>
                                            <td>{item.submitted_on}</td>
                                            <td>{item.delivered_on}</td>
                                        </tr>
                                        )
                                    }  
                                </tbody>
                            </table>
                            :
                            <div className="text-center">
                            <div className="card-body">
                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                <small>{this.state.responceMessage} in <strong> {this.state.channel}  </strong> Between {this.state.dateFrom} to {this.state.dateTo} </small>
                                </div>
                            </div>

                            
                           

                        }



                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
               </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RichMediaReport;