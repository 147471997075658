import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { HOST_URL } from "../api/Api";

class AwsFromEmail extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      from_email: "",
      awsStatus: "",
      ListOfVerifyEmailID: null,
      errorMessage: "",
      successMessage: false,
      bgColor: "alert alert-success alert-dismissible",
    };
  }

  componentDidMount() {
    this.getAwsStataus();
    this.GetListOfEnlityId();
  }

  getAwsStataus() {
    fetch(
      `${HOST_URL}/aws_setting.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({ awsStatus: result.data[0].status });
        }
      });
    });
  }

  GetListOfEnlityId() {
    fetch(
      `${HOST_URL}/aws_from_email.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({ ListOfVerifyEmailID: result });
        }
      });
    });
  }

  verified(email) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/aws_fromemail_verify.php?user_id=${this.state.user_id}&from_email=${email}&method=verify&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        //console.log(result)
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
          });
          this.setState({ errorMessage: result.message });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
          });
          this.setState({ errorMessage: result.message });
        }
      });
    });
  }

  resend(email) {
    document.querySelector("body").scrollTo(0, 0);
    fetch(
      `${HOST_URL}/aws_fromemail_verify.php?user_id=${this.state.user_id}&from_email=${email}&method=resend&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
          });
          this.setState({ errorMessage: result.message });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
          });
          this.setState({ errorMessage: result.message });
        }
      });
    });
  }

  emailVerify() {
    document.querySelector("body").scrollTo(0, 500);

    const event = {
      user_id: this.state.user_id,
      from_email: this.state.from_email,
      method: "create",
      token: this.state.token,
    };

    fetch(`${HOST_URL}/aws_from_email.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(event),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
          });
          this.setState({ errorMessage: resp.message });
          window.location.reload();
          this.props.history.push("/dashboard/aws-email-verify");
        } else {
          this.setState({ successMessage: true });
          this.setState({ bgColor: "alert alert-danger alert-dismissible" });
          this.setState({ errorMessage: resp.message });
        }
      });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>AWS </strong> Email Verify{" "}
                  </h3>
                </div>
              </div>
              <div className="">
                {this.state.successMessage ? (
                  <div className={this.state.bgColor} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{this.state.errorMessage}</div>
                  </div>
                ) : null}
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Email Verify Process</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      {" "}
                      To send email from Authkey, you need to add & verify
                      "From" email addresses here. You can also add multiple
                      email addresses one-by-one. Follow below given steps to
                      add and verify your From Email now:{" "}
                    </p>
                    <p>
                      <ol>
                        <li>Enter Email Address</li>
                        <li>
                          Click on <strong> Submit </strong>
                        </li>
                        <li>
                          Go to your email inbox and find{" "}
                          <strong>AWS verification link.</strong>
                        </li>
                        <li>Click on verification link in your email inbox.</li>
                        <li>
                          After success verification, Click on "
                          <strong>I have verified </strong>" button here.
                        </li>
                      </ol>
                    </p>
                    <hr />
                    {this.state.awsStatus === 3 ? (
                      <form>
                        <div className="mb-3">
                          <input
                            type="text"
                            onChange={(event) =>
                              this.setState({ from_email: event.target.value })
                            }
                            placeholder="Enter Email Address"
                            className="form-control"
                            id="inputPasswordNew"
                          />
                        </div>
                        <button
                          onClick={() => this.emailVerify()}
                          type="button"
                          className="btn btn-sm btn-primary"
                        >
                          Submit
                        </button>
                      </form>
                    ) : (
                      <p>
                        <div id="addTemplateBox">
                          <label className="form-label">
                            {" "}
                            To verify your email, kindly setup{" "}
                            <Link to="/dashboard/aws-setup">
                              {" "}
                              AWS Setting{" "}
                            </Link>{" "}
                            first.{" "}
                          </label>
                        </div>
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title mb-0">
                        List Of Verified Email
                      </h5>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="">
                          {this.state.ListOfVerifyEmailID ? (
                            <div className="table-responsive">
                              <table className="table table-hover my-0">
                                <thead>
                                  <tr>
                                    <th className="d-none d-md-table-cell">
                                      {" "}
                                      ID
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                      Email ID
                                    </th>
                                    <th className="d-none d-xl-table-cell">
                                      Added On Date / Time{" "}
                                    </th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.ListOfVerifyEmailID.data.map(
                                    (item, i) => (
                                      <tr key={i}>
                                        <td className="d-none d-xl-table-cell">
                                          {item.id}
                                        </td>
                                        <td className="d-none d-xl-table-cell">
                                          {item.email}
                                        </td>
                                        <td className="d-none d-xl-table-cell">
                                          {item.created}
                                        </td>
                                        <td>
                                          {item.is_verified === 2 ? (
                                            <span className="badge bg-success">
                                              {" "}
                                              Active{" "}
                                            </span>
                                          ) : item.is_verified === 1 ? (
                                            <>
                                              <button
                                                onClick={() =>
                                                  this.verified(item.email)
                                                }
                                                className="badge bg-primary mr-2"
                                              >
                                                {" "}
                                                I have verified{" "}
                                              </button>
                                              <button
                                                onClick={() =>
                                                  this.resend(item.email)
                                                }
                                                className="badge bg-warning"
                                              >
                                                {" "}
                                                Resend Verification Link{" "}
                                              </button>
                                            </>
                                          ) : (
                                            <span className="badge bg-danger">
                                              {" "}
                                              Pending{" "}
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>{" "}
                            </div>
                          ) : (
                            <div className="text-center">
                              <div className="card-body">
                                <img
                                  alt="Empty Data"
                                  className="project-empty-img"
                                  src={EmptyImg}
                                />
                                <p>No Verified Email ID Yet</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default AwsFromEmail;
