import React from 'react';
import SmsApiComponent from '../../site/SmsApiComponent'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
const SmsApiDashboard = () => {
    return (
        <div className="wrapper">
            <DashboardLeftMenu />
            <div className="main">
                <DashboardHeader />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className=" row mb-2 mb-xl-3">
                            <div className="col-auto d-none d-sm-block">
                                <h3><strong> SMS </strong> Docs </h3>
                            </div>
                            
                        </div>
                        <div className="">

                        
                            <div className="card">
                            <div className="card-body">
                                    <SmsApiComponent />
                                </div>

                            </div>
                        </div>

                    </div>
                </main>
                <DashboardFooter />
            </div>
        </div>
    );
}

export default SmsApiDashboard;