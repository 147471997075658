import React, { useRef, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { HOST_URL } from "../api/Api";
export default function Paypal(props) {
    

const paypal = useRef();
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Authkey Credits",
                amount: {
                  currency_code: "EUR",
                  value: props.amount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          TransactionUrl(1,order.id,props.originalAmount);
         // console.log(order);
          //console.log(order.id)
        },
        onError: (err) => {
          TransactionUrl(0,'ERROR',props.originalAmount);
         // console.log(err);
        },
      })
      .render(paypal.current);
  }, []);


  
const TransactionUrl= (status,transId,amount)=> {

    
        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId= lStorage.user.userId;
        const userToken= lStorage.token;

        fetch(`${HOST_URL}/payment_gateway_paypal.php?user_id=${userId}&method=recharge&amount=${amount}&trans_id=${transId}&status=${status}&token=${userToken}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    return (<Redirect push to="/dashboard/transaction-history"/>)
                }
            })
        })


   
}

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}