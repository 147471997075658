import React, { useState } from 'react';
import { ADMIN_USERS_LIST } from '../../../../../constants/ServiceConstants'
import api from '../../../../../axios/Axios';
import Dialog from 'react-dialog';
import { userloginDetails } from '../../../../Utils/AdminAuth';

function ApproveTemplate({ templateid, user_id, dynamicContent, status }) {
    let template_id = templateid;
    let userID = user_id;
    console.log('status==>', status);
    const [selectStatus, setselectStatus] = useState(null);
    const [isDynamic, setIsDynamic] = useState(null);
    const [isDialogOpen, setisDialogOpen] = useState(false);
    const adminAuthDetails = userloginDetails();

    const approveTemplate = async () => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                ret_user_id: userID,
                template_id: template_id,
                status: selectStatus,
                dynamic_content: isDynamic,
                method: 'update_whatsapp_template',
            });

            if (data.success === true) {
                setisDialogOpen(false);
                alert(data.message);
            }
        } catch (err) {
            console.log(err)
        }

    }

    const openDialog = (e) => {
        setisDialogOpen(true);
    }

    const handleClose = () => {
        setisDialogOpen(false)
    }

    return (
        <div>

            {
                isDialogOpen &&
                <Dialog
                    title={'Please select the status'}
                    modal={true}
                    height={500}
                    isDraggable={true}
                    onClose={handleClose}
                    buttons={
                        [{
                            text: "Update Status",
                            className: 'btn-info btn-sm btn mr-2',
                            onClick: () => approveTemplate()
                        },
                        ]
                    }>

                    <div className="form-group col-md-12">
                        <select defaultValue={dynamicContent} onChange={e => setIsDynamic(e.target.value)} className='form-control'>
                            <option value={null}>Dynamic Content</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                        </select>
                    </div>

                    <div className="form-group col-md-12">
                        <select defaultValue={status} onChange={e => setselectStatus(e.target.value)} className='form-control'>
                            <option value={null}>Select Status</option>
                            <option value={0}>Peding</option>
                            <option value={1}>Approve</option>
                            <option value={3}>Rejected</option>
                            <option value={4}>Processing</option>
                        </select>
                    </div>
                </Dialog>
            }

            <span onClick={() => openDialog()} className='badge bg-success mr-2'> Approve Template </span>
        </div>
    );
}

export default ApproveTemplate;