import React, { useEffect, useState } from "react";
import Spining from "../Spining/Spining";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { customTableStylesForSummery } from "../../Utils/customTableStyles";

function AdvanceDataTable(props) {
  const TableCell = props.tableCell;

  const [TableData, setTableData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(true);
  const [paginationStatus, setPaginationStatus] = useState(true);

  useEffect(() => {
    setTableData(props.tableData);
  }, [props.tableData]);

    useEffect(()=>{
      setFilterStatus(props.filterstatus)
    },[props.filterstatus])
    useEffect(()=>{
      setPaginationStatus(props.pagination)
    },[props.pagination])
  const filterData = (text) => {
    if (!text) {
      setTableData(props.tableData); 
    } else {
      const regex = new RegExp(`${text}`, "gi");
      const data = TableData.filter((items) => items.email.match(regex));
      setTableData(data);
    }
  };

  const reset = () => {
    setTableData(props.tableData); 
  };
 

  return (
    <div className="row">
      {props.isLoading && <Spining />} 

      <div className="col-12 col-lg-12 col-xxl-12">
        <div className="card flex-fill  table-content-show">
          <div className="d-flex align-items-center">
            {props.tableName && (
              <div className="card-body">
                <h6 className="card-subtitle m-0 text-muted">
                  {" "}
                  {props.tableName}{" "}
                </h6>
              </div>
            )}
            {props.filter && (
              <div className="input-group pr-3 w-50">
                <input
                  type="text"
                  onChange={(e) => filterData(e.target.value)}
                  className="form-control"
                  placeholder="Recipient's email address"
                />
                <div className="input-group-append">
                  <span
                    onClick={reset}
                    className="btn btn-danger btn-sm"
                    id="basic-addon2"
                  >
                    Reset
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="table-responsive">
            <DataTableExtensions
              columns={TableCell || []}
              data={TableData || []} 
              print={false}
              export={false}
              filter={filterStatus}
              filterPlaceholder	={"Search..."}
            >
              <DataTable
                noHeader
                pagination={paginationStatus}
                customStyles={customTableStylesForSummery}
                highlightOnHover
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdvanceDataTable;
