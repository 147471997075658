import React from "react";
const DashboardFooter = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row text-muted">
          <div className="col-12 text-right"></div>
        </div>
      </div>
    </footer>
  );
};

export default DashboardFooter;
