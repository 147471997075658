import React, { Component } from 'react';
import "react-slideshow-image/dist/styles.css";
import { Slide } from 'react-slideshow-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointDown} from '@fortawesome/free-solid-svg-icons';
const slideImages = [
  '../aws/Step-1.png',
  '../aws/Step-2.png',
  '../aws/Step-3.png',
  '../aws/Step-4.png',
  '../aws/Step-5.png',
  '../aws/Step-6.png'
];

class AwsSlider extends Component {

    constructor(){
        super();
        this.state ={
            autoplay:false
        }
    }

    render() {
        return (
            <div>
                <Slide autoplay={this.state.autoplay} easing="ease">
                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[0]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-4">

                            <div className="row">
                                <div className="col-md-12">
                                    Login to your AWS console and open this URL :
                                      <a  target="_blank" rel="noopener noreferrer" href="https://console.aws.amazon.com/iam/home?#/users$new?step=details">https://console.aws.amazon.com/iam/home?#/users$new?step=details </a>
                                </div>

                                <ul>
                                    <li>  <span> 1. </span> Enter <span> authkey </span> as the user name </li>
                                    <li> <span> 2. </span> Check Programmatic access for the access type </li>
                                    <li> <span> 3. </span> If everything looks like the image attached, click Next: Permissions  </li>
                                </ul>

                            </div>

                        </div>
                    </div>

                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[1]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">

                            <div className="row">

                                <ul>
                                    <li>  <span> 4. </span> Click the tab named Attach existing policies directly </li>
                                    <li> <span> 5. </span> Search for sesf </li>
                                    <li> <span> 6. </span> Check the AmazonSESFullAccess policy, this gives us permission
            to use SES of your account  </li>

                                </ul>

                            </div>

                        </div>
                    </div>


                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[2]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">

                            <div className="row">

                                <ul>
                                    <li>  <span> 7. </span> Search for snsf </li>
                                    <li> <span> 8. </span> Check the AmazonSNSFullAccess policy, this gives us permission to
   use SNS of your account </li>
                                    <li> <span> 9. </span> If everything looks like the image attached, click Next: Tags  </li>

                                </ul>

                            </div>

                        </div>
                    </div>



                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[3]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">

                            <div className="row">

                                <ul className="">
                                          <li>  <span> 10. </span> Nothing is needed to perform in this step, just click Next: Review </li>
 

                                        </ul>

                            </div>

                        </div>
                    </div>


                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[4]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">

                            <div className="row">

                            <ul>
                                          <li>  <span> 11. </span> Check that the attached policies have AmazonSESFullAccess &amp; 
                                                         AmazonSNSFullAccess </li>

                                          <li>  <span> 12. </span> If everything looks like the image attached, click Create user</li>

                                        </ul>

                            </div>

                        </div>
                    </div>



                    <div className="each-slide row">
                        <div className="col-md-7" style={{ 'backgroundImage': `url(${slideImages[5]})` , backgroundSize:'contain' }}>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">

                            <div className="row">

                            <ul>
                                          <li>  <span> 13. </span>  All done! You should now see the <span> authkey </span> user you created AmazonSNSFullAccess </li>

                                          <li>  <span> 14. </span> Click Show under Secret access key, to reveal your credentials </li>

                                           <li>  <span> 15. </span> You should now see the Access key ID and Secret access key </li>

                                        <li className="text-success">  <span> 16. </span> Save the following  <br/> credentials below. <FontAwesomeIcon icon={faHandPointDown} /> </li>

 

                                        </ul>

                            </div>

                        </div>
                    </div>


                </Slide>

            </div>
        );
    }
}

export default AwsSlider;