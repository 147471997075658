import React from 'react';
import AdminHeader from '../../Header/AdminHeader';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';

const AdminLayout = ({children}) => {
    return (
        <div className="wrapper">
        <AdminLeftMenu />
        <div className="main">
            <AdminHeader />
            <main className="content">
                <div className="container-fluid p-0">
                   {children}
                </div>
            </main>
            <AdminFooter />
        </div>
    </div>
    );
};

export default AdminLayout;