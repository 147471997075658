import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import AdminLayout from "../UI/Layout/AdminLayout";
import { HOST_URL } from '../Dashboard/api/Api';


class AdminMyProfile extends Component {
    constructor() {
        const lStorage =
      JSON.parse(localStorage.getItem("admin")) ||
      JSON.parse(localStorage.getItem("emp"))||
      JSON.parse(localStorage.getItem("reseller"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    

        super();

        this.state = {
            UserDetails: [],
            user_id: userId,
            token: userToken,
            fullname: '',
           
            address_line_1: '',
            city: '',
            country: '',
            currency: '',
            enable_ip_whitelist: 0,
            userisWhitelable: false,
            whiteLableIP: '',
            mobile: '',
            id: '',
            expiryDate: '',
          
            successMessage: false,
            errorMessage: '',
            kylas_crm_id: '',
            bgColor: "alert alert-danger alert-dismissible"

        }

        this.updateProfile = this.updateProfile.bind(this);

    }

    componentDidMount() {
        this.getUserDetails();
    }

    getUserDetails() {

        fetch(`${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({
                        UserDetails: result.data[0],
                        id: result.data[0].id,
                        fullname: result.data[0].fullname,
                        address_line_1: result.data[0].address_line_1,
                        city: result.data[0].city,
                        country: result.data[0].country,
                        currency: result.data[0].currency,
                        mobile: result.data[0].mobile,
                        expiryDate: result.data[0].expiry,
                        whiteLableIP: result.data[0].whitelist_ip,
                        kylas_crm_id: result.data[0].kylas_crm_id,
                        enable_ip_whitelist: result.data[0].enable_ip_whitelist
                    });
                }
            })
        })
    }

  

  


    updateProfile() {

        const updateUserDetails = ({ user_id: this.state.user_id, fullname: this.state.fullname, address_line_1: this.state.address_line_1, city: this.state.city, mobile: this.state.mobile, id: this.state.id, whitelist_ip: this.state.whiteLableIP, enable_ip_whitelist: this.state.enable_ip_whitelist, method: 'update', token: this.state.token, country: this.state.country, kylas_crm_id: this.state.kylas_crm_id });

        fetch(`${HOST_URL}/profile.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(updateUserDetails)
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {
                    document.querySelector('body').scrollTo(0, 0);
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", errorMessage: resp.message });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                } else {
                    this.setState({ successMessage: true, bgColor: "alert alert-danger alert-dismissible", errorMessage: resp.message });
                }
            })
        })
    }
   

    render() {

        return (
            <AdminLayout>
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="row mb-2 mb-xl-3">

                           


                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className="col-auto d-none d-sm-block">
                                <h3><strong>Profile </strong> Details </h3>
                            </div>

                            
                          


                            <div className="card">
                                <div className="card-body">

                                    <form>
                                        <div className="row">
                                            <div className="mb-3 col-md-8">
                                                <label className="form-label" htmlFor="inputFirstName">Full Name</label>
                                                <input type="text" Value={this.state.UserDetails.fullname} onChange={(event) => this.setState({ fullname: event.target.value })} className="form-control" id="inputFirstName" placeholder="Enter Full Name" />
                                            </div>




                                            <div className="mb-3 col-md-4">
                                                <label className="form-label" htmlFor="inputFirstName">Account Expiry Date  </label>

                                                <div className="input-group mb-2 mr-sm-2"><div className="input-group-text"><FontAwesomeIcon icon={faCheckCircle} color='green' size='lg' /></div>
                                                    <input type="text" defaultValue={this.state.UserDetails.expiry} className="form-control" readOnly id="inputFirstName" placeholder="Enter Full Name" />
                                                </div>

                                            </div>



                                        </div>
                                        <div className="row">
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="inputEmail4">Email</label>
                                                <input type="email" value={this.state.UserDetails.email} readOnly className="form-control" id="inputEmail4" placeholder="Email" />
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="inputFirstName">Enter Mobile Number</label>
                                                <input type="text" Value={this.state.UserDetails.mobile} readOnly onChange={(event) => this.setState({ mobile: event.target.value })} className="form-control" id="inputFirstName" placeholder="Enter 10 Digit Mobile Number" />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="inputAddress">Address</label>
                                            <textarea defaultValue={this.state.UserDetails.address_line_1} className="form-control" onChange={(event) => this.setState({ address_line_1: event.target.value })} placeholder="1234 Main St" ></textarea>
                                        </div>

                                        <div className="row">
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="inputCity">Country</label>
                                                <input type="text" Value={this.state.UserDetails.country} onChange={(event) => this.setState({ country: event.target.value })} placeholder="Country" className="form-control" />
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="inputCity">City</label>
                                                <input Value={this.state.UserDetails.city} onChange={(event) => this.setState({ city: event.target.value })} type="text" placeholder="City" className="form-control" />
                                            </div>
                                        </div>

                                        <button type="button" onClick={() => this.updateProfile()} className="btn btn-sm float-right btn-primary">Update Profile</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </AdminLayout>
        );
    }
}

export default AdminMyProfile;