import React, { Component } from 'react';
import AdminLayout from '../../UI/Layout/AdminLayout';
import Title from '../../UI/Text/Title';
import AdvanceDataTable from '../../UI/Table/AdvanceDataTable';
import { ADMIN_WHATSAPP_ACCOUNT_LIST } from '../../UI/Table/ColumnConst'
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';

class WhatsappProfile extends Component {

    constructor() {
        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const adminType = lStorage.user.isAdmin;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            adminType: adminType,
            allUserList: null,
            serchData: '',
            userCurrentStatus: '',
            setIsLoading: true,
            ErrorResponse: '',
        }

    }

    componentDidMount() {
        fetch(`${HOST_URL}/admin_user_list.php?user_id=${this.state.user_id}&method=retrieve_whatsapp_user&type=${this.state.adminType}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({ allUserList: result.data, setIsLoading: false });
                } else {
                    this.setState({
                        setIsLoading: false,
                        ErrorResponse: result.message
                    })
                }
            })
        })
    }
    render() {

        return (

            <AdminLayout>

                <Title isButton={false}> Whatsapp  User List</Title>



                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12">
                        <div className="">

                            {
                                this.state.successMessage ?
                                    <div className={this.state.bgColor} role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="">
                                            {this.state.errorMessage}
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <AdvanceDataTable
                                // tableName={'WhatsApp Account User List'}
                                // filter={true}
                                tableCell={ADMIN_WHATSAPP_ACCOUNT_LIST}
                                tableData={this.state.allUserList}
                                isLoading={this.state.setIsLoading}
                                isError={this.state.ErrorResponse}
                            />


                        </div>
                    </div>
                </div>

            </AdminLayout>

        );
    }
}
export default WhatsappProfile;