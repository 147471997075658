import React, { Component } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { HOST_URL } from "../api/Api";
class VoiceTemplate extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      template_name: null,
      is_keypress: 0,
      is_repeat: 0,
      repeat_keypress: null,
      call_patch_numbers: null,
      call_patch_keypress: null,
      varibaleInTemplate: [],
      content: null,
      errorMessage: "",
      method: "create",
      successMessage: false,
      successBg: "",
    };
    //this.is_keypressHandler = this.is_keypressHandler.bind(this);
  }

  remove_linebreaks(str) {
    var regex = /{#[^#]*#}/g;
    str = str.replace(regex, function (match, capture) {
      return match.replace(/[\n\r@#$%^&*{}?,.'":;()/[/]]\s*/g, "");
    });
    return str;
  }

  getVaribale() {
    var str = this.state.content;
    var regex = /{#([^#]+)#}/g;
    let m;
    var tempArr = [];
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      tempArr.push(m[1]);
    }
    this.setState({ varibaleInTemplate: tempArr });
  }

  componentDidMount() {
    this.FatchVoiceTemplate();
  }

  FatchVoiceTemplate() {
    fetch(
      `${HOST_URL}/voice_template.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            template_name: result.data[0].template_name,
            content: result.data[0].content,
            is_active: result.data[0].is_active,
            is_flash: result.data[0].is_flash,
            is_keypress_edit: result.data[0].is_keypress,
            is_repeat_edit: result.data[0].is_repeat,

            repeat_keypress: result.data[0].repeat_keypress,
            call_patch_numbers: result.data[0].call_patch_numbers,
            call_patch_keypress: result.data[0].call_patch_keypress,
          });
        }
      });
    });
  }

  CreateVoiceTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    if (this.state.content === null || this.state.content === "") {
      this.setState({ successMessage: true });
      this.setState({ errorMessage: "Template Body can not be blank" });
      this.setState({ successBg: "alert alert-danger alert-dismissible" });
    } else {
      var newContent = this.remove_linebreaks(this.state.content);
      var bodyObject = {
        user_id: this.state.user_id,
        token: this.state.token,
        template_name: this.state.template_name,
        content: newContent,
        is_keypress: this.state.is_keypress,
        is_repeat: this.state.is_repeat,
        repeat_keypress: this.state.repeat_keypress,
        call_patch_numbers: this.state.call_patch_numbers,
        call_patch_keypress: this.state.call_patch_keypress,
        method: "create",
      };

      fetch(`${HOST_URL}/voice_template.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(bodyObject),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === false) {
            // console.warn('message',resp.message);
            this.setState({ successMessage: true });
            this.setState({ errorMessage: resp.message });
            this.setState({
              successBg: "alert alert-danger alert-dismissible",
            });
          } else {
            this.setState({ successMessage: true });
            this.setState({
              successBg: "alert alert-success alert-dismissible",
            });
            this.setState({ errorMessage: resp.message });
            this.props.history.push("voice-template-list");
          }
        });
      });
    }
  }

  UpdateVoiceTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    var newContent = this.remove_linebreaks(this.state.content);
    var bodyObject = {
      user_id: this.state.user_id,
      token: this.state.token,
      template_name: this.state.template_name,
      content: newContent,
      id: this.props.match.params.id,
      is_keypress: this.state.is_keypress,
      is_repeat: this.state.is_repeat,
      repeat_keypress: this.state.repeat_keypress,
      call_patch_numbers: this.state.call_patch_numbers,
      call_patch_keypress: this.state.call_patch_keypress,
      method: "update",
    };

    fetch(`${HOST_URL}/voice_template.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(bodyObject),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.success === "false") {
          console.warn("message", resp.message);
          this.setState({ successMessage: true });
          this.setState({ errorMessage: resp.message });
        } else {
          this.setState({ successMessage: true });
          this.setState({ successBg: "alert alert-success alert-dismissible" });
          this.setState({ errorMessage: resp.message });
          document.getElementById("voiceForm").reset();
          window.location.reload();
        }
      });
    });
  }

  render() {
    console.log("state", this.state);
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Create New Voice </strong> Template{" "}
                  </h3>
                </div>
              </div>
              <div className="card">
                <div className="card-body template-link">
                  <div className="row">
                    <div className="col-md-8">
                      {this.state.successMessage ? (
                        <div className={this.state.successBg} role="alert">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          {this.state.errorMessage}
                        </div>
                      ) : null}

                      <form id="voiceForm">
                        <div className="row justify-content-center">
                          <div className="mb-3 col-md-12">
                            <div className="text-left">
                              <h3 className="font-weight-bold mb-4">
                                {" "}
                                Voice Template{" "}
                              </h3>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <input
                                  type="text"
                                  defaultValue={this.state.template_name}
                                  onChange={(event) => {
                                    this.setState({
                                      template_name: event.target.value,
                                    });
                                  }}
                                  className="form-control"
                                  id="inputEmail4"
                                  placeholder="Enter Template Name"
                                />
                              </div>
                            </div>

                            <div className="form-group mt-2">
                              <textarea
                                defaultValue={this.state.content}
                                onChange={(event) => {
                                  this.setState({
                                    content: event.target.value,
                                  });
                                  this.getVaribale();
                                }}
                                placeholder="Template Message Example : Dear {#Name#}, Thanks for the registraion with us"
                                className="form-control"
                                rows="5"
                              ></textarea>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-5 mt-2 ml-2">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={
                                      this.state.is_keypress_edit === 1
                                        ? "checked"
                                        : null
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        is_keypress: event.target.value,
                                      });
                                    }}
                                    value="1"
                                  />
                                  <span className="form-check-label">
                                    is keypress
                                  </span>
                                </label>
                              </div>

                              <div className="form-group col-md-5 mt-2 ml-2">
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={
                                      this.state.is_repeat_edit === 1
                                        ? "checked"
                                        : null
                                    }
                                    onChange={(event) => {
                                      this.setState({
                                        is_repeat: event.target.value,
                                      });
                                    }}
                                    value="1"
                                  />
                                  <span className="form-check-label">
                                    is Repeat
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div className="form-group mt-2 ">
                              <select
                                value={this.state.repeat_keypress}
                                onChange={(event) => {
                                  this.setState({
                                    repeat_keypress: event.target.value,
                                  });
                                }}
                                className="form-control"
                              >
                                <option value="">Repeat Keypress</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                              </select>
                            </div>

                            <div className="form-group mt-2 ">
                              <label>Call Patch Number</label>
                              <input
                                defaultValue={this.state.call_patch_numbers}
                                placeholder="91XXXXXXX"
                                className="form-control"
                                onChange={(event) => {
                                  this.setState({
                                    call_patch_numbers: event.target.value,
                                  });
                                }}
                                type="text"
                              />
                            </div>

                            <div className="form-group mt-2 ">
                              <select
                                value={this.state.call_patch_keypress}
                                onChange={(event) => {
                                  this.setState({
                                    call_patch_keypress: event.target.value,
                                  });
                                }}
                                className="form-control"
                              >
                                <option>Call Patch Keypress</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                              </select>
                            </div>

                            {this.props.match.params.id ? (
                              <button
                                type="button"
                                onClick={() => {
                                  this.UpdateVoiceTemplate();
                                }}
                                className="btn btn-sm  mt-5 btn-primary"
                              >
                                Update Template
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  this.CreateVoiceTemplate();
                                }}
                                className="btn btn-sm  mt-5 btn-primary"
                              >
                                Create Template
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-4">
                      <div className="card">
                        <p className="tooglebox">
                          <Link
                            className=""
                            data-toggle="collapse"
                            to="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Useful Links
                          </Link>
                        </p>
                        <div className="">
                          <div className="collapse show" id="collapseExample">
                            <div className="links-set">
                              <ol className="mt-3">
                                <li>
                                  <Link to="">
                                    How to Create Voice Template
                                  </Link>
                                </li>
                                <li>
                                  <Link to="">How to Use Voice Template</Link>
                                </li>
                                <li>
                                  <Link to="">How to Use Voice Varibale </Link>
                                </li>
                                <li>
                                  <Link to="">
                                    How to Save or Choose SMS Template{" "}
                                  </Link>
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <p className="tooglebox">
                          <Link to="" className="">
                            Variables In Template
                          </Link>
                        </p>

                        <div className="card-body">
                          <form className="form-inline">
                            {this.state.varibaleInTemplate.map(
                              (item, index) => (
                                <div key={index} className="variable mr-1">
                                  <span>{item}</span>
                                </div>
                              )
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default VoiceTemplate;
