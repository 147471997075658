import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faArrowLeft,} from '@fortawesome/free-solid-svg-icons'
import loading from "../../Dashboard/assets/img/loading.gif";
import { HOST_URL } from '../api/Api';
class CampaignPreview extends Component {

    constructor(props) {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            id:this.props.match.params.id,
            is_loading:false,
            CampaignName: '',
            template_name_for_campaign: '',
            sender_id_for_campaign: '',
            entity_id_for_campaign: '',
            template_id_for_campaign: '',
            is_unicode_for_campaign: null,
            message_for_campaign: '',
            campaignSernderIDList: '',
            camp_start_datetime:'',
            status: '',
            date_from:'',
            date_to:'',
            is_schedule: 0,
            schedule_datetime: '',
            sending_senderid: '',
            campaign_selected_status: '',
            TotaldataSet: 0,
            channel: 'SMS',
            SelecteddateFrom: '',
            SelecteddateTo: '',
        }
    }

    componentDidMount = () => {
        this.getTemplateDetails();
    }

    getTemplateDetails() {
        fetch(`${HOST_URL}/bulk_campaign.php?user_id=${this.state.user_id}&id=${this.state.id}&method=retrieveid&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                 console.log(result)
                if (result.success === true) {
                    this.setState({
                        is_loading:true,
                        template_name_for_campaign: result.data[0].template_name,
                        message_for_campaign: result.data[0].text_message,
                        sender_id_for_campaign: result.data[0].senderid,
                        entity_id_for_campaign: result.data[0].entity_id,
                        template_id_for_campaign: result.data[0].template_id,
                        is_unicode_for_campaign: result.data[0].is_unicode,
                        contact_count: result.data[0].contact_count,
                        sending_senderid: result.data[0].sending_senderid,
                        CampaignName: result.data[0].campaign_name,
                        sender_id_for_campaign: result.data[0].sender_id,
                        TotaldataSet: result.data[0].contact_count,
                        campaign_selected_status: result.data[0].sending_status,
                        date_from: result.data[0].date_from,
                        date_to: result.data[0].date_to,
                        camp_start_datetime: result.data[0].camp_start_datetime,
                    });
                }
                else {

                }
            })
        })
    }

    render() {
       
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                               
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>About  </strong> Campaign </h3>
                                </div>
                                <div className="col-auto ml-auto text-right mt-n1">
                               <Link className="btn btn-primary btn-sm" to="/dashboard/campaign/"> <FontAwesomeIcon icon={faArrowLeft} color='' />  Go back </Link>
                                </div>

                            </div>
                            <div className="campaign-box">
                                <div className="card">
                                {this.state.is_loading ?
                                
                                    <div className="card-body">
                                        <div className='border-bottom pb-4'>
                                            <div className=''>
                                                <div className='campaign-preview'>
                                                    <p className='m-0'> <strong> Total Number of Campaign Data: </strong>  <span className='badge badge-primary'> {this.state.TotaldataSet} </span> </p>
                                                </div>

                                                <div className='Campaign-status mt-2'>
                                                    <p className='m-0'>   <strong> Sender ID's: </strong>


                                                        {this.state.sending_senderid }

                                                    </p>
                                                </div>

                                                <div className='Campaign-status mt-2'>
                                                    <p className='m-0'>   <strong> Selected Status : </strong>


                                                        {this.state.campaign_selected_status }


                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="campaign-preview mb-3 mt-4">
                                            <div className='row'>
                                                <div className='col-md-6'>

                                                    <div className="bg-white">
                                                        <div className=''>
                                                            <strong> Campaign Name : </strong> <span>{this.state.CampaignName} </span>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white">
                                                        <div className=''>
                                                            <strong> Campaign Channel Name : </strong>  <strong><span className='text-success fw-blod'>{this.state.channel}</span> </strong>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <FontAwesomeIcon icon={faCalendarCheck} />  <strong> Date From : </strong> {this.state.date_from}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <FontAwesomeIcon icon={faCalendarCheck} /> <strong> Date To : </strong> {this.state.date_to}
                                                        </div>
                                                    </div>
                                                    <hr className='m-2' />
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <FontAwesomeIcon icon={faCalendarCheck} />  <strong> Started Date & Time : </strong> {this.state.camp_start_datetime}
                                                        </div>
                                                       
                                                    </div>



                                                    
                                                </div>
                                                <div className='col-md-6'>

                                                    <div className="bg-white d-flex">
                                                        <div className='mr-3'>
                                                            <strong>Senrder ID : </strong>  <strong><span className='text-success fw-blod'>{this.state.sender_id_for_campaign}</span> </strong>
                                                        </div>
                                                        <div className='mr-3'>
                                                            <strong>Entity ID : </strong>  <strong><span className='text-success fw-blod'>{this.state.entity_id_for_campaign}</span> </strong>
                                                        </div>
                                                        <div className=''>
                                                            <strong>Template ID: </strong>  <strong><span className='text-success fw-blod'>{this.state.template_id_for_campaign}</span> </strong>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white mt-3">
                                                        <div className=''>
                                                            <strong> Message Content: </strong> <span> {this.state.message_for_campaign} </span>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white  d-flex mt-3">
                                                        <div className='mr-3'>
                                                            <strong> Message Count: </strong> <span className='badge bg-primary'> 0 </span>
                                                        </div>
                                                        <div className=''>
                                                            <strong> Is Unicode: </strong> <span className='badge bg-danger'> NA </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <div className='card-body text-center'>
                                        <img style={{'width':'80px'}} src={loading} />
                                        <p>Loading......</p>
                                    </div>

                                }
                                  
                                    
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );

    }
}

export default CampaignPreview;