import React, { useEffect, useState } from 'react';
import Collapsible from "react-collapsible";
import {Tabs, Tab} from 'react-bootstrap-tabs';
import {getSMSTemplateList} from '../../api/Api'

function FallBack(props) {
 
  const [smsTemplateList, setSmsTemplateList] = useState();

  useEffect(()=>{
    getSMSTemplateList().then((response)=>{
			if (response.data.success === true) {
        setSmsTemplateList(response.data.data)
			}
		})
  },[])

  const smsTemplateHandler = (e) => {
     props.sendSMStemplateId(e.target.value)
  }

    return (
        <Collapsible
        triggerStyle={{ backgroundColor: "#065f54" }}
        trigger="FallBack Setting (optional)"
      >

        <div className="p-3">
        <p>If whatsapp message got failed then fallback message will trigger</p>
        <div>
                <Tabs id="fall-back-tab">
                    <Tab label="Choose From Template">
                    <div className="input-group mt-3 drop-down-icons">
                        <select
                        name="templateType"
                        className="form-control"
                        onChange={smsTemplateHandler}
                        >
                          <option value={""}>Select Template Type</option>
                          {smsTemplateList && 
                            smsTemplateList.map((item, index)=>
                            <option key={index} value={item.id}>{item.template_name}</option>
                            )
                          }
                        </select>
                    </div>
                    </Tab>
                    {/* <Tab label="New Template">
                    <div className="input-group mt-3">
                       <input type='text' placeholder='Enter Sender ID' className='form-control' />
                    </div>
                    <div className="input-group mt-3">
                       <textarea className='form-control' placeholder='Enter Message'></textarea>
                    </div>
                    </Tab> */}
                </Tabs>
           
        </div>

   
        </div>
      </Collapsible>
    );
}

export default FallBack;