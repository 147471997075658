import React from "react";
import { Link } from "react-router-dom";
const Error = ({ location }) => {
  setTimeout(function () {
    localStorage.clear();
    window.location = "/login";
  });
  return (
    <div>
      <main className="d-flex d-flex align-items-center w-100 h-100">
        <div className="container d-flex align-items-center">
          <div className="row vh-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center">
                  <h1 className="display-1 font-weight-bold">404 </h1>
                  <p className="h1 text-danger">
                    Something Went Wrong or Page not found.
                  </p>
                  <p className="h2 font-weight-normal mt-3 mb-4">
                    {" "}
                    Oops! 😖 The requested URL was not found on this server.
                  </p>
                  <Link to="/" className="btn btn-primary btn-lg">
                    Return to website
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Error;
