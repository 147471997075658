import React, { useState, useEffect } from 'react';

const ModalExample = ({ status, message, onData }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(status);
  }, [status]);

  const toggleModal = () => {
    const data = false;
    onData(data);
    setShowModal(false);
  };

  const handleClick = () => {
    const data = true;
    onData(data);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Alert</h5>
                <button type="button" className="close" onClick={toggleModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">{message}</div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={toggleModal}>
                  No
                </button>
                <button type="button" className="btn btn-success" onClick={handleClick}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default ModalExample;
