import React, { useState } from 'react'
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { userloginDetails } from '../../Utils/AdminAuth'
import { HOST_URL } from '../../Dashboard/api/Api'

const PinnacleRegister = () => {
    const lStorage = userloginDetails();
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const { id, wabaid, pnid } = useParams();
    const [accessToken, setAccessToken] = useState('')
    const [successMessage, setSuccessMessage] = useState({
        status: false,
        bgColor: "",
        message: ""
    })

    const onChange = (e) =>{
      setAccessToken(e.target.value)
      setSuccessMessage({status: false})
    }

    const isValid = () => {
        if (accessToken === "" || accessToken === undefined) {
            setSuccessMessage({
                status: true,
                bgColor: "alert alert-danger",
                message :"Please Enter Permanent Access Token"
            })
        }
        else {
            return true
        }
    }

    const OnRegister =async () => {
        if (isValid()) {
            let data = {
                user_id: userId,
                id: id,
                token: userToken,
                method: "register_to_pinnacle",
                pnid: pnid,
                wabaid: wabaid,
                access_token: accessToken
            }
            let response = await fetch(`${HOST_URL}/whatsapp_setup.php`,{
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            let result = await response.json();
            if(result.success){
                setSuccessMessage({
                    status: true,
                    bgColor: "alert alert-success",
                    message :`${result.message}`
                })
                setAccessToken("")
setTimeout(() => {
                    window.location.href = '/admin/pinnacle-embeded-signup';
                }, 2000); 
            }
            else{
                setSuccessMessage({
                    status: true,
                    bgColor: "alert alert-danger",
                    message :`${result.message}`
                })
            }
        }
    }

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>Register To Pinnacle </strong></h3>
                                </div>

                                {
                                    successMessage.status ?
                                        <div className={successMessage.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {successMessage.message}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div className="row">
                                <div className="card">


                                    <div className="card-body">


                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className=" flex-fill">
                                                    <form>
                                                        <div className="row">
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Waba Id</label>
                                                                <input type="text" className="form-control" value={wabaid} disabled placeholder=" Waba Id" />
                                                                <p style={{ fontSize: "12px", fontWeight: "bold" }}> WabaId cannot be changed</p>
                                                            </div>
                                                            <div className="mb-3 col-md-6">
                                                                <label className="form-label">Phone Number Id</label>
                                                                <input type="text" className="form-control" value={pnid} disabled placeholder="Phone Number Id" />
                                                                <p style={{ fontSize: "12px", fontWeight: "bold" }}> Phone Number Id cannot be changed</p>
                                                            </div>
                                                            <div className="mb-3 col-md-12">
                                                                <label className="form-label">Permanent Access Token</label>
                                                                <input type="text" onChange={onChange} value={accessToken} className="form-control" placeholder="Enter Permanent Access Token" />
                                                            </div>
                                                        </div>
                                                        <div className="float-right">
                                                            <input onClick={() => OnRegister()} type="button" value="Register" className="btn btn-sm  btn-primary" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    )
}

export default PinnacleRegister
