import React, { useCallback, useState, useEffect } from 'react';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { HOST_URL } from '../Dashboard/api/Api';


const formSchema = Yup.object().shape({
    route_name: Yup.string().required('This field is required'),
    vendor_name: Yup.string().required(),
    vendor_contact: Yup.string().required(),
    vendor_address: Yup.string().required(),
    channel: Yup.string().required("Please Select Channel"),
    sms_ip: Yup.string(),
    sms_port: Yup.string(),
    sms_username: Yup.string(),
    sms_password: Yup.string(),
    smpp_name: Yup.string(),
    voice_ip: Yup.string(),
    voice_port: Yup.string(),
    voice_username: Yup.string(),
    voice_password: Yup.string(),

});




const EditRoute = (props) => {

    const lStorage = JSON.parse(localStorage.getItem("admin"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    const routeId = props.match.params.id;
    const [Editroute, setEditroute] = useState('');

    const EditRouteDetails = useCallback(() => {
        fetch(`${HOST_URL}/route.php?user_id=${userId}&method=retrieveid&id=${routeId}&token=${userToken}&type=admin`).then((response) => {
            response.json().then((result) => {
                //  console.log();
                if (result.success === true) {

                    setEditroute(result.data[0]);
                }
            })
        })
    }, [userId, userToken, routeId])


    useEffect(() => {

        EditRouteDetails();

    }, [EditRouteDetails])

    // console.log('Editroute',Editroute);

    if (Editroute === '') {
        return (
            <div className="col d-flex mt-10 align-items-center justify-content-center">
                <div className="spinner-border text-dark mr-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }



    return (

        <Formik
            initialValues={{
                route_name: Editroute.route_name,
                vendor_name: Editroute.vendor_name,
                vendor_address: Editroute.vendor_address,
                vendor_contact: Editroute.vendor_contact,
                channel: Editroute.channel,
                sms_ip: Editroute.sms_ip===null?'':Editroute.sms_ip,
                sms_port: Editroute.sms_port===null?'':Editroute.sms_port,
                sms_username: Editroute.sms_username===null?'':Editroute.sms_username,
                sms_password: Editroute.sms_password===null?'':Editroute.sms_password,
                smpp_name: Editroute.smpp_name===null?'':Editroute.smpp_name,
                voice_ip: Editroute.voice_ip===null?'':Editroute.voice_ip,
                voice_port: Editroute.voice_port===null?'':Editroute.voice_port,
                voice_username: Editroute.voice_username===null?'':Editroute.voice_username,
                voice_password: Editroute.voice_password===null?'':Editroute.voice_password
            }}

            validationSchema={formSchema}

            onSubmit={(data) => {
                data.user_id = userId;
                data.method = 'update';
                data.token = userToken;
                data.type = 'admin';
                data.id = routeId;
                data.is_active = 1;
               // console.log(data);

                fetch(`${HOST_URL}/route.php`, {
                    method: "post",
                    headers: {
                        'content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then((result) => {
                    result.json().then((resp) => {
                        if (resp.success === true) {
                            alert(resp.message);
                        } else {
                            alert(resp.message);
                        }
                    })
                })
            }
            }


        >


            {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {

                return (
                    <div className="wrapper">
                        <AdminLeftMenu />
                        <div className="main">
                            <AdminHeader />
                            <main className="content">
                                <div className="container-fluid p-0">
                                    <div className="row mb-2 mb-xl-3">
                                        <div className="col-auto d-none d-sm-block">
                                            <h3><strong> Route </strong> Management </h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                            <div className="card flex-fill">
                                                <div className="card-header">
                                                    <h5 className="card-title mb-0">Add New Route</h5>
                                                </div>
                                                <div className="card-body">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row justify-content-center">
                                                            <div className="mb-3 col-md-12">

                                                                <input type="text" value={values.route_name} onBlur={handleBlur} name={"route_name"} onChange={handleChange} className="form-control mb-2" id="inputEmail4" placeholder="Route Name" />
                                                                {errors.route_name && touched.route_name && <small className="text-danger">{errors.route_name}</small>}

                                                                <input type="text" value={values.vendor_name} onBlur={handleBlur} name={"vendor_name"} onChange={handleChange} className="form-control mb-2" id="inputEmail4" placeholder="Vender Name" />
                                                                {errors.vendor_name && touched.vendor_name && <small className="text-danger">{errors.vendor_name}</small>}

                                                                <input type="text" value={values.vendor_contact} onBlur={handleBlur} name={"vendor_contact"} onChange={handleChange} className="form-control mb-2" id="inputEmail4" placeholder="vendor_contact or Contact Details " />
                                                                {errors.vendor_contact && touched.vendor_contact && <small className="text-danger">{errors.vendor_contact}</small>}

                                                                <input type="text" value={values.vendor_address} onBlur={handleBlur} name={"vendor_address"} onChange={handleChange} className="form-control mb-2" id="inputEmail4" placeholder="vendor_address or Contact Details " />
                                                                {errors.vendor_address && touched.vendor_address && <small className="text-danger">{errors.vendor_address}</small>}


                                                                <select disabled  value={values.channel} onBlur={handleBlur} name={"channel"} onChange={handleChange} className="form-control">
                                                                    <option  value="">Select Channel</option>
                                                                    <option value="sms">sms</option>
                                                                    <option value="voice">Voice</option>
                                                                </select>
                                                                {errors.channel && touched.channel && <small className="text-danger">{errors.channel}</small>}

                                                                {values.channel === 'sms' &&

                                                                    <div className="filter-option mt-3">
                                                                        <div className="row">
                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>SMS IP</label>
                                                                                <input value={values.sms_ip} onBlur={handleBlur} name={"sms_ip"} onChange={handleChange} type="text" className="form-control " id="inputEmail4" placeholder="SMS IP" />
                                                                                {errors.sms_ip && touched.sms_ip && <small className="text-danger">{errors.sms_ip}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>SMS Port</label>
                                                                                <input value={values.sms_port} onBlur={handleBlur} name={"sms_port"} onChange={handleChange} type="text" className="form-control " id="inputEmail4" placeholder="SMS Port" />
                                                                                {errors.sms_port && touched.sms_port && <small className="text-danger">{errors.sms_port}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>User Name </label>
                                                                                <input type="text" value={values.sms_username} onBlur={handleBlur} name={"sms_username"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="User Name" />
                                                                                {errors.sms_username && touched.sms_username && <small className="text-danger">{errors.sms_username}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>Password </label>
                                                                                <input type="text" value={values.sms_password} onBlur={handleBlur} name={"sms_password"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="Password" />
                                                                                {errors.sms_password && touched.sms_password && <small className="text-danger">{errors.sms_password}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>SMPP Name </label>
                                                                                <input type="text" value={values.smpp_name} onBlur={handleBlur} name={"smpp_name"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="SMPP Name" />
                                                                                {errors.smpp_name && touched.smpp_name && <small className="text-danger">{errors.smpp_name}</small>}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }

                                                                {values.channel === 'voice' &&
                                                                    <div className="filter-option mt-3">

                                                                        <div className="row">
                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>Voice IP</label>
                                                                                <input type="text" value={values.voice_ip} onBlur={handleBlur} name={"voice_ip"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="SMS IP" />
                                                                                {errors.voice_ip && touched.voice_ip && <small className="text-danger">{errors.voice_ip}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>Voice Port</label>
                                                                                <input type="text" value={values.voice_port} onBlur={handleBlur} name={"voice_port"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="SMS Port" />
                                                                                {errors.voice_port && touched.voice_port && <small className="text-danger">{errors.voice_port}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>User Name </label>
                                                                                <input type="text" value={values.voice_username} onBlur={handleBlur} name={"voice_username"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="User Name" />
                                                                                {errors.voice_username && touched.voice_username && <small className="text-danger">{errors.voice_username}</small>}
                                                                            </div>

                                                                            <div className="form-group mb-2 col-md-6">
                                                                                <label>Password </label>
                                                                                <input type="text" value={values.voice_password} onBlur={handleBlur} name={"voice_password"} onChange={handleChange} className="form-control " id="inputEmail4" placeholder="Password" />
                                                                                {errors.voice_password && touched.voice_password && <small className="text-danger">{errors.voice_password}</small>}
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                }

                                                                <button type="submit" className="btn btn-sm float-right mt-2 btn-primary">Create Route</button>
                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </main>
                            <AdminFooter />
                        </div>
                    </div>
                );
            }}
        </Formik>


    );

}

export default EditRoute;