import React, { useEffect } from 'react';
import '../Dashboard/assets/css/app.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../Dashboard/api/Api';



const AdminHeader = () => {

	const lStorage = JSON.parse(localStorage.getItem("admin")) || JSON.parse(localStorage.getItem("emp")) || JSON.parse(localStorage.getItem("reseller")) || JSON.parse(localStorage.getItem("client"));
	const Id = lStorage.user.userId;
	const name = lStorage.user.name;
	const Token = lStorage.token;
	const type = lStorage.user.isAdmin;
	// const loginthrough = localStorage.getItem("loginthrough");
	

	useEffect(() => {
		const verifyUser = ({ user_id: Id, token: Token, method: 'verify', type: type });
		fetch(`${HOST_URL}/authentication_data.php`, {
			method: "post",
			headers: {
				'content-Type': 'application/json'
			},
			body: JSON.stringify(verifyUser)
		}).then((result) => {
			result.json().then((resp) => {
				// console.log(resp)
				if (resp.success === true) {
				} else {
					window.location = "/admin/logout";
				}
			})
		})
	}, []);

	// const goBackToAdmin = () => {
	// 	localStorage.removeItem("emp");
	// 	// localStorage.removeItem("loginthrough");
	// 	// localStorage.setItem()
	// 	window.location = "/admin";
	//   };

	return (
		<nav className="navbar navbar-expand navbar-light navbar-bg">
			<div className="navbar-collapse navbarcollapse_none collapse">
				<ul className="navbar-nav navbar-align ">
					<li className="nav-item border-right">
						<div className="position-relative">
							<p className='mr-3 mb-0 mt-2 widthBlock'> <strong> Welcome	: {name} </strong> </p>
						</div>
					</li>
					<li className="nav-item dropdown">
						<Link className="nav-icon" to="/admin/logout">
							<div className="position-relative">
								<FontAwesomeIcon icon={faPowerOff} />
							</div>
						</Link>
					</li>

					{/* {loginthrough ? (
            <li className="nav-item dropdown">
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => goBackToAdmin(e)}
                className="nav-icon"
              >
                <div color="red" className="position-relative">
                  <FontAwesomeIcon color="red" icon={faTimesCircle} />
                </div>
              </span>
            </li>
          ) : null } */}
					
				</ul>
			</div>
		</nav>
	);
}

export default AdminHeader;