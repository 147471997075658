import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import wordpress from "../../Dashboard/assets/img/wordpress-sms-plugin.jpg";
import pabblyImg from "../../Dashboard/assets/img/pabbly.png";
import integrately from "../../Dashboard/assets/img/integretaly.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faArrowRight } from "@fortawesome/free-solid-svg-icons";

class MarketPlace extends Component {
  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>Market </strong> Place{" "}
                  </h3>
                </div>

                <div
                  className="alert card alert-outline alert-dismissible"
                  role="alert"
                >
                  <div className="card-body">
                    <div className="row justify-content-center">
                      <div className="col-md-8 text-center">
                        <h5 className="m-0">
                          Solutions installed directly from Authkey!
                        </h5>
                        <p>
                          Find, Test and deploy software that runs on authkey.
                          Explore solutions by topic area and use case
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-fluid" alt="img" src={wordpress} />
                        <h5 className="mt-2">
                          Wordpress Plugin For Send SMS.{" "}
                        </h5>
                        <p>
                          Authkey has some incredible features that make it the
                          most powerful WordPress SMS, Voice and Email plugin.
                        </p>
                        <div className="d-flex justify-content-between">
                          <a
                            className="btn w-50 btn-primary btn-sm"
                            href="/wordpress-plugin-for-sending-sms"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faArrowRight} /> Read more
                            about
                          </a>

                          <a
                            className="btn w-50 btn-bg-color btn-sm"
                            download
                            href="/wordpress-plugin/authkey.zip"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faDownload} /> Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          alt="img"
                          src={integrately}
                        />
                        <h5 className="mt-2">
                          Integrately plugin to automate all tasks.{" "}
                        </h5>
                        <p>
                          Authkey offers integration with over 1000 apps via the
                          Integrately plugin. Automate all your notifications,
                          from abandoned cart reminders to order dispatch
                          updates.
                        </p>
                        <div className="d-flex justify-content-between">
                          <a
                            className="btn w-50 btn-primary btn-sm"
                            href="https://blog.authkey.io/authkey-integrations-for-non-techies-at-integrately-automation-messaging/"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faArrowRight} /> Read more
                            about
                          </a>

                          <a
                            className="btn w-50 btn-bg-color btn-sm"
                            download
                            href="https://integrately.com/integrations/authkey"
                          >
                            {" "}
                            Click to connect
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-fluid" alt="img" src={pabblyImg} />
                        <h5 className="mt-2">
                          Connect and Automate Your Tasks with Pabbly Connect
                        </h5>
                        <p>
                          Pabbly Connect is a powerful automation platform that
                          enables seamless integration between multiple
                          applications. Our platform features an extensive
                          library of over 2,000 application integrations, which
                          you can explore further.
                        </p>
                        <div className="d-flex justify-content-between">
                          <a
                            className="btn w-50 btn-primary btn-sm"
                            href="https://blog.authkey.io/connect-authkey-with-more-than-1000-apps-at-pabbly-connect/"
                          >
                            {" "}
                            <FontAwesomeIcon icon={faArrowRight} /> Read more
                            about
                          </a>

                          <a
                            className="btn w-50 btn-bg-color btn-sm"
                            download
                            href="https://payments.pabbly.com/api/affurl/RVYZ07kQyUZ0Z1HUKZ1m/91jvhKKkKc0Phnfp?target=tm576znNj1Z0Tagzn"
                          >
                            {" "}
                            Click to connect
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default MarketPlace;
