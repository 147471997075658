import React, { Component } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Alert from "../../UI/Alert/Alert";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { OPTOUTLIST ,OPTIN_OPTOUT_WHATSAPP_LIST} from "../../UI/Table/ColumnConst";
import { HOST_URL } from "../api/Api";
import Spining from "../../UI/Spining/Spining";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

class NumberOptOutlist extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();

    this.state = {
      user_id: userId,
      token: userToken,
      isLoading: false,
      optoutno: "",
      fileURL: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      showUploadOtions: false,
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    this.SearchDataOnLoad();
  }

  SearchDataOnLoad() {
    this.setState({ isLoading: true });
    fetch(
      ` ${HOST_URL}/optout_list.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        console.log(result);
        if (result.success === true) {
          this.setState({ optoutno: result.data, isLoading: false });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
            isLoading: false,
          });
        }
      });
    });
  }

  uploadData() {
    this.setState({ isLoading: true });
    document.querySelector("body").scrollTo(0, 0);
    const data = new FormData();
    data.append("csvfile", this.state.fileURL);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.token);
    data.append("method", "upload");
    fetch(`${HOST_URL}/optout_list.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp);
        if (resp.success === true) {
          document.querySelector("body").scrollTo(0, 0);
          this.setState({
            successMessage: true,
            bgColor: "alert-success",
            errorMessage: resp.message,
            isLoading: false,
          });
          //  window.location.reload();
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert-danger",
            errorMessage: resp.message,
            isLoading: false,
          });
        }
      });
    });
  }

  fileChangedHandler(e) {
    console.log(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      this.setState({ fileURL: e.target.files[0] });
    }
  }

  render() {
    return (
      // <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            {this.state.isLoading && <Spining />}
            <div className="mb-2 mb-xl-3">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="m-0">
                  <strong>OPT OUT </strong> Number{" "}
                </h3>
              </div>
            </div>

            <div className="input-group mb-2 mt-4 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text">Upload .CSV File</div>
              </div>
              <input
                type="file"
                name="file"
                accept=".csv"
                onChange={this.fileChangedHandler}
                className="form-control"
              />
              <button
                onClick={() => this.uploadData()}
                className="btn btn-primary btn-sm"
              >
                Upload .CSV File
              </button>
            </div>

            <p>
              {" "}
              <a download href="/uplod-Optout.csv">
                {" "}
                <FontAwesomeIcon icon={faFileCsv} /> Download Sample File{" "}
              </a>
            </p>

            {this.state.successMessage && (
              <Alert type={this.state.bgColor}>{this.state.errorMessage}</Alert>
            )}
            {this.state.optoutno && (
              <AdvanceDataTable
                tableName={"Number List"}
                tableCell={OPTIN_OPTOUT_WHATSAPP_LIST}
                tableData={this.state.optoutno}
                isLoading={this.state.setIsLoading}
                isError={this.state.errorMessage}
              />
            )}
          </div>
        </main>
      // </DashboardLayout>
    );
  }
}

export default NumberOptOutlist;
