import React, { useState } from "react";
import Papa from "papaparse";

const UploadCSVFile = (props) => {
  const [csvFile, setcsvFile] = useState(false);

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
        let data = {
          rowData: results.data,
          tableColume: rowsArray[0],
        };
        setcsvFile(true);
        sendData(data);
      },
    });
  };

  const sendData = (data) => {
    props.sendCsvData(data);
  };

  return (
    <>
      <div className="input-group mb-2 mt-4 mr-sm-2">
        <div className="input-group-prepend">
          <div className="input-group-text">AMD CSV File</div>
        </div>
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={changeHandler}
          className="form-control"
        />
      </div>

      {csvFile && (
        <div className="alert alert-success">File Upload Successfully</div>
      )}
    </>
  );
};

export default UploadCSVFile;
