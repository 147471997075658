import React, { Component } from "react";
import { WHATSAPP_OPTIN_OPTOUT } from "../../../constants/ServiceConstants";
import { OPTIN_OPTOUT_WHATSAPP_LIST } from "../../UI/Table/ColumnConst";
import Axios from "../../../axios/Axios";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import { HOST_URL } from "../api/Api";
import ReactTelInput from "react-telephone-input";
import flags from "../../Dashboard/assets/img/flags.png";
import OptInUpload from "../../../components/Dashboard/sms/NumberOptOutlist";
class WhatsAppOptinOtpout extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      optinList: "",
      method: "",
      recipientNumber: "",
      setIsLoading: true,
      ErrorResponse: "",
      defaultCountry: "in",
      countryCode: "91",
      isActive: true,
    };

    this.OptinOptout = this.OptinOptout.bind(this);
  }

  componentDidMount() {
    this.optInList();
  }

  optInList = async () => {
    try {
      const { data } = await Axios.post(WHATSAPP_OPTIN_OPTOUT, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "retrieve",
      });
      if (data.success === true) {
        this.setState({ optinList: data.data, setIsLoading: false });
      } else {
        this.setState({ setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  checkPhonNumber() {
    var mobileNumber = isNaN(this.state.recipientNumber);
    if (mobileNumber === true) {
      this.setState({
        successMessage: true,
        bgColor: "alert alert-danger alert-dismissible",
        errorMessage: "Please enter correct phone number",
      });
      return false;
    } else {
      return true;
    }
  }

  OptinOptout() {
    let number = this.checkPhonNumber();
    if (number === true) {
      let numberwithcountrycode =
        this.state.countryCode + this.state.recipientNumber;
      const whatsapp_data = {
        user_id: this.state.userId,
        token: this.state.token,
        method: this.state.method,
        mobile: numberwithcountrycode,
      };
      fetch(`${HOST_URL}/wp_optinout.php`, {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(whatsapp_data),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.success === true) {
            document.querySelector("body").scrollTo(0, 0);
            this.setState({
              successMessage: true,
              bgColor: "alert alert-success alert-dismissible",
              errorMessage: resp.message,
            });
            this.optInList();
          } else {
            this.setState({
              successMessage: true,
              bgColor: "alert alert-danger alert-dismissible",
              errorMessage: resp.message,
            });
          }
        });
      });
    }
  }

  handleInputChange = (telephone, selectedCountry) => {
    if (selectedCountry) {
      const { dialCode, iso2 } = selectedCountry;
      this.setState({
        countryCode: dialCode,
        selectedCountryCode: iso2,
      });
    }
  };

  btnToggle1 = () => {
    this.setState({
      isActive: true,
    });
  };
  btnToggle2 = () => {
    this.setState({
      isActive: false,
    });
  };

  render() {
    const { defaultCountry } = this.state;
    return (
      <DashboardLayout>
        <main className="content">
          <button
            type="button"
            onClick={this.btnToggle1}
            className={
              this.state.isActive === true
                ? "btn btn-success rounded-pill"
                : "btn btn btn-info rounded-pill"
            }
          >
            Enter mobile No.
          </button>
          <button
            type="button"
            onClick={this.btnToggle2}
            className={
              this.state.isActive === false
                ? "btn btn-success rounded-pill"
                : "btn btn btn-info rounded-pill"
            }
          >
            Upload Csv File
          </button>
          {this.state.isActive === true ? (
            <div className="container-fluid p-0">
              <Title> WhatsAPP Number OPTIN / OPTOUT </Title>

              <div className="">
                {this.state.successMessage ? (
                  <div className={this.state.bgColor} role="alert">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="">{this.state.errorMessage}</div>
                  </div>
                ) : null}

                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-5">
                          <label for="colFormLabel" className="col-form-label">
                            {" "}
                            Select Types{" "}
                          </label>
                          <div className="">
                            <div className="input-group drop-down-icons mb-2">
                              <select
                                onChange={(e) =>
                                  this.setState({ method: e.target.value })
                                }
                                className="form-control"
                              >
                                <option value="">Select Type</option>
                                <option value="optin">OPTIN</option>
                                <option value="optout">OPTOUT</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <label for="colFormLabel" className="col-form-label">
                            Recipient Number{" "}
                          </label>
                          <div className="">
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="">
                                  <ReactTelInput
                                    defaultCountry={defaultCountry}
                                    flagsImagePath={flags}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <input
                                type="text"
                                required
                                maxLength={10}
                                onChange={(event) =>
                                  this.setState({
                                    recipientNumber: event.target.value,
                                  })
                                }
                                className="form-control"
                                id="inlineFormInputGroup"
                                placeholder="Example: 99XXXXXXX"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-2 text-right">
                          <button
                            type="button"
                            onClick={() => this.OptinOptout()}
                            className="btn mt-5 btn-block btn-blue btn-sm ml-auto "
                          >
                            {" "}
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <AdvanceDataTable
                  tableName={"OPTIN / OPTOUT List"}
                  tableCell={OPTIN_OPTOUT_WHATSAPP_LIST}
                  tableData={this.state.optinList}
                  isLoading={this.state.setIsLoading}
                  isError={this.state.ErrorResponse}
                />
              </div>
            </div>
          ) : (
            <OptInUpload />
          )}
        </main>
      </DashboardLayout>
    );
  }
}

export default WhatsAppOptinOtpout;
