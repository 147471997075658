import React from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../DashboardLeftMenu";
import whatsapp from "../../Dashboard/assets/img/whatsapp.png";
import sms from "../../Dashboard/assets/img/chat-bubbles-with-ellipsis.png";
import voice from "../../Dashboard/assets/img/microphone.png";
import email from "../../Dashboard/assets/img/awesome-scroll.png";
const DashboardAPi = () => {
  return (
    <div className="wrapper">
      <DashboardLeftMenu />
      <div className="main">
        <DashboardHeader />
        <main className="content">
          <div className="container-fluid p-0">
            <div className=" row mb-2 mb-xl-3">
              <div className="col-auto d-none d-sm-block">
                <h3>
                  <strong> API </strong> Docs
                </h3>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <div className="text-center">
                    <h5 className="">
                      Welcome to Authkey! Get familiar with our product and
                      explore its features:
                    </h5>

                    <p className="lead text-muted lh-190">
                      Authkey APIs are fast, AI-Driven and supports message
                      deliveries through multiple channels in the same request.
                      You can choose multiple channel deliveries in parallel or
                      fallback as per your requirement. You also have options to
                      create templates with dynamic contents and also club
                      multiple templates into events to be triggered via a
                      single API request.
                    </p>

                    <h2 className="my-4 text-muted text-left">
                      Channels supported by Authkey:
                    </h2>

                    <div className="row mb-5">
                      <div className="col-sm-3">
                        <div className="card shadow-lg rounded-lg border-0 mb-sm-0">
                          <div className="p-4 text-center">
                            <img src={sms} alt="api" className="img-fluid" />
                            <h3 className="mb-3">SMS</h3>
                            <p className=" mt-2 mb-0">
                              Transactional SMS can be triggered either by
                              directly passing the sms message in the API
                              request or you can also create a template with
                              dynamic values and use the template identifier in
                              the API request.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/sms-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card shadow-lg rounded-lg border-0 mb-sm-0">
                          <div className="p-4 text-center">
                            <img
                              src={voice}
                              alt="api voice"
                              className="img-fluid"
                            />
                            <h3 className="mb-3"> Voice </h3>
                            <p className=" mt-2 mb-0">
                              Voice call can be placed from Authkey by passing
                              the voice message in text in the API request. The
                              platform will auto-convert the text into audio and
                              place the call. You can also create voice template
                              and use it in the API.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/voice-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card shadow-lg rounded-lg border-0 mb-sm-0">
                          <div className="p-4 text-center">
                            <img
                              src={email}
                              alt="email"
                              className="img-fluid"
                            />
                            <h3 className="mb-3">Email</h3>
                            <p className=" mt-2 mb-0">
                              Create Email templates on Authkey and use the
                              created template id in the API request to send
                              email. Authkey uses AWS SES to send emails, so you
                              need to have an account in AWS to use this
                              feature.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/email-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="card shadow-lg rounded-lg border-0 mb-sm-0">
                          <div className="p-4 text-center">
                            <img
                              src={whatsapp}
                              alt="whatsapp"
                              className="img-fluid"
                            />
                            <h3 className="mb-3">WhatsApp</h3>
                            <p className=" mt-2 mb-0">
                              Whatsapp API is a communication connection to
                              enable a marketing lineup to customers or
                              prospects. Whatsapp business web was launched in
                              Aug '18 for marketers to scale their engagements.
                            </p>
                            <Link
                              className="btn btn-sm btn-primary mt-3"
                              to="/dashboard/whatsapp-api-docs"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <DashboardFooter />
      </div>
    </div>
  );
};

export default DashboardAPi;
