import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip";
import { HOST_URL } from '../api/Api';
class WebHookReport extends Component {

    constructor(){

        const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
        const userToken= lStorage.token;
        
        super();

        this.state={
            user_id: userId,
            token: userToken,
            emailCost:'',
            webHookReport:null,
            successMessage:false,
            errorMessage:'',
            bgColor:"alert alert-success alert-dismissible",
        }
    }
    
    componentDidMount(){
        fetch(`${HOST_URL}/webhook.php?user_id=${this.state.user_id}&method=report&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
                // console.log(result)
                if(result.success===true){
                    this.setState({webHookReport:result.data});
                }else{
                    this.setState({
                        successMessage:true,
                        successBg:"alert alert-danger alert-dismissible",
                        errorMessage:result.message
                    }) 
                }
            })
        })
    }

urldecode(url) { 

    return decodeURIComponent(url.replace(/\+/g, ' ')); 
}

render() {
    return (
<div className="wrapper">
    <DashboardLeftMenu />
    <div className="main">
        <DashboardHeader />
        <main className="content">
            <div className="container-fluid p-0">
                <div className=" mb-2 mb-xl-3">
                    <div className="col-auto d-none d-sm-block">
                        <h3><strong> Webhook </strong> Report </h3>
                    </div>
                </div>
                <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card flex-fill">
                                    <div className="card-header">
                                    <h5 className="card-title mb-0">Webhook Details Report </h5>
                                    </div>

                                    {this.state.webHookReport?

                                    
                                        <div  className="table-responsive" style={{height:400+'px'}}>
                                        <table className="table table-hover my-0">
                                        <thead>
                                            <tr>
                                            <th className="d-xl-table-cell">Request Time</th>
                                                <th className="d-xl-table-cell">Channel</th>
                                                <th className="d-xl-table-cell">Called URL</th>
                                                <th className="d-xl-table-cell">URL Response</th>
                                               
                                            </tr>
                                        </thead>
                                            <tbody>

                                            {
                                            this.state.webHookReport.map((item,i)=>
                                        <tr>

                                        <td>{item.request_time}</td>

                                        <td style={{textTransform:'capitalize'}}>{item.channel}</td>


                                           

                                            <td>
                                                Called URL <span  data-tip data-for={"registerTip"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                                <ReactTooltip className="tooltip"  html={true}  id={"registerTip"+i} place="bottom" effect="solid">
                                                {this.urldecode(item.called_url)}
                                                </ReactTooltip>
                                            </td>

                                            <td>
                                                Message <span  data-tip data-for={"url_response"+i} style={{cursor:'pointer',fontSize:15,}} > <FontAwesomeIcon icon={faInfoCircle} color='#008aff'  /></span>
                                                <ReactTooltip className="tooltip"  html={true}  id={"url_response"+i} place="bottom" effect="solid">
                                                {item.url_response}
                                                </ReactTooltip>
                                            </td>


                                           
                                        </tr>
                                        
                                        )
                                    }                                    
                                               
                                            </tbody>
                                        </table>
                                        </div>
                                        :
                                        <div className="text-center">
                                        <div className="card-body">
                                            <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                            <p>No Report Found </p>

                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default WebHookReport;