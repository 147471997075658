import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faBroadcastTower, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import { userloginDetails } from '../Utils/AdminAuth';
import { HOST_URL } from '../Dashboard/api/Api';

class ChangedUserdRoute extends Component {

    constructor(props) {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const isAdmin = lStorage.user.isAdmin;
        super(props);

        this.state = {
            user_id: userId,
            token: userToken,
            routeAssignedUsreList: '',
            seachUser: '',
            route_name: '',
            channel: '',
            countryCode: '',
            userType: isAdmin,
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
        this.getRought = this.getRought.bind(this);
    }

    componentDidMount() {
        this.routeChnagedUser();
    }

    routeChnagedUser() {
        const getlist = ({
            user_id: this.state.user_id,
            method: 'retrieve',
            type: this.state.userType,
            token: this.state.token
        })
        fetch(`${HOST_URL}/route_transfer.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(getlist)
        }).then((result) => {
            result.json().then((result) => {
                //   console.log(result)
                if (result.success === true) {
                    this.setState({
                        routeAssignedUsreList: result
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    getRouteSearchData() {

        const getSearchRotelist = ({
            user_id: this.state.user_id,
            method: 'search',
            country_code: this.state.countryCode,
            user: this.state.seachUser,
            route_name: this.state.route_name,
            channel: this.state.channel,
            type: this.state.userType,
            token: this.state.token
        })

        //console.log(getSearchRotelist)

        fetch(`${HOST_URL}/route_transfer.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(getSearchRotelist)
        }).then((result) => {
            result.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({
                        routeAssignedUsreList: result,
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: "Result Updated"
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    getRought(e) {
        this.setState({
            route_name: e.target.value
        });
    }


    render() {
        // console.log(this.props.all_list)
        return (
            <>

                {
                    this.state.successMessage ?
                        <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <div className="">
                                {this.state.errorMessage}
                            </div>
                        </div>
                        :
                        null
                }

                <div className="filter-option">
                    <h6 className="card-subtitle text-muted mb-3">List Of Changed User Routes </h6>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faUser} /></div>
                                    <input onChange={(event) => this.setState({ seachUser: event.target.value })} type="text" placeholder="Enter user Name" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input-group drop-down-icons mb-2 mr-sm-2">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faBroadcastTower} /></div>
                                    {
                                        this.props.all_list ?
                                            <select onChange={this.getRought} className="form-control ">
                                                <option>Select Route</option>
                                                {
                                                    this.props.all_list.data.map((item, i) =>
                                                        <option key={i} value={item.id}>{item.route_name}</option>
                                                    )}
                                            </select>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faInfoCircle} /></div>
                                    <input onChange={(event) => this.setState({ countryCode: event.target.value })} type="text" placeholder="Country Code" className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="form-group drop-down-icons  col-md-3">
                            <select onChange={(event) => this.setState({ channel: event.target.value })} className="form-control">
                                <option value=''> Select Channel </option>
                                <option value="sms"> SMS </option>
                                <option value="voice"> Voice </option>
                            </select>
                        </div>

                        <div className="col-md-2 text-right ">
                            <button type="button" onClick={() => this.getRouteSearchData()} className="btn-success btn-block btn-sm  ml-auto"><FontAwesomeIcon icon={faSearch} /> Filter </button>
                        </div>



                    </div>



                </div>


                <div style={{ height: 400 + 'px' }} className="table-responsive">
                    {this.state.routeAssignedUsreList ?
                        <table className="table table-hover my-0">
                            <thead>
                                <tr>
                                    <th>User Name </th>
                                    <th>Route Name</th>
                                    <th>Channel</th>
                                    <th>Country Name</th>
                                    <th> Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.routeAssignedUsreList.data.map((item, i) =>
                                        <tr key={i}>
                                            <td>{item.email}</td>
                                            <td>{item.root} <br /> <strong>Route ID: {item.routeid}</strong> </td>
                                            <td>{item.channel}</td>
                                            <td>{item.country_name}</td>
                                            <td>{item.created}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        :
                        <div className="text-center">
                            <div className="card-body text-center">
                                <p>Loading...... </p>
                            </div>
                        </div>
                    }

                </div>
            </>
        );
    }
}

export default ChangedUserdRoute;