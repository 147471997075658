import React, { useEffect, useState } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Spining from "../../UI/Spining/Spining";
import Title from "../../UI/Text/Title";

import { conversation } from "../api/Api";

const ChatSetting = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    successMessage: false,
    errorMessage: "",
    bgColor: "",
  });
  const [selectedHs, setSelectedHs] = useState("");
  const [insert, setInsert] = useState("");
  useEffect(() => {
    retrieveData();
  }, []);
  const retrieveData = async () => {
    let data = {
      token: userToken,
      user_id: userId,
      method: "retrieve_chat_setting",
      channel: "whatsapp",
    };
    try {
      const res = await conversation(data);
      if (res.data.success === true) {
        setSelectedHs(res.data.data);
        setInsert(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateData = async () => {
    if (selectedHs === "") {
      setAlert({
        successMessage: true,
        errorMessage: "Please select Hunt Strategy",
        bgColor: "alert alert-danger alert-dismissible",
      });
      return;
    }
    let method = insert ? "update_chat_setting" : "insert_chat_setting";
    let data = {
      token: userToken,
      user_id: userId,
      method,
      channel: "whatsapp",
      hunt_strategy: selectedHs,
    };
    setIsLoading(true);
    try {
      const res = await conversation(data);
      if (res.data.success === true) {
        setAlert({
          successMessage: true,
          errorMessage: res.data.message,
          bgColor: "alert alert-success alert-dismissible",
        });
      } else {
        setAlert({
          successMessage: true,
          errorMessage: res.data.message,
          bgColor: "alert alert-danger alert-dismissible",
        });
      }

      console.log(res);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const handleHuntStrategy = (e) => {
    setSelectedHs(e.target.value);
  };
  return (
    <div>
      <DashboardLayout>
        {isLoading && <Spining />}
        <main className="content">
          <div className="container-fluid p-0">
            <Title isButton={false}> Chat Setting </Title>

            {alert.successMessage ? (
              <div className={alert.bgColor} role="alert">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="">{alert.errorMessage}</div>
              </div>
            ) : null}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      Hunt Strategy<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group drop-down-icons mb-2 mr-sm-2">
                      <div className="input-group mb-2 mr-sm-2">
                        <select
                          className="form-control "
                          value={selectedHs}
                          onChange={handleHuntStrategy}
                        >
                          <option value="">Select Hunt Strategy</option>
                          {/* <option value="parallel">Parallel</option> */}

                          <option value="random">Random</option>
                          <option value="sequence">Sequence</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mt-4">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={updateData}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </div>
  );
};

export default ChatSetting;
