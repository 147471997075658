import React, { Component } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import { WHATSAPP_TEMPLATE_LIST_CONST } from "../../UI/Table/ColumnConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch
} from "@fortawesome/free-solid-svg-icons";

class WhatsappTemplateList extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      setIsLoading: true,
      WhatsappTemplateList: null,
      search:"",
      filterTemplateList:null,
    };
    this.searchHandler = this.searchHandler.bind(this);
    this.reset = this.reset.bind(this);

  }

  componentDidMount() {
    this.getWhatsaAppTemplateList();
  }

  getWhatsaAppTemplateList = async () => {
    this.setState({ setIsLoading: true });
    try {
      const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "retrieve",
      });
      if (data.success === true) {
        this.setState({ WhatsappTemplateList: data.data, setIsLoading: false });
      } else {
        this.setState({ setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  searchHandler(e){
    let searchValue=e.target.value.toString();
    this.setState({
        search:searchValue
    })

  }

  reset(e){
    this.setState({
        search:"",
    })
  }

  render() {
    // document.querySelector("body").scrollTo(0, 0);
    //   if(this.state.WhatsappTemplateList){
    // var filteredList = this.state.WhatsappTemplateList.filter(
    //   (item) =>
    //     // console.log(item.temp_name)
    //     item.temp_name.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase())||
    //     item.id.toString().includes(this.state.search)
    // );}
  console.log(this.state.WhatsappTemplateList)
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Create New Template`}
              actionURL={`/dashboard/create-whatsapp-template`}
            >
              {" "}
              WhatsApp Template List{" "}
            </Title>
            {/* <div className="input-group pr-3 w-75">
              <input
                type="text"
                
                className="form-control"
                placeholder="Enter template name or WID"
                value={this.state.search}
                onChange={this.searchHandler}
              />
              <div className="input-group-append">
                <button disable style={{ border:"none", paddingLeft:"15px", paddingRight:"15px", cursor:"context-menu"}}><FontAwesomeIcon icon={faSearch} /></button>
              
              </div>
            </div> */}
            <AdvanceDataTable
              // tableName={"Template List"}
              tableCell={WHATSAPP_TEMPLATE_LIST_CONST}
              tableData={this.state.WhatsappTemplateList}
              isLoading={this.state.setIsLoading}
            />
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default WhatsappTemplateList;
