import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminLayout from "../../UI/Layout/AdminLayout";
import Title from "../../UI/Text/Title";
import Spining from "../../UI/Spining/Spining";
import { userloginDetails } from "../../Utils/AdminAuth";
import Axios from "axios";
import { NODE_HOST_URL } from "../../Dashboard/api/Api";
import JSONPretty from "react-json-pretty";
import dayjs from "dayjs";

const WhatsappSetupDetails = () => {
  const lStorage = userloginDetails();
  const userId = lStorage.user.userId;
  const userToken = lStorage.token;
  const { id } = useParams();

  const [alert, setAlert] = useState({
    status: false,
    bgColor: "",
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [resData, setResData] = useState([]);
  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);

    try {
      const dataforsearch = {
        user_id: userId,
        token: userToken,
        method: "retrieve_wp_setup",
        search_id: id,
      };
      const res = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_setup`,
        dataforsearch
      );
     
      if (res.data.success === true) {
        if (res.data.data.length > 0) {
          setResData(res.data.data[0]);
        } else {
          setResData([]);
        }
      } else {
        setAlert({
          status: true,
          bgColor: "danger",
          errorMessage: res.data.message,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <AdminLayout>
        <Title isButton={false}> Embeded Setup Details</Title>

        <div className="row">
          <div className="">
            <div className="card">
              {alert.status === true ? (
                <div className={alert.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{alert.errorMessage}</div>
                </div>
              ) : null}

              {isLoading && <Spining />}

              <table className="table table-bordered table-striped">
                <tbody>
                <tr>
                    <td>User ID</td>
                    <td>{resData.user_id}</td>
                  </tr>
                  <tr>
                    <td>User Name</td>
                    <td>{resData.user_name}</td>
                  </tr>
                  <tr>
                    <td>App Name</td>
                    <td>{resData.app_name}</td>
                  </tr>
                  <tr>
                    <td>Country Code</td>
                    <td>{resData.country_code}</td>
                  </tr>
                  <tr>
                    <td>Mobile No.</td>
                    <td>{resData.mobile_number}</td>
                  </tr>
                  <tr>
                    <td>Mobile Status</td>
                    <td>{resData.mobile_status}</td>
                  </tr>
                  <tr>
                    <td>Remark</td>
                    <td>{resData.remark}</td>
                  </tr>
                  <tr>
                    <td>Access Token</td>
                    <td>
                      <p
                        className="p-2"
                        style={{
                          height: "80px",
                          width: "40rem",
                          overflowY: "auto",
                          border: "1px solid black",
                          fontSize: "15px",
                          scrollbarWidth: "thin",
                        }}
                      >
                        {resData.access_token}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Api Response App Name</td>
                    <td><JSONPretty id="json-pretty" data={resData.api_response_appname} className="admin-json" ></JSONPretty></td>
                  </tr>
                  <tr>
                    <td>Api Response Mobile Status</td>
                    <td><JSONPretty id="json-pretty" data={resData.api_response_mobilestatus} className="admin-json" ></JSONPretty></td>
                  </tr>
                  <tr>
                    <td>Api Response WABA</td>
                    <td><JSONPretty id="json-pretty" data={resData.api_response_waba} className="admin-json" ></JSONPretty></td>
                  </tr>
                  <tr>
                    <td>Business Manager ID</td>
                    <td>{resData.business_management}</td>
                  </tr>
                  <tr>
                    <td> WABA ID</td>
                    <td>{resData.whatsapp_business_management}</td>
                  </tr>
                  <tr>
                    <td> Whatsapp Business Messaging</td>
                    <td>{resData.whatsapp_business_messaging}</td>
                  </tr>
                  <tr>
                    <td> IP Address</td>
                    <td>{resData.ip_address}</td>
                  </tr>
                  <tr>
                    <td> Phone No. ID</td>
                    <td>{resData.phone_no_id}</td>
                  </tr>
                  <tr>
                    <td> Phone No. Reg. Status</td>
                    <td>{resData.phone_no_reg_status}</td>
                  </tr>
                  <tr>
                    <td> Reg. WABA App Status</td>
                    <td>{resData.register_waba_app_status}</td>
                  </tr>
                  <tr>
                    <td> Status</td>
                    <td>{resData.status===1?<span className="badge bg-success">Setup Done</span>:<span className="badge bg-danger">Pending</span>}</td>
                  </tr>
                  <tr>
                    <td> Modified Date</td>
                    <td>{dayjs(resData.modified).format("DD/MM/YYYY h:mm A")}</td>
                  </tr>
                  <tr>
                    <td> Created Date</td>
                    <td>{dayjs(resData.created).format("DD/MM/YYYY h:mm A")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default WhatsappSetupDetails;
