import React, { Component } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import Card from "../../UI/Card/Card";
import WhatsAppPreview from "../whatsapp/components/WhatsAppPreview";
import ReactTelInput from "react-telephone-input";
import flags from "../../Dashboard/assets/img/flags.png";
import { WHATSAPP_TEMPLATE_LIST } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { no_data } from "../../../images/WhatsAppImages";
import Alert from "../../UI/Alert/Alert";
import { HOST_URL, REQUEST_URL } from "../api/Api";
import { WhstappAccountList } from "../../Dashboard/api/Api";
class SendWhstappMessage extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;
    super();

    this.state = {
      user_id: userId,
      token: userToken,
      recipientNumber: "",
      authkey: "",
      UserDetails: "",
      templatePreView: "",
      showButton: false,
      WhatsAppTemplate: [],
      wid: "",
      message: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
      defaultCountry: "in",
      countryCode: "91",
      whatsapplimittotal: "",
      showLimits: false,
    };
  }

  handleInputChange = (telephone, selectedCountry) => {
    if (selectedCountry) {
      const { dialCode, iso2 } = selectedCountry;
      this.setState({
        countryCode: dialCode,
        selectedCountryCode: iso2,
      });
    }
  };

  componentDidMount() {
    this.getTemplateList();
    this.getUserDetails();
    WhstappAccountList().then((res) => {
      if (res.data.data === undefined) {
        this.setState({ showLimits: true });
      } else {
        this.setState({ showLimits: false });
      }
    });
  }

  getTemplateList = () => {
    WhstappAccountList().then((res) => {
      if (res.data.data === undefined) {
        fetch(
          `${HOST_URL}/whatsapp_template.php?user_id=${this.state.user_id}&method=retrieve_for_test&token=${this.state.token}`
        ).then((response) => {
          response.json().then((result) => {
            if (result.success === true) {
              result.data.map((item) => {
                if (item.temp_status === 1) {
                  this.setState({
                    WhatsAppTemplate: [...this.state.WhatsAppTemplate, item],
                  });
                }
              });
            } else {
              this.setState({
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
                errorMessage: result.message,
              });
            }
          });
        });
      } else {
        fetch(
          `${HOST_URL}/whatsapp_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
        ).then((response) => {
          response.json().then((result) => {
            if (result.success === true) {
              result.data.map((item) => {
                if (item.temp_status === 1) {
                  this.setState({
                    WhatsAppTemplate: [...this.state.WhatsAppTemplate, item],
                  });
                }
              });
            } else {
              this.setState({
                successMessage: true,
                bgColor: "alert alert-danger alert-dismissible",
                errorMessage: result.message,
              });
            }
          });
        });
      }
    });
  };

  getUserDetails = () => {
    fetch(
      `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          const userDetails = result.data[0];
          const testwhatsapplimit = userDetails.test_whatsapp_limit1 || 0;
          const testwhatsappsent = userDetails.test_whatsapp_sent || 0;
          const desiredParameterValue = Math.max(
            testwhatsapplimit - testwhatsappsent,
            0
          );

          this.setState({
            UserDetails: userDetails,
            testwhatsapplimits: testwhatsapplimit,
            test_whatsapp_sent: testwhatsappsent,
            desiredParameterValue: desiredParameterValue.toString(),
          });
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: result.message,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  updatetextwhatsapplimit = () => {
    fetch(
      `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=update_limit&token=${this.state.token}`
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.getUserDetails();
        } else {
          console.error(
            "Failed to update WhatsApp template limit:",
            result.message
          );
        }
      })
      .catch((error) => {
        console.error("Error updating WhatsApp template limit:", error);
      });
  };

  sendWhatsAppTestMessage = async () => {
    try {
      // Set isLoading to true
      this.setState({ setIsLoading: true });
      
      



      // Check if Recipient Mobile Number exists and is valid
      if (!this.state.recipientNumber.trim()) {
        // If not exists or is empty, show an error message
        this.setState({
          errorMessage: "Recipient Mobile Number is required.",
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
        });
        return;
      } else {
        // Validate the mobile number using a regular expression
        const mobileNumberRegex = /^[0-9]{10}$/; // Adjust the regex based on your validation criteria

        if (!mobileNumberRegex.test(this.state.recipientNumber.trim())) {
          // If the mobile number is not valid, show an error message
          this.setState({
            errorMessage: "Please enter a valid 10-digit mobile number.",
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
          });
          return;
        }
      }

      // Continue with the rest of your checks
      if (!this.state.wid) {
        // If not selected, show an error message
        this.setState({
          errorMessage: "Please select a WhatsApp Template.",
          successMessage: true,
          bgColor: "alert alert-danger alert-dismissible",
        });
        return;
      }

      // Fetch WhatsApp account list
      const res = await WhstappAccountList();

      let authkey = "";
      let urlauthkey = "";
      if (res.data.data === undefined) {
        urlauthkey = `${REQUEST_URL}?authkey=523912703d2967f9&mobile=${this.state.recipientNumber}&wid=${this.state.wid}&country_code=${this.state.countryCode}&1=${this.state.UserDetails.username}`;
      } else {
        authkey = this.state.UserDetails.authkey;
        urlauthkey = `${REQUEST_URL}?authkey=${authkey}&mobile=${this.state.recipientNumber}&wid=${this.state.wid}&country_code=${this.state.countryCode}`;
      }

      // Perform the actual delivery of the WhatsApp message
      const response = await fetch(urlauthkey);
      const result = await response.json();
      this.updatetextwhatsapplimit();

      document.querySelector("body").scrollTo(0, 0);

      // Directly set the success or error state based on result.success
      this.setState({
        errorMessage: "Submitted Successfully",
        successMessage: true,
        bgColor: "alert alert-success alert-dismissible",
        recipientNumber: "",
        wid: "",
      });

      console.log("Is Loading set to false");
    } catch (error) {
      console.error(error);
    } finally {
      // Set isLoading to false
      this.setState({ setIsLoading: false });
    }
  };

  selectedTemplate = async (e) => {
    this.setState({ wid: e.target.value });
    try {
      const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
        token: this.state.token,
        user_id: this.state.user_id,
        id: e.target.value,
        method: "retrieveid",
      });
      let urlbtn=[];
      let urlbtn2=[];
      let callbtn=[];
      if (data.success === true) {
        if (data.data[0].temp_button) {
          let buttonData=data.data[0].temp_button;
          buttonData.map((item, index) => {
            let key = Object.keys(item);
            if (key.includes("urltext2")) {
              urlbtn2[0]=item;
            }
            if (key.includes("urltext")) {
              urlbtn[0]=item;
            }
            if (key.includes("phone")) {
              callbtn[0]=item;
            }
          });
        }

        let PreviewTemplateData = {
          tampleName: data.data[0].temp_name,
          language: data.data[0].temp_language,
          accountType: data.data[0].temp_category,
          templateType: data.data[0].temp_type,
          headerText: data.data[0].temp_header,
          headerOptions: data.data[0].temp_header === "" ? false : true,
          footerText: data.data[0].temp_footer,
          footerOptions: data.data[0].temp_header === "" ? false : true,
          temp_button: data.data[0].temp_button,
          bodyMessage: data.data[0].temp_body,
          button: data.data[0].temp_button,
          temp_status: data.data[0].temp_status,
          Urlbutton: urlbtn,
          Urlbutton2: urlbtn2,
          Callbutton: callbtn,
          quickButton:
            data.data[0].temp_button.length > 0 &&
            data.data[0].temp_button[0].actionType === "quickReply"
              ? [data.data[0].temp_button[0]]
              : "",
          temp_attribute: [],
          isLoading: false,
        };
        this.setState({ templatePreView: PreviewTemplateData });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { defaultCountry, showLimits } = this.state;
    const { testwhatsapplimits, test_whatsapp_sent } = this.state;

    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title> WhatsApp </Title>
            <div className="row">
              <div className="col-md-8">
                {this.state.successMessage && (
                  <Alert type={this.state.bgColor}>
                    {this.state.errorMessage}
                  </Alert>
                )}

                <Card CardTitle={`Send test message on whatsApp`}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12">
                        <label for="colFormLabel" className="col-form-label">
                          Recipient Mobile Number
                        </label>

                        <div className="">
                          <div className="input-group mb-2">
                            <div className="input-group-prepend">
                              <div className="">
                                <ReactTelInput
                                  defaultCountry={defaultCountry}
                                  flagsImagePath={flags}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.recipientNumber}
                              onChange={(event) =>
                                this.setState({
                                  recipientNumber: event.target.value,
                                })
                              }
                              id="inlineFormInputGroup"
                              placeholder="Enter whatsApp Number"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          for="colFormLabel"
                          className="col-sm-12 col-form-label"
                        >
                          {" "}
                          Select WhatsApp Template
                        </label>
                        <div className="input-group drop-down-icons mb-2">
                          <select
                            value={this.state.wid}
                            onChange={this.selectedTemplate}
                            className="form-control"
                          >
                            <option value="">Select Template</option>
                            {this.state.WhatsAppTemplate.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.temp_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {showLimits && (
                          <div className="form-group">
                            {/* ... (rest of your form-group code) */}
                            <p className="text-sm">
                              Current Sent Limit:{" "}
                              <span className="text-success">
                                {testwhatsapplimits}
                              </span>
                            </p>
                            <p className="text-sm mb-0">
                              Used Sent Limit:{" "}
                              <span className="text-danger">
                                {test_whatsapp_sent}
                              </span>
                            </p>
                            {testwhatsapplimits == test_whatsapp_sent && (
                              <span className="alert alert-danger mt-3 mb-0">
                                Please Contact the Person
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {showLimits ? (
                    <button
                      type="button"
                      onClick={() => this.sendWhatsAppTestMessage()}
                      className="btn btn-sm button-cus"
                      disabled={
                        this.state.testwhatsapplimits ===
                        this.state.test_whatsapp_sent
                      }
                    >
                      Send WhatsApp Test Message
                    </button>
                  ):(<button
                    type="button"
                    onClick={() => this.sendWhatsAppTestMessage()}
                    className="btn btn-sm button-cus"
                  >
                    Send WhatsApp Test Message
                  </button>)}
                  
                </Card>
              </div>

              <div className="col-md-4 ">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0"> Template Preview </h5>
                  </div>
                  <hr className="m-0" />
                  <div className="card-body">
                    {this.state.templatePreView ? (
                      <WhatsAppPreview
                        previewData={this.state.templatePreView}
                      />
                    ) : (
                      <div className="text-center">
                        <img className="img-fluid" src={no_data} />
                        <p className="text-right">
                          Please select WhatsApp Template to preview
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default SendWhstappMessage;
