import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { HOST_URL } from '../api/Api';


class RcsPlan extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

super();
    this.state = {
        user_id:userId,
        token:userToken,
        email:'',
        department:'',
        projectList:'',
        messageBody:'',
        parent_id:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}

componentDidMount() {
    fetch(`${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            // console.log(result);
            if(result.success===true){
            this.setState({projectList:result});
            }
        })
    })

}


render() {
    document.querySelector('body').scrollTo(0, 0);
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content">
                <div className="container-fluid p-0">

                 <div className="row mb-2 mb-xl-3">
								<div className="col-auto d-none d-sm-block">
									<h3><strong>RCS </strong> Pricing Plan  </h3>
								</div>
							</div>


                       {
                        this.state.successMessage? 
                        <div className={this.state.bgColor} role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                        <div className="">
                            {this.state.errorMessage}
                        </div>
                        </div>
                        :
                        null
                        }


<div className="row">

<div className="col-12 col-lg-12 col-xxl-12">

<div className="card">

<div className="pricing-header px-3 py-3 pb-md-4  text-center">
<div className="row justify-content-center">
<p className=" mb-3 col-md-8 mt-5">Authkey is a leader in trusted RCS delivery. Our powerful cloud-based solution sends transactional and marketing RCS for over 58,000 paying customers, allowing them to focus on their business, instead of their RCS infrastructure.</p>
</div>
</div>
    <div className="card-body">

    <div className="">
        <div className="card-deck mb-3 text-center">
            <div className="card mb-4 box-shadow">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">Forever Free</h4>
            </div>
            <hr className="m-0"/>
            <div className="card-body">
                <h1 className="card-title pricing-card-title">0 <small className="text-muted">/ Year</small></h1>
              
                <button type="button" className="btn  btn-sm btn-block btn-outline-primary">Choose Plan</button>
            </div>
            </div>
            <div className="card mb-4 box-shadow">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">Startup Plan</h4>
            </div>
            <hr className="m-0"/>
            <div className="card-body">
                <h1 className="card-title pricing-card-title">999 <small className="text-muted">/ Year</small></h1>
               
                <button type="button" className="btn  btn-sm btn-block btn-primary">Choose Plan</button>
            </div>
            </div>
            <div className="card mb-4 box-shadow">
            <div className="card-header">
                <h4 className="my-0 font-weight-normal">Enterprise Plan</h4>
            </div>
            <hr className="m-0"/>
            <div className="card-body">
                <h1 className="card-title pricing-card-title">12000   <small className="text-muted">/ Year</small></h1>
               
                <button type="button" className="btn btn-sm btn-block btn-primary">Choose Plan</button>
            </div>
            </div>
        </div>
        </div>

    </div>
</div>


</div>
</div>

                    
            </div>
        </main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsPlan;