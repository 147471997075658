import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTrash} from '@fortawesome/free-solid-svg-icons'
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { HOST_URL } from '../api/Api';


class EmailValidator extends Component {
    constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

        super();
        this.state = {
			user_id: userId,
            token: userToken,
            free_check:'null',
            disposable_check:'null',
            catchall_check:'null',
            isActive:false,
            settingOnly:false,
            activateValidator:false,
			successShow:false,
			successMessage:"",
			bgColor:''
			
        }
    }

    componentDidMount() {

        fetch(`${HOST_URL}/email_validaor.php?user_id=${this.state.user_id}&method=checkstatus&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
               // console.log(result)
                if(result.success===true){
                    this.setState({ 
                        isActive:true, 
                        activateValidator:true,
                        settingOnly:true,
                        free_check: result.data[0].free_check, 
                        disposable_check: result.data[0].disposable_check,
                        catchall_check: result.data[0].catchall_check
                    });
                }else{
                    this.setState({ isActive:false});
                }
            })
        })
  
	}


    ActiveEmailValidator() {
        document.querySelector('body').scrollTo(0, 0);
        fetch(` ${HOST_URL}/email_validaor.php?user_id=${this.state.user_id}&free_check=${this.state.free_check}&disposable_check=${this.state.disposable_check}&catchall_check=${this.state.catchall_check}&method=create&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                 //  console.log(result)
                if (result.success === true) {
                    this.setState({
                        successShow: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                    setTimeout(function(){window.location.reload();}, 2000);
                } else {
                    this.setState({
                        successShow: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


   updateEmailValidator() {
        document.querySelector('body').scrollTo(0, 0);
        fetch(` ${HOST_URL}/email_validaor.php?user_id=${this.state.user_id}&free_check=${this.state.free_check}&disposable_check=${this.state.disposable_check}&catchall_check=${this.state.catchall_check}&method=update&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                 //  console.log(result)
                if (result.success === true) {
                    this.setState({
                        successShow: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })
                    setTimeout(function(){window.location.reload();}, 2000);
                } else {
                    this.setState({
                        successShow: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }

    activateValidator(){
        this.setState({ isActive:true, activateValidator:true, settingOnly:false});
    }
	

	DeactiveEmailValidator(){
		document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/email_validaor.php?user_id=${this.state.user_id}&method=delete&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
				//console.log(result.success);
				if(result.success === true ){
					this.setState({successShow:true});
					this.setState({errorMessage:result.message});
					this.setState({bgColor:"alert alert-success alert-dismissible"});
					setTimeout(function(){window.location.reload();}, 2000);
					 
					}else{
					this.setState({successMessage:true});	
					this.setState({errorMessage:result.message});	
					this.setState({bgColor:"alert alert-danger alert-dismissible"});
					}
            })
        })

	}




render() {    
       // console.log(this.state)
		return (
			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				<main className="content">
				<div className="container-fluid p-0">
				<div className="row mb-2 mb-xl-3">
				<div className="col-auto d-none d-sm-block">
					<h3><strong> Email  </strong> Validator  </h3>
				</div>
			</div>

			
			     {
                     this.state.successShow? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                }


                    <div className="">
                        <div className="col-lg-12 api-doc  bg-white p-3 shadow mb-0 text-left">
                                <div className="email-unsubscribe">
                            {
                                this.state.isActive ?

                              
                                
                
                                <div>
                               
                                {this.state.activateValidator ?
   
                                <div className="row">
                                    
                                {
                                this.state.settingOnly ?
                                    <>
                                <div className="col-md-6">
                                <h3 className="text-success mt-2" to="/dashboard/email-campaning"> <FontAwesomeIcon icon={faCheckCircle} color=''  />  Email Validator Active </h3>
                                </div>

                                <div className="col-md-6 text-right">
                                <button onClick={()=> this.DeactiveEmailValidator()} className="btn btn-danger btn-sm" to="/dashboard/email-campaning"> <FontAwesomeIcon icon={faTrash} color=''  /> Disable Email Validator </button>
                                </div>
                           
                                  <hr/>
                                  </>
                                  :
                                  null
                                
                                 }
                                

                                     

                                <form className="text-left col-md-8">


                             

                                <p>
                                      Default Email validator checked
                                </p>

                                <div className="form-check ml-2">
                                    <input className="form-check-input" checked disabled  type="checkbox" name="emailunsub" value="Smtp check" id="flexCheckDefault"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                    Smtp check
                                    </label>
                                 </div>
                                 <div className="form-check  ml-2">
                                    <input className="form-check-input" checked disabled  type="checkbox" name="emailunsub" value=" Dns check" id="flexCheckDefault"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                    Dns check
                                    </label>
                                 </div>

                                 <hr/>

                                 <p>Kindly checked status for blocking email</p>

                                 <div className="form-check  ml-2">
                                    <input className="form-check-input" onChange={(event)=>
 {this.setState({free_check:event.target.value}) }} 
												defaultChecked={this.state.free_check===1?'checked':null}
                                                 type="checkbox" name="emailunsub" value="1" id="flexCheckDefault"/>
                                    <label value="I don't remember signing up for this" className="form-check-label" for="flexCheckDefault">
                                    Free check
                                    </label>
                                 </div>

                                 <div className="form-check ml-2">
                                    <input  onChange={(event)=>
												{this.setState({disposable_check:event.target.value}) }} className="form-check-input"
												defaultChecked={this.state.disposable_check===1?'checked':null}   type="checkbox" name="disposable_check" value="1" id="disposable_check"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                    Disposable check
                                    </label>
                                 </div>

                                 <div className="form-check  ml-2">
                                    <input className="form-check-input" onChange={(event)=>
												{this.setState({catchall_check:event.target.value}) }} 
												defaultChecked={this.state.catchall_check===1?'checked':null}  type="checkbox" name="emailunsub" value="1" id="flexCheckDefault"/>
                                    <label className="form-check-label" for="flexCheckDefault">
                                    Catchall check
                                    </label>
                                 </div>
                                 <div className="text-center col-md-12 m-auto">

                                 {
                                this.state.settingOnly ?
                                  
                                <input type="button" onClick={()=> this.updateEmailValidator()}className='btn btn-primary mt-3' value="Update Now " />
                                :
                                <input type="button" onClick={()=> this.ActiveEmailValidator()}className='btn btn-primary mt-3' value="Activate Now"/>

                                 }
                               
                        
                                </div>
                                </form>

                                <div className="col-md-4">
                                <div className="card">
                                    <p className="tooglebox"><a className="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="/dashboard/email-template#collapseExample">Note</a></p>
                                    <ul className="mt-3 template-mng">
                                    <li><strong>Smtp check : </strong> Checks if the email address exists and can receive emails by using SMTP connection and email-sending emulation techniques. </li>

                                    <li><strong>Dns check : </strong> Ensures that the domain in the email address, eg: gmail.com, is a valid domain. </li>

                                    <li><strong>Free check : </strong> 	Check to see if the email address is from a free email provider like Gmail or not.  </li>
                                    <li><strong>Disposable check </strong> Tells you whether or not the email address is disposable (created via a service like Mailinator).  </li>
                                    <li><strong> Catchall check </strong> 	Tells you whether or not this mail server has a “catch-all” address. </li>
                                  
                                    </ul>                      
                                </div>
                                </div>
                                </div>

                                    :

                                    null
                                
                                }
                            </div>



                                :
                              
                                                                

                            <div className="text-center pt-lg-6  pb-lg-6">
                            <h2>Enable Email Address validator API </h2>
                            <p>We have verified over 90 million emails for over 100,000 users in the last year.<br/>
                                We can clean your email list too.</p>


                                <div className="mt-4">
                                <button onClick={()=> this.activateValidator()} className="btn btn-primary btn-sm" to="/dashboard/email-campaning"> <FontAwesomeIcon icon={faCheckCircle} color=''  /> Enable  Email Validator </button>
                                </div>


                            </div>

                            }























                        </div>
                        </div>
                    </div>
	</div>
	</main>

				 <DashboardFooter />
			 </div>
		 </div>




	);
  }
}
 
export default EmailValidator ;