import React, { Component } from "react";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { WHATSAPP_MEDIA_GALLERY } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import Title from "../../UI/Text/Title";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Alert from "../../UI/Alert/Alert";
import { MEDIA_GALLERY } from "../../UI/Table/ColumnConst";
import Card from "../../UI/Card/Card";
import { HOST_URL } from "../api/Api";
class WhatsAppGallery extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      mediaName: "",
      mediaType: "",
      image_url: "",
      isError: false,
      errorMessage: "",
      messageType: "",
      setIsLoading: true,
      WhatsappMediaGalleryList: null,
      ErrorResponse: "",
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    this.getWhatsaAppMediaGallery();
  }

  getWhatsaAppMediaGallery = async () => {
    this.setState({ setIsLoading: true });
    try {
      const { data } = await api.post(WHATSAPP_MEDIA_GALLERY, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "retrieve",
      });

      if (data.success === true) {
        this.filterJSONData(data.data);
        this.setState({ setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: data, setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  filterJSONData = async (data) => {
    let Data = [];

    try {
      data.map((item) => {
        let FilterData = {};
        for (let key in item) {
          if (
            key === "id" ||
            key === "doc_type" ||
            key === "doc_name" ||
            key === "created" ||
            key === "doc_url" ||
            key === "status"
          ) {
            FilterData[key] = item[key];
          }
        }
        Data.push(FilterData);
      });
    } catch (err) {
      console.log(err);
    }
    this.setState({ WhatsappMediaGalleryList: Data });
  };

  mediaHandlar = (e) => {
    this.setState({ mediaType: e.target.value });
  };

  uploadWhatsAppMedia() {
    this.setState({ isError: false });
    let fileSize = this.state.image_url.size / (1024 * 1024);
    if (this.state.mediaType === "image") {
      if (fileSize > 30) {
        this.setState({
          isError: true,
          errorMessage: "Image should be less than 30MB",
          messageType: "alert-danger",
        });
        return;
      }
    }
    if (this.state.mediaType === "video") {
      if (fileSize > 30) {
        this.setState({
          isError: true,
          errorMessage: "Video should be less than 30MB",
          messageType: "alert-danger",
        });
        return;
      }
    }

    if (this.state.mediaType === "doc") {
      if (fileSize > 30) {
        this.setState({
          isError: true,
          errorMessage: "Document should be less than 30MB",
          messageType: "alert-danger",
        });
        return;
      }
    }

    const data = new FormData();
    data.append("amdfile", this.state.image_url);
    data.append("doc_name", this.state.mediaName);
    data.append("doc_type", this.state.mediaType);
    data.append("user_id", this.state.userId);
    data.append("token", this.state.token);
    data.append("method", "create");
    fetch(`${HOST_URL}/uploadFileWhatsapp.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        console.log("resp", resp);
        if (resp.success === true) {
          this.setState({
            isError: true,
            errorMessage: resp.message,
            messageType: "alert-success",
          });
          this.getWhatsaAppMediaGallery();
        } else {
          this.setState({
            isError: true,
            errorMessage: resp.message,
            messageType: "alert-danger",
          });
        }
      });
    });
  }

  fileChangedHandler(e) {
    //console.log(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      this.setState({ image_url: e.target.files[0] });
    }
  }

  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title isButton={false}> WhatsApp Media Gallery </Title>
            {this.state.isError && (
              <Alert type={this.state.messageType}>
                {this.state.errorMessage}
              </Alert>
            )}

            <Card CardTitle="Update New Media">
              <div className="row">
                <div className="col-sm-4">
                  <label for="colFormLabel" className="col-form-label">
                    Media Type{" "}
                  </label>
                  <div className="">
                    <div className="input-group drop-down-icons  mb-2">
                      <select
                        className="form-control"
                        onChange={this.mediaHandlar}
                      >
                        <option value={``}>Choose Media Type</option>
                        <option value={`image`}>Image</option>
                        <option value={`video`}>Video</option>
                        <option value={`doc`}>Doc type</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <label for="colFormLabel" className="col-form-label">
                    Media Name{" "}
                  </label>
                  <div className="">
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ mediaName: e.target.value })
                        }
                        id="inlineFormInputGroup"
                        placeholder="Media File Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <label for="colFormLabel" className="col-form-label">
                    Select Media File{" "}
                  </label>
                  <div className="">
                    <div className="input-group mb-2">
                      <input
                        type="file"
                        onChange={this.fileChangedHandler}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 d-flex align-items-end">
                  <button
                    type="button"
                    onClick={() => this.uploadWhatsAppMedia()}
                    className="btn mt-2 btn-blue btn-sm ml-auto "
                  >
                    {" "}
                    Upload Media
                  </button>
                </div>
              </div>
            </Card>

            <AdvanceDataTable
              // tableName={"WhatsApp Media URL List"}
              tableCell={MEDIA_GALLERY}
              tableData={this.state.WhatsappMediaGalleryList}
              isLoading={this.state.setIsLoading}
              isError={this.state.ErrorResponse}
            />
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default WhatsAppGallery;
