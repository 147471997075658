import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faSearch } from "@fortawesome/free-solid-svg-icons";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { getSMSTemplateList, HOST_URL } from "../api/Api";
class SmsTemplateList extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      SmsTemplateList: null,
      serchData: "",
      enableEdit: "",
      user_id: userId,
      token: userToken,
      successShow: false,
      successMessage: "",
      bgColor: "alert alert-danger alert-dismissible",
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.fatchTemplateDetails();
  }

  getUserDetails() {
    fetch(
      `${HOST_URL}/profile.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log(result)
        if (result.success === true) {
          this.setState({ enableEdit: result.data[0].template_editable });
        }
      });
    });
  }

  fatchTemplateDetails() {
    getSMSTemplateList().then((response) => {
      if (response.data.success === true) {
        this.setState({ SmsTemplateList: response.data });
      }
    });
  }

  searchUser() {
    if (this.state.serchData === "") {
      this.setState({
        successShow: true,
        successMessage: "Enter Message content or Sernder ID",
        bgColor: "alert alert-danger alert-dismissible",
      });
    } else {
      fetch(
        `${HOST_URL}/sms_template.php?user_id=${this.state.user_id}&method=search&keyword=${this.state.serchData}&token=${this.state.token}`
      ).then((response) => {
        response.json().then((result) => {
          //  console.log(result)
          if (result.success === true) {
            this.setState({
              bgColor: "alert alert-success alert-dismissible",
              errorMessage: "Data Updated",
            });
            this.setState({ SmsTemplateList: result });
          } else {
            this.setState({
              successShow: true,
              successBg: "alert alert-danger alert-dismissible",
              successMessage: result.message,
            });
          }
        });
      });
    }
  }

  render() {
    document.querySelector("body").scrollTo(0, 0);
    // console.log(this.state.enableEdit)
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong>SMS Template </strong> List{" "}
                  </h3>
                </div>

                <div className="col-auto ml-auto text-right mt-n1">
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/sms-template"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faPlus} color="" /> Create New SMS
                    Template{" "}
                  </Link>
                </div>
              </div>

              {this.state.successShow ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.successMessage}</div>
                </div>
              ) : null}

              <div className="card flex-fill table-content-show">
                <div className="filter-option">
                  <h6 className="card-subtitle text-muted">Search </h6>
                  <div className="row">
                    <div className="col-md-9 text-right">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                          type="text"
                          onChange={(event) => {
                            this.setState({ serchData: event.target.value });
                          }}
                          placeholder="Enter Message content or Sender ID"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group mb-2 mr-sm-2">
                        <input
                          type="button"
                          onClick={() => this.searchUser()}
                          value="Search"
                          className="btn mt-1 btn-block btn-blue btn-sm ml-auto "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                    <div className=" table-responsive">
                      {this.state.SmsTemplateList ? (
                        <table className="table table-hover  my-0">
                          <thead>
                            <tr style={{ whiteSpace: "normal" }}>
                              <th>SID</th>
                              <th>Name</th>
                              <th style={{ width: 300 + "px" }}>SMS Content</th>
                              <th>Unicode / Flash</th>
                              <th>Sender ID</th>
                              <th>Created Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.SmsTemplateList.data.map((itam, i) => (
                              <tr style={{ whiteSpace: "normal" }} key={i}>
                                <td>{itam.id}</td>
                                <td>{itam.template_name}</td>
                                <td>{itam.content}</td>

                                <td className="table-short-content">
                                  {itam.is_unicode === 1 ? "Yes" : "No"} /{" "}
                                  {itam.is_flash === 1 ? "Yes" : "No"}
                                </td>

                                <td>{itam.senderid}</td>

                                <td>{itam.created}</td>
                                <td>
                                  {itam.entity_id === "1001487500000015900" ||
                                  itam.entity_id === "1201160578232705664" ||
                                  this.state.enableEdit === 0 ? (
                                    <span className="badge bg-info">
                                      {" "}
                                      Default Template{" "}
                                    </span>
                                  ) : (
                                    <Link
                                      to={
                                        "/dashboard/sms-template/edit/" +
                                        itam.id
                                      }
                                    >
                                      <span className="badge bg-primary">
                                        {" "}
                                        <FontAwesomeIcon icon={faEdit} />{" "}
                                      </span>{" "}
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="text-center">
                          <div className="card-body">
                            <img
                              alt="Empty Data"
                              className="project-empty-img"
                              src={EmptyImg}
                            />
                            <p>No SMS Template Yet</p>
                            <Link
                              className="btn btn-success btn-sm"
                              to="/dashboard/sms-template"
                            >
                              Create New SMS Template
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default SmsTemplateList;
