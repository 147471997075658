import React from "react";
import WhatsAppApiComponent from "../../site/WhatsAppApiComponent";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";

const WhatsAppAPIDashboard = () => {
  return (
    <DashboardLayout>
      <main className="content">
        <div className="container-fluid p-0">
          <Title isButton={false}> WhatsApp Docs </Title>

          <div className="card">
            <div className="card-body">
              <WhatsAppApiComponent />
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  );
};
export default WhatsAppAPIDashboard;
