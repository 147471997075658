import React, { Component } from 'react';
import Title from '../../../UI/Text/Title';
import DashboardLayout from '../../../UI/Layout/DashboardLayout';
import Spining from '../../../UI/Spining/Spining';
import { whatsAppAgent } from '../../api/Api';
import Alert from '../../../UI/Alert/Alert';

class AddAgent extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const user_id = lStorage.user.userId;
        const token = lStorage.token;
        super();

        this.state = {
            userId: user_id,
            token: token,
            email: '',
            password: '',
            cpassword: '',
            name: '',
            mobile: '',
            setIsLoading: false,
            isError:false,
            messageType:'',
            errorMessage:''

        }
    }

    addAgent = async () => {
        this.setState({ setIsLoading: true })
        let data = {
            user_id: this.state.userId,
            token: this.state.token,
            email: this.state.email,
            password: this.state.password,
            cpassword: this.state.cpassword,
            name: this.state.name,
            mobile: this.state.mobile,
            method:'create',
        }
        whatsAppAgent(data).then((res)=>{
            if(res.data.success === true){
                this.setState({
                    isError:true,
                    messageType:'alert alert-success',
                    errorMessage: res.data.message,
                    setIsLoading: false
                })
                setTimeout(function(){
                    window.location = "/dashboard/wp-agent-list"
                }, 2000);
            }else{
                this.setState({
                    isError:true,
                    messageType:'alert alert-danger',
                    errorMessage: res.data.message,
                    setIsLoading: false
                })
            }
        })
       
    };

    render() {
        document.querySelector('body').scrollTo(0, 0);
        return (
            <DashboardLayout>
               {this.state.setIsLoading && <Spining />}
                <main className="content">
                    <div className="container-fluid p-0">
                        
                        <Title isButton={true} btnName={` Go Back`} actionURL={`/dashboard/wp-agent-list`} >  Add Agent </Title>
                        <div className="card p-3">
                            
                        {this.state.isError && (
                        <Alert type={this.state.messageType}>
                            {this.state.errorMessage}
                        </Alert>
                        )}

                            <div className='row'>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label col-form-label" htmlFor="inputFirstName">Agent Full Name</label>
                                    <input type="text" defaultValue={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} className="form-control" placeholder="Enter Full Name" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label col-form-label" htmlFor="inputEmail4">Email Address</label>
                                    <input defaultValue={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} type="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label col-form-label" htmlFor="">Enter Mobile Number</label>
                                    <input defaultValue={this.state.mobile} type="text" onChange={(event) => this.setState({ mobile: event.target.value })} className="form-control" placeholder="Enter 10 Digit Mobile Number" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label col-form-label" htmlFor="">Create Password</label>
                                    <input autocomplete="new-password" type="password" onChange={(event) => this.setState({ password: event.target.value })} className="form-control" placeholder="New Password" />
                                </div>
                                <div className="mb-3 col-md-6">
                                    <label className="form-label col-form-label" htmlFor="">Confirm Password</label>
                                    <input autocomplete="new-password" type="password" onChange={(event) => this.setState({ cpassword: event.target.value })} className="form-control" placeholder="New Password" />
                                </div>

                            </div>

                            <div className="">
                            <input type="button" onClick={this.addAgent} value="Create Agent" className="btn btn-sm float-right  btn-primary" />
                        </div>

                        </div>
                        

                    </div>
                </main>
            </DashboardLayout >
        );
    }
}

export default AddAgent;