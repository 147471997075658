import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import AdminHeader from '../Header/AdminHeader';
import AdminFooter from '../Footer/AdminFooter';
import AdminLeftMenu from '../Navbar/AdminLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch,faEye,faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import EmptyImg from "../Dashboard/assets/img/create-project.png";
import { HOST_URL } from '../Dashboard/api/Api';
class RichMediaManagement extends Component {

	constructor(){

        const lStorage = JSON.parse(localStorage.getItem("admin"));
        const userId= lStorage.user.userId;
        const userToken= lStorage.token;

		const AdminEmail= lStorage.user.username;
		const userPassword= lStorage.user.password;
    
        
        super();

        this.state={
            user_id: userId,
            adminPassword: userPassword,
            AdminEmail: AdminEmail,
            token: userToken,
            allrichMediaUsers:null,
            serchData:'',
            userCurrentStatus:'',
            successMessage:false,
            errorMessage:'',
            bgColor:"alert alert-success alert-dismissible",
        }
        
    }
    
    componentDidMount(){
        this.onserch();
    }

 onserch(){
        fetch(`${HOST_URL}/yulore_template.php?user_id=${this.state.user_id}&method=retrieveadmin&type=admin&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
               // console.log(result)
                if(result.success===true){
                    this.setState({allrichMediaUsers:result});
                }else{
                    this.setState({
                        successMessage:true,
                        successBg:"alert alert-danger alert-dismissible",
                        errorMessage:result.message
                    }) 
                }
            })
        })
    }

searchUser(){

    if(this.state.serchData===''){
        this.onserch();
        this.setState({successMessage:true});	
        this.setState({errorMessage:"Enter User Name "});	
        this.setState({bgColor:"alert alert-danger alert-dismissible"});
    }else{

        fetch(`${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=retrieve&type=search&user_name=${this.state.serchData}&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
                if(result.success===true){
                     this.setState({successMessage:false});	
                     this.setState({allrichMediaUsers:result});
                }else{
                    this.setState({successMessage:true});	
					this.setState({errorMessage:result.message});	
					this.setState({bgColor:"alert alert-danger alert-dismissible"});
                }
            })
        })
    }
}


render() {

		return (
<div className="wrapper">
    <AdminLeftMenu />
        <div className="main">
            <AdminHeader />
               <main className="content">
				<div className="container-fluid p-0">
					<div className="row mb-2 mb-xl-3">
						<div className="col-auto d-none d-sm-block">
							<h3><strong> Rich Media </strong> Request </h3>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-lg-12 col-xxl-12 d-flex">
							<div className="card flex-fill">
                                <div className="card-body">
                                <div className="filter-option">
                           <h6 className="card-subtitle text-muted">Filter </h6> 
                           <div className="row">

                                <div className="col-md-9">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-text"><FontAwesomeIcon icon={faSearch}/></div>
                                    <input type="text" onChange={(event) => { this.setState({serchData: event.target.value}) }} placeholder="Enter user id or name of users" className="form-control"/>
                                    </div>
                                </div>
                 

                                <div className="col-md-3">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <input type="button" onClick={()=> this.searchUser()} value="Search" className="btn mt-1 btn-block  btn-primary btn-sm ml-auto "/>
                                    </div>
                                </div>


                                    </div>
                                </div>

                                
                            {
                            this.state.successMessage? 
                                    <div className={this.state.bgColor} role="alert">
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                          </button>
                                <div className="">
                                        {this.state.errorMessage}
                                    </div>
                                </div>
                                    :
                                    null
                                }
                                
								{this.state.allrichMediaUsers?

									<div className="table-responsive">
								<table className="table table-striped table-xs table-sm my-0">
									<thead>
										<tr>
											<th> User ID</th>
											<th>  Template Name </th>
											<th> Status </th>
											<th>Created Date / Time</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										
									{
                                        this.state.allrichMediaUsers.data.map((item,i)=>
                                        <tr key={i}>
                                                <td>{item.user_id}</td>
                                                <td>{item.template_name}</td>
                                                <td>
                                            {item.is_active===0?

                                            <span className="badge bg-danger"> <FontAwesomeIcon icon={faCheckCircle} color=''/> Pending </span>
                                            :

                                            <span className="badge bg-success"> <FontAwesomeIcon icon={faCheckCircle} color=''/> Active </span>

                                        }
                                           
                                            
                                         </td>
												<td>{item.created}</td>
                                                <td>
                                                <Link className="badge bg-info"  to={'/admin/rich-media-view-template/'+item.id+'/'}>
                                                   View <FontAwesomeIcon icon={faEye} />
                                                </Link>
                                                </td>
                                        </tr>
                                        )
                                    }
        
                                               
                                            </tbody>
                                        </table>
										</div>
                                        :
                                        <div className="text-center">
                                        <div className="card-body">
                                            <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                            <p> No Any Rich Media Template</p>
                                            </div>
                                        </div>
                                        }

                                </div>
							</div>
						</div>
					</div>
				</div>
			</main>
            <AdminFooter />
        </div>
    </div>

    );
 }
}
export default RichMediaManagement;