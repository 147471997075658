import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap-tabs";
import FacebookLogin from "react-facebook-login";
import { HOST_URL } from "../api/Api";
class WhatsappEmbed extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();
    this.state = {
      user_id: userId,
      token: userToken,
      comp_name: "",
      brand_number: "",
      number_not_active: "",
      address: "",
      description: "",
      category: "",
      account_status: "",
      image_url: "",
      live_image_url: "",
      fb_businness_id: "",
      email: "",
      profile_status: "",
      successMessage: false,
      errorMessage: "",
      bgColor: "",
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.whatsAppUses = this.whatsAppUses.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id !== "") {
      this.editWPTemplate();
      // var form = document.getElementById("formViewOnly");
      // var elements = form.elements;
      // for (var i = 0, len = elements.length; i < len; ++i) {
      //    elements[i].disabled = true;
      // }
    } else {
    }
  }

  editWPTemplate() {
    fetch(
      `${HOST_URL}/wp_profile.php?user_id=${this.state.user_id}&method=retrieveid&id=${this.props.match.params.id}&token=${this.state.token}`
    ).then((response) => {
      response.json().then((result) => {
        if (result.success === true) {
          this.setState({
            comp_name: result.data[0].comp_name,
            brand_number: result.data[0].brand_number,
            number_not_active: result.data[0].number_not_active,
            address: result.data[0].address,
            description: result.data[0].description,
            category: result.data[0].category,
            live_image_url: result.data[0].image_url,
            email: result.data[0].email,
            profile_status: result.data[0].profile_status,
            fb_businness_id: result.data[0].fb_businness_id,
            account_status: result.data[0].account_status,
          });
        } else {
        }
      });
    });
  }

  enableWhatsapp() {
    document.querySelector("body").scrollTo(0, 0);
    const data = new FormData();
    data.append("image_url", this.state.image_url);
    data.append("comp_name", this.state.comp_name);
    data.append("brand_number", this.state.brand_number);
    data.append("number_not_active", this.state.number_not_active);
    data.append("address", this.state.address);
    data.append("description", this.state.description);
    data.append("category", this.state.category);
    data.append("email", this.state.email);
    data.append("profile_status", this.state.profile_status);
    data.append("fb_businness_id", this.state.fb_businness_id);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.token);
    data.append("method", "create");
    fetch(`${HOST_URL}/wp_profile.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        // console.log(resp)
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
          setTimeout(function () {
            window.location = "/dashboard/whatsapp";
          }, 1000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  updateWPTemplate() {
    document.querySelector("body").scrollTo(0, 0);
    const data = new FormData();
    data.append("image_url", this.state.image_url);
    data.append("comp_name", this.state.comp_name);
    data.append("brand_number", this.state.brand_number);
    data.append("number_not_active", this.state.number_not_active);
    data.append("address", this.state.address);
    data.append("description", this.state.description);
    data.append("category", this.state.category);
    data.append("email", this.state.email);
    data.append("profile_status", this.state.profile_status);
    data.append("user_id", this.state.user_id);
    data.append("token", this.state.token);
    data.append("method", "update");
    data.append("id", this.props.match.params.id);
    fetch(`${HOST_URL}/wp_profile.php`, {
      method: "POST",
      body: data,
    }).then((result) => {
      result.json().then((resp) => {
        //console.log(resp)
        if (resp.success === true) {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: resp.message,
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          this.setState({
            successMessage: true,
            bgColor: "alert alert-danger alert-dismissible",
            errorMessage: resp.message,
          });
        }
      });
    });
  }

  fileChangedHandler(e) {
    //console.log(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      this.setState({ image_url: e.target.files[0] });
    }
  }

  whatsAppUses(e) {
    this.setState({ number_not_active: e.target.value });
  }

  responseFacebook = (response) => {
    console.log(response);

    const loginWithFacebook = {
      accessToken: response.accessToken,
      method: "setup",
      api_response: response,
    };
    fetch(`${HOST_URL}/whatsapp_setup.php`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify(loginWithFacebook),
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.success);
      });
    });
  };

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    {this.props.match.params.id
                      ? "WhatsApp Edit and Update"
                      : "WhatsApp Onboarding"}{" "}
                  </h3>
                </div>

                <div className="col-auto ml-auto text-right mt-n1">
                  <Link
                    className="btn btn-primary btn-sm"
                    to="/dashboard/whatsapp"
                  >
                    <FontAwesomeIcon icon={faBackward} color="" /> Go Back
                  </Link>
                </div>
              </div>

              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="">
                <div className="api-doc  bg-white  shadow mb-0 text-left">
                  <div className="whatsapp-unsubscribe ">
                    <Tabs>
                      {/* <Tab label="Manual Onboarding ">
                        <div>
                          <div className="row p-2">
                            <form
                              id="formViewOnly"
                              className={
                                this.props.match.params.id
                                  ? "col-md-12"
                                  : "col-md-8"
                              }
                            >
                              <div className="card-body">
                                <div className="form-row ">

                                  <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      FB Business Manager ID
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.fb_businness_id}
                                      onChange={(event) =>
                                        this.setState({
                                          fb_businness_id: event.target.value,
                                        })
                                      }
                                      placeholder="Example : 34243433454332"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                    <small className="text-info">(This is the first step for us to
                                      begin, Please complete this at
                                      PRIORITY)</small>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Company Name:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.comp_name}
                                      onChange={(event) =>
                                        this.setState({
                                          comp_name: event.target.value,
                                        })
                                      }
                                      placeholder="Company Pvt. Ltd."
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>



                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Proposed WhatsApp Number:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.brand_number}
                                      onChange={(event) =>
                                        this.setState({
                                          brand_number: event.target.value,
                                        })
                                      }
                                      placeholder="91XXXXXXXX"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      No. not active on WhatsApp
                                    </label>
                                    <select
                                      value={this.state.number_not_active}
                                      onChange={this.whatsAppUses}
                                      className="form-control"
                                    >
                                      <option value=""> Select </option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-12">
                                    <div className="row">
                                      <div
                                        className={
                                          this.props.match.params.id
                                            ? "col-md-6"
                                            : "col-md-12"
                                        }
                                      >
                                        <label className="col-form-label">
                                          Display Picture:
                                        </label>
                                        <input
                                          className="form-control"
                                          onChange={this.fileChangedHandler}
                                          type="file"
                                          name="emailunsub"
                                          id="flexCheckDefault"
                                        />
                                        <small>
                                          (Max size of 5MB, an image size of
                                          640x640 is recommended)
                                        </small>
                                      </div>
                                      {this.props.match.params.id ? (
                                        <div className="col-md-6">
                                          <label className="col-form-label">
                                            Uploaded Display Picture:
                                          </label>
                                          <br />
                                          <img
                                            className="img-fluid whatsapp-image"
                                            src={this.state.live_image_url}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      Business Profile Address:
                                    </label>
                                    <textarea
                                      defaultValue={this.state.address}
                                      onChange={(event) =>
                                        this.setState({
                                          address: event.target.value,
                                        })
                                      }
                                      placeholder="Enter Full Address (Maximum of 256 characters)"
                                      className="form-control"
                                    ></textarea>
                                  </div>

                                  <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      Business Description:
                                    </label>
                                    <textarea
                                      defaultValue={this.state.description}
                                      onChange={(event) =>
                                        this.setState({
                                          description: event.target.value,
                                        })
                                      }
                                      placeholder="Enter about your business (Maximum of 256 characters)"
                                      className="form-control"
                                    ></textarea>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Business Category:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.category}
                                      onChange={(event) =>
                                        this.setState({
                                          category: event.target.value,
                                        })
                                      }
                                      placeholder="Grocery, Technology"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label className="col-form-label">
                                      Business Email:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.email}
                                      onChange={(event) =>
                                        this.setState({
                                          email: event.target.value,
                                        })
                                      }
                                      placeholder="company@mail.com"
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>

                                  <div className="form-group col-md-12">
                                    <label className="col-form-label">
                                      WhatsApp Profile Status:
                                    </label>
                                    <input
                                      className="form-control"
                                      defaultValue={this.state.profile_status}
                                      onChange={(event) =>
                                        this.setState({
                                          profile_status: event.target.value,
                                        })
                                      }
                                      placeholder="The max length for the string is 139 characters."
                                      type="text"
                                      name="emailunsub"
                                      id="flexCheckDefault"
                                    />
                                  </div>

                                  {this.props.match.params.id ? (
                                    <div className="text-center col-md-12 m-auto">
                                      { this.state.account_status === 1 ? 
                                     
                                      <button
                                      type="button"
                                      disabled
                                      className="btn btn-sm btn-primary mt-3"
                                    >
                                      Account Profile Approved
                                    </button>
                                    :

                                    <button
                                      type="button"
                                      onClick={() => this.updateWPTemplate()}
                                      className="btn btn-sm btn-primary mt-3"
                                    >
                                      Update Profile
                                    </button>

                                      }

                                    </div>
                                  ) : (
                                    <div className="text-center col-md-12 m-auto">
                                      <input
                                        type="button"
                                        onClick={() => this.enableWhatsapp()}
                                        className="btn btn-sm btn-primary mt-3"
                                        value="Request Now"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </form>
                            {this.props.match.params.id ? null : (
                              <div className="col-md-4 pl-0 mt-5">
                                <strong className="mb-4 d-block">To create facebook Business Manager ID follow the steps:</strong>
                                <ol style={{ 'lineHeight': 2 }}>
                                  <li>

                                    Create Business manager account by logging
                                    into <a target="_blank" href="https://business.facebook.com/">business.facebook.com</a>
                                  </li>
                                  <li>

                                    Use your fb account and start creating
                                    account by clicking create business account
                                  </li>
                                  <li>

                                    Enter legal entity name and official email
                                    id
                                  </li>
                                  <li> Put legal addresses, phone numbers</li>
                                  <li>

                                    After submitting details. click business
                                    setting and Business info
                                  </li>
                                  <li>

                                    Share Business manager ID so I can create
                                    your whatsapp account
                                  </li>
                                  <li>

                                    You need to go to request `{'>'}` received to
                                    approve Netcore’s request
                                  </li>
                                  <li>

                                    Go to `{'>'}` Security centre `{'>'}` Start verification
                                  </li>
                                  <li>

                                    Facebook will ask legal documents for
                                    verifying Entity name, Address, Phone
                                    number, domain - keep below link documents
                                    handy and upload it
                                  </li>
                                  <li>

                                    Once your account is verified confirm me
                                    back so we will give back whatsapp
                                    confirmation
                                  </li>
                                </ol>
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab> */}
                      <Tab label="With Facebook">
                        <div className="p-5 d-block text-center">
                          <FacebookLogin
                            appId="4884679621657801"
                            textButton="On Boarding With Facebook "
                            autoLoad={false}
                            fields="name,email"
                            scope="whatsapp_business_messaging, business_management, whatsapp_business_management"
                            callback={this.responseFacebook}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default WhatsappEmbed;
