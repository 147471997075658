import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../api/Api';


class TemplateManagement extends Component {

    constructor(props) {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super(props);
        this.state = {
            user_id: userId,
            token: userToken,
            listOfentityID: '',
            template_msg: null,
            template_name: null,
            template_id: null,
            entityid: null,
            senderid: null,
            enable_unicode: 0,
            servicetype: '',
            method: "create",
            tabactive: '',
            isLoading: true,
            errorMessage: '',
            successMessage: false,
            successBg: '',

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fileInput = React.createRef();
    }

    componentDidMount() {
        this.GetListOfEntityID();
        this.EditDltTemplate();
    }

    GetListOfEntityID() {
        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log('listOfentityID', result)
                if (result.success === true) {
                    this.setState({ listOfentityID: result });
                }

            })
        })
    }


    addDltTemplate() {
        document.querySelector('body').scrollTo(0, 0);
        const singleTemplateData = ({ user_id: this.state.user_id, method: 'create', template_name: this.state.template_name, entityid: this.state.entityid, template_id: this.state.template_id, template_message: this.state.template_msg, senderid: this.state.senderid, enable_unicode: this.state.enable_unicode, servicetype: this.state.servicetype, token: this.state.token, });

        // console.log(singleTemplateData);

        fetch(`${HOST_URL}/dlt_template.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(singleTemplateData)
        }).then((result) => {
            result.json().then((result) => {

                if (result.success === true) {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ successBg: "alert alert-success mt-3 alert-dismissible" });
                    setTimeout(function () { window.location = "/dashboard/dlt-template-list"; }, 1000);
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ successBg: "alert alert-danger mt-3 alert-dismissible" });
                }
            })

        })
    }





    updaDltTemplate() {
        document.querySelector('body').scrollTo(0, 0);
        const singleTemplateData = ({ user_id: this.state.user_id, method: 'update', template_name: this.state.template_name, entityid: this.state.entityid, template_id: this.state.template_id, template_message: this.state.template_msg, senderid: this.state.senderid, enable_unicode: this.state.enable_unicode, servicetype: this.state.servicetype, token: this.state.token, id: this.props.match.params.id });

        //console.log(singleTemplateData);

        fetch(`${HOST_URL}/dlt_template.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(singleTemplateData)
        }).then((result) => {
            result.json().then((result) => {

                if (result.success === true) {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ successBg: "alert alert-success mt-3 alert-dismissible" });
                    setTimeout(function () { window.location.reload() }, 1000);
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ successBg: "alert alert-danger mt-3 alert-dismissible" });
                }
            })

        })
    }


    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isLoading: false })
        const data = new FormData()
        data.append('user_id', this.state.user_id)
        data.append('token', this.state.token)
        data.append('entityid', this.state.entityid)
        data.append('method', 'bulkupload')
        data.append('csvfile', this.fileInput.current.files[0])
        // console.log(this.fileInput.current.files[0]);
        fetch(`${HOST_URL}/dlt_template.php`, {
            method: "POST",
            body: data
        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp);
                if (resp.success === true) {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: resp.message, isLoading: true });
                    this.setState({ successBg: "alert alert-success mt-3 alert-dismissible" });
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: resp.message, isLoading: true });
                    this.setState({ successBg: "alert alert-danger mt-3 alert-dismissible" });
                }
            })
        })
    }


    EditDltTemplate() {
        fetch(`${HOST_URL}/dlt_template.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log('edit', result)
                if (result.success === true) {
                    this.setState({
                        entityid: result.data[0].entityid,
                        senderid: result.data[0].senderid,
                        template_id: result.data[0].template_id,
                        template_name: result.data[0].template_name,
                        template_msg: result.data[0].template_msg,
                        enable_unicode: result.data[0].enable_unicode,
                    });
                }
            })
        })
    }



    render() {

        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className=" row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Template </strong> Management </h3>
                                </div>
                            </div>
                            <div className="templatemanagement">
                                <div className="card">
                                    <div className="card-body">

                                        {
                                            this.state.successMessage ?
                                                <div className={this.state.successBg} role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="">
                                                        {this.state.errorMessage}
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        <Tabs selected={this.props.match.params.id ? 'Manually Add Templates' : 'Bulk Templates Upload'} onSelect={(index, label) => console.log(label + ' selected')}>


                                            <Tab label="Bulk Templates Upload">
                                                <form className="mt-3">

                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="input-group drop-down-icons mb-3">


                                                                {
                                                                    this.state.listOfentityID ?

                                                                        <select value={this.state.entityid} onChange={(event) => this.setState({ entityid: event.target.value })} className="form-control">
                                                                            <option value="">Select Entity ID</option>
                                                                            {
                                                                                this.state.listOfentityID.data.map((item, i) =>
                                                                                    <option key={i} value={item.entity_id}>{item.entity_id} ({item.company_name})</option>
                                                                                )}

                                                                        </select>
                                                                        :

                                                                        <div id="addTemplateBox">
                                                                            <label className="form-label"> <FontAwesomeIcon icon={faPlus} color='' />  <Link to="/dashboard/add-entity-id"> Add Entity To Upload Bulk Template</Link>  </label>
                                                                        </div>
                                                                }


                                                            </div>

                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text">AMD CSV File</div>
                                                                </div>
                                                                <input type="file" ref={this.fileInput} className="form-control" />
                                                            </div>

                                                            <p className="mt-2">(upload csv only) Sample <a target="_blank" download href="/sampledlt.csv">  <FontAwesomeIcon icon={faFileCsv} /> Download </a> </p>

                                                            {this.state.isLoading ?
                                                                <div className="col-md-12 ml-auto text-right">
                                                                    <button onClick={this.handleSubmit} className="btn btn-primary btn-sm">
                                                                        <FontAwesomeIcon icon={faUpload} />  Upload
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="col-md-12 ml-auto text-right">
                                                                    <button className="btn btn-sm" type="button">Loading....<span className="spinner-border text-white spinner-border-sm"></span></button>
                                                                </div>
                                                            }

                                                        </div>


                                                        <div className="col-md-4">
                                                            <div className="card">
                                                                <p className="tooglebox"><a className="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="/dashboard/email-template#collapseExample">Note</a></p>
                                                                <ol className="mt-3 template-mng">
                                                                    <li>While you use upload csv then pass sender id is null if you want that template id for all approved sender id</li>
                                                                    <li>During csv upload always use approved sender id other wise you don't use it during sending sms</li>
                                                                    <li>On csv upload don't remove first row of sample file</li>
                                                                </ol>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>

                                            </Tab>
                                            <Tab label="Manually Add Templates">

                                                <div className="row">
                                                    <div className="col-md-8">




                                                        <div className="form-row">

                                                            <div className="input-group drop-down-icons col-md-12 mt-3">

                                                                <select value={this.state.entityid} onChange={(event) => this.setState({ entityid: event.target.value })} className="form-control">
                                                                    {
                                                                        this.state.listOfentityID ?
                                                                            <>
                                                                                <option value="">Select Entity ID</option>
                                                                                {
                                                                                    this.state.listOfentityID.data.map((item, i) =>
                                                                                        <option key={i} value={item.entity_id}>{item.entity_id} ({item.company_name})</option>
                                                                                    )}
                                                                            </>
                                                                            :
                                                                            <option value="">No Entity ID</option>
                                                                    }
                                                                </select>

                                                            </div>


                                                            <div className="input-group col-md-12 mt-3">
                                                                <input disabled={this.props.match.params.id ? 'disabled' : null} type="text" defaultValue={this.state.template_id} onChange={(event) => { this.setState({ template_id: event.target.value }) }} className="form-control mt-2" placeholder="Template ID" />
                                                            </div>

                                                            <div className="input-group col-md-12 mt-3">
                                                                <input type="text" defaultValue={this.state.template_name} onChange={(event) => { this.setState({ template_name: event.target.value }) }} className="form-control mt-2" placeholder="Template Name" />
                                                            </div>

                                                            <div className="input-group col-md-12 mt-3">
                                                                <select className="form-control">
                                                                    <option className="">Select Type</option>
                                                                    <option value="Service-Implicit">Service-Implicit</option>
                                                                    <option className="Service-Explicit">Service-Explicit</option>
                                                                </select>
                                                            </div>


                                                            <div className="input-group col-md-12 mt-3">
                                                                <input type="text" disabled={this.props.match.params.id ? 'disabled' : null} defaultValue={this.state.senderid} onChange={(event) => { this.setState({ senderid: event.target.value }) }} className="form-control mt-2" placeholder="Sender ID" />
                                                            </div>

                                                            <div className="input-group col-md-12 mt-3">
                                                                <textarea rows="5" defaultValue={this.state.template_msg} onChange={(event) => { this.setState({ template_msg: event.target.value }) }} placeholder="Template Message" className="form-control mt-2">
                                                                </textarea>
                                                            </div>

                                                            <div className="input-group col-md-6 mt-3">
                                                                <span className="ml-4 mt-2">
                                                                    <input onChange={(event) => { this.setState({ enable_unicode: event.target.value }) }}
                                                                        value="1"
                                                                        type="checkbox"
                                                                        className="form-check-input mr-2"
                                                                        id="fallback"
                                                                    /> <span className="p-2"> Enable Unicode </span>  </span>

                                                            </div>
                                                        </div>
                                                        {
                                                            this.props.match.params.id ?

                                                                <button type="button" onClick={() => this.updaDltTemplate()} className="btn btn-block mt-3 btn-primary">Update Template</button>

                                                                :

                                                                <button type="button" onClick={() => this.addDltTemplate()} className="btn btn-block mt-3 btn-primary">Add Template</button>

                                                        }



                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="card mt-3">
                                                            <p className="tooglebox"><a className="" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseExample" href="/dashboard/email-template#collapseExample">Note</a></p>
                                                            <ol className="mt-3 template-mng">
                                                                <li>Label mark with * is required parameter</li>
                                                                <li>If you want to make any tempate id for all sender id approved in your account then don't select sender id from sender id dropdown</li>
                                                                <li>If you want that any template message only send from particular sender id then choose sender id from sender id drop down.</li>
                                                                <li>While you use upload csv then pass sender id is null if you want that template id for all approved sender id</li>
                                                                <li>During csv upload always use approved sender id other wise you don't use it during sending sms</li>
                                                                <li>On csv upload don't remove first row of sample file</li>
                                                            </ol>

                                                        </div>
                                                    </div>
                                                </div>



                                            </Tab>
                                        </Tabs>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}
export default TemplateManagement;