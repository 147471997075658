import React, { Component } from "react";
import DashboardHeader from "../../Header/DashboardHeader";
import DashboardFooter from "../../Footer/DashboardFooter";
import DashboardLeftMenu from "../../Dashboard/DashboardLeftMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import loading from "../../Dashboard/assets/img/loading.gif";
import { getUserPriceList } from "../api/Api";
class BillingHistory extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const userId = lStorage.user.userId;
    const userToken = lStorage.token;

    super();

    this.fileNames = {
      csv: "priceList.csv",
    };

    this.state = {
      user_id: userId,
      token: userToken,
      emailCost: "",
      serchData: "",
      fileType: "csv",
      fileDownloadUrl: null,
      priceListData: null,
      successMessage: false,
      errorMessage: "",
      bgColor: "alert alert-success alert-dismissible",
    };
  }

  componentDidMount() {
    this.SearchDataOnLoad();
  }

  SearchDataOnLoad() {
    getUserPriceList().then((result) => {
      if (result.data.success === true) {
        this.setState({ priceListData: result.data });
        this.setState({ emailCost: result.data.email_cost });
      } else {
        this.setState({
          successMessage: true,
          successBg: "alert alert-danger alert-dismissible",
          errorMessage: result.data.message,
        });
      }
    });
  }

  searchUser() {
    if (this.state.serchData === "") {
      this.SearchDataOnLoad();
      this.setState({
        successMessage: true,
        errorMessage: "Enter User Name",
        bgColor: "alert alert-danger alert-dismissible",
      });
    } else {
      getUserPriceList("search", this.state.serchData).then((result) => {
        if (result.data.success === true) {
          this.setState({
            bgColor: "alert alert-success alert-dismissible",
            errorMessage: "Data Updated",
          });
          this.setState({ priceListData: result.data });
          this.setState({ emailCost: result.data.email_cost });
        } else {
          this.setState({
            successMessage: true,
            successBg: "alert alert-danger alert-dismissible",
            errorMessage: result.data.message,
          });
        }
      });
    }
  }

  render() {
    return (
      <div className="wrapper">
        <DashboardLeftMenu />
        <div className="main">
          <DashboardHeader />
          <main className="content">
            <div className="container-fluid p-0">
              <div className=" row mb-2 mb-xl-3">
                <div className="col-auto d-none d-sm-block">
                  <h3>
                    <strong> My Price </strong> List{" "}
                  </h3>
                </div>
              </div>

              {this.state.successMessage ? (
                <div className={this.state.bgColor} role="alert">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="">{this.state.errorMessage}</div>
                </div>
              ) : null}

              <div className="card flex-fill">
                <div className="card-body">
                  <h6 className="card-subtitle text-muted">Filter </h6>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                          type="text"
                          onChange={(event) => {
                            this.setState({
                              serchData: event.target.value,
                            });
                          }}
                          placeholder="Enter Country Code or Country Name"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 text-right">
                      <div className="input-group mb-2 mr-sm-2">
                        <button
                          onClick={() => this.searchUser()}
                          className="btn btn-sm btn-primary btn-block"
                        >
                          {" "}
                          <FontAwesomeIcon icon={faSearch} /> Search{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <a
                    style={{ display: "none" }}
                    download={this.fileNames[this.state.fileType]}
                    href={this.state.fileDownloadUrl}
                    ref={(e) => (this.dofileDownload = e)}
                  >
                    {" "}
                    download it{" "}
                  </a>

                  {this.state.priceListData ? (
                    <div
                      style={{ height: 400 + "px" }}
                      className="table-responsive  mt-3"
                    >
                      <table className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th className="d-xl-table-cell">Country</th>
                            <th className="d-xl-table-cell">
                              Currency / SMS Cost
                            </th>
                            <th className="d-xl-table-cell">Email Cost</th>
                            <th className="d-xl-table-cell">Wp conv cost</th>
                            <th className="d-xl-table-cell">WP sending cost</th>
                            <th className="d-xl-table-cell">Email Cost</th>
                            <th className="d-xl-table-cell">Voice Cost</th>
                            <th>Voice Pulse</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.priceListData.data.map((item, i) => (
                            <tr>
                              <td>
                                {item.country_name} ({item.country_code})
                              </td>
                              <td>
                                {item.currency} :{" "}
                                {parseFloat(item.sms_cost).toFixed(6)}
                              </td>
                              <td>
                                {parseFloat(this.state.emailCost).toFixed(3)}
                              </td>
                              <td>{item.wp_conv_cost}</td>
                              <td>{item.wp_sending_cost}</td>

                              {item.country_name === "India" ? (
                                <td>
                                  Rs.
                                  {parseFloat(item.voice_cost).toFixed(3)}
                                </td>
                              ) : (
                                <td>NA</td>
                              )}

                              {item.country_name === "India" ? (
                                <td>{item.voice_pulse} Sec</td>
                              ) : (
                                <td>NA</td>
                              )}
                              <td>{item.voice_pulse}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="card-body">
                        <img
                          alt="Empty Data"
                          style={{ width: 100 + "px" }}
                          className="project-empty-img"
                          src={loading}
                        />
                        <p>Loading...... </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
          <DashboardFooter />
        </div>
      </div>
    );
  }
}

export default BillingHistory;