import React, { Component } from "react";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
// import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
// import { WHATSAPP_ACCOUNT_LIST } from "../../UI/Table/ColumnConst";
import { HOST_URL, WhstappAccountList } from "../api/Api";
// import {
//   faEdit,
//   faSignInAlt,
//   faUserLock,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Axios from "axios";
import Dialog from "react-dialog";
import JSONPretty from "react-json-pretty";

class WhatsappNumberList extends Component {
  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      whatsappNumber: [],
      setIsLoading: true,
      ErrorResponse: "",
      route: "",
      dialogOpen: false,
      fbauthToken:
        "EABFalxmwxMkBAKPaaVqUHZABaCldGzuIUFOFEQDjoMqJDJFSZCctsq57ky2p29ma7CMZCd7vWZBFVAfXUnIOYhKkUc66RtTQpsREj6EEmKyLNwvMHZANAvDcbKsUOs3uHTiLrecZCbUdpU6cVQZCvQ3SYrDO5aouwcUZCyNJQU0JAQrQaPy3VlYU2wKxfNdGEbiGVimS2IIYoAZDZD",
      fbres: [],
    };
  }

  componentDidMount() {
    this.getWhatsaAppNumberList();
    this.fetchRoute();
  }

  getWhatsaAppNumberList = async () => {
    WhstappAccountList().then((res) => {
      if (res.data.success === true) {
        this.setState({ whatsappNumber: res.data.data, setIsLoading: false });
      } else {
        this.setState({ ErrorResponse: res.data, setIsLoading: false });
      }
    });
  };

  healthStatus = async (phone_no_id) => {
    try {
      // if(!this.state.whatsappNumber.phone_number_id){
      //     return;
      // }
      const response = await fetch(`https://graph.facebook.com/v20.0/${phone_no_id}?fields=health_status`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.state.fbauthToken}`,
        },
      });
      const data = await response.json(); 
      this.setState({ fbres: data });
    } catch (error) {
      console.log(error.message);
    }
  };

  fetchRoute = () => {
    try {
      Axios.get(
        `${HOST_URL}/get_user_route.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`
      )
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              route: res.data.data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  ACDetails = (phone_no_id) => {
    if (this.state.route === 25) {
      this.setState({ dialogOpen: true });
      this.healthStatus(phone_no_id);
    }
  };
  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };
  render() {
    console.log(this.state.fbres.error);
    
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={this.state.whatsappNumber.length > 0 ? false : true}
              btnName={`Enable WhatsAPP Service `}
              actionURL={`/dashboard/whatsapp-onboarding-new`}
            >
              {" "}
              WhatsApp Business{" "}
            </Title>

            {this.state.dialogOpen && (
              <Dialog
                modal={true}
                title="Response Status"
                isDraggable={true}
                onClose={this.handleCloseDialog}
                maxWidth={"md"}
              >
                {this.state.fbres.error?<JSONPretty id="json-pretty" data={this.state.fbres.error}></JSONPretty>:<table className="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>Over All Status</td>
                      <td>{this.state.fbres.health_status?.can_send_message}</td>
                    </tr>
                    {this.state.fbres.health_status?.entities?.map((entity) => (
                      <>
                        <tr key={entity.id} >
                          <td>
                            {entity.entity_type} Status (Id : {entity.id})
                          </td>
                          <td>{entity.can_send_message}</td>
                        </tr>
                        {entity.errors && (
                          <div>
                            <h4>Errors</h4>
                            {entity.errors?.map((error, index) => (
                              <div key={index}>
                                <tr>
                                  <td>Error Code:</td>
                                  <td>{error.error_code}</td>
                                </tr>
                                <tr>
                                  <td>Description: </td>
                                  <td>{error.error_description}</td>
                                </tr>
                                <tr>
                                  <td>Possible Solution: </td>
                                  <td>{error.possible_solution}</td>
                                </tr>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>}
              </Dialog>
            )}

            {/* <AdvanceDataTable
              tableName={"Enable WhatsAPP Service"}
              tableCell={WHATSAPP_ACCOUNT_LIST}
              tableData={this.state.whatsappNumber}
              isLoading={this.state.setIsLoading}
              isError={this.state.ErrorResponse}
            /> */}

            <div className="table-responsive">
              <table className="table table-striped table-xs table-sm my-0">
                <thead>
                  <tr>
                    <th> ID</th>
                    <th> Company Name</th>
                    <th> Account Status</th>
                    <th> FB Manager ID </th>
                    <th> WhatsApp Number </th>
                    <th>Daily Sending Limit</th>
                    <th>Daily Used</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.whatsappNumber?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.id}</td>
                      <td>{item.comp_name}</td>
                      <td>
                        {" "}
                        <div
                          onClick={() => {
                            if (
                              this.state.route === 25 &&
                              item.phone_number_id
                            ) {
                              this.ACDetails(item.phone_number_id);
                            }
                          }}
                        >
                          {item.account_status === 1 ? (
                            <button
                              className="badge bg-primary mr-2"
                              style={{ border: "none" }}
                            >
                              {this.state.route === 25?"View":"Green"}
                              
                            </button>
                          ) : item.account_status === 2 ? (
                            <button
                              className="badge bg-info mr-2"
                              style={{ border: "none" }}
                            >
                             {this.state.route === 25?"View":"Yellow"}
                            </button>
                          ) : item.account_status === 3 ? (
                            <button
                              className="badge bg-danger mr-2"
                              style={{ border: "none" }}
                            >
                              {this.state.route === 25?"View":"Red"}
                            </button>
                          ) : (
                            <button
                              className="badge badge-info mr-2"
                              style={{ border: "none" }}
                            >
                              {" "}
                              N/A{" "}
                            </button>
                          )}{" "}
                        </div>
                      </td>
                      <td>{item.fb_businness_id}</td>
                      <td>{item.brand_number}</td>
                      <td>{item.daily_sending_limit}</td>
                      <td>{item.daily_used}</td>
                      <td>
                        <>
                          {item.status === 0 ? (
                            <span className="badge bg-warning mr-2">
                              {" "}
                              Pending{" "}
                            </span>
                          ) : (
                            <span className="badge bg-primary mr-2">
                              {" "}
                              Approved{" "}
                            </span>
                          )}
                        </>
                      </td>
                      <td>
                        <>
                          <Link to={`whatsapp/edit/${item.id}`}>
                            <span className="badge bg-success mr-2">
                              {" "}
                              Edit / Update{" "}
                            </span>{" "}
                          </Link>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default WhatsappNumberList;
