import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faHourglass } from '@fortawesome/free-solid-svg-icons';
import { checkUserUrl } from '../../Utils/Utils';
import { HOST_URL } from '../api/Api';
class TransactionHistory extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;
        const url = lStorage.url;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            url: url,
            paymentTransactionList: null,
            successMessage: false,
            errorMessage: '',
            bgColor: "alert alert-success alert-dismissible",
        }
    }

    componentDidMount() {
        fetch(`${HOST_URL}/transaction_history.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //   console.log(result)
                if (result.success === true) {
                    this.setState({ paymentTransactionList: result });
                } else {
                    this.setState({
                        successMessage: true,
                        successBg: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    render() {
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Last Transaction </strong> Details </h3>
                                </div>
                            </div>
                            <div className="row">



                                <div className="card">
                                    <div className="">
                                        <h5 className="card-title mt-4">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        {checkUserUrl(this.state.url) &&
                                                            <Link to="transaction-payment"> <li className="breadcrumb-item  "> Recharge </li> </Link>
                                                        }
                                                        <Link to="transaction-history"><li className="breadcrumb-item active">Transaction History </li> </Link>

                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className=" flex-fill">


                                                    {this.state.paymentTransactionList ?


                                                        <div className="table-responsive">
                                                            <table className="table table-striped table-xs table-sm my-0">
                                                                <thead>
                                                                    <tr>

                                                                        <th className="d-xl-table-cell">Amount</th>
                                                                        <th className="d-xl-table-cell">Status</th>
                                                                        <th>Transaction ID</th>
                                                                        <th className="d-md-table-cell">Date / Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        this.state.paymentTransactionList.data.map((item, i) =>
                                                                            <tr>
                                                                                <td>{item.amount}</td>

                                                                                <td>
                                                                                    {
                                                                                        item.status === 1 ?
                                                                                            <span className="badge bg-success"> <FontAwesomeIcon icon={faCheckCircle} color='#fff' /> Success </span>
                                                                                            :

                                                                                            item.status === 0 ?
                                                                                                <>
                                                                                                    <span className="badge bg-info"> <FontAwesomeIcon icon={faHourglass} color='#fff' />  Pending  </span>
                                                                                                </>
                                                                                                :
                                                                                                <span className="badge bg-danger"> <FontAwesomeIcon icon={faTimesCircle} color='#fff' />  Failed    </span>
                                                                                    }


                                                                                </td>


                                                                                <td>{item.transaction_id}</td>
                                                                                <td>{item.created}</td>

                                                                            </tr>

                                                                        )
                                                                    }


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <div className="text-center">
                                                            <div className="card-body">
                                                                <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                                                                <p>NO transaction Details Found </p>

                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default TransactionHistory;