import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons' ;
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL } from '../api/Api';

class RcsReport extends Component {

constructor () {

const lStorage = JSON.parse(localStorage.getItem("login"));
const userId= lStorage.user.userId;
const userToken= lStorage.token;

var today = new Date(),
date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);


super();
    this.state = {
        user_id:userId,
        token:userToken,
        email:'',
        department:'',
        projectList:'',
		TodayDate: date,
		dateFrom:date,
		dateTo:date,
		SelecteddateFrom:'',
		SelecteddateTo:'',
        messageBody:'',
        parent_id:'',
        errorMessage:'',
        successMessage:false,
        bgColor:''
    }
}

componentDidMount() {
    fetch(`${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            // console.log(result);
            if(result.success===true){
            this.setState({projectList:result});
            }
        })
    })

}
dateFrom = (date) => {
	let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
	 this.setState({
		 dateFrom: dated,
		   SelecteddateFrom:date
	 });
 };

 dateTo = (date) => {
	 let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
	  this.setState({
		  dateTo: dated,
		  SelecteddateTo:date
	  });
  };


render() {
	document.querySelector('body').scrollTo(0, 0);
    return (
<div className="wrapper">
    <DashboardLeftMenu />
        <div className="main">
            <DashboardHeader />
            <main className="content mt-0">
				<div className="container-fluid p-0">

					<div className=" mb-2 mb-xl-3">
							<div className="">
								<h3 className="mb-3"><strong>RCS Report </strong> </h3>
							</div>
					</div>

					<div className="card mt-3">


						<div className="">
							<h5 className="card-title mt-5">
								<div className="col-auto ml-auto text-right mt-n1">
									<nav className="text-center" aria-label="breadcrumb">
										<ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
											<Link to="/dashboard/rcs-reports"><li className="breadcrumb-item active"> Today's Summary </li> </Link>
											<Link to='/dashboard/rcs-details-reports'> <li className="breadcrumb-item "> Detailed Reports </li> </Link>

										</ol>
									</nav>
								</div>
							</h5>
						</div>


					<div className="card-body">
						<div className="row">
						<div className="input-group col-md-4 mb-2">
										 <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
									   <DatePicker className="form-control btn-block"
										   dateFormat="yyyy/MM/dd" 
										   value={this.state.dateFrom}
										   onChange={this.dateFrom}
										   selected={this.state.SelecteddateFrom}
										   placeholderText='Date From'
										   /> 

								   </div>

								   <div className="input-group col-md-4 mb-2">
										 <div className="input-group-text"><FontAwesomeIcon icon={faCalendarAlt} /></div>
										 <DatePicker className="form-control btn-block"
										   dateFormat="yyyy/MM/dd"
										   value={this.state.dateTo}
										   onChange={this.dateTo}
										   selected={this.state.SelecteddateTo}
										   placeholderText='Date To'
										   
										   /> 
								   </div>


								   <div className="col-md-4">
									   <button type="button" onClick={()=> this.fiterDatewiseData()}  className="btn-primary btn-block btn-sm ml-auto"> <FontAwesomeIcon icon={faSearch}/> Filter </button>
								   </div>
						   </div>
						</div>

						<hr className="m-0" />
						<div className="card-body">
								<h3 className="m-0">Overview</h3>
						</div>


						
						<hr className="m-0"/>
						<div className="">

								<div className="row">
									<div className="col-md-2 text-center border-right">
										<div className="card-body">
											<h2 className="m-0 text-success"><strong></strong> 40 </h2>
											<h4 className="text-muted text-success font-weight-bold">Total Chat</h4>
										</div>	
									</div>

									<div className="col-md-2 text-center border-right">
										<div className="card-body ">
											<h2 className="m-0 text-danger"><strong></strong> 40 </h2>
											<h4 className="text-muted text-danger font-weight-bold">Pending</h4>
										</div>	
									</div>

									<div className="col-md-2 text-center border-right">
										<div className="card-body ">
											<h2 className="m-0 text-info"><strong></strong> 23 </h2>
											<h4 className="text-muted text-info font-weight-bold">Visitors</h4>
										</div>	
									</div>

									<div className="col-md-2 text-center border-right">
										<div className="card-body ">
											<h2 className="m-0 text-primary"><strong></strong> 234 </h2>
											<h4 className="text-muted text-primary font-weight-bold">Page Views</h4>
										</div>	
									</div>

									<div className="col-md-2 text-center border-right">
										<div className="card-body">
											<h2 className="m-0"><strong></strong> 40 </h2>
											<h4 className="text-muted font-weight-bold">Message</h4>
										</div>	
									</div>

									<div className="col-md-2 text-center">
										<div className="card-body">
											<h2 className="m-0 text-warning"><strong></strong> 40 </h2>
											<h4 className="text-muted  text-warning font-weight-bold">Reporting</h4>
										</div>	
									</div>
								</div>			
                         </div>

						


						<div className="">
						<div className="col-md-12">
							<div className="card">
								<div className="card-body">
									<h4 className="summary-title">Chat Analysis</h4>

									<div className="d-flex summary-data text-center">
										<div className="mb-1">
											<span className="text-muted"> Message  </span> <br />
											<span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> 13 </span> </div>

											<div className="mb-1">
											<span className="text-muted"> links  </span> <br />
											<span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> 43 </span> </div>

										<div className="mb-1">
											<span className="text-muted"> Users  </span> <br />
											<span className="textvalue"> <FontAwesomeIcon icon={faArrowUp} color="green" size='1x' /> 976 </span> </div>
										<div className="mb-1">
											<span className="text-muted"> Opened  </span>
											<br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> 9 </span> </div>

										<div className="mb-1">
											<span className="text-muted"> Pending  </span>
											<br /> <span className="textvalue"> <FontAwesomeIcon icon={faArrowDown} color="red" size='1x' /> 90 </span> </div>
									</div>
								</div>
							</div>
                        </div>
					</div>
				</div>
				</div>
			</main>
        <DashboardFooter />
    </div>
</div>
        );
    }
}

export default RcsReport;