import React, { Component } from "react";
import { CAMPAIGN_CONTACT_LIST_API } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import AdvanceDataTable from "../../UI/Table/AdvanceDataTable";
import { CAMPAIGN_CONTACT_LIST,CAMPAIGN_FILTER_CONTACT_LIST } from "../../UI/Table/ColumnConst";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import Alert from "../../UI/Alert/Alert";

class ContactList extends Component {

  constructor() {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super();

    this.state = {
      userId: user_id,
      token: token,
      ContactListData: [],
      setIsLoading: true,
      searchDataTable:false,
      filterContact:'',
      isError:false,
      messageType:'',
      errorMessage:''
    };
  }

  componentDidMount() {
    this.getWhatsaAppNumberList();
  }

  getWhatsaAppNumberList = async () => {
    try {
      const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "retrieve",
      });
      //console.log(data);
      if (data.success === true) {
        this.setState({ ContactListData: data.data, setIsLoading: false });
      } else {
        this.setState({ errorMessage: data.message, isError:true, messageType:'alert-danger', setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  filterData = async () => {
    this.setState({isError:false})
    try {
      const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "filter",
        mobile:this.state.filterContact
      });
      console.log(data);
      if (data.success === true) {
        this.setState({ ContactListData: data.data, setIsLoading: false, searchDataTable:true});
      } else {
        this.setState({ errorMessage: data.message, isError:true, messageType:'alert-danger', setIsLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  resetFilter = () => {
    this.getWhatsaAppNumberList();
    this.setState({
      searchDataTable:false,
      filterContact:'',
    })
  }

  render() {
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={true}
              btnName={`Create New Contact List `}
              actionURL={`/dashboard/create-contact-list`}
            >
              Campaign Contact List
            </Title>

            {this.state.isError && <Alert type={this.state.messageType}>{this.state.errorMessage}</Alert>}

            <div className="card">
                <div className="filter-option">
                <h6 className="card-subtitle text-muted">Filter </h6>
                <div className="row">
                    <div className="col-md-8">
                    <div className="input-group mb-2 mr-sm-2">
                        <div className="input-group-text">
                        <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <input
                        type="text"
                        onChange={(event) => {
                            this.setState({ filterContact: event.target.value });
                        }}
                        value={this.state.filterContact}
                        placeholder="Enter mobile number"
                        className="form-control"
                        />
                    </div>
                    </div>
                    <div className="col-md-2">
                    <div className="input-group mb-2 mr-sm-2">
                        <input
                        type="button"
                        onClick={() => this.filterData()}
                        value="Search"
                        className="btn mt-1  btn-primary btn-sm btn-block ml-auto "
                        />
                    </div>
                    </div>
                    <div className="col-md-2">
                    <div className="input-group mb-2 mr-sm-2">
                      <button onClick={this.resetFilter} className="btn mt-1  btn-danger btn-sm btn-block ml-auto ">Reset Filter</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            {!this.state.searchDataTable ?
            <AdvanceDataTable
              tableName={"Contact list"}
              tableCell={CAMPAIGN_CONTACT_LIST}
              tableData={this.state.ContactListData}
              isLoading={this.state.setIsLoading}
              isError={this.state.ErrorResponse}
            />
            :
            <AdvanceDataTable
              tableName={`Search Data ${this.state.filterContact}`}
              tableCell={CAMPAIGN_FILTER_CONTACT_LIST}
              tableData={this.state.ContactListData}
              isLoading={this.state.setIsLoading}
              isError={this.state.ErrorResponse}
            />
             }

          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default ContactList;
