import React, { Component } from "react";
import { CAMPAIGN_REPORT } from "../../../constants/ServiceConstants";
import api from "../../../axios/Axios";
import { Link } from "react-router-dom";
import DashboardLayout from "../../UI/Layout/DashboardLayout";
import Title from "../../UI/Text/Title";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../../UI/Alert/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from "../api/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
class CampaignSummayReport extends Component {
  constructor(props) {
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;
    super(props);

    this.state = {
      userId: user_id,
      token: token,
      summayReport: [],
      channel: this.props.match.params.channel,
      id: this.props.match.params.id,
      campaignName: this.props.match.params.campaignName,
      setIsLoading: true,
      searchDataTable: false,
      filterContact: "",
      isError: false,
      messageType: "",
      errorMessage: "",
    };
  }
  componentDidMount() {
    this.getSummaryReport();
  }

  getSummaryReport = async () => {
    try {
      const { data } = await api.post(CAMPAIGN_REPORT, {
        token: this.state.token,
        user_id: this.state.userId,
        method: "report_summary",
        channel: this.state.channel,
        campaign_id: this.state.id,
      });
      console.log(data);
      if (data.success === true) {
        this.setState({ summayReport: data.data, setIsLoading: false });
      } else {
        this.setState({
          errorMessage: data.message,
          isError: true,
          messageType: "alert-danger",
          setIsLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  dataDownload() {
    window.location.href = `${HOST_URL}/bulk_campaign.php?user_id=${this.state.userId}&method=download&campaign_id=${this.state.id}&channel=${this.state.channel}&token=${this.state.token}`;
  }

  render() {
    
    return (
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <Title
              isButton={false}
              btnName={`Create New Contact List `}
              actionURL={`/dashboard/create-contact-list`}
            >
              Campaign Reports
            </Title>

            {this.state.isError && (
              <Alert type={this.state.messageType}>
                {this.state.errorMessage}
              </Alert>
            )}

            <div className="card">
              <h3 className="pl-3 pt-3"><span className="text-success">Campaign Name / ID : </span> {this.state.campaignName} / {this.state.id}</h3>
              <hr className="mt-0" />
              <div className="">
                <h5 className="card-title">
                  <div className="col-auto ml-auto text-right mt-n1">
                    <nav className="text-center" aria-label="breadcrumb">
                      <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                        <a href={`/dashboard/campaign/summary-report/${this.state.channel}/${this.state.id}/${encodeURIComponent(this.state.campaignName)}`}>
                          <li className="breadcrumb-item active">
                            Today's Summary
                          </li>
                        </a>
                        <a
                          href={`/dashboard/campaign/detailed-report/${this.state.channel}/${this.state.id}/${encodeURIComponent(this.state.campaignName)}`}
                        >
                          <li className="breadcrumb-item ">Detailed Reports</li>
                        </a>
                      </ol>
                    </nav>
                  </div>
                </h5>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="summary-title">{this.state.channel}</h4>

                        {this.state.summayReport ? (
                          <div className="d-flex summary-data text-center">
                            {Object.entries(this.state.summayReport).map(
                              ([key, value],i) => {
                                return (
                                  <div className="mb-1" key={i}>
                                    <span className="text-muted">
                                      {key}
                                    </span>
                                    <br />
                                    <a
                                      href={`/dashboard/campaign/detailed-report/${this.state.channel}/${this.state.id}/${encodeURIComponent(this.state.campaignName)}`}
                                    >
                                      <span className="text-success">
                                        {value}
                                      </span>
                                    </a>
                                  </div>
                                );
                              }
                            )}
                            <div className="mb-1">
                              <span className="text-muted">
                                Download
                              </span>
                              <br />
                              <span onClick={()=> this.dataDownload()} className="text-success">
                              <Link  className="text-success"> <FontAwesomeIcon  icon={faDownload} /> </Link> 
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    );
  }
}

export default CampaignSummayReport;
