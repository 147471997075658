import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import { HOST_URL } from '../api/Api';

class CreateDirectEvent extends Component {

	constructor() {

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

		super();
		this.state={
			user_id:userId,
            token:userToken,
            projectid:'',
			projectList:null,
			website:null,
			method:"create",
            successMessage:false,
            errorMessage:'',
			bgColor:"alert alert-success alert-dismissible"
		}
	}


    componentDidMount() {
        this.GetProjectList();
    }

    GetProjectList(){
        fetch(`${HOST_URL}/project_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
            response.json().then((result)=>{
                //console.log(result)
                if(result.success===true){
                    this.setState({projectList:result});
                }
                
            })
        })
    }

goToEvent(e) {
    const SelectedProjectId = this.state.projectid;
    if(this.state.projectid===''){
        this.setState({successMessage:true,errorMessage:'Please Select One Projet ',bgColor:"alert alert-danger alert-dismissible"});
    }else{
    this.props.history.push('/dashboard/create-new-event/'+SelectedProjectId);
    }
}

	render() {    
		return (

			<div className="wrapper">
			<DashboardLeftMenu />
			  <div className="main">
				 <DashboardHeader />
				 
				 <main className="content">
	<div className="container-fluid p-0">
		<div className="row mb-2 mb-xl-3">
			<div className="col-auto d-none d-sm-block">
				<h3><strong>Create New</strong> Event </h3>
			</div>
		</div>
		<div className="row justify-content-center">
			<div className="col-md-8">
				<div className="card">
					<div className="card-body">


					{
                     this.state.successMessage? 
							<div className={this.state.bgColor} role="alert">
					<button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
						<div className="">
								{this.state.errorMessage}
							</div>
						</div>
                            :
                            null
                        }

						<form>
							<div className="row justify-content-center">
								<div className="mb-3 col-md-12">
									<div className="text-center">
									<p> Select the project which one you want to create event </p>
									</div>
									
                                      {
														
                                        this.state.projectList?
                                                <select onChange={(event)=>
                                                        {this.setState({projectid:event.target.value}) }} className="form-control">
                                                <option value="">Select Project</option>
                                                {
                                                this.state.projectList.data.map((item,i)=>
                                                <option  key={i} value={item.id}> {item.project_name}</option>
                                                )}
                                                </select>
                                                :
                                                <div id="addTemplateBox m-auto">  
                                        <label className="form-label"> <FontAwesomeIcon icon={faPlus} color=''/>  No Project Found,  <Link to="/dashboard/create-project">Create New Project </Link>  </label>
                                            </div>
                                        }
        

									<button type="button" onClick={()=>this.goToEvent()} className="btn btn-block mt-3 btn-primary">Create Event</button> 

								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>

				 <DashboardFooter />
			 </div>
		 </div>
  
    );
}

}
 
export default CreateDirectEvent;