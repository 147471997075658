import React, {Component} from 'react';
import  { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCalendarCheck} from '@fortawesome/free-solid-svg-icons'
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from "suneditor-react";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HOST_URL } from '../api/Api';


class EmailCampaign extends Component {

	constructor(){

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;
		var today = new Date(),
		date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + ("0" + today.getDate()).slice(-2);
		super();
			this.state={
				user_id:userId,
				token:userToken,
				campaign_name:'',
				mid:'',
				template_name:null,
				AllEmailStatus:null,
				SelectedEmailStatus:null,
				TodayDate: date,
				trigger_time:'',
				trigger_date:'',
				dateFrom:date,
				EmailTemplateList:'',
				from_email:null,
				subject:null,
				content:null,
				EmailUnsubscribe:'',
				successMessage:false,
				successBg:'',
				errorMessage:''
			}
			this.emailEditorRef= React.createRef();
			this.getEmailTemplateDetails = this.getEmailTemplateDetails.bind(this);

	}



	
componentDidMount() {
	    this.GetEmailTemplateList();
		this.GetEmailStatus();
		this.FatchEmailCampaing();
		this.EditEmailTemplateDetails();
}
	


GetEmailStatus(){
    fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
			// console.log('result', result)
            if(result.success===true){
                this.setState({AllEmailStatus:result.data});
            }else{
				this.setState({AllEmailStatus:true});
			}
           
        })
    })
}

GetEmailTemplateList(){
	fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
		response.json().then((result)=>{
			// console.log(result)
			if(result.success===true){
				this.setState({EmailTemplateList:result});
			}
		   
		})
	})
}



getEmailTemplateDetails(e){

	this.setState({mid:e.target.value})

	fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&id=${e.target.value}&method=retrieveid&token=${this.state.token}`).then((response) => {
		response.json().then((result) => {
			// console.log(result)
			if(result.success===true){
				this.setState({
					from_email:result.data[0].from_email,
					subject:result.data[0].subject,
					content:result.data[0].content,
				
				});
			}
		})
	})
}


EditEmailTemplateDetails(){

	fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`).then((response) => {
		response.json().then((result) => {
			// console.log(result)
			if(result.success===true){
				this.setState({
					template_name:result.data[0].template_name,
					from_email:result.data[0].from_email,
					subject:result.data[0].subject,
					content:result.data[0].content,
				
				});
			}
		})
	})
}




dateFrom = (date) => {
	let  dated = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + ("0" + date.getDate()).slice(-2);
	let  time  = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
	 this.setState({
		 trigger_date: dated,
		 trigger_time: time,
		 SelecteddateFrom:date
	 });
 };


ActiveEmailCampaning() {
	document.querySelector('body').scrollTo(0, 0);
	fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&method=create&token=${this.state.token}&campaign_name=${this.state.campaign_name}&mid=${this.state.mid}&list_condition=${this.state.SelectedEmailStatus}&trigger_date=${this.state.trigger_date}&trigger_time=${this.state.trigger_time}`).then((response) => {
		response.json().then((result) => {
			   //console.log(result)
			if (result.success === true) {
				this.setState({
					successMessage: true,
					successBg: "alert alert-success alert-dismissible",
					errorMessage: result.message
				})
				setTimeout(function(){window.location = "/dashboard/email-campaning-list";}, 1000);
				
			} else {
				this.setState({
					successMessage: true,
					successBg: "alert alert-danger alert-dismissible",
					errorMessage: result.message
				})
			}
		})
	})
}

FatchEmailCampaing(){
	fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`).then((response) => {
		response.json().then((result) => {
			//console.log('result', result)
			if (result.success === true) {
				this.setState({ 
					campaign_name: result.data[0].campaign_name, 
					list_condition: result.data[0].list_condition,
					trigger_date: result.data[0].trigger_date,
					trigger_time: result.data[0].trigger_time,
					mid: result.data[0].mid,
				});
			}
		})
	})
}


updateEmailCampaign() {
	document.querySelector('body').scrollTo(0, 0);
	fetch(`${HOST_URL}/email_campaign.php?user_id=${this.state.user_id}&method=update&token=${this.state.token}&campaign_name=${this.state.campaign_name}&mid=${this.state.mid}&list_condition=${this.state.SelectedEmailStatus}&trigger_date=${this.state.trigger_date}&trigger_time=${this.state.trigger_time}&id=${this.props.match.params.id}`).then((response) => {
		response.json().then((result) => {
			 //  console.log(result)
			if (result.success === true) {
				this.setState({
					successMessage: true,
					successBg: "alert alert-success alert-dismissible",
					errorMessage: result.message
				})
				setTimeout(function(){window.location = "/dashboard/email-campaning-list";}, 1000);
				
			} else {
				this.setState({
					successMessage: true,
					successBg: "alert alert-danger alert-dismissible",
					errorMessage: result.message
				})
			}
		})
	})
}



render() {
	// console.log(this.state)
	return (
		<div className="wrapper">
			<DashboardLeftMenu />
			<div className="main">
				<DashboardHeader />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="row mb-2 mb-xl-3">
							<div className="col-auto d-none d-sm-block">
								<h3><strong> Create New Autometed Email </strong>  Campaign  </h3>
							</div>
						</div>
						<div className="card">
									<div className="card-body  template-link">
						<div className="row">
							<div className="col-md-12">

							{
									this.state.successMessage? 
									<div className={this.state.successBg} role="alert">
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
						</button>
								{this.state.errorMessage}
									</div>
									:
									null
								}

										<form id="EmailTemplateForm">
											<div className="row justify-content-center">
												<div className="mb-3 col-md-12">
													<div className="text-left">
														<h3 className="font-weight-bold mb-4"> Email Campain Name </h3>
													</div>

													

													<input type="text" defaultValue={this.state.campaign_name}  onChange={(event)=>
												{this.setState({campaign_name:event.target.value}) }} className="form-control" id="inputEmail4" placeholder="Enter Campaign Name" />



                                                <div className="input-group drop-down-icons   mt-3">		
													
												{
														
														this.state.EmailTemplateList?
		
														 <select value={this.state.mid}
														 onChange={this.getEmailTemplateDetails} className="form-control">
														 <option value=""> Select Email Template </option>
														 {
														   this.state.EmailTemplateList.data.map((item,i)=>
															<option  key={i} value={item.id}> {item.template_name}</option>
														 )}
														 </select>
														 :
												    <div id="addTemplateBox">
													<label className="form-label"> You Don't have Email template  <Link to="/dashboard/email-template"> Create New </Link> <FontAwesomeIcon icon={faArrowRight} color=''/>  </label>
														</div>
												}

												</div>


											

												   <div className="form-group mt-3">
														<input type="text" readOnly defaultValue={this.state.from_email} className="form-control" id="inputEmail4" placeholder="Sender From Email" />
													</div>

													<div className="form-group mt-3">
														<input type="text" readOnly defaultValue={this.state.subject} className="form-control" id="inputEmail4" placeholder="Email Subject Messgae" />
													</div>
												
													<SunEditor setContents={this.state.content} disable={true} height="300" onChange={this.handleChange}   
													placeholder="Please type here..." />
											

                                                    

                                                <hr className="mb-3" />

                                                <div className="text-left mb-4">
														<h3 className="font-weight-bold mb-0"> Sending Condition Emails</h3>
                                                        <small>Send Trigger Email based on action on this email</small>
												</div>
                                                <div style={{background:'#f8f8f8',padding:10+'px',}} className="pricing email-camp">
												{
												this.state.AllEmailStatus?

                                                    <Tabs>
												{

													this.state.AllEmailStatus.map((itam,i)=>

                                                        <Tab label={itam.status}>
                                                        <div className="input-group mt-3">		

														<div className="form-check-inline">
														<label className="form-check-label">
														<p> <input type="radio" defaultValue={itam.status} 
														  onChange={(event)=>
															{this.setState({SelectedEmailStatus:event.target.value}) }}
														className="form-check-input" name={i} /> Numbers Of Email <strong> {itam.cnt} </strong>  </p>
														</label>
														</div>

														</div>
                                                        </Tab>
														)
													}
                                                    </Tabs>
												:
												null
												}
																									
                                                </div>


                                                <hr className="mb-3" />

                                                <div className="text-left">
														<h3 className="font-weight-bold mb-2"> Date and Time For Sending Emails</h3>
												</div>

                                                <div className="form-row">

												<div className="col-md-6"> 
                                                    <div className="input-group mb-2 mr-sm-2">
                                                        <div className="input-group-text"><FontAwesomeIcon icon={faCalendarCheck} /></div>
                                                        <DatePicker className="form-control btn-block"
														   selected={this.state.SelecteddateFrom}
														   onChange={this.dateFrom}
														   minDate={new Date()}
														   timeInputLabel="Time:"
														   dateFormat="MM/dd/yyyy h:mm aa"
														   showTimeInput
														   showDisabledMonthNavigation
                                                        /> 
                                                    </div>
                                                </div>

												

                                              
                                                </div>


												{this.props.match.params.id?

													<button type="button" 
													onClick={()=>{this.updateEmailCampaign()}} 
													className="btn btn-sm  mt-5 btn-info">Update </button>
												
												:

												<>
													<button type="button" onClick={()=>{this.ActiveEmailCampaning()}} className="btn btn-sm  mt-5 btn-primary">Save Email Template</button>

													<button type="button" onClick={()=>{this.ActiveEmailCampaning()}} className="btn btn-sm  mt-5 btn-info">Send Now</button>
												</>

												

												}

												</div>
											</div>
										</form>
									</div>
							</div>
							</div>
						</div>
					</div>
				</main>
				<DashboardFooter />
			</div>
		</div>
	);
  }
}

export default EmailCampaign;