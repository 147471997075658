import React from 'react';
import Dialog from 'react-dialog';
import { useState } from 'react';
import { WHATSAPP_TEMPLATE_LIST } from '../../../../../constants/ServiceConstants';
import api from '../../../../../axios/Axios';

function Delete(props) {

    const [isDialogOpen, setisDialogOpen] = useState(false);
    const id = props.id;
    const name = props.name;
    const lStorage = JSON.parse(localStorage.getItem("login"));
    const user_id = lStorage.user.userId;
    const token = lStorage.token;

    const openDialog = (e) => {
        setisDialogOpen(true);
    }

    const handleClose = () => {
        setisDialogOpen(false)
    }

    const deleteWPTemplate = async () => {
    console.log('deleteWPTemplate');
        try {
            const { data } = await api.post(WHATSAPP_TEMPLATE_LIST, {
                token : token,
                user_id : user_id,
                temp_id : id,
                temp_name : name,
                method: 'delete',
            });
            if (data.success === true) {
                window.location.reload();
                setisDialogOpen(false)
            } else {
                console.log('somthing went wrong');
            }
            
        } catch (err) {
            console.log(err)
        }
    }

    return (

        <>
            {
                isDialogOpen &&
                <Dialog
                    title={'Alert'}
                    modal={true}
                    height={500}
                    isDraggable={true}
                    onClose={handleClose}
                    buttons={
                        [{
                            text: "No",
                            className: 'btn-info btn-sm btn mr-2',
                            onClick: () => handleClose()
                        },
                        {
                            text: "Yes",
                            className: 'btn-danger btn-sm btn mr-2',
                            onClick: () => deleteWPTemplate()
                        },
                        ]
                    }>
                    <p> Are you sure want to delete this template </p>
                </Dialog>
            }

            <span onClick={() => openDialog()} className='badge bg-danger'> Delete</span>

        </>
    );
}

export default Delete;