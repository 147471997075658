import React, { Component } from 'react';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import AwsSlider from '../../Dashboard/aws/AwsSlider';
import { HOST_URL } from '../api/Api';
class AwsSetup extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            userId: userId,
            token: userToken,
            awsSettingShow: 1,
            awsStatus: '',
            awsSecretAccessKey: '',
            awsAccessKeyId: '',
            awsRegion: '',
            successMessage: false,
            successBg: ''
        }
    }

    componentDidMount() {
        this.GetingProfile();
        fetch(`${HOST_URL}/aws_setting.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ awsStatus: result.data[0].status });
                    this.setState({ awsAccessKeyId: result.data[0].access_key_id });
                    this.setState({ awsSecretAccessKey: result.data[0].secret_access_key });
                    this.setState({ awsRegion: result.data[0].region });
                    this.setState({ awsStatus: result.data[0].status });
                }

            })
        })
    }


    GetingProfile() {
        fetch(`${HOST_URL}/profile.php?user_id=${this.state.userId}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {

                    this.setState({
                        awsSettingShow: result.data[0].aws_setting,
                    })
                }
            })
        })
    }

    saveAwsSetting = (method) => {

        const awsSetup = {
            user_id: this.state.userId,
            method: method,
            token: this.state.token,
            access_key_id: this.state.awsAccessKeyId,
            secret_access_key: this.state.awsSecretAccessKey,
            region: this.state.awsRegion
        };
        fetch(`${HOST_URL}/aws_setting.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(awsSetup)
        }).then((result) => {

            result.json().then((resp) => {
                console.warn("resp", resp);
                if (resp.success === false) {
                    console.warn('message', resp.message);
                    this.setState({ successMessage: true })
                    this.setState({ successMessage: resp.message })
                    this.setState({ successBg: "alert alert-danger alert-dismissible" })
                } else {
                    this.setState({ successMessage: true })
                    this.setState({ successBg: "alert alert-success alert-dismissible" })
                    this.setState({ successMessage: resp.message })
                    this.setState({ template_name: "" })
                    this.setState({ content: "" })
                }

            })

        })

    }


    render() {

        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">

                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong>AWS </strong> Setup </h3>
                                </div>
                            </div>
                            <div className="">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Step By Step Setup Guide</h5>
                                    </div>
                                    <div className="card-body">

                                        <AwsSlider />

                                    </div>
                                </div>


                                <div className=" mt-2">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0">AWS Details</h5>
                                        </div>

                                        {this.state.awsSettingShow === 1 ?

                                            <div className="card-body">


                                                {
                                                    this.state.successMessage ?
                                                        <div className={this.state.successBg} role="alert">
                                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                                <span aria-hidden="true">×</span>
                                                            </button>
                                                            {this.state.successMessage}
                                                        </div>
                                                        :
                                                        null
                                                }



                                                {(this.state.awsStatus !== 3) ?
                                                    <form className="form-row">
                                                        <div className="mb-3 col-md-4">
                                                            <input onChange={(e) => { this.setState({ awsAccessKeyId: e.target.value }) }} value={this.state.awsAccessKeyId} type="text" placeholder="Access  Key ID" className="form-control" />
                                                        </div>
                                                        <div className="mb-3 col-md-4">
                                                            <input onChange={(e) => { this.setState({ awsSecretAccessKey: e.target.value }) }} value={this.state.awsSecretAccessKey} type="text" placeholder="Secret Access Key" className="form-control" />
                                                        </div>

                                                        <div className="mb-3 drop-down-icons col-md-4">
                                                            <select onChange={(e) => { this.setState({ awsRegion: e.target.value }) }} value={this.state.awsRegion} className="form-control">
                                                                <option value=""> Select Region </option>
                                                                <option value="us-east-1">us-east-1</option>
                                                                <option value="us-east-2">us-east-2</option>
                                                                <option value="us-west-1">us-west-1</option>
                                                                <option value="us-west-2">us-west-2</option>
                                                                <option value="af-south-1">af-south-1</option>
                                                                <option value="ap-east-1">ap-east-1</option>
                                                                <option value="ap-south-1">ap-south-1</option>
                                                                <option value="ap-northeast-3">ap-northeast-3</option>
                                                                <option value="ap-northeast-2">ap-northeast-2</option>
                                                                <option value="ap-southeast-1">ap-southeast-1</option>
                                                                <option value="ap-southeast-2">ap-southeast-2</option>
                                                                <option value="ap-northeast-1">ap-northeast-1</option>
                                                                <option value="ca-central-1">ca-central-1</option>
                                                                <option value="eu-central-1">eu-central-1</option>
                                                                <option value="eu-west-1">eu-west-1</option>
                                                                <option value="eu-west-2">eu-west-2</option>
                                                                <option value="eu-south-1">eu-south-1</option>
                                                                <option value="eu-west-3">eu-west-3</option>
                                                                <option value="eu-north-1">eu-north-1</option>
                                                                <option value="me-south-1">me-south-1</option>
                                                                <option value="sa-east-1">sa-east-1</option>

                                                            </select>

                                                        </div>


                                                        {this.state.awsStatus === '' ?
                                                            <button type="button" onClick={this.saveAwsSetting.bind(this, 'create')} className="btn btn-sm btn-primary">Save changes</button>
                                                            :
                                                            <button type="button" onClick={this.saveAwsSetting.bind(this, 'update')} className="btn btn-sm btn-primary">Update changes</button>
                                                        }


                                                    </form>
                                                    : <>

                                                        <p><strong>Access Key ID: </strong> {this.state.awsAccessKeyId}</p>

                                                        <p><strong> Secret Access Key: </strong> {this.state.awsSecretAccessKey}</p>

                                                        <p> <strong>Region: </strong> {this.state.awsRegion}</p>

                                                        <p> <strong>Status: </strong> {

                                                            this.state.awsStatus === 0 || this.state.awsStatus === 1 || this.state.awsStatus === 2 ?
                                                                <span>Pending</span>
                                                                : (this.state.awsStatus === 4 ? <span className="badge bg-danger" >Error Executing</span> :
                                                                    <span className="badge bg-success">
                                                                        Success
                                                                    </span>
                                                                )

                                                        }</p>
                                                    </>}
                                            </div>

                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default AwsSetup;