import React, {Component} from 'react';
import  { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor,{buttonList} from "suneditor-react";
import { HOST_URL } from '../api/Api';
class EmailTemplate extends Component {

	constructor(){

		const lStorage = JSON.parse(localStorage.getItem("login"));
		const userId= lStorage.user.userId;
		const userToken= lStorage.token;

		super();
			this.state={
				user_id:userId,
				token:userToken,
				template_name:null,
				verifiedEmailID:null,
				listOFverifyedEmail:true,
				varibaleInTemplate:[],
				from_email:null,
				subject:null,
				content:null,
				method:"create",
				successMessage:false,
				successBg:'',
				errorMessage:''
			}
			this.emailEditorRef= React.createRef();
			this.handleChange = this.handleChange.bind(this);

	}



	
componentDidMount() {
		this.GetverifiedEmailID();
		this.FatchEmailTemplate();
	}


getVaribale(){
    var str = this.state.content;
    var regex = /{#([^#]+)#}/g;
    let m;
    var tempArr = [];
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        tempArr.push(m[1]);
    }
    this.setState({varibaleInTemplate: tempArr});
}

handleChange(content){
	this.setState({content:content});
	this.getVaribale();
}



GetverifiedEmailID(){
    fetch(`${HOST_URL}/aws_from_email.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response)=> {
        response.json().then((result)=>{
            if(result.success===true){
                this.setState({verifiedEmailID:result});
            }else{
				this.setState({listOFverifyedEmail:true});
			}
           
        })
    })
}

CreateEmailTemplate(){

	// this.emailEditorRef.current.editor.exportHtml((data) => {
	// 	this.setState({content:data.html})
	// });
	
	document.querySelector('body').scrollTo(0, 0);
		fetch(`${HOST_URL}/email_template.php`,{
			method: "post",
			headers:{
				'content-Type':'application/json'
			},
			body: JSON.stringify(this.state)
		}).then((result)=>{

			result.json().then((resp)=>{
				// console.warn("resp",resp);
			if(resp.success===true){

				this.setState({
					successMessage:true,
					successBg:"alert alert-success alert-dismissible",
					errorMessage:resp.message
				})

				this.props.history.push('email-template-list');
			
			}else{
			
				this.setState({
					successBg:"alert alert-danger alert-dismissible",
					successMessage:true,
					errorMessage:resp.message})
		     	}

			})

		})

	}


FatchEmailTemplate(){
		fetch(`${HOST_URL}/email_template.php?user_id=${this.state.user_id}&id=${this.props.match.params.id}&method=retrieveid&token=${this.state.token}`).then((response) => {
			response.json().then((result) => {
				//console.log(result)
				if (result.success === true) {
					this.setState({ 
						template_name: result.data[0].template_name, 
						content: result.data[0].content,
						subject: result.data[0].subject,
						from_email: result.data[0].from_email,
					});
				}
			})
		})
	}


	UpdateEmailTemplate(){
		document.querySelector('body').scrollTo(0, 0);
		var bodyObject = {
				user_id:this.state.user_id,
				token:this.state.token,
				id:this.props.match.params.id,
				template_name:this.state.template_name,
				content:this.state.content,
				from_email:this.state.from_email,
				subject:this.state.subject,
				method:"update"
		};
		console.log('bodyObject',bodyObject)
		fetch(`${HOST_URL}/email_template.php`,{
			method: "post",
			headers:{
				'content-Type':'application/json'
			},
			body: JSON.stringify(bodyObject)
		}).then((result)=>{
			result.json().then((resp)=>{
			if(resp.success===false){
				this.setState({successMessage:true,errorMessage:resp.message,successBg:"alert alert-danger alert-dismissible"})
			}else{
				this.setState({successMessage:true,errorMessage:resp.message,successBg:"alert alert-success alert-dismissible"})
			}
	
			})
	
		})
	
	}
	


render() {
	return (
		<div className="wrapper">
			<DashboardLeftMenu />
			<div className="main">
				<DashboardHeader />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="row mb-2 mb-xl-3">
							<div className="col-auto d-none d-sm-block">
								<h3><strong>Create New Email </strong>  Template </h3>
							</div>
						</div>
						<div className="card">
									<div className="card-body  template-link">
						<div className="row">
							<div className="col-md-8">

							{
									this.state.successMessage? 
									<div className={this.state.successBg} role="alert">
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
						</button>
								{this.state.errorMessage}
									</div>
									:
									null
								}

							
										<form id="EmailTemplateForm">
											<div className="row justify-content-center">
												<div className="mb-3 col-md-12">
													<div className="text-left">
														<h3 className="font-weight-bold mb-4"> Email Template </h3>
													</div>

													{this.state.listOFverifyedEmail?
													<>
													<input type="text" defaultValue={this.state.template_name}  onChange={(event)=>
												{this.setState({template_name:event.target.value}) }} className="form-control" id="inputEmail4" placeholder="Enter Template Name" />

												<div className="input-group drop-down-icons   mt-3">			
													
												{
														
														this.state.verifiedEmailID?
			
														 <select defaultValue={this.state.from_email} onChange={(event)=>
												{this.setState({from_email:event.target.value}) }}  className="form-control">
														 <option value="">Select Sender Email</option>
														 {
														   this.state.verifiedEmailID.data.map((item,i)=>
															<option  key={i} value={item.email}> {item.email}</option>
														 )}
														 </select>
														 :
												    <div id="addTemplateBox">
													<label className="form-label"> You Don't have  Verified  <Link to="/dashboard/aws-email-verify"> Sender Email ID   </Link> <FontAwesomeIcon icon={faArrowRight} color=''/>  </label>
														</div>
												}

												</div>

													<div className="form-group mt-2">
													<input type="text" defaultValue={this.state.subject}  onChange={(event)=>
												{this.setState({subject:event.target.value}) }} className="form-control" id="inputEmail4" placeholder="Subject Messgae" />
													</div>

											
									


													<SunEditor setContents={this.state.content} height="300" onChange={this.handleChange} setOptions={{
													buttonList: buttonList.complex }}  
													placeholder="Please type here..." />

												
													{this.props.match.params.id?
														<button type="button" onClick={()=>{this.UpdateEmailTemplate()}}  className="btn btn-sm  mt-5 btn-primary">Update Template</button>
													:

													<button type="button" onClick={()=>{this.CreateEmailTemplate()}} className="btn btn-sm  mt-5 btn-primary">Create  Template</button>
												}

													</>
													:
													<div id="addTemplateBox">
													<label className="form-label"> <p>You don't have any verified Email ID, First verify email id to create a email template. Go to Confing Setting  <FontAwesomeIcon icon={faArrowRight} color=''/>  <Link to="/dashboard/aws-email-verify"> From Email ID </Link>  </p> </label>
														</div>
													}

												</div>
											</div>
										</form>
									</div>
							
							<div className="col-md-4">

								<div className="card mt-5">

									<p className="tooglebox">
										<Link to="" className="">
											Variables In Template
						            	</Link>
									</p>

									<div className="card-body">
										<form className="form-inline">
										
				
										{
											this.state.varibaleInTemplate.map((item,index) => 
											<div key={index} className="variable mr-1"><span>{item}</span></div>
										)}


										</form>

									</div>
								</div>
							</div>
						
					

					

							</div>
							</div>
						</div>
					</div>
				</main>
				<DashboardFooter />
			</div>
		</div>
	);
  }
}

export default EmailTemplate;