import React, { useState } from 'react';

function Alert({ children, type }) {

    const [show, setShow] = useState(true);
    
    return (
        <>
            { show &&
                <div className={'alert alert-dismissible '+ type} role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    
                    <div className="">
                        {children}
                    </div>
                </div>
            }
        </>
  
    );
}

export default Alert;