import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import payment from '../../Dashboard/assets/img/payment-page.png';
import DashboardLeftMenu from '../../Dashboard/DashboardLeftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
import Paypal from '../payment/Paypal'
import { HOST_URL } from '../api/Api';
class TransactionPayment extends Component {

    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const country_currency = lStorage.user.country_currency;
        const userToken = lStorage.token;
        super();
        this.state = {
            user_id: userId,
            token: userToken,
            amount: '',
            baseAmount: '',
            originalAmount: '',
            UserCountryCurrency: country_currency,
            convenienceFee: 0,
            successMessage: false,
            errorMessage: '',
            IndiaUser: true,
            ProfileError: false,
            showPayPal: true,
            bgColor: "alert alert-danger alert-dismissible"
        }
    }



    componentDidMount() {

        if (this.state.UserCountryCurrency === 'INR') {
            this.setState({ IndiaUser: true });
        } else {
            this.setState({ IndiaUser: false });
        }
    }


    MakePayment() {
        document.querySelector('body').scrollTo(0, 0);
        fetch(`${HOST_URL}/payment_gateway.php?user_id=${this.state.user_id}&method=recharge&token=${this.state.token}&base_amount=${this.state.baseAmount}&amount=${this.state.amount}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    var url = result.message;
                    window.location = url;
                }
                else if (result.message === 'IncompleteProfile') {
                    this.setState({ ProfileError: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ errorMessage: result.message });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });

                }
            })
        })
    }

    render() {
        console.log(this.state)
        return (
            <div className="wrapper">
                <DashboardLeftMenu />
                <div className="main">
                    <DashboardHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong> Account </strong> Recharge </h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="card">
                                    <div className="">
                                        <h5 className="card-title mt-4">
                                            <div className="col-auto ml-auto text-right mt-n1">
                                                <nav className="text-center" aria-label="breadcrumb">
                                                    <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                        <Link to="transaction-payment"> <li className="breadcrumb-item active "> Recharge </li> </Link>
                                                        <Link to="transaction-history"><li className="breadcrumb-item ">Transaction History </li> </Link>

                                                    </ol>
                                                </nav>
                                            </div>
                                        </h5>
                                    </div>

                                    <div className="card-body">

                                        {
                                            this.state.successMessage ?
                                                <div className={this.state.bgColor} role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="">
                                                        {this.state.errorMessage}
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            this.state.ProfileError ?
                                                <div className="alert alert-danger alert-dismissible" role="alert">
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
                                                    </button><div className="">You need to complete your profile before doing , First Payment <a href="/dashboard/my-profile">Profile Setting </a></div></div>
                                                : null
                                        }


                                        {this.state.IndiaUser ?


                                            <div className="filter-option mt-2 ">

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h6 className="card-subtitle text-muted">Recharge </h6>
                                                        <div className="input-group mt-1">
                                                            <div className="input-group-text"><FontAwesomeIcon icon={faWallet} /></div>
                                                            <input type="number" onChange={(event) => {
                                                                var sum = parseFloat(event.target.value);
                                                                var convFee = parseFloat(sum * 0.18);
                                                                var tc = sum + convFee;
                                                                this.setState({ amount: tc, convenienceFee: convFee, baseAmount: sum });

                                                            }
                                                            } placeholder="Min Amount 1000 ( Amount should be multiple of 1000)" className="form-control" />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-1 border-right"></div>
                                                    <div className="col-md-1 "></div>
                                                    <div className="col-md-4 text-md-left">

                                                        <div className="card-subtitle text-muted"> <small> </small>GST Charges : {this.state.convenienceFee}

                                                        </div>

                                                        Total Amount : <strong className=""> 
                                                            {
                                                                this.state.amount
                                                            }
                                                        </strong> INR
                                                        <p className="mt-1 mb-1">
                                                            <small>Convenience charges will be added *</small>
                                                        </p>
                                                        <div className="input-group mb-2 mr-sm-2">
                                                            <button onClick={() => this.MakePayment()} className="btn mt-1  btn-success btn-block btn-sm ml-auto text-center ">  Recharge </button>
                                                            <img alt={'payment'} className='img-fluid m-auto pt-2' src={payment} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-right">
                                                    </div>
                                                </div>
                                            </div>

                                            :
                                            <>
                                                <div className="filter-option mt-2">
                                                    <h6 className="card-subtitle text-muted">Recharge </h6>
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="input-group mb-2 mr-sm-2">
                                                                <div className="input-group-text"><FontAwesomeIcon icon={faWallet} /></div>
                                                                <input type="number" onChange={(event) => {
                                                                    var sum = parseFloat(event.target.value);
                                                                    var convFee = parseFloat(sum * 0.06);
                                                                    var tc = sum + convFee;
                                                                    this.setState({ amount: tc });
                                                                    this.setState({ originalAmount: event.target.value })
                                                                    this.setState({ convenienceFee: convFee });
                                                                }
                                                                } placeholder="Min Amount 100 EUR" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">

                                                            <div className="text-muted">
                                                                Convenience Fee : {this.state.convenienceFee}
                                                            </div>
                                                            Total Amount : <strong>   
                                                                {
                                                                    this.state.amount || 0
                                                                }
                                                            </strong> EUR
                                                        </div>
                                                        </div>
                                                            
                                                            <hr/>

                                                            {this.state.showPayPal ?

                                                                <div className='row mt-5 justify-content-center'>
                                                                <div className="input-group col-md-4 text-center  mb-2 mr-sm-2">
                                                                    <button onClick={() => {
                                                                        if (this.state.amount >= 100) {
                                                                            this.setState({ showPayPal: false });
                                                                        } else {

                                                                            this.setState({ successMessage: true, errorMessage: 'Amount should be minimum 100 EUR', bgColor: "alert alert-danger alert-dismissible" });
                                                                        }

                                                                    }} className="btn mt-1  btn-success btn-block btn-sm ml-auto text-center ">  Recharge </button>
                                                                    <img alt={'payment'} className='img-fluid m-auto pt-2' src={payment} />
                                                                
                                                                </div>
                                                                </div>
                                                                :
                                                                <div className="paythroughpaypal">

                                                                    <Paypal originalAmount={this.state.originalAmount} amount={this.state.amount} />

                                                                </div>
                                                            }
                                                        </div>
                                                  
                                            </>
                                        }
                                    </div>
                                
                                     
                                </div>
                            </div>
                        </div>
                    </main>
                    <DashboardFooter />
                </div>
            </div>
        );
    }
}

export default TransactionPayment;