import React, { Component } from 'react';
import Dialog from 'react-dialog';
import DashboardHeader from '../../Header/DashboardHeader';
import DashboardFooter from '../../Footer/DashboardFooter';
import DashboardLeftMenu from '../DashboardLeftMenu';
import EmptyImg from "../../Dashboard/assets/img/create-project.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { HOST_URL } from '../api/Api';

class CreateSenderID extends Component {


    constructor() {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();
        this.state = {
            user_id: userId,
            token: userToken,
            entity_id: '',
            method: 'create',
            sender_id: '',
            updateSender: '',
            deleteID: '',
            ListodSernderID: null,
            enitytIdList: null,
            errorMessage: '',
            isDialogOpen: false,
            successMessage: false,
            bgColor: "alert alert-success alert-dismissible"
        }
    }


    componentDidMount() {

        this.GetListOfEnlityId();
        this.GetListOfSenderId();
    }


    GetListOfEnlityId() {
        fetch(`${HOST_URL}/entityid.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                // console.log(result)
                if (result.success === true) {
                    this.setState({ enitytIdList: result });
                }

            })
        })
    }

    GetListOfSenderId() {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=retrieve&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                //  console.log(result)
                if (result.success === true) {
                    this.setState({ ListodSernderID: result });
                }

            })
        })
    }



    EditSenderID(id) {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=retrieveid&id=${id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
               // console.log(result)
                this.setState({
                    updateId: id,
                    updateSender: true,
                    entity_id: result.data[0].entity_id,
                    sender_id: result.data[0].sender_id,
                });
            })
        })
    }




    CreateSenderId() {

        var SenderData = {
            user_id: this.state.user_id,
            token: this.state.token,
            sender_id: this.state.sender_id,
            method: 'create',
            entity_id: this.state.entity_id,

        };

        fetch(`${HOST_URL}/senderid_request.php`, {
            method: "post",
            headers: {
                'content-Type': 'application/json'
            },
            body: JSON.stringify(SenderData)
        }).then((result) => {
            result.json().then((resp) => {
                if (resp.success === true) {
                    document.querySelector('body').scrollTo(0, 0);
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible" });
                    this.setState({ errorMessage: resp.message });
                    this.GetListOfSenderId();
                    document.getElementById('createSernderID').reset();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: resp.message });
                }
            })
        })
    }




    UpdateSenderID() {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=update&id=${this.state.updateId}&entity_id=${this.state.entity_id}&sender_id=${this.state.sender_id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                    document.getElementById("createSernderID").reset();
                    this.GetListOfSenderId();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })

    }



    DeleteSenderID(id) {
        fetch(`${HOST_URL}/senderid_request.php?user_id=${this.state.user_id}&method=delete&id=${id}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    this.setState({ successMessage: true, bgColor: "alert alert-success alert-dismissible", deleteID: '' });
                    this.setState({ errorMessage: result.message });
                    this.GetListOfSenderId();
                    this.handleClose();
                } else {
                    this.setState({ successMessage: true });
                    this.setState({ bgColor: "alert alert-danger alert-dismissible" });
                    this.setState({ errorMessage: result.message });
                }
            })
        })

    }


    openDialog = (id) => this.setState({ isDialogOpen: true, deleteID: id })

    handleClose = () => this.setState({ isDialogOpen: false, deleteID: '' })


    render() {
return (
<div className="wrapper">
<DashboardLeftMenu />
<div className="main">
<DashboardHeader />
<main className="content">

{
this.state.isDialogOpen &&

<Dialog
title="Alert"
modal={true}
onClose={this.handleClose}
buttons={
[{
text: "Close",
className: 'btn-info btn-sm btn mr-2',
onClick: () => this.handleClose()
},
{
text: "Yes",
className: 'btn btn-sm btn-danger',
onClick: () => this.DeleteSenderID(this.state.deleteID)
}

]


}>
<p>Are You Sure ? </p>
</Dialog>
}

<div className="container-fluid p-0">
<div className="row mb-2 mb-xl-3">
<div className="col-auto  d-sm-block">
<h3><strong>DLT </strong> Setup </h3>
</div>

</div>

<div className="card">

<div className="card-body">

<h5 className="card-title mb-4">Add  Sender ID</h5>
<hr />

<div className="row">


<div className="col-md-12">

{
this.state.successMessage ?
<div className={this.state.bgColor} role="alert">
    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
    <div className="">
        {this.state.errorMessage}
    </div>
</div>
:
null
}

<div className="">

<div className="">



<form className="form-row" id="createSernderID">
    <div className="mb-3 col-md-4">
        <select value={this.state.entity_id} onChange={(event) => this.setState({ entity_id: event.target.value })} className="form-control">

            {
                this.state.enitytIdList ?
                    <>
                        <option value="">Select Entity ID</option>
                        {
                            this.state.enitytIdList.data.map((item, i) =>
                                <option key={i} value={item.entity_id}>{item.entity_id}({item.company_name})</option>
                            )}
                    </>
                    :
                    <option>No Entity ID</option>
            }

        </select>
    </div>

    <div className="mb-3 col-md-4">
        <input type="text" defaultValue={this.state.sender_id} onChange={(event) => this.setState({ sender_id: event.target.value })} placeholder="Enter Sender ID" className="form-control" id="inputPasswordNew2" />
    </div>
    <div className="col-md-4">
        {
            this.state.updateSender ?
                <button type="button" onClick={() => this.UpdateSenderID()} className="btn btn-block btn-sm btn-success">Update Sender ID</button>
                :
                <button type="button" onClick={() => this.CreateSenderId()} className="btn btn-block btn-sm btn-success">Add Sender ID</button>
        }


    </div>


</form>

</div>
</div>
</div>
<div className="">
<div className="col-md-12">
<div className="">
<div className="card-body">
    <h5 className="card-title mb-4">List of sender IDs</h5>
    <div className="">
        <div className=" flex-fill">

            {this.state.ListodSernderID ?
                <div className="table-responsive">
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th className=" d-md-table-cell">Action</th>
                                <th className=" d-md-table-cell">Entity ID</th>
                                <th className=" d-md-table-cell">Sender ID</th>
                                <th className=" d-xl-table-cell">Added On Date / Time </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.state.ListodSernderID.data.map((item, i) =>
                                    <tr key={i}>
                                        <td>
                                            <span onClick={() => this.EditSenderID(item.id)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                            <span onClick={() => this.openDialog(item.id)} className="badge bg-danger">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </span>
                                        </td>
                                        <td className=" d-xl-table-cell">{item.entity_id}</td>
                                        <td className=" d-xl-table-cell">{item.sender_id}</td>
                                        <td className=" d-md-table-cell">{item.added_on}</td>

                                    </tr>
                                )}
                        </tbody>
                    </table> </div> :
                <div className="text-center">
                    <div className="card-body">
                        <img alt="Empty Data" className="project-empty-img" src={EmptyImg} />
                        <p>No Sender ID  Yet</p>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</main>
<DashboardFooter />
</div>
</div>
);
    }
}

export default CreateSenderID;