import React, { Component } from 'react';
import AdminHeader from '../../Header/AdminHeader';
import AdminFooter from '../../Footer/AdminFooter';
import AdminLeftMenu from '../../Navbar/AdminLeftMenu';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';

class AffiliatePayout extends Component {

    constructor() {

        const lStorage = userloginDetails();
        const userId = lStorage.user.userId;
        const userToken = lStorage.token;

        super();

        this.state = {
            user_id: userId,
            token: userToken,
            bankDetails: null,
            refrenceID: '',
            description: '',
            amount: '',
            fromAccount: {
                bank_name: 'ICICI Bank',
                person_name: 'Datagen Internet Services Pvt. Ltd',
                account_no: 1212121332113,
                ifsc_code: 'ICICI3211',

            },
            successMessage: false,
            errorMessage: '',
            bgColor: '',
        }
    }

    componentDidMount() {
        this.getAffliatebankDetails();
    }


    getAffliatebankDetails() {
        fetch(`${HOST_URL}/affiliate.php?user_id=${this.state.user_id}&method=bank_list&token=${this.state.token}&aff_id=${this.props.match.params.id}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({
                        bankDetails: result.data[0]
                    })
                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }



    sendPayOut() {

        document.querySelector('body').scrollTo(0, 0);

        fetch(`${HOST_URL}/employee_user_list.php?user_id=${this.state.user_id}&method=transfer_amount&aff_id=${this.props.match.params.id}&type=admin&recharge_amount=${this.state.amount}&bank_ref_id=${this.state.refrenceID}&from_account=${this.state.fromAccount.account_no}&to_account=${this.state.bankDetails.account_no}&description=${this.state.description}&token=${this.state.token}`).then((response) => {
            response.json().then((result) => {
                console.log(result)
                if (result.success === true) {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-success alert-dismissible",
                        errorMessage: result.message
                    })

                    setTimeout(() => {
                        window.location = '/admin/affilite-commision-hostory';
                    }, 2000);

                } else {
                    this.setState({
                        successMessage: true,
                        bgColor: "alert alert-danger alert-dismissible",
                        errorMessage: result.message
                    })
                }
            })
        })
    }


    render() {
        return (
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                                <div className="col-auto mb-2 d-none d-sm-block">
                                    <h3><strong>Affiliate </strong> Payout </h3>
                                </div>

                                {
                                    this.state.successMessage ?
                                        <div className={this.state.bgColor} role="alert">
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="">
                                                {this.state.errorMessage}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div className="row">
                                <div className="card">


                                    <div className="card-body">


                                        <div className="row">
                                            <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                                <div className="flex-fill">
                                                    Payout Amount :  <span className='text-success'> {this.props.match.params.username} </span> <span className='text-primary'> INR {this.props.match.params.amount}  </span>
                                                    <hr className='mt-3' />
                                                    <form>

                                                        <div className="row">
                                                            <div className="mb-3 font-weight-bold col-md-6">
                                                                <label className="form-label" htmlFor="inputFirstName">From  Bank</label>
                                                                <div className='input-group drop-down-icons mb-2'>
                                                                    <select className='form-control'>
                                                                        <option>Select Bank</option>
                                                                    </select>
                                                                </div>
                                                                {this.state.fromAccount ?


                                                                    <ul className="list-group mt-3">
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Bank Name :
                                                                            <span className='blank'>{this.state.fromAccount.bank_name || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Account Holder Name :
                                                                            <span className='blank'>{this.state.fromAccount.person_name || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Account No :
                                                                            <span className='blank'>{this.state.fromAccount.account_no || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            IFSC Code
                                                                            <span className='blank'>{this.state.fromAccount.ifsc_code || 'NA'}</span>
                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    null
                                                                }

                                                            </div>
                                                            <div className="mb-3 font-weight-bold col-md-6">

                                                                <label className="form-label" htmlFor="inputFirstName">To  Bank</label>
                                                                <div className='input-group drop-down-icons mb-2'>
                                                                    <select className='form-control'>
                                                                        <option>Select Bank</option>
                                                                    </select>
                                                                </div>

                                                                {this.state.bankDetails ?


                                                                    <ul className="list-group mt-3">
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Bank Name :
                                                                            <span className='blank'>{this.state.bankDetails.bank_name || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Account Holder Name :
                                                                            <span className='blank'>{this.state.bankDetails.person_name || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            Account No :
                                                                            <span className='blank'>{this.state.bankDetails.account_no || 'NA'}</span>
                                                                        </li>
                                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                            IFSC Code
                                                                            <span className='blank'>{this.state.bankDetails.ifsc_code || 'NA'}</span>
                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    null
                                                                }


                                                            </div>
                                                        </div>

                                                        <div className='shadow border p-3 '>
                                                            <div className="row">
                                                                <div className="mb-3 col-md-12">
                                                                    <label className="form-label" htmlFor="inputFirstName">Enter Ammount to Transfer to the Affiliate</label>
                                                                    <input type="text" onChange={(event) => this.setState({ amount: event.target.value })} className="form-control" placeholder="Enter Ammount" />
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="inputFirstName">Enter Reference ID </label>
                                                                    <input type="text" onChange={(event) => this.setState({ refrenceID: event.target.value })} className="form-control" placeholder="Reference ID" />
                                                                </div>

                                                                <div className="mb-3 col-md-6">
                                                                    <label className="form-label" htmlFor="inputFirstName">Enter Description </label>
                                                                    <input type="text" onChange={(event) => this.setState({ description: event.target.value })} className="form-control" placeholder="description" />
                                                                </div>


                                                                <div className="mb-3 col-md-12">
                                                                    <div className="float-right">
                                                                        <input onClick={() => this.sendPayOut()} type="button" value="Send Payout" className="btn btn-sm  btn-primary" />
                                                                    </div>
                                                                </div>

                                                            </div>






                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        );
    }
}

export default AffiliatePayout;