import React from "react";

const ChooseCampaign = () => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Choose Campaign Type
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body justify-content-center d-flex ">
              <a className="mr-5" href="/dashboard/campaign-start">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  WhatsApp
                </button>
              </a>
              <a href="/dashboard/sms-campaign-start">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  SMS
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseCampaign;
