import React, { Component } from 'react';
import {CAMPAIGN_CONTACT_LIST_API} from '../../../constants/ServiceConstants'
import api from '../../../axios/Axios';
import DashboardLayout from '../../UI/Layout/DashboardLayout';
import Title from '../../UI/Text/Title';
import AdvanceDataTable from '../../UI/Table/AdvanceDataTable';
import { CAMPAIGN_CONTACT_DATA } from '../../UI/Table/ColumnConst'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
class ViewContactList extends Component {

    constructor(props) {

        const lStorage = JSON.parse(localStorage.getItem("login"));
        const user_id = lStorage.user.userId;
        const token = lStorage.token;
        super(props);

        this.state = {
            userId: user_id,
            token: token,
            ContactListData: [],
            filterContact:'',
            setIsLoading: true,
            ErrorResponse: '',
            id:this.props.match.params.id,
        }
    }

    componentDidMount() {
        this.getContactList();
    }
 
    getContactList = async () => {
        try {
          const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
            token: this.state.token,
            user_id: this.state.userId,
            list_id: this.state.id,
            method: 'retrieve_data',
          });
           console.log(data);
          if (data.success === true) {
            this.setState({ContactListData : data.data ,setIsLoading: false });

          }else{
            this.setState({ ErrorResponse: data, setIsLoading: false });
          }
        } catch (err) {
            console.log(err)
        }
    };
 
    filterData = async () => {
        this.setState({isError:false})
        try {
          const { data } = await api.post(CAMPAIGN_CONTACT_LIST_API, {
            token: this.state.token,
            user_id: this.state.userId,
            method: "filter",
            list_id: this.state.id,
            mobile:this.state.filterContact
          });
          console.log(data);
          if (data.success === true) {
            this.setState({ ContactListData: data.data, setIsLoading: false, searchDataTable:true});
          } else {
            this.setState({ errorMessage: data.message, isError:true, messageType:'alert-danger', setIsLoading: false });
          }
        } catch (err) {
          console.log(err);
        }
      };
    
      resetFilter = () => {
        this.getContactList();
        this.setState({
          searchDataTable:false,
          filterContact:'',
        })
      }

    render() {
        return (
            <DashboardLayout>
                <main className="content">
                    <div className="container-fluid p-0">
                        
                    <Title isButton={true} btnName={`Back`} actionURL={`/dashboard/contact-list`} >  Contact List Data {this.state.id} </Title>

                    <div className="card">
                        <div className="filter-option">
                        <h6 className="card-subtitle text-muted">Filter </h6>
                        <div className="row">
                            <div className="col-md-8">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-text">
                                <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <input
                                type="text"
                                onChange={(event) => {
                                    this.setState({ filterContact: event.target.value });
                                }}
                                value={this.state.filterContact}
                                placeholder="Enter mobile number"
                                className="form-control"
                                />
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="input-group mb-2 mr-sm-2">
                                <input
                                type="button"
                                onClick={() => this.filterData()}
                                value="Search"
                                className="btn mt-1  btn-primary btn-sm btn-block ml-auto "
                                />
                            </div>
                            </div>
                            <div className="col-md-2">
                            <div className="input-group mb-2 mr-sm-2">
                            <button onClick={this.resetFilter} className="btn mt-1  btn-danger btn-sm btn-block ml-auto ">Reset Filter</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                        <AdvanceDataTable
                            tableName={'List Data'}
                            tableCell={CAMPAIGN_CONTACT_DATA}
                            tableData={this.state.ContactListData}
                            isLoading={this.state.setIsLoading}
                            isError={this.state.ErrorResponse}
                        />
                    </div>
                </main>
            </DashboardLayout>
        );
    }
}

export default ViewContactList;