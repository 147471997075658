import React, { useEffect, useState } from "react";
import { HOST_URL } from "../api/Api";

const EmbededSignupPinnacle = () => {
  const [accessToken, setAccessToken] = useState();

  const id = 8702;
  const username = "kundanpd.189@gmail.com";
  const token = "ce4d8ae944edbc539d0b29dad0b3a3e4";


  useEffect(()=>{
    window.fbAsyncInit = function() {
      window.FB.init({
          cookie: true, 
          xfbml: true, 
          version: 'v20.0' 
      });
  };

  (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  },[])
  //   window.FB.init({
  //     appId: "675419764769671", // Facebook App ID
  //     cookie: true, // Enable cookies
  //     xfbml: true, // Parse social plugins on this page
  //     version: "v20.0", // Graph API version
  //   });
  //   console.log("Facebook SDK Initialized");
  // };

  // // Load the JavaScript SDK asynchronously
  // (function (d, s, id) {
  //   let js,
  //     fjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) return;
  //   js = d.createElement(s);
  //   js.id = id;
  //   js.src = "https://connect.facebook.net/en_US/sdk.js";
  //   fjs.parentNode.insertBefore(js, fjs);
  // })(document, "script", "facebook-jssdk");

  // Function to handle the session info messages

  // Add event listener for session info messages

  const sessionInfoListener = (event) => {
    if (event.origin == null) {
        return;
    }


    if (!event.origin.endsWith("facebook.com")) {
        return;
    }
    //
    try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
            if (data.event === 'FINISH') {
                const {
                    phone_number_id,
                    waba_id
                } = data.data;
                console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ",
                    waba_id);
            }
            else if (data.event === 'ERROR') {
                const {
                    error_message
                } = data.data;
                console.error("error ", error_message);
            }
            else {
                const {
                    current_step
                } = data.data;
                console.warn("Cancel at ", current_step);
            }
        }
    } catch (err) {

    }
};
function launchWhatsAppSignup() {

  window.FB.login(function(response) {
      if (response.authResponse) {
          const code = response.authResponse.code;
          console.log(code);
      } else {
          console.log('User cancelled login or did not fully authorize.');
      }
  }, {
      "scope": "business_management, whatsapp_business_management, whatsapp_business_messaging",
      "config_id": "868705554904803", 
      "response_type": "code", 
      "override_default_response_type": true, 

      "extras": {
          "feature": "whatsapp_embedded_signup",
          "sessionInfoVersion": 2,
          "setup": {
              "solutionID": "1627222894734353"
          }
      }
  }, window.addEventListener('message', sessionInfoListener));
}

  return (
    <div>
      <button onClick={launchWhatsAppSignup}>loginWithFacebook</button>
    </div>
  );
};

export default EmbededSignupPinnacle;
