import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Spining from "../../../UI/Spining/Spining";
import { HOST_URL } from "../../api/Api";
function Header(props) {
  let receiveHeadeData = props.receiveHeadeData;

  let hdData = receiveHeadeData.text;

  let paramid = props.paramid;
  const [isLoading, setIsLoading] = useState(false);
  const [sampleValue, setSampleValue] = useState("");
  const [inputValuee, setInputValuee] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [replacementText, setReplacementText] = useState("");
  const [specialUser, setSpecialUser] = useState();
  const [img, setImg] = useState(null);
  const [preFile, setPreFile] = useState(null);

  const [isChecked, setisChecked] = useState(false);
  const [mediaType, setmediaType] = useState("");
  const [mediaOptionSelected, setmediaOptionSelected] = useState("");
  const [userInputMediaData, setUserInputMediaData] =
    useState(receiveHeadeData);
  const [charCount, setCharCount] = useState(0);
  const limit = 60;
  const inputRef = useRef(null);

  // console.log("userInputMediaData", userInputMediaData);
  useEffect(() => {
    if (paramid) {
      setisChecked(true);
    }
  }, [paramid]);
  useEffect(() => {
    if (props.type === "Media") {
      setisChecked(true);
    }else{
      setisChecked(false);
    }
  }, [props.type]);
  useEffect(() => {
    if (props.type) {
      setmediaType(props.type);
      setmediaOptionSelected(receiveHeadeData.TemplateType);
    }
  }, [props.type]);

  useEffect(() => {
    setButtonValue();
  }, [userInputMediaData]);

  useEffect(() => {
    if (props.userId) {
      axios
        .get(
          `${HOST_URL}/get_user_route.php?user_id=${props.userId}&method=retrieve&token=${props.token}`
        )
        .then((res) => {
          if (res.data.success === true) {
            setSpecialUser(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (hdData && paramid) {
      setIsVisible(true);
    }
  });

  const setButtonValue = () => {
    props.sendHeadeData(userInputMediaData);
  };

  const changeHandler = (e) => {
    // localStorage.removeItem("preImage")
    let previous = { ...receiveHeadeData };

    if (e.target.name === "docName") {
      previous["docName"] = e.target.value;
    }

    if (e.target.name === "text") {
      const newValue = e.target.value;
      if (newValue.length <= limit) {
        setCharCount(newValue.length);
        previous["text"] = e.target.value;

        setInputValuee(e.target.value);
        previous.receiveHeadePrev = e.target.value;
      }
    }

    if (e.target.name === "document") {
      previous["document"] = e.target.value;
    }

    if (e.target.name === "documentfile") {
      const selectedFile = e.target.files[0];
      const fileSizeLimit = 10485760; // 10 MB in bytes

      if (selectedFile && selectedFile.size > fileSizeLimit) {
        previous["errMssg"] = "File size exceeds the limit (10MB)";
      } else {
        setImg(e.target.files[0]);
      }

      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreFile(reader.result);
          // previous["tempImage"] = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]); // Use `readAsText` for text files
      } else {
        setPreFile(null);
      }
    }

    if (e.target.name === "image") {
      previous["image"] = e.target.value;
    }

    if (e.target.name === "imagefile") {
      const selectedFile = e.target.files[0];
      const fileSizeLimit = 10485760; // 10 MB in bytes

      if (selectedFile && selectedFile.size > fileSizeLimit) {
        previous["errMssg"] = "File size exceeds the limit (10MB)";
      } else {
        setImg(e.target.files[0]);
      }

      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreFile(reader.result);
          // previous["tempImage"] = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]); // Use `readAsText` for text files
      } else {
        setPreFile(null);
      }
    }
    if (e.target.name === "videofile") {
      const selectedFile = e.target.files[0];
      const fileSizeLimit = 31457280; // 30 MB in bytes

      if (selectedFile && selectedFile.size > fileSizeLimit) {
        previous["errMssg"] = "File size exceeds the limit (10MB)";
      } else {
        setImg(e.target.files[0]);
      }

      if (e.target.files[0]) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreFile(reader.result);
          // previous["tempImage"] = reader.result;
        };
        reader.readAsDataURL(e.target.files[0]); // Use `readAsText` for text files
      } else {
        setPreFile(null);
      }
    }

    if (e.target.name === "video") {
      previous["video"] = e.target.value;
    }

    previous["TemplateType"] = mediaOptionSelected;

    setUserInputMediaData(previous);
  };

  const headehandler = (event) => {
    event.target.checked ? setisChecked(true) : setisChecked(false);
  };

  const meidaTypeHandler = (e) => {
    setUserInputMediaData("");
    setmediaOptionSelected(e.target.value);
  };

  const insertVariable = async (e) => {
    // e.preventDefault();
    let previous = { ...receiveHeadeData };

    let addValue = " {{1}}";
    let pvalue = inputValuee;
    var newval = pvalue + addValue;

    previous["text"] = newval;

    // let newtxt=JSON.stringify(previous.text)
    if (inputValuee || previous.receiveHeadePrev) {
      setInputValuee(inputValuee + "{{1}}");
      previous.receiveHeadePrev = newval;
    } else {
      setInputValuee("{{1}}");
      previous.receiveHeadePrev = "{{1}}";
    }

    setIsVisible(true);
    setUserInputMediaData(previous);
    // }

    inputRef.current.focus();
  };

  const SampleDataHandler = (e) => {
    // e.preventDefault();

    let previous = { ...receiveHeadeData };
    setSampleValue(e.target.value);

    let searchName = "{{1}}";
    previous.receiveHeadePrev = inputValuee;

    setSearchQuery(searchName);
    setReplacementText(e.target.value);
    let newmsg;
    if (paramid) {
      newmsg = previous.text.replace("{{1}}", " " + e.target.value);
    } else {
      newmsg = previous.receiveHeadePrev.replace("{{1}}", " " + e.target.value);
    }

    previous.receiveHeadePrev = newmsg;
    setUserInputMediaData(previous);
  };
  const handleUpload = async (e) => {
    let previous = { ...receiveHeadeData };
    setIsLoading(true);
    const formData = new FormData();
    formData.append("amdfile", img);
    formData.append("doc_name", "image");
    formData.append("doc_type", "image");
    formData.append("mediaType", "WhatsappMediaType");
    formData.append("user_id", props.userId);
    formData.append("token", props.token);
    formData.append("method", "create");

    await axios
      .post(`${HOST_URL}/uploadFileWhatsapp.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          previous["headerUploadMedia"] =
            response.data.airtel_file_handelid.fileHandle;

          previous["image"] = response.data.url;

          setUserInputMediaData(previous);
        }
      });
    setUserInputMediaData(previous);
    setIsLoading(false);
  };

  const handleUploadVideo = async (e) => {
    let previous = { ...receiveHeadeData };
    setIsLoading(true);
    const formData = new FormData();
    formData.append("amdfile", img);
    formData.append("doc_name", "video");
    formData.append("doc_type", "video");
    formData.append("mediaType", "WhatsappMediaType");
    formData.append("user_id", props.userId);
    formData.append("token", props.token);
    formData.append("method", "create");

    await axios
      .post(`${HOST_URL}/uploadFileWhatsapp.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          previous["headerUploadMedia"] =
            response.data.airtel_file_handelid.fileHandle;
          // console.log(response.data.url);
          previous["video"] = response.data.url;

          setUserInputMediaData(previous);
        }
      });
    setUserInputMediaData(previous);
    setIsLoading(false);
  };

  const handleUploadDocument = async (e) => {
    let previous = { ...receiveHeadeData };
    setIsLoading(true);
    const formData = new FormData();
    formData.append("amdfile", img);
    formData.append("doc_name", "document");
    formData.append("doc_type", "document");
    formData.append("mediaType", "WhatsappMediaType");
    formData.append("user_id", props.userId);
    formData.append("token", props.token);
    formData.append("method", "create");

    await axios
      .post(`${HOST_URL}/uploadFileWhatsapp.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          previous["headerUploadMedia"] =
            response.data.airtel_file_handelid.fileHandle;
          // console.log(response.data.url);
          previous["document"] = response.data.url;

          setUserInputMediaData(previous);
        }
      });
    setUserInputMediaData(previous);
    setIsLoading(false);
  };

  return (
    <div className="whatsapp-box">
      {isLoading && <Spining />}
      <div className="whatsapp-options">
        <label>
          <h4>Header {props.type==="Media"?<span className="text-danger">*</span>:null}</h4>
          <p>Add a title text or select media type for header.</p>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            onChange={headehandler}
            checked={isChecked === true ? "checked" : null}
          />
          <span className="slider round"></span>
        </label>
      </div>

      {isChecked && (
        <div className="">
          {mediaType === "Media" ? (
            <>
              <div className="input-group drop-down-icons mb-2">
                <select
                  value={mediaOptionSelected}
                  onChange={meidaTypeHandler}
                  className="form-control"
                  disabled={receiveHeadeData ? true : false}
                >
                  <option value={""}>Select Media</option>
                  <option value={"image"}>Image</option>
                  <option value={"video"}>Video</option>
                  <option value={"document"}>Document</option>
                </select>
              </div>

              {mediaOptionSelected === "image" ? (
                <>
                  {specialUser === 34 ? (
                    <div className="d-flex flex-row">
                      <input
                        type="file"
                        className="form-control"
                        name="imagefile"
                        accept="image/*"
                        // defaultValue={receiveHeadeData.image}
                        onChange={changeHandler}
                        disabled={paramid ? true : false}
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleUpload}
                        disabled={paramid ? true : false}
                      >
                        Upload
                      </button>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name="image"
                      defaultValue={receiveHeadeData.image}
                      onChange={changeHandler}
                      placeholder="Image Enter URL"
                    />
                  )}
                </>
              ) : mediaOptionSelected === "video" ? (
                <>
                  {specialUser === 34 ? (
                    <div className="d-flex flex-row">
                      <input
                        type="file"
                        className="form-control"
                        name="videofile"
                        accept="video/*"
                        // defaultValue={receiveHeadeData.video}
                        onChange={changeHandler}
                        disabled={paramid ? true : false}
                      />
                      <button
                        className="btn btn-sm btn-primary"
                        disabled={paramid ? true : false}
                        onClick={handleUploadVideo}
                      >
                        Upload
                      </button>
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      name="video"
                      defaultValue={receiveHeadeData.video}
                      onChange={changeHandler}
                      placeholder="Video Enter URL"
                    />
                  )}
                </>
              ) : mediaOptionSelected === "document" ? (
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="docName"
                      defaultValue={receiveHeadeData.docName}
                      onChange={changeHandler}
                      placeholder="Document Name"
                    />
                  </div>
                  <div className="col-md-6">
                    <>
                      {specialUser === 34 ? (
                        <div className="d-flex flex-row">
                          <input
                            type="file"
                            className="form-control"
                            name="documentfile"
                            disabled={paramid ? true : false}
                            onChange={changeHandler}
                          />
                          <button
                            className="btn btn-sm btn-primary"
                            disabled={paramid ? true : false}
                            onClick={handleUploadDocument}
                          >
                            Upload
                          </button>
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="document"
                          defaultValue={receiveHeadeData.document}
                          onChange={changeHandler}
                          placeholder="Document URL"
                        />
                      )}
                    </>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              <input
                type="text"
                name="text"
                ref={inputRef}
                // defaultValue={receiveHeadeData.text}
                // value={inputValuee}
                value={receiveHeadeData.text}
                maxLength={60}
                onChange={changeHandler}
                className="form-control"
                placeholder="Enter Header Text"
              />
              <div div class="d-flex flex-column float-right">
                <div className="float-right p-1">
                  <button
                    onClick={insertVariable}
                    disabled={isVisible}
                    className="btn btn-sm btn-primary"
                  >
                    Add Varibale
                  </button>
                </div>

                <div className="float-right" style={{ fontSize: "13px" }}>
                  Character : {charCount}/{limit}
                </div>
              </div>

              {isVisible && (
                <div>
                  <p className="m-0">Please Add Sample Data of variables </p>
                  <div className="input-group mb-2 mr-sm-2">
                    <div className="input-group-text">{`{{1}}`}</div>
                    <input
                      type="text"
                      value={sampleValue}
                      onChange={SampleDataHandler}
                      placeholder={`Sample Data of {{1}}`}
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
